import { FC } from 'react';

import { ICardStatValuesProps } from './types';
import { StatData } from './StatData';

export const CardStatValues: FC<ICardStatValuesProps> = (props) => {
  const { values, size = 'lg' } = props;

  if (values?.length <= 3) {
    return (
      <div className='grid grid-cols-1 grid-rows-2'>
        <StatData label={values[0].label} value={values[0].value} percent={values[0].percent} percentType={values[0].percentType} size={size} isHaveBorder />
        <div className='grid grid-cols-2 grid-rows-1 gap-x-32'>
          {values.slice(1, 3).map((item) => (
            <StatData key={item.label} label={item.label} value={item.value} percent={item.percent} percentType={item.percentType} size={size} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className='grid grid-cols-2 grid-rows-2 gap-x-32'>
      {values.map((item) => (
        <StatData
          key={item.label}
          label={item.label}
          value={item.value}
          percent={item.percent}
          percentType={item.percentType}
          isHaveBorder={values.indexOf(item) < 2}
          size={size}
        />
      ))}
    </div>
  );
};
