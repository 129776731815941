import { useFormContext } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';

import { SubmitButton } from 'src/presentations/components/atom/button/SubmitButton';
import IconCheckCircle from 'src/presentations/components/atom/icons/CheckCircle';
import { ModalFooterProps, NoContractForm, NoContractStep } from './types';

export const NoContractFooter = (props: ModalFooterProps) => {
  const { activeStep, onSaveDraft, onSuccessSubmit, onGoBack, onConfirm, onClose } = props;
  const formContext = useFormContext<NoContractForm>();
  const isStep1And2 = activeStep === NoContractStep.Step2_Resolution || activeStep === NoContractStep.Step1_Select;

  const { watch } = formContext;
  const { contractChoice, verdict } = watch();

  const isStep1Done = verdict && verdict !== 'accept' ? true : !!contractChoice;

  return (
    <div className='mt-auto px-24 py-20 border-t border-neutral-20 flex items-center'>
      <div className='flex items-center gap-16'>
        <MuiButton type='button' variant='outlined' color='inherit' onClick={onClose} sx={{ textTransform: 'capitalize' }}>
          Close
        </MuiButton>
        {activeStep > NoContractStep.Step1_Select && (
          <MuiButton type='button' variant='outlined' color='inherit' onClick={onGoBack} sx={{ textTransform: 'capitalize' }}>
            Back
          </MuiButton>
        )}
      </div>
      <div className='ml-auto pl-12 flex items-center gap-16'>
        <SubmitButton
          variant='outlined'
          color='inherit'
          onClick={formContext.handleSubmit(onSaveDraft)}
          sx={(theme) => ({
            ':disabled': {
              color: theme.palette.common.black,
              background: theme.palette.background.default,
            },
            textTransform: 'capitalize',
          })}
        >
          Save Draft & Close
        </SubmitButton>
        {isStep1And2 && (
          <SubmitButton
            disabled={!isStep1Done}
            variant='contained'
            color='primary'
            onClick={formContext.handleSubmit(onSuccessSubmit)}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Next Step
          </SubmitButton>
        )}
        {!isStep1And2 && (
          <SubmitButton
            variant='contained'
            color={verdict === 'reject-reject' ? 'error' : 'primary'}
            onClick={formContext.handleSubmit(onConfirm)}
            endIcon={verdict !== 'reject-reject' ? <IconCheckCircle color='white' size={20} /> : null}
            sx={{ textTransform: 'capitalize' }}
          >
            {verdict === 'reject-reject' ? 'Reject' : 'Confirm'}
          </SubmitButton>
        )}
      </div>
    </div>
  );
};
