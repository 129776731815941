import { FC, useCallback, useState } from 'react';

import IconArrow from 'src/assets/images/icons/arrow-left_ico.svg';
import { cn } from 'src/utils/utility';
import IconTrends from '../icons/PresentionChart';
import IconArrows from '../icons/ProgrammingArrows';
import IconRouting from '../icons/Routing';
import IconBuilding from '../icons/Building2';
import { SidebarButtonNav } from './ButtonNav';
import { SidebarProps, MenuItemProps, ChartViewType } from './types';

const menuItems: MenuItemProps[] = [
  {
    name: ChartViewType.TREND,
    title: 'Trends',
    icon: IconTrends,
    submenus: null,
  },
  {
    name: ChartViewType.TRADE_LANE,
    title: 'Correlations',
    icon: IconArrows,
    submenus: [
      {
        name: ChartViewType.TRADE_LANE,
        title: 'Trade Lane',
        icon: IconRouting,
        submenus: null,
      },
      {
        name: ChartViewType.SERVICE_PROVIDER,
        title: 'Shipment Details',
        icon: IconBuilding,
        submenus: null,
      },
    ],
  },
];

export const AnalyticSidebar: FC<SidebarProps> = ({ selected, onChange }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const checkIsParentActive = useCallback(
    (item: MenuItemProps) => {
      if (item?.submenus) {
        return !!item?.submenus.find((submenu) => submenu.name === selected);
      }
      return false;
    },
    [selected]
  );

  return (
    <div className={cn('overflow-hidden transition-all duration-500', isCollapsed ? 'w-[4.875rem]' : 'w-[16rem]')}>
      <div className='flex flex-col'>
        <div className='flex items-center h-[4rem] border-b border-neutral-20 px-20'>
          <h3
            className={cn(
              'flex-1 text-14 text-neutral-50 font-medium overflow-hidden transition-all duration-200',
              isCollapsed ? 'invisible opacity-0 w-0' : 'delay-500'
            )}
          >
            CHART VIEW
          </h3>
          <button
            type='button'
            aria-label='collapse button'
            className='ml-auto w-32 h-32 rounded-8 bg-white border border-neutral-20 flex items-center justify-center'
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <img src={IconArrow} alt='' className={cn('transition-all duration-500', isCollapsed && 'rotate-180')} />
          </button>
        </div>
        <div className='border-t border-white py-16 px-12'>
          <div className='flex flex-col gap-2'>
            {menuItems.map((item) => {
              const isParentActive = checkIsParentActive(item);
              return (
                <SidebarButtonNav
                  key={`menu-${item?.name}`}
                  title={item?.title}
                  IconComponent={item?.icon}
                  isCollapsed={isCollapsed}
                  onClick={() => onChange(item?.name)}
                  isParentActive={isParentActive}
                  isActive={!isParentActive && selected === item?.name}
                  className={cn('p-4 rounded-12', item?.submenus && isCollapsed && 'bg-white border border-neutral-20')}
                >
                  {item?.submenus && (
                    <div className={cn('pt-8 transition-all duration-500', !isCollapsed && 'pl-22')}>
                      {item?.submenus.map((submenu) => (
                        <SidebarButtonNav
                          key={`menu-${submenu?.name}`}
                          title={submenu?.title}
                          IconComponent={submenu?.icon}
                          isCollapsed={isCollapsed}
                          isActive={selected === submenu?.name}
                          onClick={() => onChange(submenu?.name)}
                          className={cn(
                            'relative',
                            !isCollapsed &&
                              'pl-22 after:absolute after:top-0 after:bottom-0 last:after:bottom-auto last:after:h-1/2 after:left-0 after:w-1 after:bg-neutral-20 before:absolute before:left-0 before:top-1/2 before:w-18 before:h-1 before:bg-neutral-20'
                          )}
                        />
                      ))}
                    </div>
                  )}
                </SidebarButtonNav>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
