import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAdornment from '@mui/material/InputAdornment';

import { useFieldMaterialUIStyles } from 'src/presentations/components/molecules/fields/useFieldMaterialUIStyles';

export interface ITableFieldNumberProps {
  name: string;
  defaultValue: string;
  startAdornment?: string;
  endAdornment?: string;
  onAccept?(value: number): void;
  onCancel(): void;
}

export const TableFieldNumber: React.FC<ITableFieldNumberProps> = (props) => {
  const { name, defaultValue, startAdornment, endAdornment, onAccept, onCancel } = props;
  const [numberValue, setNumberValue] = useState<string>(defaultValue);

  const { control } = useFormContext();
  const { classes, cx } = useFieldMaterialUIStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: onBaseChange, value: rhfValue, ...otherFields } = field;

        return (
          <div className='flex items-center gap-20'>
            <div className='relative'>
              <TextField
                name={name}
                value={numberValue}
                onChange={(event) => {
                  setNumberValue(event?.target?.value);
                }}
                fullWidth
                className={cx(classes.shortWidth, classes.inputField, classes.shortSpace)}
                inputProps={{
                  inputMode: 'decimal',
                  min: '0',
                  step: '.1',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: startAdornment ? <InputAdornment position='start'>{startAdornment}</InputAdornment> : null,
                  endAdornment: endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : null,
                }}
                type='number'
                {...otherFields}
              />
            </div>

            <div className='flex items-center gap-8'>
              <button type='button' onClick={onCancel} aria-label='Cancel'>
                <CancelIcon sx={{ color: '#aaaaaa' }} />
              </button>
              <button
                type='button'
                aria-label='accept'
                onClick={() => {
                  onBaseChange(numberValue);
                  if (onAccept) {
                    onAccept(parseFloat(numberValue));
                  }
                  onCancel();
                }}
              >
                <CheckCircleIcon sx={{ color: '#aaaaaa' }} />
              </button>
            </div>
          </div>
        );
      }}
    />
  );
};
