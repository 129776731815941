import { FC } from 'react';
import { INoContractSectionHeaderProps } from './types';

export const NoContractSectionHeader: FC<INoContractSectionHeaderProps> = (props) => {
  const { title, description, children } = props;

  return (
    <div className='flex items-center'>
      <div className='flex-1'>
        <h1 className='text-20 leading-[32px] font-bold'>{title}</h1>
        <p className='text-[#475B69] text-14 leading-[170%] font-normal'>{description}</p>
      </div>
      {children && <div className='ml-auto'>{children}</div>}
    </div>
  );
};
