import { IRootState } from '../../store';
import IUser, { COGNITO_GROUP } from './user';

const authSelectors = {
  selectUser: (state: IRootState): IUser | null => state.auth.user,
  selectIsLoading: (state: IRootState): boolean => state.auth.isLoading,
  selectUserRole: (state: IRootState): COGNITO_GROUP => (state.auth.user?.roles?.length > 0 ? state.auth.user?.roles[0] : null),
  getIsRoleFinance: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.FINANCE),
  getIsRoleOperations: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.OPERATIONS_MANAGER),
  getIsRoleProcurement: (state: IRootState): boolean => state.auth.user?.roles?.includes(COGNITO_GROUP.PROCUREMENT_MANAGER),
};

export default authSelectors;
