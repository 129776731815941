import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'src/infra/stores/store';

export interface IApiState {
  isShow: boolean;
  errorCode: number | null;
  resultMessage: string;
  resultTitle: string;
}

const initialState: IApiState = {
  isShow: false,
  errorCode: null,
  resultMessage: '',
  resultTitle: '',
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    showError: (state, action) => {
      const { error, defaultMessage, defaultTitle } = action.payload;
      const { response } = error;
      let message = '';
      let title = '';
      let errorCode = null;

      if (!defaultTitle) {
        title = response?.statusText || 'Error, please try again.';
      } else {
        title = defaultTitle;
      }

      if (response) {
        switch (response.status) {
          case 401:
            message = 'No credential';
            break;
          case 403:
            message = 'No access';
            break;
          case 500:
            message = 'Server error';
            break;
          default:
            message = response?.data?.error || response?.data?.message || defaultMessage;
            break;
        }
        errorCode = response.status;
      } else if (typeof error === 'string') {
        message = error;
      } else {
        message = defaultMessage || 'Error, please try again.';
      }

      state.isShow = true;
      state.errorCode = errorCode;
      state.resultMessage = message;
      state.resultTitle = title;
    },
    clearError: (state) => {
      state.isShow = false;
      state.resultMessage = '';
      state.resultTitle = '';
      state.errorCode = null;
    },
    setError: (state, action) => {
      const { title, message, code = '500' } = action.payload;
      state.isShow = true;
      state.resultMessage = message;
      state.resultTitle = title;
      state.errorCode = code;
    },
    hideResult: (state) => {
      state.isShow = false;
      state.resultMessage = '';
      state.resultTitle = '';
      state.errorCode = null;
    },
    showResult: (state, action) => {
      const { title, message, errorCode } = action.payload;
      state.isShow = true;
      state.resultMessage = message;
      state.resultTitle = title;
      if (errorCode) {
        state.errorCode = errorCode;
      }
    },
  },
});

export const { showError, clearError, setError, showResult, hideResult } = apiSlice.actions;

export default apiSlice.reducer;

export const getIsShow = (state: IRootState) => state.api.isShow;
export const getErrorCode = (state: IRootState) => state.api.errorCode;
export const getResultTitle = (state: IRootState) => state.api.resultTitle;
export const getResultMessage = (state: IRootState) => state.api.resultMessage;
