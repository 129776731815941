import { FC } from 'react';
import Button from 'src/presentations/components/atom/button';
import { ITopButtonProps } from './types';

export const TopButton: FC<ITopButtonProps> = (props) => {
  const { title, icon, path } = props;

  return (
    <Button path={path} variant='neutral_outline' rounded='large' className='min-w-0 px-14'>
      <div className='flex items-center gap-10'>
        <img src={icon} alt='' className='w-20' />
        {title}
      </div>
    </Button>
  );
};
