import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import DocumentGreen from 'src/assets/images/icons/document-text-green.svg';
import CloseCircle from 'src/assets/images/icons/close-circle.svg';

import { TableShippingService } from './TableShippingService';
import { INoContractContentProps, NoContractForm } from './types';
import { NoContractSectionHeader } from './SectionHeader';
import { SelectCard } from './SelectCard';

export const NoContractContentStep1: FC<INoContractContentProps> = (props) => {
  const { matchingService, activeStep } = props;

  const { watch, setValue } = useFormContext<NoContractForm>();
  const { verdict } = watch();

  return (
    <div className='pt-12 pb-24'>
      <div className='px-24'>
        <NoContractSectionHeader title='Select an Option' description='Please choose one:' />

        <div className='flex flex-col gap-3 mt-3'>
          <SelectCard
            onClick={() => {}}
            isChosen={verdict === 'accept'}
            icon={<img src={DocumentGreen} alt='green document icon' />}
            header='Available Rate Card Shipping Services Partially Matching this Invoice'
            description='Select one of these Shipping Services that closely match the key elements of the selected invoice. You will be able to amend the corresponding tariffs in the next screen.'
          >
            <TableShippingService
              name='list-service'
              data={matchingService.partialMatchingServices}
              onChangeSelected={() => {
                setValue('verdict', 'accept');
              }}
              isSelectable
              activeStep={activeStep}
            />
          </SelectCard>
          <SelectCard
            onClick={() => {
              setValue('verdict', 'reject-reject');
            }}
            isChosen={verdict === 'reject-reject'}
            icon={<img src={CloseCircle} alt='close circle' />}
            header='Reject Invoice'
          />
        </div>
      </div>
    </div>
  );
};
