import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';

import {
  FiltersRequestDto,
  PageDto,
  PerformanceAssessmentKeyMetricRequestDto,
  PerformanceSavingsTableResponseDto,
  PerformanceVolumetricTableResponseDto,
  PerformanceAssessmentSavingsTableRequestDto,
  PerformanceAssessmentVolumetricTableRequestDto,
  SavingsMetricResponseDto,
  SavingsSortDto,
  VolumetricResponseDto,
  VolumetricSortDto,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { convertEmptyToNull } from 'src/presentations/components/organisms/analytics/useAnalytics';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

interface IGetConfigParams<TSort> {
  page?: PageDto;
  sort?: TSort;
}

interface IStatsApiParams {
  filterConfigs: FiltersRequestDto;
  provider?: string;
  modality?: string;
}

interface ITableApiParams<TSort> {
  filterConfigs: FiltersRequestDto;
  apiConfigs: IGetConfigParams<TSort>;
  provider?: string;
  modality?: string;
}

interface ITableDownloadParams<TSort> {
  fileConfigs: {
    name: string;
    type: 'CSV' | 'EXCEL';
  };
  filterConfigs: FiltersRequestDto;
  apiConfigs: IGetConfigParams<TSort>;
  provider?: string;
  modality?: string;
}

export const tableFiltersToPerformanceFilters = (filterOptions: Record<string, any> = null): FiltersRequestDto => {
  const apiParams = {
    filters: filterOptions?.filters,
    invoiceStatusSet: filterOptions?.invoiceStatusSet,
    dateReference: filterOptions?.dateReference,
    currencyReference: filterOptions?.currencyReference,
  };

  return apiParams as FiltersRequestDto;
};

export const useSavingsStats = (params: IStatsApiParams): UseQueryResult<SavingsMetricResponseDto> => {
  const { filterConfigs, provider = null, modality = null } = params;
  const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
  const apiParams: PerformanceAssessmentKeyMetricRequestDto = {
    filtersRequest: filter,
    serviceProvider: provider,
    modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
  };

  convertEmptyToNull(apiParams);

  return useQuery(
    cacheKeysUtil.performanceSavingsStats(apiParams),
    async (): Promise<SavingsMetricResponseDto> => (await tFinancialService.api.getKeyMetricsSavingsWithFilter(apiParams)).data.data,
    { enabled: apiParams.serviceProvider !== '' && !!params?.filterConfigs?.currencyReference }
  );
};

export const useVolumetricStats = (params: IStatsApiParams): UseQueryResult<VolumetricResponseDto> => {
  const { filterConfigs, provider = null, modality = null } = params;
  const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
  const apiParams: PerformanceAssessmentKeyMetricRequestDto = {
    filtersRequest: filter,
    serviceProvider: provider,
    modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
  };

  convertEmptyToNull(apiParams);

  return useQuery(
    cacheKeysUtil.performanceVolumetricStats(apiParams),
    async (): Promise<VolumetricResponseDto> => (await tFinancialService.api.getKeyMetricsVolumesWithFilter(apiParams)).data.data,
    { enabled: apiParams.serviceProvider !== '' && !!params?.filterConfigs?.currencyReference }
  );
};

export const useSavingsTable = (params: ITableApiParams<SavingsSortDto>): UseQueryResult<PerformanceSavingsTableResponseDto> => {
  const { filterConfigs, apiConfigs, provider = null, modality = null } = params;
  const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
  const apiParams: PerformanceAssessmentSavingsTableRequestDto = {
    performanceAssessmentRequest: {
      filtersRequest: filter,
      serviceProvider: provider,
      modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
    },
    ...apiConfigs,
  };

  convertEmptyToNull(apiParams);

  return useQuery(
    cacheKeysUtil.performanceSavingsTable(apiParams),
    async (): Promise<PerformanceSavingsTableResponseDto> => (await tFinancialService.api.getSavingsTableDataByMonthWithFilter(apiParams)).data.data,
    { enabled: apiParams.performanceAssessmentRequest.serviceProvider !== '' }
  );
};

export const useVolumetricTable = (params: ITableApiParams<VolumetricSortDto>): UseQueryResult<PerformanceVolumetricTableResponseDto> => {
  const { filterConfigs, apiConfigs, provider = null, modality = null } = params;
  const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
  const apiParams: PerformanceAssessmentVolumetricTableRequestDto = {
    performanceAssessmentRequest: {
      filtersRequest: filter,
      serviceProvider: provider,
      modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
    },
    ...apiConfigs,
  };

  convertEmptyToNull(apiParams);

  return useQuery(
    cacheKeysUtil.performanceVolumetricTable(apiParams),
    async (): Promise<PerformanceVolumetricTableResponseDto> => (await tFinancialService.api.getVolumetricTableDataByMonthWithFilter(apiParams)).data.data,
    { enabled: apiParams.performanceAssessmentRequest.serviceProvider !== '' }
  );
};

export const useSavingsTableDownload = () =>
  useMutation(async (params: ITableDownloadParams<SavingsSortDto>) => {
    const { fileConfigs, filterConfigs, apiConfigs, provider = null, modality = null } = params;
    const query = {
      'file-name': fileConfigs.name,
      'file-type': fileConfigs.type,
    };
    const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
    const apiParams: PerformanceAssessmentSavingsTableRequestDto = {
      performanceAssessmentRequest: {
        filtersRequest: filter,
        serviceProvider: provider,
        modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
      },
      ...apiConfigs,
    };

    convertEmptyToNull(apiParams);

    return (await tFinancialService.api.getSavingsTableDataDownload(query, apiParams)).data;
  });

export const useVolumetricTableDownload = () =>
  useMutation(async (params: ITableDownloadParams<VolumetricSortDto>) => {
    const { fileConfigs, filterConfigs, apiConfigs, provider = null, modality = null } = params;
    const query = {
      'file-name': fileConfigs.name,
      'file-type': fileConfigs.type,
    };
    const filter = { ...tableFiltersToPerformanceFilters(filterConfigs) };
    const apiParams: PerformanceAssessmentVolumetricTableRequestDto = {
      performanceAssessmentRequest: {
        filtersRequest: filter,
        serviceProvider: provider,
        modality: modality as PerformanceAssessmentKeyMetricRequestDto['modality'],
      },
      ...apiConfigs,
    };

    convertEmptyToNull(apiParams);

    return (await tFinancialService.api.getVolumetricTableDataDownload(query, apiParams)).data;
  });
