import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useVolumetricStats } from 'src/use-cases/performance/usePerformance';
import { CardStat } from './Stat/CardStat';
import { LoaderVolumetric } from './LoaderVolumetric';
import { SectionVolumetric } from './SectionDetail/SectionVolumetric';
import { getProviderModalityCombinations, getVolumetricStatsList } from './helpers';
import { IProviderModalityProps } from './types';

export const PerformanceContentVolumetric = ({ dataFilter }) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const combinations = getProviderModalityCombinations(formValues, dataFilter);
  const { data: dataStats, isLoading: isLoadingStats } = useVolumetricStats({
    filterConfigs: formValues,
  });

  const { currencyReference } = formValues;

  const statsList = useMemo(() => getVolumetricStatsList(dataStats, { unitCurrency: currencyReference }), [dataStats, currencyReference]);

  return (
    <div>
      {isLoadingStats ? (
        <LoaderVolumetric />
      ) : (
        <div className='pt-8 px-32 pb-24 grid gap-24 border-b border-neutral-20 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 2xl:grid-cols-5'>
          {statsList.map((item) => (
            <CardStat key={`card-stat-main-${item?.type}`} type={item?.type} values={item?.values} />
          ))}
        </div>
      )}

      {combinations?.length > 0 && (
        <div className='border-t border-white px-32 py-24 flex flex-col gap-40'>
          {combinations.map((item: IProviderModalityProps) => (
            <SectionVolumetric key={`performance-${item.provider}-${item.modality}`} provider={item.provider} modality={item.modality} />
          ))}
        </div>
      )}
    </div>
  );
};
