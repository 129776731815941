import { useQuery } from '@tanstack/react-query';
import { InvoiceAnalyticSearchRequestDto, InvoiceChargeSearchRequestDto, ShipmentSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useGetShipmentsData = (shipmentRequest: ShipmentSearchRequestDto) =>
  useQuery(cacheKeysUtil.getShipmentsData(shipmentRequest), async () => (await tFinancialService.api.fetchShipmentData(shipmentRequest)).data.data);

export const useGetInvoicesData = (invoiceRequest: InvoiceAnalyticSearchRequestDto) =>
  useQuery(cacheKeysUtil.getInvoicesData(invoiceRequest), async () => (await tFinancialService.api.fetchInvoiceColumns(invoiceRequest)).data.data);

export const useGetInvoiceChargesData = (invoiceChargeRequest: InvoiceChargeSearchRequestDto) =>
  useQuery(
    cacheKeysUtil.getInvoiceChargesData(invoiceChargeRequest),
    async () => (await tFinancialService.api.fetchInvoiceChargeColumns(invoiceChargeRequest)).data.data
  );
