import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReceiptIcon from 'src/assets/images/icons/receipt-alt.svg';
import BookIcon from 'src/assets/images/icons/book.svg';
import { TableTab } from 'src/presentations/components/organisms/analytics/table/useTable';
import { useFormContext } from 'react-hook-form';
import { useDownloadTemplate } from 'src/presentations/components/organisms/analytics/useAnalytics';

const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    borderRadius: '8px!important',
    width: '200px',
  },
});

interface IDownloadMenuProps {
  moreActionEl: HTMLButtonElement | null;
  onCloseMoreAction: () => void;
  tableTab: TableTab;
  otherProps?: Record<string, any>;
  customFileName?: string;
  pageSize?: number;
}

export const DownloadMenu = (props: IDownloadMenuProps) => {
  const { moreActionEl, onCloseMoreAction, tableTab, customFileName, otherProps, pageSize } = props;
  const { watch } = useFormContext();
  const { name, description, ...formValues } = watch();

  const fileName = name || customFileName;
  const downloadProps = {
    filtersRequest: formValues,
    ...otherProps,
  };
  const { download } = useDownloadTemplate(tableTab, fileName, downloadProps, pageSize);

  return (
    <StyledMenu
      open={!!moreActionEl}
      anchorEl={moreActionEl}
      onClose={onCloseMoreAction}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <img src={ReceiptIcon} alt='ReceiptIcon' />
        </ListItemIcon>
        <ListItemText>
          <button onClick={() => download('CSV')}> Download CSV</button>
        </ListItemText>
      </MenuItem>

      <MenuItem>
        <ListItemIcon>
          <img src={BookIcon} alt='BookIcon' />
        </ListItemIcon>
        <ListItemText>
          <button onClick={() => download('EXCEL')}> Download XLSX</button>
        </ListItemText>
      </MenuItem>
    </StyledMenu>
  );
};
