import { SelectCardProps } from './types';

export const SelectCard = ({ header, description, onClick, isChosen, icon, children }: SelectCardProps) => (
  <>
    <button
      type='button'
      onClick={onClick}
      className={`p-[20px] border-[1px] rounded-[20px] w-full ${isChosen ? 'border-[#00992B] shadow-md' : 'border-[#DFDFDF]'}`}
    >
      <div className='flex gap-[20px] items-center'>
        {icon}
        <div className='text-left'>
          <h1 className='font-extrabold'>{header}</h1>
          <p className='text-[#808080]'>{description}</p>
        </div>
      </div>
      {children && <div className='mt-20'>{children}</div>}
    </button>
  </>
);
