import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { cn } from 'src/utils/utility';
import { LandingSectionName, ILandingMenuProps } from './types';

const StyledTabs = styled(Tabs)({
  minHeight: 0,
  '& .MuiButtonBase-root': {
    color: '#808080',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: '#202020',
  },
  '& .MuiTouchRipple-root': {
    color: '#00992B',
  },
  '& .MuiTabs-indicator': {
    height: 2,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
});

export const LandingMenu: FC<ILandingMenuProps> = (props) => {
  const { selected, onChangeMenu, auditTotal, analyticTotal, performanceTotal } = props;

  const handleChange = (event, newValue: string) => {
    onChangeMenu(newValue);
  };

  const menuItems = useMemo(
    () => [
      {
        id: LandingSectionName.ALL,
        name: 'All',
        count: auditTotal + analyticTotal + performanceTotal,
      },
      {
        id: LandingSectionName.AUDIT,
        name: 'Audit',
        count: auditTotal,
      },
      {
        id: LandingSectionName.PERFORMANCE,
        name: 'Performance Assessment',
        count: performanceTotal,
      },
      {
        id: LandingSectionName.ANALYTIC,
        name: 'Reports & Analytics',
        count: analyticTotal,
      },
    ],
    [auditTotal, analyticTotal, performanceTotal]
  );

  return (
    <div className='bg-white flex items-center justify-center border-b border-x border-[#EBEDF1] rounded-b-24 shadow-[0_6px_20px_0_rgba(0,0,0,0.04)]'>
      <StyledTabs value={selected} onChange={handleChange}>
        {menuItems.map((item) => (
          <Tab
            value={item.id}
            key={`landing-menu-${item.id}`}
            className='!py-16 !px-32 group'
            label={
              <div className='flex flex-row items-center gap-10'>
                <strong className='normal-case text-16 font-medium transition-all duration-300 group-hover:text-neutral-90'>{item.name}</strong>
                <span
                  className={cn(
                    'text-14 rounded-full px-6 py-1 font-medium transition-all duration-300 group-hover:bg-primary group-hover:text-white',
                    selected === item?.id ? 'bg-primary text-white' : 'bg-neutral-20 text-neutral-50'
                  )}
                >
                  {item.count}
                </span>
              </div>
            }
          />
        ))}
      </StyledTabs>
    </div>
  );
};
