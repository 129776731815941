import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ISectionDetailProps } from './types';
import { SectionDetailTable } from './Table';
import { SectionDetailHeader } from './Header';

const StyledAccordion = styled(MuiAccordion)({
  borderRadius: '14px!important',
  boxShadow: 'none',
  border: '1px solid #dfdfdf',
  borderBottomWidth: '3px',
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0',
});

export const SectionDetail: FC<ISectionDetailProps> = (props) => {
  const { title, transportMedium, linkAudit, linkReport, children, tabName, tableProps } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className='relative'>
      <StyledAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <div className='relative'>
          <SectionDetailHeader title={title} transportMedium={transportMedium} linkAudit={linkAudit} linkReport={linkReport} />
          <div className='px-16 py-20'>{children}</div>
        </div>
        <StyledAccordionDetails>
          <div className='mt-16 px-16 pb-24'>
            <SectionDetailTable tabName={tabName} title={title} transportMedium={transportMedium} {...tableProps} />
          </div>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};
