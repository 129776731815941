import { ReactElement, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Button from 'src/presentations/components/atom/button';
import { Modal } from './Modal';

interface IModalProps {
  open: boolean;
  children: ReactElement;
  title: string;
  buttonCancelLabel?: string;
  buttonAcceptLabel?: string;
  isLoading?: boolean;
  onCancel: () => void;
  onAccept: () => void;
}

export const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '540px',
    borderRadius: '16px',
  },
});

export const ModalConfirm: React.FC<IModalProps> = (props) => {
  const { open, title, children, onCancel, onAccept, isLoading, buttonCancelLabel = 'Cancel', buttonAcceptLabel = 'Confirm' } = props;

  return (
    <StyledModal open={open} title={title} fullWidth onClose={!isLoading && onCancel}>
      <div className='relative'>
        {children}

        <div className='mt-48 grid gap-16 grid-cols-2 grid-rows-1'>
          <Button variant='secondary' onClick={onCancel} disabled={isLoading}>
            {buttonCancelLabel}
          </Button>
          <Button variant='primary' onClick={onAccept} isLoading={isLoading}>
            {buttonAcceptLabel}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export const ModalConfirmCustom: React.FC<IModalProps & { buttonChildren: ReactNode }> = (props) => {
  const { open, title, children, onCancel, isLoading, buttonChildren } = props;

  return (
    <StyledModal open={open} title={title} fullWidth onClose={!isLoading && onCancel}>
      <div className='relative'>
        {children}

        <div className='mt-48 grid gap-16 grid-cols-2 grid-rows-1'>{buttonChildren}</div>
      </div>
    </StyledModal>
  );
};
