export interface IUploadProps {
  open: boolean;
  onClose: () => void;
}

export enum UploadStep {
  Step1_SelectInvoice = 0,
  Step2_UploadStatus = 1,
}

export interface IUploadFormValues {
  serviceProvider: string;
  modality: string;
  invoiceFiles: File[];
}

export enum OneOffOrRecurring {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING',
}

export enum DisputeStep {
  Step1_ContactServiceProvider = 0,
  Step2_Resolution = 1,
  Step3_Summary = 2,
}
