import { isEqual } from 'lodash';
import { SetStateAction, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parse, stringify } from 'superjson';
import { compressToURI, decompressFromURI } from 'lz-ts';

// A wrapper around useState that persists state with a base64 encoded query parameter
// Uses superjson so that complex types like dates can be serialized
export const useEncodedQueryState = <T>(defaultValue: T, name: string): [T, (value: SetStateAction<T>) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const decodedQuery: T = useMemo(() => {
    const paramAsString = searchParams.get(name);
    if (!paramAsString) return {} as T;
    try {
      const decoded = decompressFromURI(paramAsString);
      if (!decoded) return {} as T;
      return parse(decoded);
    } catch (error) {
      return {} as T;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const [queryState, setQueryState] = useState<T>({
    ...defaultValue,
    ...decodedQuery,
  });

  const setQuery = useCallback(
    (valueOrNewValueFunction: SetStateAction<T>) => {
      const newValue = valueOrNewValueFunction instanceof Function ? valueOrNewValueFunction(queryState) : valueOrNewValueFunction;
      setQueryState(valueOrNewValueFunction);

      if (isEqual(newValue, defaultValue)) {
        // If resetting to default then just remove the query parameter
        searchParams.delete(name);
        setSearchParams(searchParams);
      } else {
        searchParams.set(name, compressToURI(stringify(newValue)));
        setSearchParams(searchParams);
      }
    },
    [defaultValue, name, queryState, searchParams, setSearchParams]
  );

  return [queryState, setQuery];
};
