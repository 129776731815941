import { FC } from 'react';
import { InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';

import { UploadStatusItem } from './UploadStatusItem';
import { useUploadSelector } from './useUploadSelector';

export interface IStepUploadStatusProps {
  onFinishUpload(data: InvoiceUploadSignedUrlDto): void;
}

export const StepUploadStatus: FC<IStepUploadStatusProps> = ({ onFinishUpload }) => {
  const { uploadResults, uploadProcessings } = useUploadSelector();

  return (
    <div className='relative'>
      <h3 className='text-18 font-bold mb-4'>Upload & Processing Status: {uploadResults?.length || 0} Items</h3>
      <p className='text-14'>Feel free to close this window while invoices are being processed.</p>

      <div className='mt-24 flex flex-col gap-24'>
        {uploadResults?.map((result) => {
          const invoiceDetail = uploadProcessings?.find((item) => item?.invoiceFileProcessingId === result?.dataPipelineProcessingId);
          return <UploadStatusItem key={`result-${result?.fileName}`} data={result} onFinish={onFinishUpload} invoiceDetail={invoiceDetail || null} />;
        })}
      </div>
    </div>
  );
};
