import { ICustomIconProps } from 'src/presentations/components/atom/icons/types';

export enum ChartViewType {
  TREND = 'trend',
  CORRELATIONS = 'correlations',
  TRADE_LANE = 'trade_lane',
  SERVICE_PROVIDER = 'service_provider',
  BILLING_REFERENCE = 'billing_reference',
}

export interface ComponentOptionDto {
  dataKey: string;
  name: string;
  type: string;
  orientation?: 'left' | 'right';
  mirror?: boolean;
  color: string;
  unit: string;
}

export interface SidebarProps {
  selected: ChartViewType;
  onChange(value: ChartViewType): void;
}

export interface MenuItemProps {
  name: ChartViewType;
  title: string;
  icon: React.FC<ICustomIconProps>;
  submenus: MenuItemProps[];
}
