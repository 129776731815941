import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { cn } from 'src/utils/utility';
import CheckIcon from '@mui/icons-material/Check';

export const TableWrapper = styled(Paper)({
  boxShadow: 'none',
});

export const TableMain = styled(Table)({
  '& .MuiTableCell-head': {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#F5F6FA',
    padding: '16px 16px',
    verticalAlign: 'middle',
  },
  '& .MuiTableCell-body ': {
    padding: '16px 16px',
    fontSize: '14px',
  },
  '& .Mui-selected td': {
    backgroundColor: '#F5F6FA',
  },
  '& .MuiTableRow-root:hover td': {
    backgroundColor: '#F5F6FA',
  },
});

export const CheckIconSquare = () => (
  <button
    aria-label='Action'
    type='button'
    className={cn(
      'relative w-18 h-18 rounded-4 border-2 border-neutral-80 flex items-center justify-center',
      'transition-all duration-500 cursor-pointer',
      'bg-neutral-80',
      'border-blue_grey-80'
    )}
  >
    <CheckIcon style={{ color: '#ffffff', fontSize: 14, opacity: 1 }} />
  </button>
);
