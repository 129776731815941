import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnalyticSearchRequestDto, ChartResponseDto, InvoiceChargeSortDto, InvoiceSortDto, ShipmentSortDto } from 'src/generated/services/TFinancialApi';
import FilterNav, { IOptionBase } from './FilterNav';
import { TableTab, getChargesColumns, getInvoicesColumns, getShipmentsColumns } from './useTable';
import { TableDetail } from './TableDetail';
import { useGetInvoiceChargesData, useGetInvoicesData, useGetShipmentsData } from './useTableData';

export const AnalyticsTable: FC<{ chartData: ChartResponseDto }> = ({ chartData }) => {
  const [tableTab, setTableTab] = useState<TableTab>(TableTab.SHIPMENTS);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortingShipmentField, setSortingShipmentField] = useState<ShipmentSortDto['field']>('SHIPMENT_NUMBER');
  const [sortingInvoiceField, setSortingInvoiceField] = useState<InvoiceSortDto['field']>('INVOICE_NUMBER');
  const [sortingChargeield, setSortingChargeField] = useState<InvoiceChargeSortDto['field']>('INVOICE_NUMBER');
  const [sortingMethod, setSortingMethod] = useState<string>('ASC');
  const page = useMemo(() => ({ pageNumber, pageSize: 25 }), [pageNumber]);

  const { watch } = useFormContext();
  const { name, description, ...formValues } = watch();

  const { data: shipmentsData, refetch: refetchShipments } = useGetShipmentsData({
    page,
    searchRequest: formValues as AnalyticSearchRequestDto,
    sorting: {
      field: sortingShipmentField,
      direction: sortingMethod as ShipmentSortDto['direction'],
    },
  });
  const { data: invoicesData, refetch: refetchInvoices } = useGetInvoicesData({
    page,
    searchRequest: formValues as AnalyticSearchRequestDto,
    sorting: {
      field: sortingInvoiceField,
      direction: sortingMethod as InvoiceSortDto['direction'],
    },
  });
  const { data: chargesData, refetch: refetchCharges } = useGetInvoiceChargesData({
    page,
    searchRequest: formValues as AnalyticSearchRequestDto,
    sorting: {
      field: sortingChargeield,
      direction: sortingMethod as InvoiceChargeSortDto['direction'],
    },
  });
  useEffect(() => {
    switch (tableTab) {
      case TableTab.SHIPMENTS:
        refetchShipments();
        break;
      case TableTab.INVOICES:
        refetchInvoices();
        break;
      case TableTab.CHARGES:
        refetchCharges();
        break;
      default:
        break;
    }
  }, [refetchCharges, refetchInvoices, refetchShipments, tableTab]);

  const optionTabs: IOptionBase[] = useMemo(
    () => [
      {
        label: TableTab.SHIPMENTS,
        count: shipmentsData?.total,
        info: TableTab.SHIPMENTS,
        value: TableTab.SHIPMENTS,
      },
      {
        label: TableTab.INVOICES,
        count: invoicesData?.total,
        info: TableTab.INVOICES,
        value: TableTab.INVOICES,
      },
      {
        label: TableTab.CHARGES,
        count: chargesData?.total,
        info: TableTab.CHARGES,
        value: TableTab.CHARGES,
      },
    ],
    [chargesData, invoicesData, shipmentsData]
  );

  const tableDetail = useMemo(() => {
    switch (tableTab) {
      case TableTab.SHIPMENTS:
        return {
          columns: getShipmentsColumns({ defaultCurrency: formValues?.currencyReference }),
          data: shipmentsData?.shipments,
          total: shipmentsData?.total,
          page: shipmentsData?.page,
        };
      case TableTab.INVOICES:
        return {
          columns: getInvoicesColumns({ defaultCurrency: formValues?.currencyReference }),
          data: invoicesData?.invoices,
          total: invoicesData?.total,
          page: invoicesData?.page,
        };
      case TableTab.CHARGES:
        return {
          columns: getChargesColumns({ defaultCurrency: formValues?.currencyReference }),
          data: chargesData?.invoiceCharges,
          total: chargesData?.total,
          page: chargesData?.page,
        };
      default:
        return {
          columns: [],
          data: [],
        };
    }
  }, [
    chargesData?.invoiceCharges,
    chargesData?.page,
    chargesData?.total,
    formValues?.currencyReference,
    invoicesData?.invoices,
    invoicesData?.page,
    invoicesData?.total,
    shipmentsData?.page,
    shipmentsData?.shipments,
    shipmentsData?.total,
    tableTab,
  ]);

  const refetchData = useCallback(() => {
    switch (tableTab) {
      case TableTab.SHIPMENTS:
        refetchShipments();
        break;
      case TableTab.INVOICES:
        refetchInvoices();
        break;
      case TableTab.CHARGES:
        refetchCharges();
        break;
      default:
        break;
    }
  }, [refetchCharges, refetchInvoices, refetchShipments, tableTab]);

  const onSorting = useCallback(
    (method: string, fieldName: string) => {
      setSortingMethod(method);

      switch (tableTab) {
        case TableTab.SHIPMENTS:
          setSortingShipment(fieldName);
          break;
        case TableTab.INVOICES:
          setSortingInvoice(fieldName);
          break;
        case TableTab.CHARGES:
          setSortingCharge(fieldName);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableTab]
  );

  const setSortingShipment = useCallback((fieldName: string) => {
    let fieldId = null;
    switch (fieldName) {
      case 'shipmentNumber':
        fieldId = 'SHIPMENT_NUMBER';
        break;
      case 'departureDate':
        fieldId = 'DEPARTURE_DATE';
        break;
      case 'arrivalDate':
        fieldId = 'ARRIVAL_DATE';
        break;
      case 'invoiceCount':
        fieldId = 'INVOICE_COUNT';
        break;
      case 'invoiceChargesCount':
        fieldId = 'INVOICE_CHARGES_COUNT';
        break;
      case 'grossWeight':
        fieldId = 'GROSS_WEIGHT';
        break;
      case 'totalAmount':
        fieldId = 'TOTAL_AMOUNT';
        break;
      case 'chargeableWeight':
        fieldId = 'CHARGEABLE_WEIGHT';
        break;
      case 'volume':
        fieldId = 'VOLUME';
        break;
      case 'co2Emission':
        fieldId = 'CO2_EMISSION';
        break;
      case 'serviceProvider':
        fieldId = 'SERVICE_PROVIDER';
        break;
      case 'modality':
        fieldId = 'MODALITY';
        break;
      case 'vehicleId':
        fieldId = 'VEHICLE_ID';
        break;
      case 'shipper':
        fieldId = 'SHIPPER_NAME';
        break;
      case 'pol':
        fieldId = 'POL';
        break;
      case 'shipperCountry':
        fieldId = 'SHIPPER_COUNTRY';
        break;
      case 'consignee':
        fieldId = 'CONSIGNEE_NAME';
        break;
      case 'pod':
        fieldId = 'POD';
        break;
      case 'consigneeCountry':
        fieldId = 'CONSIGNEE_COUNTRY';
        break;
      case 'goodsName':
        fieldId = 'GOOD_NAME';
        break;
      case 'packageCount':
        fieldId = 'PACKAGE_COUNT';
        break;
      case 'packageUnit':
        fieldId = 'PACKAGE_UNIT';
        break;
      default:
        fieldId = 'SHIPMENT_NUMBER';
        break;
    }
    setSortingShipmentField(fieldId);
  }, []);

  const setSortingInvoice = useCallback((fieldName: string) => {
    let fieldId = null;
    switch (fieldName) {
      case 'invoiceNumber':
        fieldId = 'INVOICE_NUMBER';
        break;
      case 'invoiceDate':
        fieldId = 'INVOICE_DATE';
        break;
      case 'invoiceDueDate':
        fieldId = 'INVOICE_DUE_DATE';
        break;
      case 'shipmentNumber':
        fieldId = 'SHIPMENT_COUNT';
        break;
      case 'invoiceChargesCount':
        fieldId = 'INVOICE_CHARGES_COUNT';
        break;
      case 'billedEntityName':
        fieldId = 'BILLED_ENTITY_NAME';
        break;
      case 'totalAmount':
        fieldId = 'TOTAL_AMOUNT';
        break;
      case 'invoiceStatus':
        fieldId = 'INVOICE_STATUS';
        break;
      case 'serviceProvider':
        fieldId = 'SERVICE_PROVIDER_NAME';
        break;
      case 'modality':
        fieldId = 'MODALITY';
        break;
      case 'shipper':
        fieldId = 'SHIPPER_NAME';
        break;
      case 'pol':
        fieldId = 'POL';
        break;
      case 'shipperCountry':
        fieldId = 'SHIPPER_COUNTRY';
        break;
      case 'consignee':
        fieldId = 'CONSIGNEE_NAME';
        break;
      case 'pod':
        fieldId = 'POD';
        break;
      case 'consigneeCountry':
        fieldId = 'CONSIGNEE_COUNTRY';
        break;
      default:
        fieldId = 'INVOICE_NUMBER';
        break;
    }
    setSortingInvoiceField(fieldId);
  }, []);

  const setSortingCharge = useCallback((fieldName: string) => {
    let fieldId = null;
    switch (fieldName) {
      case 'shipmentNumber':
        fieldId = 'SHIPMENT_NUMBER';
        break;
      case 'invoiceNumber':
        fieldId = 'INVOICE_NUMBER';
        break;
      case 'invoiceDate':
        fieldId = 'INVOICE_DATE';
        break;
      case 'chargeDomain':
        fieldId = 'CHARGE_DOMAIN';
        break;
      case 'chargeName':
        fieldId = 'CHARGE_NAME';
        break;
      case 'chargeAmount':
        fieldId = 'CHARGE_AMOUNT';
        break;
      case 'chargeQuantity':
        fieldId = 'CHARGE_QUANTITY';
        break;
      case 'chargeUnitPrice':
        fieldId = 'CHARGE_UNIT_PRICE';
        break;
      case 'exchangeRate':
        fieldId = 'EXCHANGE_RATE';
        break;
      case 'serviceProvider':
        fieldId = 'SERVICE_PROVIDER_NAME';
        break;
      case 'modality':
        fieldId = 'MODALITY';
        break;
      case 'pol':
        fieldId = 'POL';
        break;
      case 'pod':
        fieldId = 'POD';
        break;
      default:
        fieldId = 'INVOICE_NUMBER';
        break;
    }
    setSortingChargeField(fieldId);
  }, []);
  return (
    <div className='w-full pt-24 px-24 pb-40 ' key={tableTab}>
      <div className=' border border-neutral-20 rounded-16 shadow-[0_10px_24px_0_rgba(0,0,0,0.1)]'>
        <FilterNav
          total={tableDetail.total}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          tableTab={tableTab}
          optionTabs={optionTabs}
          setTableTab={setTableTab}
          refetchData={refetchData}
          pageSize={page.pageSize}
        />
        <TableDetail tableDetail={tableDetail} chartData={chartData} page={page} tableTab={tableTab} refetchData={refetchData} onSorting={onSorting} />
      </div>
    </div>
  );
};
