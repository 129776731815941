import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationResponse } from 'src/generated/services/TFinancialApi';

import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useGetNotifications = (viewDate: string = null, intervalTime: number = 120000): UseQueryResult<NotificationResponse> => {
  const query: { viewDate?: string } = {};
  if (viewDate) {
    query.viewDate = viewDate;
  }
  return useQuery(
    cacheKeysUtil.getNotifications(),
    async (): Promise<NotificationResponse> => (await tFinancialService.api.getNotifications(query)).data.data,
    {
      refetchInterval: intervalTime,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );
};

export const useGetNotificationsByDate = (viewDate: string = null): UseQueryResult<NotificationResponse> => {
  const query: { viewDate?: string } = {};
  if (viewDate) {
    query.viewDate = viewDate;
  }
  return useQuery(
    cacheKeysUtil.getNotifications(),
    async (): Promise<NotificationResponse> => (await tFinancialService.api.getNotifications(query)).data.data,
    {
      enabled: !!viewDate,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(async () => tFinancialService.api.markNotificationAsRead(), {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: async (_, args) => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getNotifications())]);
    },
  });
};
