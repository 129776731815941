/* eslint-disable @typescript-eslint/naming-convention */

export enum USER_TYPE {
  EMAIL = 'email',
  AZURE = 'azure',
}

export enum COGNITO_GROUP {
  FINANCE = 'finance',
  OPERATIONS_MANAGER = 'operations-manager',
  PROCUREMENT_MANAGER = 'procurement-manager',
}

interface IUser {
  id: string;
  email: string;
  roles: COGNITO_GROUP[];
}

export default IUser;
