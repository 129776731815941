import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFormContext } from 'react-hook-form-mui';
import { FC, useMemo } from 'react';
import format from 'date-fns/format';
import { INotificationHeaderProps } from './types';
import { MultipleSelectDropdown } from '../../organisms/analytics/filter/MultipleSelectDropdown';

const StyledBox = styled(Box)({
  '& .MuiFormControl-root': {
    width: '180px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

export const NotificationContentHeader: FC<INotificationHeaderProps> = ({ allNotifications }) => {
  const { control } = useFormContext();

  const dateOptions = useMemo(() => {
    let yearOptions = allNotifications.map((item) => ({
      value: format(new Date(item?.uploadedOn), 'EEEE, dd-MMM-yyyy'),
      label: format(new Date(item?.uploadedOn), 'iii, dd-MMM-yyyy'),
    }));

    yearOptions = yearOptions.filter((value, index, array) => array.findIndex((item) => item?.value === value?.value) === index);

    return [
      {
        value: 'ALL',
        label: 'View All',
      },
      ...yearOptions,
    ];
  }, [allNotifications]);

  const onChange = () => {};

  return (
    <div className='px-24 py-20 flex items-center gap-24'>
      <div>
        <h3 className='flex items-center gap-10'>
          <strong className='text-18 font-bold'>Notifications</strong>
          <span className='text-16 font-medium text-[#BFBFBF]'>{allNotifications?.length}</span>
        </h3>
        <p className='text-14 text-blue_grey-30'>for Invoices Uploaded via Emails in the last 30 days</p>
      </div>

      <div className='ml-auto'>
        <StyledBox>
          <MultipleSelectDropdown
            id='notif-filter-date'
            name='date'
            label='View'
            control={control}
            defaultValue='ALL'
            options={dateOptions}
            multiple={false}
            onChangeCustom={onChange}
            disabled={allNotifications?.length < 1}
          />
        </StyledBox>
      </div>
    </div>
  );
};
