import { HttpClient, TFinancialApi } from 'src/generated/services/TFinancialApi';
import { apiConfig } from 'src/config';
import { attachAuthInterceptor, attachCommonErrorInterceptor, attachMetaInterceptor, attachStatusCodeErrorInterceptor } from './interceptors';

const httpClient = new HttpClient({
  timeout: 30 * 1000,
  baseURL: apiConfig.tfinapiEndpointAuthUser,
});

// Order is important here. These interceptors will be executed in reverse order of attachment
attachCommonErrorInterceptor(httpClient.instance);
attachStatusCodeErrorInterceptor(httpClient.instance);
attachMetaInterceptor(httpClient.instance);
attachAuthInterceptor(httpClient.instance);

export const tFinancialService = new TFinancialApi(httpClient);
