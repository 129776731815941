import { ReactNode } from 'react';

export enum StatPercentType {
  PRIMARY = 'PRIMARY',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}

export enum CardStatType {
  AMOUNT = 'amount',
  ASKED_AMOUNT = 'askedAmount',
  SHIPMENTS = 'shipments',
  INVOICES = 'invoices',
  CHARGER_LINES = 'chargeLines',
  GROSS_WEIGHT = 'grossWeight',
  CHARGEABLE_WEIGHT = 'chargeableWeight',
  VOLUME = 'volume',
  CO2E_EMISSIONS = 'totalCo2Emissions',
}

export interface CardStatDefault {
  unitCurrency?: ReactNode;
  unitShipment?: ReactNode;
  unitInvoice?: ReactNode;
  unitChargeLines?: ReactNode;
  unitGrossWeight?: ReactNode;
  unitChargableWeight?: ReactNode;
  unitVolume?: ReactNode;
  unitCo2Emissions?: ReactNode;
}

export interface IStatValueProps {
  label: string;
  value: number;
  percent?: number;
  percentType?: StatPercentType;
}

export interface ICardStatValuesProps {
  values: IStatValueProps[];
  size?: 'md' | 'lg';
}

export interface ICardStatProps extends ICardStatValuesProps {
  type: CardStatType;
}

export interface IStatSavingsContentProps {
  beforeAudit: IStatValueProps;
  afterAudit: IStatValueProps;
  deviations: IStatValueProps;
  savings: IStatValueProps;
  size?: 'md' | 'lg';
}

export interface IStatVolumetricContentProps {
  mainAmount: {
    label: string;
    data: IStatValueProps;
  };
  shipmentAmount: {
    label: string;
    data: IStatValueProps;
  };
  unitAmount?: {
    label: string;
    data: IStatValueProps;
  };
  size?: 'md' | 'lg';
}

export interface ICardStatSavingsProps {
  type: CardStatType;
  beforeAudit: IStatValueProps;
  afterAudit: IStatValueProps;
  deviations: IStatValueProps;
  savings: IStatValueProps;
}

export interface ICardStatVolumetricProps {
  type: CardStatType;
  mainAmount: {
    label: string;
    data: IStatValueProps;
  };
  shipmentAmount: {
    label: string;
    data: IStatValueProps;
  };
  unitAmount?: {
    label: string;
    data: IStatValueProps;
  };
}

export interface IStatDataProps extends IStatValueProps {
  className?: string;
  isHaveBorder?: boolean;
  size?: 'md' | 'lg';
}

export interface IChipOptionsProps {
  icon: string;
  label: ReactNode;
  unit?: ReactNode;
  styles: string;
}
