import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ResponseDtoSetPermission } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useUserPermissions = (): UseQueryResult<ResponseDtoSetPermission> =>
  useQuery(cacheKeysUtil.userPermission(), async (): Promise<ResponseDtoSetPermission> => {
    const response = await tFinancialService.api.getLoggedInUserPermissions();
    const { data } = response;
    return data;
  });
