import clsx from 'clsx';
import { ICustomIconProps } from 'src/presentations/components/atom/icons/types';

const IconProgrammingArrows: React.FC<ICustomIconProps> = ({ className }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className={clsx('relative w-[1em] h-[1em]', className)}>
    <path
      d='M15.8335 13.3333V5.41667C15.8335 4.5 15.0835 3.75 14.1668 3.75H9.5835'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M11.6665 1.6665L9.1665 3.74984L11.6665 5.83317' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15.8335 18.3335C17.2142 18.3335 18.3335 17.2142 18.3335 15.8335C18.3335 14.4528 17.2142 13.3335 15.8335 13.3335C14.4528 13.3335 13.3335 14.4528 13.3335 15.8335C13.3335 17.2142 14.4528 18.3335 15.8335 18.3335Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.1665 6.6665V14.5832C4.1665 15.4998 4.9165 16.2498 5.83317 16.2498H10.4165'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8.3335 18.3332L10.8335 16.2498L8.3335 14.1665' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M4.1665 6.6665C5.54722 6.6665 6.6665 5.54722 6.6665 4.1665C6.6665 2.78579 5.54722 1.6665 4.1665 1.6665C2.78579 1.6665 1.6665 2.78579 1.6665 4.1665C1.6665 5.54722 2.78579 6.6665 4.1665 6.6665Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconProgrammingArrows;
