import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICardStatProps, IChipOptionsProps } from './types';
import { CardStatValues } from './CardStatValues';
import { useCardOptions } from './helpers';

export const CardStatDetail: FC<ICardStatProps> = ({ type, values }) => {
  const { watch } = useFormContext();
  const { currencyReference } = watch();
  const titleOptions: IChipOptionsProps | null = useCardOptions(type, {
    unitCurrency: currencyReference,
  });

  return (
    <div className='relative bg-white border border-b-[2px] border-neutral-40 rounded-10 shadow-[0_2px_0px_0_rgba(112,149,221,0.12)] w-full'>
      <div className='border-b border-neutral-40 py-10 px-16 flex items-center gap-10 text-14 font-bold bg-neutral-10 rounded-t-10'>
        {titleOptions.label}
        {titleOptions?.unit && (
          <>
            <span className='w-1 h-12 bg-neutral-20' />
            <span className='text-12 font-normal text-neutral-60'>{titleOptions.unit}</span>
          </>
        )}
      </div>
      <div className='px-16 py-6'>
        <CardStatValues values={values} size='md' />
      </div>
    </div>
  );
};
