import { useGetDomainMetaData } from 'src/use-cases/invoice/useInvoices';
import { LandingTopWidget } from '../../organisms/landing';
import { PerformanceAnalysisMain } from '../../organisms/performance/Main';

export const PerformanceAnalysis = () => {
  const { data: dataStatus } = useGetDomainMetaData();
  const defaultCurrency = dataStatus?.defaultCurrency || null;

  return (
    <div className='h-full overflow-auto bg-neutral-10'>
      <div className='relative bg-neutral-10'>
        <LandingTopWidget />
        {defaultCurrency && <PerformanceAnalysisMain defaultCurrency={defaultCurrency} />}
      </div>
    </div>
  );
};
