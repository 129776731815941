/* eslint-disable max-len */
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from 'src/main/App.jsx';
import 'src/presentations/styles/index.css';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { envConfig } from './config';

const apiUrlsRegexes = [/http:\/\/localhost.*/, /.*execute-api\.ap-southeast-1\.amazonaws\.com.*/];

if (import.meta.env.PROD || ENABLE_LOCAL_SENTRY) {
  Sentry.init({
    dsn: 'https://f0447925649d29ad31f314e79ff6e2bb@o4507361442791424.ingest.de.sentry.io/4507361476935760',
    environment: ENABLE_LOCAL_SENTRY ? 'local' : envConfig.envLong,
    tracePropagationTargets: apiUrlsRegexes,
    release: APP_TAG_SHORT,
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        blockAllMedia: false,
        maskAllText: false,
        // Not sure if this works, the bodies are not logged
        networkDetailAllowUrls: apiUrlsRegexes,
        networkCaptureBodies: true,
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
