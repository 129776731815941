import { FC, ReactNode } from 'react';
import { UrlObject } from 'url';
import { Link } from 'react-router-dom';
import TransportMediumIcon from 'src/presentations/components/molecules/card/TransportMediumIcon';
import { TransportMedium } from './types';

interface ICardLandingProps {
  title: string;
  info: string;
  path: string | UrlObject;
  transportMedium?: TransportMedium;
  children?: ReactNode;
}

export const CardLanding: FC<ICardLandingProps> = (props) => {
  const { title, info, path, transportMedium, children } = props;

  return (
    <Link
      to={path}
      className='h-full bg-white border border-b-2 border-neutral-20 rounded-8 flex flex-col transition-all duration-300 hover:border-primary shadow-[0_4px_7px_0_rgba(0,0,0,0.06)]'
    >
      <div className='px-16 pt-20 pb-30 flex items-center gap-12'>
        {transportMedium && (
          <div className='w-36 h-36 rounded-full bg-[#F5F6FA] flex items-center justify-center'>
            <TransportMediumIcon medium={transportMedium} iconColor='#9F9F9F' />
          </div>
        )}
        <div className='flex flex-col'>
          <h3 className='text-16 font-medium'>{title}</h3>
          <p className='text-12 text-neutral-60'>{info}</p>
        </div>
      </div>
      {children && <div className='mt-auto bg-[#FAFBFF] border-t border-[#EBEDF1] py-10 px-16 flex items-center gap-4 rounded-b-8'>{children}</div>}
    </Link>
  );
};
