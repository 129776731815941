import { FC, useEffect, useState } from 'react';

import TablePagination from 'src/presentations/components/molecules/table/Pagination';
import { RefreshButton } from 'src/presentations/components/molecules/refreshButton';
import { TableContainer } from '@mui/material';
import { TableMain, TableWrapper } from 'src/presentations/components/molecules/table/StyledTable';
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { DownloadMenu } from 'src/presentations/components/molecules/dataTable/component/DownloadMenu';
import { DownloadButton } from 'src/presentations/components/molecules/dataTable/component/DownloadButton';
import { ITableProps } from '../../dataTable/types';
import { TableTab } from '../../analytics/table/useTable';

const getRowId = (row: Record<string, any>) => row?.id;

interface ISectionDetailTableProps extends ITableProps {
  tabName: TableTab;
  title: string;
  transportMedium: string;
}

export const SectionDetailTable: FC<ISectionDetailTableProps> = (props) => {
  const { data, title, transportMedium, totalItems, onChangePage, params, columns, isLoading, orderBy, orderMethod, onSorting, tabName } = props;

  const [moreActionEl, setMoreActionEl] = useState<HTMLButtonElement | null>(null);

  const [sorting, setSorting] = useState([{ id: orderBy, desc: orderMethod === 'desc' }]);

  const onClickMoreAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreActionEl(event.currentTarget);
  };

  const onCloseMoreAction = () => {
    setMoreActionEl(null);
  };

  useEffect(() => {
    const runSorting = (sortingConfig) => {
      if (sortingConfig.length > 0) {
        const config = sortingConfig[0];
        const method = config?.desc ? 'desc' : 'asc';
        onSorting(method, config?.id);
      } else {
        onSorting('asc', 'INVOICE_DATE');
      }
    };
    runSorting(sorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const tableProps = useMaterialReactTable({
    enablePinning: false,
    enableRowDragging: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSortingRemoval: false,
    enableEditing: true,
    editDisplayMode: 'table',
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    columns: columns as MRT_ColumnDef<Record<any, any>>[],
    data,
    getRowId,

    enableSorting: true,
    manualSorting: true,
    state: {
      sorting,
      isLoading,
    },
    onSortingChange: setSorting,
    renderEmptyRowsFallback: () => <p className='text-center text-16 font-medium uppercase text-neutral-40 py-30'>NO DATA AVAILABLE</p>,
  });

  return (
    <div className='relative bg-white border border-b-[2px] border-neutral-40 rounded-10 shadow-[0_2px_0px_0_rgba(112,149,221,0.12)] w-full'>
      <div className='flex items-center gap-24 px-20 py-16 border-b border-neutral-20'>
        <div className='flex-1 flex items-center gap-10'>
          <h3 className='text-16 font-medium'>Overview By Month</h3>
          <div className='px-6 py-2 rounded-full bg-primary text-white text-14 font-medium'>{totalItems || 0}</div>
        </div>
        <div className='ml-auto flex items-center gap-16'>
          <TablePagination total={totalItems} onChangePage={onChangePage} params={params} />
          <RefreshButton onClick={() => {}} />
          <DownloadButton onClickMoreAction={onClickMoreAction} />
          <DownloadMenu
            tableTab={tabName}
            moreActionEl={moreActionEl}
            onCloseMoreAction={onCloseMoreAction}
            otherProps={{
              serviceProvider: title,
              modality: transportMedium,
            }}
            customFileName={`${title}_${transportMedium}`}
            pageSize={25}
          />
        </div>
      </div>

      <div className='w-full bg-blue_grey-5 overflow-hidden border-b border-neutral-20 rounded-bl-2xl rounded-br-2xl'>
        <TableContainer className='rounded-b-2xl' component={TableWrapper}>
          <TableMain>
            <MaterialReactTable table={tableProps} />
          </TableMain>
        </TableContainer>
      </div>
    </div>
  );
};
