import { FC, useMemo } from 'react';
import IconCheckImg from 'src/assets/images/icons/tick-circle-primary.svg';
import { cn } from 'src/utils/utility';
import { IChipInvoiceStatusProps } from './types';

export const ChipInvoiceStatus: FC<IChipInvoiceStatusProps> = (props) => {
  const { status = '', count } = props;

  const isUpToDate = !status || status.length === 0;

  // Styles for the count box
  const countStyles = useMemo(() => {
    if (isUpToDate) {
      return 'text-white';
    }
    switch (status) {
      case 'FLAGGED':
        return 'bg-orange-30 text-white';
      case 'TO_APPROVE':
        return 'bg-[#269ECC] text-white';
      case 'TO_REJECT':
        return 'bg-red-40 text-white';
      case 'PENDING_DOCUMENT':
        return 'bg-[#FDCA40] text-white';
      default:
        return null;
    }
  }, [isUpToDate, status]);

  // Styles for the root container
  const rootStyles = useMemo(() => {
    if (isUpToDate) {
      return 'bg-[#d8ebe1] text-[#4CAF50]';
    }
    switch (status) {
      case 'FLAGGED':
        return 'bg-[#FAEFE5] text-orange-30';
      case 'TO_APPROVE':
        return 'bg-[#E1F0F9] text-[#269ECC]';
      case 'TO_REJECT':
        return 'bg-[#F8E1E9] text-red-40';
      case 'PENDING_DOCUMENT':
        return 'bg-[#FAF5E8] text-[#FDCA40]';
      default:
        return null;
    }
  }, [isUpToDate, status]);

  // Status name mapping
  const statusName = useMemo(() => {
    if (isUpToDate) {
      return 'Up To Date'; // Default status name for empty status
    }
    switch (status) {
      case 'FLAGGED':
        return 'Flagged';
      case 'TO_APPROVE':
        return 'To Approve';
      case 'TO_REJECT':
        return 'To Reject';
      case 'PENDING_DOCUMENT':
        return 'Pending Document';
      case 'UP_TO_DATE':
        return 'Up To Date';
      default:
        return null;
    }
  }, [isUpToDate, status]);

  if (!statusName) {
    return null;
  }

  return (
    <div className={cn('relative h-24 rounded-full group', rootStyles)}>
      <div className='p-2 flex items-center'>
        {count !== undefined ? (
          <div className={cn('h-20 rounded-full px-6 flex items-center justify-center', countStyles)}>
            <strong className='text-12 font-bold'>{count}</strong>
          </div>
        ) : (
          <div className={cn('h-20 rounded-full py-4 flex items-center justify-center', countStyles)}>
            <img src={IconCheckImg} alt='Up To Date' className='w-full' />
          </div>
        )}
        <div className='flex-1 overflow-hidden h-20 max-w-0 transition-[max-width] duration-500 group-hover:max-w-[1000px]'>
          <div className='pl-6 pr-10 flex items-center'>
            <strong className='text-12 font-bold leading-20'>{statusName}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
