/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { createRoutesFromElements, Route } from 'react-router-dom';

import LayoutMain from 'src/presentations/components/templates/Main';
import LayoutRoot from 'src/presentations/components/templates/Root';
import LoginPage from 'src/presentations/components/pages/loginPage';
import Superset from 'src/presentations/components/pages/superset';
import Analytics from 'src/presentations/components/pages/analytics';
import LandingPage from 'src/presentations/components/pages/landing';
import { PerformanceAnalysis } from 'src/presentations/components/pages/performance/PerformanceAnalysis';

import NotFound from 'src/containers/NotFound';
import RequireAuth from './RequireAuth';

const Dashboard = lazy(() => import('src/containers/Dashboard'));
const ActionDetails = lazy(() => import('src/containers/actionDetails'));
const ContractManager = lazy(() => import('src/containers/contractManager/ContractManager'));

export const routesConfig = createRoutesFromElements(
  <>
    <Route path='/login' element={<LoginPage />} />

    <Route element={<LayoutRoot />}>
      <Route
        path='/'
        element={
          <RequireAuth>
            <LandingPage />
          </RequireAuth>
        }
      />
      <Route
        path='/analytics'
        element={
          <RequireAuth>
            <Analytics />
          </RequireAuth>
        }
      />
      <Route
        path='/analytics-metabase'
        element={
          <RequireAuth>
            <Superset />
          </RequireAuth>
        }
      />
      <Route
        element={
          <RequireAuth>
            <LayoutMain />
          </RequireAuth>
        }
      >
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/action-details/:id' element={<ActionDetails />} />
        <Route path='/performance' element={<PerformanceAnalysis />} />
        <Route path='/ratecard-manager' element={<ContractManager />} />
      </Route>
    </Route>
    <Route path='*' element={<NotFound />} />
  </>
);
