export type TransportMedium = 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER';

export enum CardTheme {
  ORANGE = 'orange',
  GREEN = 'green',
  BLUE = 'blue',
  BLUE_LIGHT = 'blue_light',
  NEUTRAL = 'neutral',
  PURPLE = 'purple',
  PURPLE_LIGHT = 'purple_light',
  PINK = 'pink',
  RED = 'RED',
}

export enum ChartType {
  LINE = 'line',
  BAR = 'bar',
}
