import { ICustomIconProps } from './types';

const IconChartLine: React.FC<ICustomIconProps> = ({ size = 17, color = '#808080' }) => (
  <svg width={size} height={size} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 11.6667L5.39191 8.27475C6.13313 7.53354 7.29448 7.41854 8.16667 8V8C9.03885 8.58146 10.2002 8.46646 10.9414 7.72524L13.6667 5'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export default IconChartLine;
