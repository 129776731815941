/* eslint-disable no-console */
import { useEffect } from 'react';
import axios from 'axios';
import { embedDashboard } from '@superset-ui/embedded-sdk';

const AnalyticsContainer = () => {
  const fetchGuestTokenFromBackend = () =>
    new Promise<string>((resolve) => {
      axios.get('https://superset.dev.tetrixx.io:8443/fetchGuestToken').then((response) => {
        console.log(response);
        resolve(response.data);
      });
    });

  useEffect(() => {
    embedDashboard({
      id: '82ee6db4-ad6e-437b-9259-fc8454474a66', // given by the Superset embedding UI
      supersetDomain: 'https://superset.dev.tetrixx.io',
      mountPoint: document.getElementById('my-superset-container'), // any html element that can contain an iframe
      fetchGuestToken: () => fetchGuestTokenFromBackend(),
      dashboardUiConfig: {
        // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
        hideTitle: true,
      },
    });

    const iframe = document.querySelector('#my-superset-container iframe') as HTMLIFrameElement;
    if (iframe) {
      iframe.style.width = '100%'; // Set width to 100%
      iframe.style.height = '100%'; // Set height to 100%
    }
  }, []);

  return (
    <div id='my-superset-container' className='w-full h-full'>
      {' '}
      Analytics Page
    </div>
  );
};
export default AnalyticsContainer;
