import { useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import { ChartResponseDto } from 'src/generated/services/TFinancialApi';
import { SimpleFormatNumber } from 'src/utils/utility';
import { ModalConfirm } from 'src/presentations/components/molecules/modal';
import { useFormContext } from 'react-hook-form';
import { useAssignFapRef } from 'src/use-cases/invoice/useFapRef';
import { FapRefModal } from './FapRefModal';
import { TableTab } from './useTable';

interface IMassActionsProps {
  selectedItems: any[];
  chartData: ChartResponseDto;
  handleClearSelection(): void;
  tableTab: TableTab;
  page: {
    pageNumber: number;
    pageSize: number;
  };
  refetchData: () => void;
}

const MassActions: React.FC<IMassActionsProps> = ({ selectedItems, chartData, handleClearSelection, page, tableTab, refetchData }) => {
  const [isShowFapRef, setIsShowFapRef] = useState<boolean>(false);
  const [isShowFapRefConfirm, setIsShowFapRefConfirm] = useState<boolean>(false);
  const [isLoadingFapRef, setIsLoadingFapRef] = useState<boolean>(false);
  const [fapRef, setFapRef] = useState<string>('');

  const { watch } = useFormContext();
  const { name, description, ...formValues } = watch();

  const { mutateAsync } = useAssignFapRef({
    formValues,
    page,
  });

  const isVisible = selectedItems?.length > 0;
  const totalAmounts = useMemo(() => {
    let total = 0;
    if (selectedItems && selectedItems?.length > 0) {
      selectedItems.forEach((item) => {
        if (item?.totalAmount) {
          total += parseInt(item?.totalAmount, 10);
        }
      });
    }
    return total;
  }, [selectedItems]);

  const onSubmitFapRef = async () => {
    if (fapRef && fapRef !== '' && selectedItems?.length > 0) {
      setIsLoadingFapRef(true);
      const invoiceIds = selectedItems.map((item) => item?.invoiceId);
      await mutateAsync({
        fapRef,
        invoiceIds,
      });
    }
    handleClearSelection();
    setIsShowFapRefConfirm(false);
    setIsShowFapRef(false);
    setIsLoadingFapRef(false);
    refetchData();
  };

  return (
    <>
      <div
        className={`fixed z-[9999] bottom-20 left-[50%] -translate-x-[50%] flex items-center justify-center transition-all duration-300 ${
          isVisible ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
      >
        <div className='flex items-center bg-neutral-80 text-white rounded-8 p-12'>
          <div className='flex items-center gap-16'>
            <button aria-label='Clear' type='button' className='flex items-center justify-center' onClick={handleClearSelection}>
              <ClearIcon style={{ color: '#dddddd', fontSize: 16 }} />
            </button>
            <div className='text-14'>{selectedItems?.length} items selected</div>

            <div className='w-1 h-14 bg-[#68747B]' />
            <strong className='text-white text-14 font-medium'>
              {chartData?.meta?.currency} {SimpleFormatNumber(totalAmounts)}
            </strong>
          </div>
          <div className='ml-[90px] flex items-center gap-12'>
            {tableTab === TableTab.INVOICES && (
              <Button variant='contained' onClick={() => setIsShowFapRef(true)}>
                Assign FAP REF
              </Button>
            )}
          </div>
        </div>
      </div>

      <FapRefModal
        selectedItems={selectedItems}
        chartData={chartData}
        isShow={isShowFapRef}
        onClose={() => {
          setIsShowFapRef(false);
        }}
        onSuccess={(data) => {
          setFapRef(data?.fap_ref_number);
          setIsShowFapRef(false);
          setIsShowFapRefConfirm(true);
        }}
      />
      <ModalConfirm
        open={isShowFapRefConfirm}
        title='Confirmation'
        isLoading={isLoadingFapRef}
        onAccept={onSubmitFapRef}
        onCancel={() => {
          setIsShowFapRefConfirm(false);
          setIsShowFapRef(true);
        }}
      >
        <>Are you sure you want to proceed with this?</>
      </ModalConfirm>
    </>
  );
};

export default MassActions;
