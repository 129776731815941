/**
 * Date utils
 */
import format from 'date-fns/format';
import { DATE_FORMAT } from 'src/config/configString';

/**
 * formatDate
 */
export const formatDateString = (value: string, fallback = '-') => {
  if (!value) return fallback;
  return formatDate(new Date(value));
};

export const formatDate = (value: Date, fallback = '-') => {
  if (!value) return fallback;
  return format(value, DATE_FORMAT);
};
