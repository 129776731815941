import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC, MouseEvent as ReactMouseEvent, useState } from 'react';

export type BaseButtonColorType = 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

export type SubmitButtonProps = Omit<ButtonProps, 'type'> & {
  color?: BaseButtonColorType;
  isSubmitting?: boolean;
  onClick?: (event?: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  testId?: string;
};

export const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { children, color, disabled, isSubmitting, onClick, testId = 'submit-button', ...rest } = props;

  const [internalIsSubmitting, setInternalIsSubmitting] = useState(false);

  return (
    <Button
      color={color ?? 'primary'}
      data-testid={testId}
      disabled={disabled ?? isSubmitting ?? internalIsSubmitting}
      onClick={
        onClick
          ? async (event) => {
              setInternalIsSubmitting(true);
              await (onClick as (event?: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>)(event);
              setInternalIsSubmitting(false);
            }
          : undefined
      }
      sx={(theme) => ({
        ':disabled': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
        },
      })}
      type='submit'
      variant='contained'
      {...rest}
    >
      {children}
      {(isSubmitting ?? internalIsSubmitting) && <CircularProgress size={20} sx={{ marginLeft: '1em' }} />}
    </Button>
  );
};
