export const awsConfig = {
  aws_project_region: window.env.AWS_REGION,
  aws_cognito_region: window.env.AWS_REGION,
  aws_user_pools_id: window.env.AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: window.env.AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: window.env.AWS_OATH_DOMAIN,
    redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
    redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
    scope: ['openid', 'email', 'phone', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
  },
};

export const apiConfig = {
  tfinapiEndpointAuthUser: window.env.TFINAPI_ENDPOINT_AUTHUSER,
  tfinapiEndpointAuthRole: window.env.TFINAPI_ENDPOINT_AUTHROLE,
};

export const envConfig = {
  envShort: window.env.ENV,
  // ToDo: Remove || 'tetrixx' after all envs are updated
  envLong: `${window.env.ACCOUNT_NAME || 'tetrixx'}_${window.env.ENV}`,
};

export const ACCEPT_FILE = {
  'application/pdf': [],
  'image/png': [],
  'image/jpeg': [],
  'image/gif': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.sealed.eml': ['.eml'],
  'application/vnd.ms-outlook': ['.msg'],
};
