import React, { PropsWithChildren } from 'react';

export type TButtonContentVariant = 'primary' | 'primary_outline' | 'secondary' | 'white' | 'white_outline' | 'neutral_outline' | 'danger' | 'danger_outline';
export type TButtonContentState = 'active' | 'disabled' | 'loading';

interface IButtonContentProps {
  state?: TButtonContentState;
}

const ButtonContent: React.FC<PropsWithChildren<IButtonContentProps>> = ({ state = 'active', children }) => (
  <>{state === 'loading' ? <div>Loading...</div> : children}</>
);

export default ButtonContent;
