import clsx from 'clsx';
import { ICustomIconProps } from 'src/presentations/components/atom/icons/types';

const IconRouting: React.FC<ICustomIconProps> = ({ className }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className={clsx('relative w-[1em] h-[1em]', className)}>
    <path
      d='M1.72488 3.8334C2.39155 0.950065 6.73322 0.950065 7.39155 3.8334C7.78322 5.52507 6.70822 6.9584 5.77488 7.85007C5.09155 8.50007 4.01655 8.49173 3.33322 7.85007C2.40822 6.9584 1.33322 5.52507 1.72488 3.8334Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M12.5583 13.8334C13.225 10.9501 17.5917 10.9501 18.2583 13.8334C18.65 15.5251 17.575 16.9584 16.6333 17.8501C15.95 18.5001 14.8667 18.4917 14.1833 17.8501C13.2417 16.9584 12.1667 15.5251 12.5583 13.8334Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M9.99991 4.1665H12.2332C13.7749 4.1665 14.4916 6.07484 13.3332 7.0915L6.67491 12.9165C5.51658 13.9248 6.23325 15.8332 7.76658 15.8332H9.99991'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4.57209 4.58317H4.58172' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.4051 14.5832H15.4147' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default IconRouting;
