import { ICustomIconProps } from './types';

const IconShip: React.FC<ICustomIconProps> = ({ size = 20, color = '#30C5FF' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.0241 12.2167L17.6825 13.7667C17.0908 16.4333 14.9991 18.3333 11.9825 18.3333H8.0158C4.99913 18.3333 2.90746 16.4333 2.3158 13.7667L1.97413 12.2167C1.80746 11.4583 2.25746 10.6 2.98246 10.3083L4.1658 9.83333L8.75746 7.99167C9.15746 7.83333 9.58246 7.75 9.99913 7.75C10.4158 7.75 10.8408 7.83333 11.2408 7.99167L15.8325 9.83333L17.0158 10.3083C17.7408 10.6 18.1908 11.4583 18.0241 12.2167Z'
      fill={color}
    />
    <path
      d='M15.8327 6.6665V7.8665C15.8327 8.15817 15.5327 8.3665 15.2577 8.24984L11.6993 6.82484C10.6077 6.39984 9.39102 6.39984 8.29102 6.83317L4.74102 8.25817C4.46602 8.37484 4.16602 8.1665 4.16602 7.87484V6.6665C4.16602 5.2915 5.29102 4.1665 6.66602 4.1665H13.3327C14.7077 4.1665 15.8327 5.2915 15.8327 6.6665Z'
      fill={color}
    />
    <path
      d='M12.0827 4.1665H7.91602V2.49984C7.91602 2.0415 8.29102 1.6665 8.74935 1.6665H11.2493C11.7077 1.6665 12.0827 2.0415 12.0827 2.49984V4.1665Z'
      fill={color}
    />
  </svg>
);

export default IconShip;
