import CloseIcon from '@mui/icons-material/Close';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { RhfMuiTextField } from 'src/presentations/components/molecules/fields/rhf-mui-fields';
import { useFieldMaterialUIStyles } from 'src/presentations/components/molecules/fields/useFieldMaterialUIStyles';
import { SimpleFormatNumber } from 'src/utils/utility';

interface IFapRefFormProps {
  currency: string;
  selectedItems: any[];
  onClose(): void;
  onSubmit(): void;
}

const CustomTextField = styled(RhfMuiTextField)({
  backgroundColor: '#fff',
  color: '#9F9F9F',
  '& .MuiInputBase-root': {
    backgroundColor: '#fff!important',
  },
});

export const FapRefForm: React.FC<IFapRefFormProps> = (props) => {
  const { onClose, onSubmit, selectedItems, currency } = props;
  const { classes } = useFieldMaterialUIStyles();

  const { setValue } = useFormContext();

  const haveValuesCount = useMemo(() => selectedItems.filter((item) => item?.fapRef && item?.fapRef !== '')?.length, [selectedItems]);

  const emptyCount = useMemo(() => selectedItems.filter((item) => !item?.fapRef || item?.fapRef === '')?.length, [selectedItems]);

  const totalAmounts = useMemo(() => {
    let total = 0;
    if (selectedItems && selectedItems?.length > 0) {
      selectedItems.forEach((item) => {
        if (item?.totalAmount) {
          total += parseInt(item?.totalAmount, 10);
        }
      });
    }
    return total;
  }, [selectedItems]);

  return (
    <div className='relative'>
      <div className='flex items-center mb-24'>
        <h3 className='text-24 font-bold'>Confirmation</h3>
        <div className='ml-auto'>
          <button type='button' onClick={onClose} aria-label='Close'>
            <CloseIcon />
          </button>
        </div>
      </div>

      <p className='mt-20 text-16 text-[#475B69]'>
        You selected <strong>{selectedItems?.length} invoices</strong>&nbsp; for a <strong>total amount</strong> of{' '}
        <strong>
          {currency} {SimpleFormatNumber(totalAmounts)}
        </strong>
      </p>

      <div className='mt-20 flex items-center gap-16'>
        {haveValuesCount > 0 && (
          <div className={clsx('p-16 rounded-8 bg-neutral-10', emptyCount > 0 ? 'w-1/2 ' : 'w-full')}>
            <p>
              <strong className='font-bold text-24'>{haveValuesCount}</strong>
            </p>
            <p className='text-14 text-blue_grey-80'>FAP Ref. will be overwritten</p>
          </div>
        )}
        {emptyCount > 0 && (
          <div className={clsx('p-16 rounded-12 bg-neutral-10', haveValuesCount > 0 ? 'w-1/2 ' : 'w-full')}>
            <p>
              <strong className='font-bold text-24'>{emptyCount}</strong>
            </p>
            <p className='text-14  text-blue_grey-80'>FAP Ref. will be newly assigned</p>
          </div>
        )}
      </div>

      <p className='mt-20 text-16 text-[#475B69]'>Please input the FAP Ref. to proceed:</p>

      <div className='mt-16'>
        <CustomTextField name='fap_ref_number' className={classes.inputField} placeholder='Input FAP Ref.' fullWidth />
      </div>

      <div className='mt-48 flex items-center gap-12'>
        <div className='w-1/2'>
          <Button
            variant='outlined'
            type='button'
            fullWidth
            onClick={() => {
              setValue('po_number', '');
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
        <div className='w-1/2'>
          <Button type='submit' variant='contained' fullWidth onClick={onSubmit}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
