import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { sleep } from 'src/utils/utility';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocalStorageState } from 'ahooks';
import { useForm } from 'react-hook-form';

import { Box, Typography, Stack } from '@mui/material';

import { RhfMuiTextField } from '../fields/rhf-mui-fields';
import { FormContainer } from '../../organisms/forms/FormContainer';
import { useSaveReport, useUpdateReport } from '../../organisms/analytics/useAnalytics';

const bla: any = null;

export const SentryDebug: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSentryDebug, setShowSentryDebug] = useLocalStorageState('show-sentry-debug', {
    defaultValue: false,
  });

  const { mutateAsync: updateReport } = useUpdateReport();
  const { mutateAsync: saveReport } = useSaveReport();

  const defaultValues = {
    errorText: 'Test Sentry',
  };

  const formContext = useForm<Record<string, string>>({
    defaultValues: defaultValues as Record<string, string>,
  });

  const showSentryDebugParam = useMemo(() => searchParams.get('showSentryDebug'), [searchParams]);

  useEffect(() => {
    if (showSentryDebugParam) {
      setShowSentryDebug(true);
    }
  }, [setShowSentryDebug, showSentryDebugParam]);

  const [showRenderError, setShowRenderError] = useState(false);

  return (
    <>
      {showSentryDebug && (
        <FormContainer formContext={formContext}>
          <Stack direction='row' alignItems='center'>
            {ENABLE_LOCAL_SENTRY && <Typography marginRight={1}>Local Sentry</Typography>}
            <Typography marginRight={1}>{APP_TAG_SHORT}</Typography>

            <div>
              <RhfMuiTextField name='errorText' placeholder='Error Text' />
            </div>
            {showRenderError && <Box>{bla.nope}</Box>}
            <Button
              onClick={formContext.handleSubmit(async (values: typeof defaultValues, e) => {
                e.preventDefault();

                setTimeout(() => {
                  throw new Error(values.errorText);
                }, 0);
                await sleep(500);
              })}
            >
              Log Error
            </Button>
            <Button onClick={() => setShowRenderError(true)}>Render Error</Button>
            <Button
              onClick={async () => {
                updateReport({ id: 'lol' });
              }}
            >
              Update Report - 400
            </Button>
            <Button
              onClick={async () => {
                saveReport({});
              }}
            >
              Save Report - 500
            </Button>
            <Button
              onClick={() => {
                setShowSentryDebug(false);
                searchParams.delete('showSentryDebug');
                setSearchParams(searchParams);
              }}
            >
              Hide
            </Button>
          </Stack>
        </FormContainer>
      )}
    </>
  );
};
