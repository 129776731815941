import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ContractChargeListsDto,
  ExistingShippingServiceDto,
  NewShippingServiceAndContractRequestDto,
  NoContractDraft,
  NoContractDraftData,
  NoContractResponseDto,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useActiveNoContractDraft = (invoiceId: string): UseQueryResult<NoContractDraft> =>
  useQuery(
    cacheKeysUtil.activeNoContractDraft(invoiceId),
    async (): Promise<NoContractDraft> => {
      const response = await tFinancialService.api.getActiveNoContractDraft(invoiceId);
      const { data } = response;
      return data.data;
    },
    {
      enabled: !!invoiceId,
    }
  );

export const useMatchingServicesForNoContract = (invoiceId: string): UseQueryResult<NoContractResponseDto> =>
  useQuery(
    cacheKeysUtil.matchingServicesForNoContract(invoiceId),
    async (): Promise<NoContractResponseDto> => {
      const response = await tFinancialService.api.getMatchingServicesForNoContractInvoice(invoiceId);
      const { data } = response;
      return data.data;
    },
    {
      enabled: !!invoiceId,
    }
  );

export const useContractChargesDetailsForShippingService = (contractFlowId: string): UseQueryResult<ContractChargeListsDto> =>
  useQuery(
    cacheKeysUtil.contractChargesDetailsForShippingService(contractFlowId),
    async (): Promise<ContractChargeListsDto> => {
      const response = await tFinancialService.api.getContractChargesDetailsForShippingService(contractFlowId);
      const { data } = response;
      return data.data;
    },
    {
      enabled: !!contractFlowId,
    }
  );

export const useIsValidityPeriodValid = (invoiceId: string) =>
  useMutation(async (existingShippingService: ExistingShippingServiceDto) => tFinancialService.api.isValidityPeriodValid(invoiceId, existingShippingService));
export const useUpdateNoContractDraft = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: NoContractDraftData) => tFinancialService.api.createOrUpdateNoContractDraft(invoiceId, { data }), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.activeNoContractDraft(invoiceId))]);
    },
  });
};

export const useDiscardNoContractDraft = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(async () => tFinancialService.api.discardNoContractDraft(invoiceId), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.activeNoContractDraft(invoiceId))]);
    },
  });
};

export const useCreateShippingServiceAndContract = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: NewShippingServiceAndContractRequestDto) => tFinancialService.api.createShippingServiceAndContract(invoiceId, data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.activeNoContractDraft(invoiceId))]);
    },
  });
};
