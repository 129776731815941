/**
 * InputPeriod
 */
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { ReactNode } from 'react';
import { FieldLabel } from 'src/presentations/components/molecules/fields/FieldLabel';

interface IInputPeriodProps {
  label?: ReactNode;
  placeholder?: any;
  name: string;
}

export interface IDateTimePickerProps<T = any> extends DatePickerProps<T> {
  name: string;
}

const StyledDatePicker = styled(DatePicker)({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: '#F5F6FA',
    borderColor: '#BFBFBF',
  },
  '& .MuiInputBase-input': {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
});

export const RhfMuiDatePicker: React.FC<IInputPeriodProps> = ({ label }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <FieldLabel label={label}>
      <div className='flex items-center gap-8'>
        <p className='text-12 leading-16 mb-8 text-blue_grey-80 '>Start Date</p>
        <StyledDatePicker />
      </div>
    </FieldLabel>
  </LocalizationProvider>
);
