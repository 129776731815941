import { ReactElement } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface IModalProps extends DialogProps {
  open: boolean;
  children: ReactElement;
  title?: string;
  onClose?: () => void;
}

export const Modal: React.FC<IModalProps> = (props) => {
  const { open, title, children, onClose, ...others } = props;

  return (
    <Dialog open={open} {...others}>
      {title && (
        <DialogTitle>
          {title}

          {onClose ? (
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
