import { configureStore } from '@reduxjs/toolkit';
import { IApiState } from 'src/infra/stores/reducer/apiSlice';
import { ISearchState } from 'src/infra/stores/reducer/searchSlice';

import { storage } from './localStorage';
import reducer from './reducer/index';
import { AuthState } from './reducer/auth';

export interface IRootState {
  readonly auth: AuthState;
  readonly api: IApiState;
  readonly search: ISearchState;
}

/**
 * load state from local storage
 */
const loadState = (): IRootState => {
  try {
    const localStorage = storage.get(import.meta.env.VITE_LOCAL_STORAGE_NAME);

    if (localStorage === null) {
      return undefined;
    }
    const preloadData = localStorage?.auth ? { auth: localStorage.auth } : {};

    return preloadData as IRootState;
  } catch (e) {
    return undefined;
  }
};

export const initStore = (preloadedState: Partial<IRootState> = loadState()) => {
  const store = configureStore({
    reducer,
    preloadedState,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //   serializableCheck: false
    // }).concat(logger)
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  // save to local storage if redux state is changed
  store.subscribe(() => {
    let currentStorage = storage.get(import.meta.env.VITE_LOCAL_STORAGE_NAME);
    if (!currentStorage) {
      currentStorage = {};
    }

    storage.store(import.meta.env.VITE_LOCAL_STORAGE_NAME, {
      ...currentStorage,
      // auth: store.getState().auth,
    });
  });

  return store;
};

export type AppDispatch = ReturnType<typeof initStore>['dispatch'];
