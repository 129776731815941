import { useFormContext } from 'react-hook-form';

import { NoContractSection } from './Section';
import { TableShippingService } from './TableShippingService';
import { NoContractForm, NoContractStep } from './types';

export const NoContractSectionSelected = ({ activeStep }: { activeStep: NoContractStep }) => {
  const { watch } = useFormContext<NoContractForm>();
  const { selectedPartialMatchingService } = watch();

  return (
    <NoContractSection title='Selected Partial Contract Shipping Service' description='Partially matching contract shipping service that you selected'>
      <TableShippingService name='selected-partial' data={selectedPartialMatchingService ? [selectedPartialMatchingService] : []} activeStep={activeStep} />
    </NoContractSection>
  );
};
