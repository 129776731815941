import { FC, useMemo } from 'react';
import { cn } from 'src/utils/utility';
import IconChartLine from 'src/presentations/components/atom/icons/ChartLine';
import IconChartBar from 'src/presentations/components/atom/icons/ChartBar';
import { CircularProgress } from '@mui/material';
import { CardTheme, ChartType } from './types';
import { ICardAnalyticItemProps } from './CardSelector';

interface ICardAnalyticContentProps {
  item: ICardAnalyticItemProps;
  isLoading: boolean;
  isHideIconType?: boolean;
  readOnly?: boolean;
  isEnable?: boolean;
  isActive?: boolean;
}

export const CardAnalyticContent: FC<ICardAnalyticContentProps> = (props) => {
  const { item, isLoading, isHideIconType, readOnly, isActive, isEnable } = props;
  const { title, cardTitle, count, icon, theme = CardTheme.NEUTRAL, chartType = ChartType.LINE } = item;

  const borderColor = useMemo(() => {
    if (isActive && isEnable) {
      switch (theme) {
        case CardTheme.NEUTRAL:
          return 'border-[#A9B2C9]';
        case CardTheme.ORANGE:
          return 'border-[#F79824]';
        case CardTheme.GREEN:
          return 'border-[#3DA264]';
        case CardTheme.BLUE:
          return 'border-[#4879F6]';
        case CardTheme.BLUE_LIGHT:
          return 'border-[#5A9CFF]';
        case CardTheme.PURPLE:
          return 'border-[#A375B8]';
        case CardTheme.PURPLE_LIGHT:
          return 'border-[#CB6FF6]';
        case CardTheme.PINK:
          return 'border-[#ED96DA]';
        case CardTheme.RED:
          return 'border-red-40';
        default:
          return 'border-[#D3D7DF]';
      }
    } else if (!readOnly && isEnable) {
      let hoverColor = '';
      switch (theme) {
        case CardTheme.NEUTRAL:
          hoverColor = 'hover:border-[#A9B2C9]';
          break;
        case CardTheme.ORANGE:
          hoverColor = 'hover:border-[#F79824]';
          break;
        case CardTheme.GREEN:
          hoverColor = 'hover:border-[#3DA264]';
          break;
        case CardTheme.BLUE:
          hoverColor = 'hover:border-[#4879F6]';
          break;
        case CardTheme.BLUE_LIGHT:
          hoverColor = 'hover:border-[#5A9CFF]';
          break;
        case CardTheme.PURPLE:
          hoverColor = 'hover:border-[#A375B8]';
          break;
        case CardTheme.PURPLE_LIGHT:
          hoverColor = 'hover:border-[#CB6FF6]';
          break;
        case CardTheme.PINK:
          hoverColor = 'hover:border-[#ED96DA]';
          break;
        case CardTheme.RED:
          hoverColor = 'hover:border-red-40';
          break;
        default:
          hoverColor = 'bg-neutral-10';
      }

      return `border-[#D3D7DF] ${hoverColor}`;
    }
    return 'border-[#D3D7DF]';
  }, [isActive, readOnly, isEnable, theme]);

  const iconBgColor = useMemo(() => {
    if (isEnable) {
      switch (theme) {
        case CardTheme.NEUTRAL:
          return 'bg-[#E9EBF1]';
        case CardTheme.ORANGE:
          return 'bg-[#FEF3E5]';
        case CardTheme.GREEN:
          return 'bg-[#E0F3E6]';
        case CardTheme.BLUE:
          return 'bg-[#E9EFFE]';
        case CardTheme.BLUE_LIGHT:
          return 'bg-[#EBF3FF]';
        case CardTheme.PURPLE:
          return 'bg-[#EEE5F2]';
        case CardTheme.PURPLE_LIGHT:
          return 'bg-[#F9EEFE]';
        case CardTheme.PINK:
          return 'bg-[#FAE4F6]';
        case CardTheme.RED:
          return 'bg-[#FCE5E9]';
        default:
          return 'bg-neutral-10';
      }
    }
    return 'bg-neutral-10';
  }, [theme, isEnable]);

  const blurColor = useMemo(() => {
    switch (theme) {
      case CardTheme.NEUTRAL:
        return 'bg-[#A9B2C9]';
      case CardTheme.ORANGE:
        return 'bg-[#F9B766]';
      case CardTheme.GREEN:
        return 'bg-[#3DA264]';
      case CardTheme.BLUE:
        return 'bg-[#4879F6]';
      case CardTheme.BLUE_LIGHT:
        return 'bg-[#5A9CFF]';
      case CardTheme.PURPLE:
        return 'bg-[#702A91]';
      case CardTheme.PURPLE_LIGHT:
        return 'bg-[#CB6FF6]';
      case CardTheme.PINK:
        return 'bg-[#D921B0]';
      default:
        return 'bg-neutral-10';
    }
  }, [theme]);

  const iconChartColor = useMemo(() => {
    if (isActive && isEnable) {
      switch (theme) {
        case CardTheme.NEUTRAL:
          return '#A9B2C9';
        case CardTheme.ORANGE:
          return '#F9B766';
        case CardTheme.GREEN:
          return '#3DA264';
        case CardTheme.BLUE:
          return '#4879F6';
        case CardTheme.BLUE_LIGHT:
          return '#5A9CFF';
        case CardTheme.PURPLE:
          return '#702A91';
        case CardTheme.PURPLE_LIGHT:
          return '#CB6FF6';
        case CardTheme.PINK:
          return '#D921B0';
        case CardTheme.RED:
          return '#E72547';
        default:
          return '#808080';
      }
    }
    return '#BFBFBF';
  }, [isActive, isEnable, theme]);

  const hoverShadowStyles = useMemo(() => {
    if (isActive && isEnable) {
      switch (theme) {
        case CardTheme.NEUTRAL:
          return 'hover:shadow-[0_0_3px_2px_rgba(73,92,140,0.35)]';
        case CardTheme.ORANGE:
          return 'hover:shadow-[0_0_3px_2px_rgba(247,152,36,0.35)]';
        case CardTheme.GREEN:
          return 'hover:shadow-[0_0_3px_2px_rgba(0,153,43,0.35)]';
        case CardTheme.BLUE:
          return 'hover:shadow-[0_0_3px_2px_rgba(68,118,246,0.35)]';
        case CardTheme.BLUE_LIGHT:
          return 'hover:shadow-[0_0_3px_2px_rgba(90,156,255,0.35)]';
        case CardTheme.PURPLE:
          return 'hover:shadow-[0_0_3px_2px_rgba(112,42,145,0.35)]';
        case CardTheme.PURPLE_LIGHT:
          return 'hover:shadow-[0_0_3px_2px_rgba(203,111,246,0.35)]';
        case CardTheme.PINK:
          return 'hover:shadow-[0_0_3px_2px_rgba(217,33,176,0.35)]';
        default:
          return 'hover:shadow-[0_0_3px_2px_rgba(73,92,140,0.35)]';
      }
    }
    return 'hover:shadow-[0_0_2px_1px_rgba(0,0,0,0.15)]';
  }, [isActive, isEnable, theme]);

  return (
    <div
      className={cn(
        'relative overflow-hidden bg-white border border-b-[3px] rounded-10 shadow-[0_2px_0px_0_rgba(112,149,221,0.12)] w-full px-[16px] py-[12px] flex flex-col items-start gap-y-10',
        'transition-all duration-300',
        hoverShadowStyles,
        borderColor
      )}
    >
      <div className='w-full flex items-center justify-between'>
        <div className={cn('w-[44px] h-[44px] rounded-full flex items-center justify-center', iconBgColor)}>
          <img src={icon} alt='' className={!isEnable ? 'grayscale' : ''} />
        </div>
        {!isHideIconType && <div>{chartType === ChartType.LINE ? <IconChartLine color={iconChartColor} /> : <IconChartBar color={iconChartColor} />}</div>}
      </div>
      <div>
        <div className='text-left'>
          {isLoading ? (
            <CircularProgress color='primary' />
          ) : (
            <>
              <h4 className={cn('text-2xl font-bold', isEnable ? 'text-blue_grey-90' : 'text-neutral-50')}>{count}</h4>
              <p className={cn('text-[12px]', isEnable ? 'text-neutral-60' : 'text-neutral-50')}>{cardTitle ?? title}</p>
            </>
          )}
        </div>
      </div>

      <div className={cn('w-[120px] h-[120px] rounded-full blur-[70px] absolute top-[-90px] right-[-70px] opacity-0', isActive && 'opacity-100', blurColor)} />
    </div>
  );
};
