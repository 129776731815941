import { ICustomIconProps } from './types';

const IconAeroplane: React.FC<ICustomIconProps> = ({ size = 20, color = '#30C5FF' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.0505 10.6301L15.3805 8.62006L14.3405 8.18006C14.1805 8.10006 14.0405 7.89006 14.0405 7.71006V4.65006C14.0405 3.69006 13.3305 2.55006 12.4705 2.11006C12.1705 1.96006 11.8105 1.96006 11.5105 2.11006C10.6605 2.55006 9.95055 3.70006 9.95055 4.66006V7.72006C9.95055 7.90006 9.81055 8.11006 9.65055 8.19006L3.95055 10.6401C3.32055 10.9001 2.81055 11.6901 2.81055 12.3701V13.6901C2.81055 14.5401 3.45055 14.9601 4.24055 14.6201L9.25055 12.4601C9.64055 12.2901 9.96055 12.5001 9.96055 12.9301V14.0401V15.8401C9.96055 16.0701 9.83055 16.4001 9.67055 16.5601L7.35055 18.8901C7.11055 19.1301 7.00055 19.6001 7.11055 19.9401L7.56055 21.3001C7.74055 21.8901 8.41055 22.1701 8.96055 21.8901L11.3405 19.8901C11.7005 19.5801 12.2905 19.5801 12.6505 19.8901L15.0305 21.8901C15.5805 22.1601 16.2505 21.8901 16.4505 21.3001L16.9005 19.9401C17.0105 19.6101 16.9005 19.1301 16.6605 18.8901L14.3405 16.5601C14.1705 16.4001 14.0405 16.0701 14.0405 15.8401V12.9301C14.0405 12.5001 14.3505 12.3001 14.7505 12.4601L19.7605 14.6201C20.5505 14.9601 21.1905 14.5401 21.1905 13.6901V12.3701C21.1905 11.6901 20.6805 10.9001 20.0505 10.6301Z'
      fill={color}
    />
  </svg>
);

export default IconAeroplane;
