import { FC, useEffect, useRef } from 'react';

import { useNotificationSelector } from './NotificationSelector';
import { INotificationContentProps } from './types';
import { NotificationContentHeader } from './ContentHeader';
import { NotificationContentMain } from './ContentMain';
import { NotificationContentEmpty } from './ContentEmpty';

export const NotificationContent: FC<INotificationContentProps> = (props) => {
  const { allNotifications } = props;
  const { isShowContent, setIsShowContent } = useNotificationSelector();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const ddMenu = document.getElementById('menu-');
      const triggerMenu = document.getElementById('btn-trigger-notif');
      const inDdMenu = ddMenu && ddMenu.contains(event.target);
      const inTriggerMenu = triggerMenu && triggerMenu.contains(event.target);

      if (contentRef.current && !contentRef.current.contains(event.target) && !inDdMenu && !inTriggerMenu) {
        setIsShowContent(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contentRef, setIsShowContent]);

  if (!isShowContent) {
    return null;
  }

  return (
    <div
      ref={contentRef}
      className='absolute top-[64px] right-[36px] w-[90vw] max-w-[760px] bg-white text-blue_grey-90 border border-[#DFDFDF] rounded-8 z-[30] flex flex-col max-h-[80vh] shadow-custom'
    >
      <NotificationContentHeader allNotifications={allNotifications} />

      {allNotifications?.length > 0 ? <NotificationContentMain allNotifications={allNotifications} /> : <NotificationContentEmpty />}
    </div>
  );
};
