import { FC } from 'react';
import { ICardStatProps } from './types';
import { CardStatChip } from './CardStatChip';
import { CardStatValues } from './CardStatValues';

export const CardStat: FC<ICardStatProps> = ({ type, values, size }) => (
  <div className='relative pt-16 px-16 pb-6 bg-white border border-b-[3px] border-grey-30 rounded-10 shadow-[0_2px_0px_0_rgba(112,149,221,0.12)] w-full'>
    <CardStatChip type={type} />
    <div className='mt-12'>
      <CardStatValues values={values} size={size} />
    </div>
  </div>
);
