import { styled } from '@mui/material/styles';
import Button from 'src/presentations/components/atom/button';
import Modal from 'src/presentations/components/molecules/modal';
import { useCallback, useState } from 'react';
import { openToast } from 'src/use-cases/toast/useToast';
import { compressToURI } from 'lz-ts';
import { stringify } from 'superjson';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useSaveReport, useUpdateReport } from '../useAnalytics';
import { UpdateChartReportOption } from './UpdateChartReportOption';
import { CreateChartReportOption } from './CreateChartReportOption copy';

interface IModalProps {
  open: boolean;
  title: string;
  buttonLabel?: string;
  onClose: () => void;
  setDefaultFilters: any;
  selectedChartView;
}

const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '540px',
    borderRadius: '16px',
  },
});

enum SaveType {
  UPDATE,
  CREATE,
}

export const SaveReportModal: React.FC<IModalProps> = (props) => {
  const { open, title, onClose, setDefaultFilters, selectedChartView } = props;
  const [saveType, setSaveType] = useState<SaveType>(null);
  const { mutateAsync: createReport } = useSaveReport();
  const { mutateAsync: updateReport } = useUpdateReport();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { watch } = useFormContext();

  const { name, description, ...formValues } = watch();

  const onCloseModal = () => {
    setSaveType(null);
    onClose();
  };

  const saveReport = useCallback(
    (selectedChartView, formValues) => {
      const currentReport = {
        name,
        description,
        filters: {
          selectedChartView,
          formValues,
        },
      };

      if (saveType === SaveType.CREATE) {
        createReport({
          name,
          description,
          hashedFilters: compressToURI(stringify(currentReport)),
        });
      } else {
        updateReport({
          id,
          name,
          description,
          hashedFilters: compressToURI(stringify(currentReport)),
        });
      }
      onClose();
      openToast({
        toastType: 'success',
        title: `${name} successfully saved`,
      });
      setDefaultFilters(currentReport);
    },
    [createReport, description, id, name, onClose, saveType, setDefaultFilters, updateReport]
  );

  return (
    <StyledModal open={open} title={title} fullWidth onClose={onCloseModal}>
      <div className='relative '>
        <div className='flex flex-col gap-[24px]'>
          <UpdateChartReportOption disabled={!id} onUpdate={() => setSaveType(SaveType.UPDATE)} isChosen={saveType === SaveType.UPDATE} />
          <CreateChartReportOption onCreate={() => setSaveType(SaveType.CREATE)} isChosen={saveType === SaveType.CREATE} />
        </div>

        <div className='mt-48 flex gap-[12px]'>
          <Button className='basis-1/2' variant='primary_outline' onClick={onCloseModal}>
            Cancel
          </Button>
          <Button className='basis-1/2' variant='primary' onClick={() => saveReport(selectedChartView, formValues)} disabled={saveType === null}>
            Save
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};
