import {
  CourierInvoiceDisputeDto,
  DeviationDto,
  DeviationQuestionDto,
  InvoiceCourierShipmentDetailDto,
  InvoiceDetailDto,
  InvoiceSummaryDto,
} from 'src/generated/services/TFinancialApi';

export const mockInvoiceSummary: InvoiceSummaryDto = {
  id: '7ee404f2-d97e-4743-b152-181b180518f5',
  invoiceNumber: '3527903',
  invoiceDisputeId: '33d1559b-93c7-4a35-95b6-5fde33c9e8fd',
  serviceProvider: 'Bollore Logistics Japan K.K.',
  status: 'TO_APPROVE',
  permissions: ['CAN_GIVE_FINANCE_REVIEW'],
  possibleWorkflowAction: 'READY_FOR_PAYMENT',
  nextWorkflowAction: 'READY_FOR_PAYMENT',
  currentDeviationWorkflow: 'FINANCE_REVIEW',
  invoiceDate: '2022-08-31',
  paymentDate: '2022-12-31',
  invoicedParty: 'Valeo Japan (TCP/T36)',
  invoiceAmount: {
    beforeTax: 194750,
    afterTax: 213275,
    currency: 'JPY',
  },
  deviations: {
    NEW_CHARGE: 2,
    QUANTITY: 1,
    UNIT_PRICE: 1,
  },
  invoiceAmountDeviation: {
    beforeTax: 79000,
    afterTax: 86425,
    currency: 'JPY',
  },
  servicesAndShipments: {},
  totalChargeableWeight: 50090,
  volume: 50.09,
  packageCount: 76,
  totalCo2Emission: 2.74,
  modality: 'SEA',
  shipmentBasicInfoList: [
    {
      shipmentId: '867fed5b-9ef6-43f3-bff7-b423ae67c8ab',
      shipmentNumber: 'GTD0727250',
      numberOfDeviations: 6,
      status: 'REJECTED',
    },
  ],
};

const mockDeviations: DeviationDto[] = [
  {
    id: '08a5e05e-57ce-45e4-a107-baecca98fdfb',
    name: 'New Charge',
    type: 'NEW_CHARGE',
    status: 'UNFILLED',
    legType: null,
    chargeDescription: 'PEAK SEASON SURCHARGE - SELLING',
    legCurrency: 'USD',
    deviationCurrency: 'USD',
    contractChargeUnit: null,
    invoiceChargeUnit: null,
    contractUnitPrice: null,
    invoiceUnitPrice: 16,
    invoiceQuantity: 1,
    deviationQuantity: null,
    deviationAmountPerUnit: 16,
    deviationAmount: 16,
    deviationAmountBeforeTax: 16,
    deviationAmountAfterTax: 16,
    rejectedByOperation: false,
  },
  {
    id: 'e378f5de-ab95-4e83-973c-eae1f6f93d1f',
    name: 'Unit Price',
    type: 'UNIT_PRICE',
    status: 'UNFILLED',
    legType: 'LEG_THREE',
    chargeDescription: 'TERMINAL HANDLING CHARGES-SEA DEST',
    legCurrency: 'JPY',
    deviationCurrency: 'JPY',
    contractChargeUnit: 'CONTAINER',
    invoiceChargeUnit: null,
    contractUnitPrice: 34000,
    invoiceUnitPrice: 47000,
    invoiceQuantity: 1,
    deviationQuantity: null,
    deviationAmountPerUnit: 13000,
    deviationAmount: 13000,
    deviationAmountBeforeTax: 13000,
    deviationAmountAfterTax: 13000,
    rejectedByOperation: false,
  },
  {
    id: 'bd79ba9a-dc66-46b6-ad41-36f679865336',
    name: 'Unit Price',
    type: 'UNIT_PRICE',
    status: 'UNFILLED',
    legType: 'LEG_TWO',
    chargeDescription: 'SEAFREIGHT CHARGES',
    legCurrency: 'USD',
    deviationCurrency: 'USD',
    contractChargeUnit: 'CONTAINER',
    invoiceChargeUnit: null,
    contractUnitPrice: 1450,
    invoiceUnitPrice: 2400,
    invoiceQuantity: 1,
    deviationQuantity: null,
    deviationAmountPerUnit: 950,
    deviationAmount: 950,
    deviationAmountBeforeTax: 950,
    deviationAmountAfterTax: 950,
    rejectedByOperation: false,
  },
  {
    id: 'f1e2d97a-a9bc-4aef-96ec-a8d9788019f1',
    name: 'Unit Price',
    type: 'UNIT_PRICE',
    status: 'UNFILLED',
    legType: 'LEG_ONE',
    chargeDescription: 'FOB CHARGES',
    legCurrency: 'THB',
    deviationCurrency: 'THB',
    contractChargeUnit: 'CONTAINER',
    invoiceChargeUnit: null,
    contractUnitPrice: 11375,
    invoiceUnitPrice: 15622,
    invoiceQuantity: 1,
    deviationQuantity: null,
    deviationAmountPerUnit: 4247,
    deviationAmount: 4247,
    deviationAmountBeforeTax: 4247,
    deviationAmountAfterTax: 4247,
    rejectedByOperation: false,
  },
  {
    id: '8f564aa8-ca03-4c1a-8e3d-45bcbc4ec5ac',
    name: 'Unit Price',
    type: 'UNIT_PRICE',
    status: 'UNFILLED',
    legType: 'LEG_TWO',
    chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
    legCurrency: 'USD',
    deviationCurrency: 'USD',
    contractChargeUnit: 'CONTAINER',
    invoiceChargeUnit: null,
    contractUnitPrice: 152,
    invoiceUnitPrice: 360,
    invoiceQuantity: 1,
    deviationQuantity: null,
    deviationAmountPerUnit: 208,
    deviationAmount: 208,
    deviationAmountBeforeTax: 208,
    deviationAmountAfterTax: 208,
    rejectedByOperation: false,
  },
];

export const mockInvoiceCourierShipment: InvoiceCourierShipmentDetailDto = {
  shipmentId: '82ba82b6-6a1d-49b1-aa95-1f70c92291c0',
  shipmentNumber: '8305782726',
  status: 'TO_APPROVE',
  shipmentAmount: {
    beforeTax: 52.78,
    afterTax: 52.78,
    currency: 'SGD',
  },
  deviationAmount: {
    beforeTax: 0,
    afterTax: 0,
    currency: 'SGD',
  },
  shippingServiceType: 'EXPRESS WORLDWIDE nondoc',
  shipper: 'HORIBA STEC CO.,LTD.',
  placeToPickup: '',
  departureDate: '2023-12-28',
  consignee: 'ULTRA CLEAN ASIA PACIFIC PTE. LTD.',
  deliveryPlace: '',
  arrivalDate: null,
  totalChargeableWeight: 3.5,
  packageCount: null,
  volume: null,
  totalCo2Emission: 0,
  deviations: mockDeviations,
};

export const mockQuestionaire: Record<string, DeviationQuestionDto[]> = {
  OPERATION_REVIEW: [
    {
      id: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      name: 'Was a special instruction given for this shipment that explains this charge?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: '24da64e0-32e8-4804-a83f-0a1276b81b39',
          name: 'Yes',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: '95055552-9891-4c2b-9a6a-e694f080df56',
              name: 'What was the special instruction given?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '0d89eb8f-845c-4911-ae0f-d1a68b374826',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '3f2322dc-432d-446e-80cf-f5b528611311',
                      name: 'Is this a one-off or a recurring change?',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: 'd9f55ff7-50d7-4a34-a2fc-1b1342d90b06',
                          name: 'Recurring',
                          displayOrder: 10,
                          type: 'RECURRING',
                          nextQuestions: [
                            {
                              id: '9ae20239-5fb1-40b3-978b-52f6ec0eada7',
                              name: 'Period for expected recurrence',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'DATE_RANGE',
                              answerOptions: [
                                {
                                  id: '7c564129-9b35-4605-9c15-8054e11d489d',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: [
                                    {
                                      id: '7c6f5469-b887-4d0e-8a5c-7aed8b792d65',
                                      name: 'What leg should we register this charge to?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'RADIO',
                                      answerOptions: [
                                        {
                                          id: 'abd7eea3-e6f8-46ab-b839-6a0c937d7b7d',
                                          name: 'Leg 1',
                                          displayOrder: 10,
                                          type: 'LEG_ONE',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          id: 'e7c6d7b1-ce52-4acc-b551-6e587ad60021',
                                          name: 'Leg 2',
                                          displayOrder: 20,
                                          type: 'LEG_TWO',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          id: '482bd43f-d87e-4eb4-b838-a4c54c17d482',
                                          name: 'Leg 3',
                                          displayOrder: 30,
                                          type: 'LEG_THREE',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'cebf7169-d62b-476e-b3da-521f4ea139c8',
                          name: 'One off',
                          displayOrder: 20,
                          type: 'ONE_OFF',
                          nextQuestions: [
                            {
                              id: '7c6f5469-b887-4d0e-8a5c-7aed8b792d65',
                              name: 'What leg should we register this charge to?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'RADIO',
                              answerOptions: [
                                {
                                  id: 'abd7eea3-e6f8-46ab-b839-6a0c937d7b7d',
                                  name: 'Leg 1',
                                  displayOrder: 10,
                                  type: 'LEG_ONE',
                                  nextQuestions: [
                                    {
                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                      name: 'Do you accept this charge as-is?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'RADIO',
                                      answerOptions: [
                                        {
                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                          name: 'Yes',
                                          displayOrder: 10,
                                          type: 'YES',
                                          nextQuestions: null,
                                        },
                                        {
                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                          name: 'No',
                                          displayOrder: 20,
                                          type: 'NO',
                                          nextQuestions: [
                                            {
                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                              name: 'Why do you reject this charge?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'TEXT',
                                              answerOptions: [
                                                {
                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                  name: null,
                                                  displayOrder: 10,
                                                  type: null,
                                                  nextQuestions: null,
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  id: 'e7c6d7b1-ce52-4acc-b551-6e587ad60021',
                                  name: 'Leg 2',
                                  displayOrder: 20,
                                  type: 'LEG_TWO',
                                  nextQuestions: [
                                    {
                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                      name: 'Do you accept this charge as-is?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'RADIO',
                                      answerOptions: [
                                        {
                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                          name: 'Yes',
                                          displayOrder: 10,
                                          type: 'YES',
                                          nextQuestions: null,
                                        },
                                        {
                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                          name: 'No',
                                          displayOrder: 20,
                                          type: 'NO',
                                          nextQuestions: [
                                            {
                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                              name: 'Why do you reject this charge?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'TEXT',
                                              answerOptions: [
                                                {
                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                  name: null,
                                                  displayOrder: 10,
                                                  type: null,
                                                  nextQuestions: null,
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  id: '482bd43f-d87e-4eb4-b838-a4c54c17d482',
                                  name: 'Leg 3',
                                  displayOrder: 30,
                                  type: 'LEG_THREE',
                                  nextQuestions: [
                                    {
                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                      name: 'Do you accept this charge as-is?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'RADIO',
                                      answerOptions: [
                                        {
                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                          name: 'Yes',
                                          displayOrder: 10,
                                          type: 'YES',
                                          nextQuestions: null,
                                        },
                                        {
                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                          name: 'No',
                                          displayOrder: 20,
                                          type: 'NO',
                                          nextQuestions: [
                                            {
                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                              name: 'Why do you reject this charge?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'TEXT',
                                              answerOptions: [
                                                {
                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                  name: null,
                                                  displayOrder: 10,
                                                  type: null,
                                                  nextQuestions: null,
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '1fc4f84d-8541-46e3-80cf-799562d0adb5',
          name: 'No',
          displayOrder: 20,
          type: 'NO',
          nextQuestions: [
            {
              id: '799797db-7472-49a1-a770-6a7fefaf487f',
              name: 'Is this change due to a new market condition?',
              deviationType: 'NEW_CHARGE',
              questionType: 'RADIO',
              answerOptions: [
                {
                  id: '4c2048a9-6a0e-4a1e-8fe8-0cf454cef29c',
                  name: 'Yes',
                  displayOrder: 10,
                  type: 'YES',
                  nextQuestions: [
                    {
                      id: 'ff0e1c0d-8c5a-4477-8771-e27a88cf5bf4',
                      name: 'What is the new market condition?',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'TEXT',
                      answerOptions: [
                        {
                          id: 'c3d571de-6192-456d-a7c3-56abb16f2c07',
                          name: null,
                          displayOrder: 10,
                          type: null,
                          nextQuestions: [
                            {
                              id: '3f2322dc-432d-446e-80cf-f5b528611311',
                              name: 'Is this a one-off or a recurring change?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'RADIO',
                              answerOptions: [
                                {
                                  id: 'd9f55ff7-50d7-4a34-a2fc-1b1342d90b06',
                                  name: 'Recurring',
                                  displayOrder: 10,
                                  type: 'RECURRING',
                                  nextQuestions: [
                                    {
                                      id: '9ae20239-5fb1-40b3-978b-52f6ec0eada7',
                                      name: 'Period for expected recurrence',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'DATE_RANGE',
                                      answerOptions: [
                                        {
                                          id: '7c564129-9b35-4605-9c15-8054e11d489d',
                                          name: null,
                                          displayOrder: 10,
                                          type: null,
                                          nextQuestions: [
                                            {
                                              id: '7c6f5469-b887-4d0e-8a5c-7aed8b792d65',
                                              name: 'What leg should we register this charge to?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'abd7eea3-e6f8-46ab-b839-6a0c937d7b7d',
                                                  name: 'Leg 1',
                                                  displayOrder: 10,
                                                  type: 'LEG_ONE',
                                                  nextQuestions: [
                                                    {
                                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                                      name: 'Do you accept this charge as-is?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'RADIO',
                                                      answerOptions: [
                                                        {
                                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                          name: 'Yes',
                                                          displayOrder: 10,
                                                          type: 'YES',
                                                          nextQuestions: null,
                                                        },
                                                        {
                                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                          name: 'No',
                                                          displayOrder: 20,
                                                          type: 'NO',
                                                          nextQuestions: [
                                                            {
                                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                              name: 'Why do you reject this charge?',
                                                              deviationType: 'NEW_CHARGE',
                                                              questionType: 'TEXT',
                                                              answerOptions: [
                                                                {
                                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                                  name: null,
                                                                  displayOrder: 10,
                                                                  type: null,
                                                                  nextQuestions: null,
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                                {
                                                  id: 'e7c6d7b1-ce52-4acc-b551-6e587ad60021',
                                                  name: 'Leg 2',
                                                  displayOrder: 20,
                                                  type: 'LEG_TWO',
                                                  nextQuestions: [
                                                    {
                                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                                      name: 'Do you accept this charge as-is?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'RADIO',
                                                      answerOptions: [
                                                        {
                                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                          name: 'Yes',
                                                          displayOrder: 10,
                                                          type: 'YES',
                                                          nextQuestions: null,
                                                        },
                                                        {
                                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                          name: 'No',
                                                          displayOrder: 20,
                                                          type: 'NO',
                                                          nextQuestions: [
                                                            {
                                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                              name: 'Why do you reject this charge?',
                                                              deviationType: 'NEW_CHARGE',
                                                              questionType: 'TEXT',
                                                              answerOptions: [
                                                                {
                                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                                  name: null,
                                                                  displayOrder: 10,
                                                                  type: null,
                                                                  nextQuestions: null,
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                                {
                                                  id: '482bd43f-d87e-4eb4-b838-a4c54c17d482',
                                                  name: 'Leg 3',
                                                  displayOrder: 30,
                                                  type: 'LEG_THREE',
                                                  nextQuestions: [
                                                    {
                                                      id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                                      name: 'Do you accept this charge as-is?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'RADIO',
                                                      answerOptions: [
                                                        {
                                                          id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                          name: 'Yes',
                                                          displayOrder: 10,
                                                          type: 'YES',
                                                          nextQuestions: null,
                                                        },
                                                        {
                                                          id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                          name: 'No',
                                                          displayOrder: 20,
                                                          type: 'NO',
                                                          nextQuestions: [
                                                            {
                                                              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                              name: 'Why do you reject this charge?',
                                                              deviationType: 'NEW_CHARGE',
                                                              questionType: 'TEXT',
                                                              answerOptions: [
                                                                {
                                                                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                                  name: null,
                                                                  displayOrder: 10,
                                                                  type: null,
                                                                  nextQuestions: null,
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  id: 'cebf7169-d62b-476e-b3da-521f4ea139c8',
                                  name: 'One off',
                                  displayOrder: 20,
                                  type: 'ONE_OFF',
                                  nextQuestions: [
                                    {
                                      id: '7c6f5469-b887-4d0e-8a5c-7aed8b792d65',
                                      name: 'What leg should we register this charge to?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'RADIO',
                                      answerOptions: [
                                        {
                                          id: 'abd7eea3-e6f8-46ab-b839-6a0c937d7b7d',
                                          name: 'Leg 1',
                                          displayOrder: 10,
                                          type: 'LEG_ONE',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          id: 'e7c6d7b1-ce52-4acc-b551-6e587ad60021',
                                          name: 'Leg 2',
                                          displayOrder: 20,
                                          type: 'LEG_TWO',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          id: '482bd43f-d87e-4eb4-b838-a4c54c17d482',
                                          name: 'Leg 3',
                                          displayOrder: 30,
                                          type: 'LEG_THREE',
                                          nextQuestions: [
                                            {
                                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                                              name: 'Do you accept this charge as-is?',
                                              deviationType: 'NEW_CHARGE',
                                              questionType: 'RADIO',
                                              answerOptions: [
                                                {
                                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                                  name: 'Yes',
                                                  displayOrder: 10,
                                                  type: 'YES',
                                                  nextQuestions: null,
                                                },
                                                {
                                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                                  name: 'No',
                                                  displayOrder: 20,
                                                  type: 'NO',
                                                  nextQuestions: [
                                                    {
                                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                                      name: 'Why do you reject this charge?',
                                                      deviationType: 'NEW_CHARGE',
                                                      questionType: 'TEXT',
                                                      answerOptions: [
                                                        {
                                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                                          name: null,
                                                          displayOrder: 10,
                                                          type: null,
                                                          nextQuestions: null,
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '62af88c7-9971-4822-b76b-b11154e551ff',
                  name: 'No',
                  displayOrder: 20,
                  type: 'NO',
                  nextQuestions: [
                    {
                      id: '7c6f5469-b887-4d0e-8a5c-7aed8b792d65',
                      name: 'What leg should we register this charge to?',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: 'abd7eea3-e6f8-46ab-b839-6a0c937d7b7d',
                          name: 'Leg 1',
                          displayOrder: 10,
                          type: 'LEG_ONE',
                          nextQuestions: [
                            {
                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                              name: 'Do you accept this charge as-is?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'RADIO',
                              answerOptions: [
                                {
                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                  name: 'Yes',
                                  displayOrder: 10,
                                  type: 'YES',
                                  nextQuestions: null,
                                },
                                {
                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                  name: 'No',
                                  displayOrder: 20,
                                  type: 'NO',
                                  nextQuestions: [
                                    {
                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                      name: 'Why do you reject this charge?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'TEXT',
                                      answerOptions: [
                                        {
                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                          name: null,
                                          displayOrder: 10,
                                          type: null,
                                          nextQuestions: null,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'e7c6d7b1-ce52-4acc-b551-6e587ad60021',
                          name: 'Leg 2',
                          displayOrder: 20,
                          type: 'LEG_TWO',
                          nextQuestions: [
                            {
                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                              name: 'Do you accept this charge as-is?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'RADIO',
                              answerOptions: [
                                {
                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                  name: 'Yes',
                                  displayOrder: 10,
                                  type: 'YES',
                                  nextQuestions: null,
                                },
                                {
                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                  name: 'No',
                                  displayOrder: 20,
                                  type: 'NO',
                                  nextQuestions: [
                                    {
                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                      name: 'Why do you reject this charge?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'TEXT',
                                      answerOptions: [
                                        {
                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                          name: null,
                                          displayOrder: 10,
                                          type: null,
                                          nextQuestions: null,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: '482bd43f-d87e-4eb4-b838-a4c54c17d482',
                          name: 'Leg 3',
                          displayOrder: 30,
                          type: 'LEG_THREE',
                          nextQuestions: [
                            {
                              id: '9fb8aad4-e3df-4ef1-a06d-c11e636d32ef',
                              name: 'Do you accept this charge as-is?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'RADIO',
                              answerOptions: [
                                {
                                  id: 'baa42afe-9ebf-420d-a341-903fd8a5392c',
                                  name: 'Yes',
                                  displayOrder: 10,
                                  type: 'YES',
                                  nextQuestions: null,
                                },
                                {
                                  id: '45dbd18d-b0a9-46d6-adda-968b5d7a4548',
                                  name: 'No',
                                  displayOrder: 20,
                                  type: 'NO',
                                  nextQuestions: [
                                    {
                                      id: '325df80d-0df7-4f54-a91f-217a68a207c4',
                                      name: 'Why do you reject this charge?',
                                      deviationType: 'NEW_CHARGE',
                                      questionType: 'TEXT',
                                      answerOptions: [
                                        {
                                          id: '81724600-9238-42f4-bde0-ed8dfe250653',
                                          name: null,
                                          displayOrder: 10,
                                          type: null,
                                          nextQuestions: null,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  PROCUREMENT_REVIEW: [
    {
      id: '959f8089-8557-49d5-831b-17d1065c554c',
      name: 'Do you accept this deviation based on the inputs from Operations?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: 'cb1a7b24-e2f2-4872-8c0c-46ed8457dcb7',
          name: 'Yes, I accept as-is',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: 'b88e16ea-d1d2-40c0-a0c8-d90e8a8f644b',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '442d3913-3b28-4492-8f5a-17dd1d6c7f5c',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '8d4a0e02-35fd-49d1-80a7-08c1dc5048f7',
          name: 'Yes, I accept but change the recurrence',
          displayOrder: 20,
          type: 'YES_BUT_WITH_CHANGE_RECURRENCE',
          nextQuestions: [
            {
              id: '89621c07-1bbe-476d-8ee5-2e6c9697a09f',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '0ae24831-cd8a-46f5-81a0-eb9dda2e783e',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '82a17375-321b-4af8-813b-59b1c058f00f',
                      name: 'Modify Recurrence',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: '0d5b954e-9d45-44b1-8a3e-54055a858d62',
                          name: 'Recurring',
                          displayOrder: 10,
                          type: 'RECURRING',
                          nextQuestions: [
                            {
                              id: '64c83e80-002e-4050-a824-a162c37c586a',
                              name: 'Period for expected recurrence',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'DATE_RANGE',
                              answerOptions: [
                                {
                                  id: '8256e1e0-7935-4af3-a90c-1a2ff90d7e8d',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'e1421940-2c0d-4e0b-bb6e-2924aed9f3ab',
                          name: 'One off',
                          displayOrder: 20,
                          type: 'ONE_OFF',
                          nextQuestions: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '22647927-8620-4fe8-b513-36eb422f4144',
          name: 'No, I reject',
          displayOrder: 30,
          type: 'NO',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a01',
              name: 'Why do you reject this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51be8',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const mockCourierInvoiceDispute: CourierInvoiceDisputeDto = {
  id: '67d14dab-378e-4d4e-beeb-90dbd9d3170f',
  invoiceId: '3fdbe20e-c4ed-4f9f-94b4-42f4178b0e26',
  currency: 'JPY',
  lumpSumAmount: null,
  lumpSumAmountBeforeTax: null,
  expectedTotalAmount: {
    beforeTax: 22859.76,
    afterTax: 22859.76,
    currency: 'JPY',
  },
  creditNoteAmount: {
    beforeTax: 22858.08,
    afterTax: 22858.08,
    currency: 'JPY',
  },
  resolutionDescription: 'ok',
  status: 'DONE',
  creditNoteFile: {
    fileName: '01 bollore_3534343.pdf',
    uploadedTime: '2024-03-20T09:30:50.118285Z',
  },
  shipmentDeviations: [
    {
      status: 'APPROVED',
      deviations: [
        {
          id: '2b0d5dae-125f-416f-b33a-4bda7b00306f',
          name: 'Unit Price',
          type: 'UNIT_PRICE',
          status: 'REJECTED_DONE',
          legType: 'LEG_TWO',
          chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
          legCurrency: 'USD',
          deviationCurrency: 'USD',
          contractChargeUnit: 'CONTAINER',
          invoiceChargeUnit: null,
          contractUnitPrice: 76,
          invoiceUnitPrice: 132,
          invoiceQuantity: 1,
          deviationQuantity: null,
          deviationAmountPerUnit: 56,
          deviationAmount: 56,
          deviationAmountBeforeTax: 56,
          deviationAmountAfterTax: 56,
          rejectedByOperation: false,
        },
      ],
    },
    {
      status: 'REJECTED',
      deviations: [
        {
          id: '2b0d5dae-125f-416f-b33a-4bda7b00306f',
          name: 'Unit Price',
          type: 'UNIT_PRICE',
          status: 'REJECTED_DONE',
          legType: 'LEG_TWO',
          chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
          legCurrency: 'USD',
          deviationCurrency: 'USD',
          contractChargeUnit: 'CONTAINER',
          invoiceChargeUnit: null,
          contractUnitPrice: 76,
          invoiceUnitPrice: 132,
          invoiceQuantity: 1,
          deviationQuantity: null,
          deviationAmountPerUnit: 56,
          deviationAmount: 56,
          deviationAmountBeforeTax: 56,
          deviationAmountAfterTax: 56,
          rejectedByOperation: false,
        },
      ],
    },
  ],
  nextWorkflowAction: 'READY_FOR_PAYMENT',
  agreementResolution: 'ACCEPT_INVOICE_AS_IS',
  deviationResolutionType: 'BREAK_DOWN_PER_DEVIATION',
};

export const mockInvoiceDetail: InvoiceDetailDto = {
  id: '7ee404f2-d97e-4743-b152-181b180518f5',
  mblHbl: 'GTD0727250',
  invoiceNumber: '3527903',
  invoiceDate: '2022-08-31',
  freightForwarder: {
    name: 'Bollore Logistics Japan K.K.',
    code: 'BOLLORE_LOGISTICS_JAPAN_K_K',
    country: null,
  },
  status: 'TO_APPROVE',
  paymentDate: '2022-12-31',
  incoterm: 'EXW',
  invoiceAmount: {
    beforeTax: 194750,
    afterTax: 213275,
    currency: 'JPY',
  },
  creditNoteAmount: null,
  amountToBePaid: {
    beforeTax: 194750,
    afterTax: 213275,
    currency: 'JPY',
  },
  invoicedParty: 'Valeo Japan (TCP/T36)',
  invoiceAmountDeviation: {
    beforeTax: 79000,
    afterTax: 86425,
    currency: 'JPY',
  },
  deviations: [
    {
      id: 'e9d15370-d682-457c-9819-f04f384cc71d',
      name: 'New Charge',
      type: 'NEW_CHARGE',
      status: 'DONE',
      legType: null,
      chargeDescription: 'DELIVERY CHARGES - DEST Drayage cancel fee 50%',
      legCurrency: 'JPY',
      deviationCurrency: 'JPY',
      contractChargeUnit: null,
      invoiceChargeUnit: null,
      contractUnitPrice: null,
      invoiceUnitPrice: 26500,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 26500,
      deviationAmount: 26500,
      deviationAmountBeforeTax: 26500,
      deviationAmountAfterTax: 29150,
      rejectedByOperation: false,
    },
    {
      id: 'b4e9e7c5-0687-408e-838d-f14ac5dd3708',
      name: 'New Charge',
      type: 'NEW_CHARGE',
      status: 'REJECTED_DONE',
      legType: null,
      chargeDescription: 'DELIVERY CHARGES - DEST Drayage cancel fee 50%',
      legCurrency: 'JPY',
      deviationCurrency: 'JPY',
      contractChargeUnit: null,
      invoiceChargeUnit: null,
      contractUnitPrice: null,
      invoiceUnitPrice: 34250,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 34250,
      deviationAmount: 34250,
      deviationAmountBeforeTax: 34250,
      deviationAmountAfterTax: 37675,
      rejectedByOperation: false,
    },
    {
      id: 'c209d29d-db45-4ab1-a431-240f5ce36ce0',
      name: 'Quantity',
      type: 'QUANTITY',
      status: 'DONE',
      legType: 'LEG_THREE',
      chargeDescription: 'CUSTOMS CLEARANCE - DEST',
      legCurrency: 'JPY',
      deviationCurrency: 'JPY',
      contractChargeUnit: 'DECLARATION',
      invoiceChargeUnit: null,
      contractUnitPrice: 4750,
      invoiceUnitPrice: 4750,
      invoiceQuantity: 2,
      deviationQuantity: 1,
      deviationAmountPerUnit: null,
      deviationAmount: 4750,
      deviationAmountBeforeTax: 4750,
      deviationAmountAfterTax: 4750,
      rejectedByOperation: false,
    },
    {
      id: '95c47264-9dff-4963-9045-8a47ffa2e207',
      name: 'Unit Price',
      type: 'UNIT_PRICE',
      status: 'REJECTED_DONE',
      legType: 'LEG_THREE',
      chargeDescription: 'DELIVERY CHARGES - DEST',
      legCurrency: 'JPY',
      deviationCurrency: 'JPY',
      contractChargeUnit: 'CONTAINER',
      invoiceChargeUnit: null,
      contractUnitPrice: 55000,
      invoiceUnitPrice: 68500,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 13500,
      deviationAmount: 13500,
      deviationAmountBeforeTax: 13500,
      deviationAmountAfterTax: 14850,
      rejectedByOperation: false,
    },
  ],
  shipmentDetail: {
    numberOfInvoices: 2,
    shipmentAmount: {
      beforeTax: 741386,
      afterTax: 760631,
      currency: 'JPY',
    },
    deviationAmount: {
      beforeTax: 101811.04,
      afterTax: 109236.04,
      currency: 'JPY',
    },
    containersCount: 2,
    containers: [
      {
        size: 20,
        quantity: 1,
        type: 'GP',
      },
      {
        size: 40,
        quantity: 1,
        type: 'GP',
      },
    ],
    incoterm: 'EXW',
    shipmentNumber: 'GTD0727250',
    shipper: 'Valeo Automotive (Thailand) CO. LTD',
    placeToPickup: 'Rayong T39 ok',
    consignee: 'Valeo Japan (TCP/T36)',
    deliveryPlace: 'Kohnan T36',
    totalGrossWeight: 39590,
    volume: 50.09,
    totalCo2Emission: 2.74,
  },
  legDetails: [
    {
      type: 'LEG_TWO',
      transportMedium: 'SEA',
      modality: 'SEA',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 1,
      legCurrency: 'JPY',
      legAmountAfterTax: 390839,
      paymentAmountAfterTax: 390839,
      legDeviationAmountAfterTax: 37843,
      paymentDeviationAmountAfterTax: 37843,
      co2Emission: 2.17,
      invoices: [
        {
          id: 'cc6e9198-d109-4578-9cfd-c39b2ee64aa7',
          invoiceNumber: '3525700',
          status: 'APPROVED',
        },
        {
          id: '7ee404f2-d97e-4743-b152-181b180518f5',
          invoiceNumber: '3527903',
          status: 'TO_APPROVE',
        },
      ],
    },
    {
      type: 'LEG_THREE',
      transportMedium: 'ROAD',
      modality: 'ROAD',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 1,
      legCurrency: 'JPY',
      legAmountAfterTax: 270720,
      paymentAmountAfterTax: 270720,
      legDeviationAmountAfterTax: 48750,
      paymentDeviationAmountAfterTax: 48750,
      co2Emission: 0.4,
      invoices: [
        {
          id: 'cc6e9198-d109-4578-9cfd-c39b2ee64aa7',
          invoiceNumber: '3525700',
          status: 'APPROVED',
        },
        {
          id: '7ee404f2-d97e-4743-b152-181b180518f5',
          invoiceNumber: '3527903',
          status: 'TO_APPROVE',
        },
      ],
    },
    {
      type: 'LEG_ONE',
      transportMedium: 'ROAD',
      modality: 'ROAD',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 3.83,
      legCurrency: 'THB',
      legAmountAfterTax: 25867.37,
      paymentAmountAfterTax: 99072,
      legDeviationAmountAfterTax: 0,
      paymentDeviationAmountAfterTax: 0,
      co2Emission: 0.18,
      invoices: [
        {
          id: 'cc6e9198-d109-4578-9cfd-c39b2ee64aa7',
          invoiceNumber: '3525700',
          status: 'APPROVED',
        },
      ],
    },
  ],
  transportDetail: {
    legOne: {
      startLocation: 'Rayong T39 ok',
      startDate: '2022-08-02',
      transportMedium: 'ROAD',
      deliveryLocation: 'THLCH',
      deliveryDate: '2022-08-11',
    },
    legTwo: {
      startLocation: 'THLCH',
      startDate: '2022-08-02',
      transportMedium: 'SEA',
      deliveryLocation: 'JPTYO',
      deliveryDate: '2022-08-11',
    },
    legThree: {
      startLocation: 'JPTYO',
      startDate: '2022-08-02',
      transportMedium: 'ROAD',
      deliveryLocation: 'Kohnan T36',
      deliveryDate: '2022-08-11',
    },
  },
  permissions: ['CAN_GIVE_FINANCE_REVIEW'],
  possibleWorkflowAction: 'READY_FOR_PAYMENT',
  nextWorkflowAction: 'READY_FOR_PAYMENT',
  currentDeviationWorkflow: 'FINANCE_REVIEW',
  importExportType: null,
  modality: 'SEA',
  invoiceDispute: {
    id: '33d1559b-93c7-4a35-95b6-5fde33c9e8fd',
    invoiceId: '7ee404f2-d97e-4743-b152-181b180518f5',
    currency: 'JPY',
    lumpSumAmount: null,
    lumpSumAmountBeforeTax: null,
    expectedTotalAmount: null,
    creditNoteAmount: null,
    resolutionDescription: 'ok',
    status: 'DONE',
    creditNoteFile: null,
    rejectedDeviations: [
      {
        id: 'b4e9e7c5-0687-408e-838d-f14ac5dd3708',
        name: 'New Charge',
        type: 'NEW_CHARGE',
        status: 'REJECTED_DONE',
        legType: null,
        chargeDescription: 'DELIVERY CHARGES - DEST Drayage cancel fee 50%',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: null,
        invoiceChargeUnit: null,
        contractUnitPrice: null,
        invoiceUnitPrice: 34250,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 34250,
        deviationAmount: 34250,
        deviationAmountBeforeTax: 34250,
        deviationAmountAfterTax: 37675,
        rejectedByOperation: false,
      },
      {
        id: '95c47264-9dff-4963-9045-8a47ffa2e207',
        name: 'Unit Price',
        type: 'UNIT_PRICE',
        status: 'REJECTED_DONE',
        legType: 'LEG_THREE',
        chargeDescription: 'DELIVERY CHARGES - DEST',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: 'CONTAINER',
        invoiceChargeUnit: null,
        contractUnitPrice: 55000,
        invoiceUnitPrice: 68500,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 13500,
        deviationAmount: 13500,
        deviationAmountBeforeTax: 13500,
        deviationAmountAfterTax: 14850,
        rejectedByOperation: false,
      },
    ],
    deviations: [
      {
        id: 'c209d29d-db45-4ab1-a431-240f5ce36ce0',
        name: 'Quantity',
        type: 'QUANTITY',
        status: 'DONE',
        legType: 'LEG_THREE',
        chargeDescription: 'CUSTOMS CLEARANCE - DEST',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: 'DECLARATION',
        invoiceChargeUnit: null,
        contractUnitPrice: 4750,
        invoiceUnitPrice: 4750,
        invoiceQuantity: 2,
        deviationQuantity: 1,
        deviationAmountPerUnit: null,
        deviationAmount: 4750,
        deviationAmountBeforeTax: 4750,
        deviationAmountAfterTax: 4750,
        rejectedByOperation: false,
      },
      {
        id: '95c47264-9dff-4963-9045-8a47ffa2e207',
        name: 'Unit Price',
        type: 'UNIT_PRICE',
        status: 'REJECTED_DONE',
        legType: 'LEG_THREE',
        chargeDescription: 'DELIVERY CHARGES - DEST',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: 'CONTAINER',
        invoiceChargeUnit: null,
        contractUnitPrice: 55000,
        invoiceUnitPrice: 68500,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 13500,
        deviationAmount: 13500,
        deviationAmountBeforeTax: 13500,
        deviationAmountAfterTax: 14850,
        rejectedByOperation: false,
      },
      {
        id: 'e9d15370-d682-457c-9819-f04f384cc71d',
        name: 'New Charge',
        type: 'NEW_CHARGE',
        status: 'DONE',
        legType: null,
        chargeDescription: 'DELIVERY CHARGES - DEST Drayage cancel fee 50%',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: null,
        invoiceChargeUnit: null,
        contractUnitPrice: null,
        invoiceUnitPrice: 26500,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 26500,
        deviationAmount: 26500,
        deviationAmountBeforeTax: 26500,
        deviationAmountAfterTax: 29150,
        rejectedByOperation: false,
      },
      {
        id: 'b4e9e7c5-0687-408e-838d-f14ac5dd3708',
        name: 'New Charge',
        type: 'NEW_CHARGE',
        status: 'REJECTED_DONE',
        legType: null,
        chargeDescription: 'DELIVERY CHARGES - DEST Drayage cancel fee 50%',
        legCurrency: 'JPY',
        deviationCurrency: 'JPY',
        contractChargeUnit: null,
        invoiceChargeUnit: null,
        contractUnitPrice: null,
        invoiceUnitPrice: 34250,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 34250,
        deviationAmount: 34250,
        deviationAmountBeforeTax: 34250,
        deviationAmountAfterTax: 37675,
        rejectedByOperation: false,
      },
    ],
    nextWorkflowAction: 'READY_FOR_PAYMENT',
    agreementResolution: 'ACCEPT_INVOICE_AS_IS',
    deviationResolutionType: null,
    deviationResolutions: [
      {
        deviationId: '95c47264-9dff-4963-9045-8a47ffa2e207',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: null,
        exchangeRate: null,
        periodType: 'ONE_OFF',
      },
      {
        deviationId: 'b4e9e7c5-0687-408e-838d-f14ac5dd3708',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: null,
        exchangeRate: null,
        periodType: 'ONE_OFF',
      },
      {
        deviationId: 'c209d29d-db45-4ab1-a431-240f5ce36ce0',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: null,
        exchangeRate: null,
        periodType: 'ONE_OFF',
      },
      {
        deviationId: 'e9d15370-d682-457c-9819-f04f384cc71d',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: null,
        exchangeRate: null,
        periodType: 'ONE_OFF',
      },
    ],
  },
};
