import { styled } from '@mui/material/styles';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { nameofFactory } from 'src/utils/nameofFactory';
import { useFormContext } from 'react-hook-form';
import { SelectChangeEvent, Box } from '@mui/material';
import { useGetDomainMetaData } from 'src/use-cases/invoice/useInvoices';
import { useMemo } from 'react';
import { MultipleSelectDropdown } from '../analytics/filter/MultipleSelectDropdown';
import { dateReferenceOptions } from '../analytics/filter/MockData';
import { FilterLoader } from './Loader';

const StyledBox = styled(Box)({
  '& .MuiFormControl-root': {
    width: '180px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

const StyledBox2 = styled(Box)({
  '& .MuiFormControl-root': {
    width: '210px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

const nameof = nameofFactory<AnalyticSearchRequestDto>();

export const CurrencyReferenceFilter = ({ options, control, onChange = null }) => (
  <StyledBox>
    <MultipleSelectDropdown
      name='currencyReference'
      label='Currency Reference'
      control={control}
      defaultValue='USD'
      options={options}
      multiple={false}
      onChangeCustom={onChange}
    />
  </StyledBox>
);

export const DateReferenceFilter = ({ control, onChange = null }) => (
  <StyledBox>
    <MultipleSelectDropdown
      name={nameof('dateReference')}
      label='Date Reference'
      control={control}
      defaultValue='PICKUP_DATE'
      options={dateReferenceOptions}
      multiple={false}
      onChangeCustom={onChange}
    />
  </StyledBox>
);

export const InvoiceStatusFilter = ({ options, control, onChange = null }) => (
  <StyledBox2>
    <MultipleSelectDropdown
      name={nameof('invoiceStatusSet')}
      label='Invoice Status'
      control={control}
      defaultValue={['APPROVED']}
      options={options}
      onChangeCustom={onChange}
    />
  </StyledBox2>
);

interface IFilterHeaderProps {
  onChangeFilter?(filter: Record<string, any>): void;
  isDashboard?: boolean;
}

export const FilterHeader = ({ onChangeFilter, isDashboard }: IFilterHeaderProps) => {
  const { control, watch } = useFormContext();
  const formValues = watch();

  const { data: dataStatus, isLoading: isLoadingStatus } = useGetDomainMetaData();
  const currencyList = dataStatus?.currencies ? dataStatus?.currencies?.map((item) => ({ label: item, value: item })) : [];
  const statusList = useMemo(() => {
    const items = [];
    if (!isDashboard && dataStatus?.resolvedInvoiceStatuses && dataStatus?.resolvedInvoiceStatuses?.length > 0) {
      items.push({
        label: 'RESOLVED',
        value: '',
        isLabel: true,
      });

      dataStatus?.resolvedInvoiceStatuses?.forEach((item) => {
        items.push({
          label: item === 'APPROVED' ? 'Ready for Payment' : item?.replace('_', ' ')?.toLowerCase(),
          value: item,
          isLabel: false,
        });
      });
    }
    if (dataStatus?.unResolvedInvoiceStatuses && dataStatus?.unResolvedInvoiceStatuses?.length > 0) {
      items.push({
        label: 'UNRESOLVED',
        value: '',
        isLabel: true,
      });

      dataStatus?.unResolvedInvoiceStatuses?.forEach((item) => {
        items.push({
          label: item?.replace('_', ' ')?.toLowerCase(),
          value: item,
          isLabel: false,
        });
      });
    }

    return items;
  }, [dataStatus, isDashboard]);

  return (
    <div className='flex items-center gap-16'>
      <div className='flex items-center gap-16'>
        {isLoadingStatus ? (
          <FilterLoader />
        ) : (
          <CurrencyReferenceFilter
            control={control}
            options={currencyList}
            onChange={(event: SelectChangeEvent<string | string[]>) => {
              onChangeFilter({
                ...formValues,
                currencyReference: event.target.value,
              });
            }}
          />
        )}
        {isLoadingStatus ? (
          <FilterLoader />
        ) : (
          <DateReferenceFilter
            control={control}
            onChange={(event: SelectChangeEvent<string | string[]>) => {
              onChangeFilter({
                ...formValues,
                dateReference: event.target.value,
              });
            }}
          />
        )}
        <div className='w-1 h-16 bg-neutral-20' />
        {isLoadingStatus ? (
          <FilterLoader />
        ) : (
          <InvoiceStatusFilter
            control={control}
            options={statusList}
            onChange={(event: SelectChangeEvent<string | string[]>) => {
              if (onChangeFilter) {
                onChangeFilter({
                  ...formValues,
                  invoiceStatusSet: event.target.value,
                });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
