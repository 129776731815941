import { FC, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import IconTruck from 'src/presentations/components/organisms/actionDetails/transportSummary/icons/Truck';
import IconShip from 'src/presentations/components/organisms/actionDetails/transportSummary/icons/Ship';
import IconAeroplane from 'src/presentations/components/organisms/actionDetails/transportSummary/icons/Aeroplane';
import IconCourier from 'src/presentations/components/organisms/actionDetails/transportSummary/icons/Courier';
import IconCt from 'src/presentations/components/organisms/actionDetails/transportSummary/icons/Ct';
import { beautifyEnum } from 'src/utils/utility';
import { CardLanding } from '../../molecules/card/CardLanding';
import { TransportMedium } from '../../molecules/card/types';
import { ChipInvoiceStatus } from '../../atom/chip/ChipInvoiceStatus';
import { IAuditProvidersProps } from './types';

const StyledAccordion = styled(MuiAccordion)({
  borderRadius: '12px!important',
  margin: '0!important',
  boxShadow: 'none',
  border: '1px solid #DFDFDF',
  backgroundColor: '#F5F6FA',
  padding: '0px 24px 0',
  '&:before': {
    display: 'none',
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '12px 0',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: '#606060',
  backgroundColor: 'transparent',
  borderRadius: '0!important',
  borderBottom: 'none!important',

  '&.Mui-expanded': {
    margin: 0,
    minHeight: 0,
    borderRadius: '8px 8px 0 0!important',
    borderBottom: '1px solid #DFDFDF!important',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    border: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#606060',

    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },

    '& .MuiSvgIcon-root': {
      color: '#606060',
    },
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '24px 0 0',
});

export const LandingAuditProviders: FC<IAuditProvidersProps> = ({ providers, modality }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const icon = useMemo(() => {
    const iconProps = {
      color: '#BFBFBF',
      size: 24,
    };

    switch (modality) {
      case 'ROAD':
      case 'RAIL':
        return <IconTruck {...iconProps} />;
      case 'SEA':
        return <IconShip {...iconProps} />;
      case 'AIR':
        return <IconAeroplane {...iconProps} />;
      case 'COURIER':
        return <IconCourier {...iconProps} />;
      case 'CT':
        return <IconCt {...iconProps} />;
      default:
        return null;
    }
  }, [modality]);

  const statsTotal = useMemo(() => providers.reduce((a, b) => a + b.invoiceStatuses.reduce((a2, b2) => a2 + b2?.count || 0, 0), 0), [providers]);

  return (
    <StyledAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <StyledAccordionSummary className='border-none border-0' expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.8rem', color: '#ffffff' }} />}>
        <div className='flex items-center'>
          <div className='flex-1'>
            <div className='flex-1 flex items-center gap-12'>
              <div className='flex items-center gap-8'>
                {icon}
                <strong className='text-18 font-bold text-blue_grey-30'>{modality === 'CT' ? 'CONTROL TOWER' : modality}</strong>
              </div>
              <div className='text-14 font-medium text-grey-40'>{providers?.length} SERVICE PROVIDERS</div>
              <div className='relative'>{statsTotal > 0 ? <ChipInvoiceStatus status='FLAGGED' count={statsTotal} /> : <ChipInvoiceStatus status='' />}</div>
            </div>
          </div>
        </div>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <div>
          {providers?.length > 0 ? (
            <div className='-mx-12 flex flex-wrap'>
              {providers.map((item) => {
                const queryParams = new URLSearchParams({
                  provider: item?.serviceProviderCode,
                  modality: item?.modality,
                });

                const url = new URL('/dashboard', window.location.origin);
                url.search = queryParams.toString();

                const constructedUrl = url.toString();

                return (
                  <div key={`provider-${item?.serviceProviderName}-${item?.modality}`} className='px-12 w-1/4 mb-30'>
                    <CardLanding
                      title={beautifyEnum(item?.serviceProviderCode)?.toUpperCase()}
                      info={`Last Updated on ${item?.latestInvoiceCreatedDate ? format(new Date(item?.latestInvoiceCreatedDate), 'dd-MMM-yyyy') : '-'}`}
                      transportMedium={item?.modality as TransportMedium}
                      path={constructedUrl}
                    >
                      {item?.invoiceStatuses && item?.invoiceStatuses?.length > 0 ? (
                        <>
                          {item?.invoiceStatuses.map((itemStat) => (
                            <div key={`stat-${item?.serviceProviderName}-${item?.modality}-${itemStat?.status}`} className='relative'>
                              <ChipInvoiceStatus status={itemStat?.status} count={itemStat?.count} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <ChipInvoiceStatus status='' />
                      )}
                    </CardLanding>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className='text-center text-sm'>Not found</p>
          )}
        </div>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
