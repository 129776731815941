import clsx from 'clsx';
import { ICustomIconProps } from 'src/presentations/components/atom/icons/types';

const IconPresentionChart: React.FC<ICustomIconProps> = ({ className }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className={clsx('relative w-[1em] h-[1em]', className)}>
    <path
      d='M4.9167 14.1665H15.075C16.6584 14.1665 17.4917 13.3332 17.4917 11.7498V1.6665H2.4917V11.7498C2.50003 13.3332 3.33337 14.1665 4.9167 14.1665Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M1.6665 1.6665H18.3332' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.6665 18.3332L9.99984 16.6665V14.1665'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M13.3333 18.3332L10 16.6665' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.25 9.1665L8.87499 6.97483C9.08333 6.79983 9.35833 6.84984 9.49999 7.08317L10.5 8.74984C10.6417 8.98317 10.9167 9.02484 11.125 8.85818L13.75 6.6665'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconPresentionChart;
