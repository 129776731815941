import { FC, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { ICustomIconProps } from 'src/presentations/components/atom/icons/types';
import { cn } from 'src/utils/utility';

interface IButtonNavProps {
  IconComponent: React.FC<ICustomIconProps>;
  title: string;
  onClick(): void;
  isActive?: boolean;
  isCollapsed?: boolean;
  isParentActive?: boolean;
  className?: string;
  children?: ReactNode;
}

export const SidebarButtonNav: FC<IButtonNavProps> = (props) => {
  const { title, IconComponent, onClick, isActive, isParentActive, isCollapsed, className, children } = props;

  const activeClassname = useMemo(() => {
    if (isActive) {
      return 'bg-[#d7eae1] text-primary';
    }
    if (isParentActive) {
      return 'text-neutral-60 bg-[#EBECF0]';
    }
    return 'text-neutral-60 hover:bg-[#EBECF0]';
  }, [isActive, isParentActive]);

  return (
    <div className={cn('relative', className)}>
      <button
        type='button'
        className={cn('w-full px-4 py-4 flex items-center justify-center rounded-12 transition-background duration-500', activeClassname)}
        onClick={() => onClick()}
      >
        <div className='w-36 h-36 flex items-center justify-center'>
          <IconComponent className={cn('text-20 transition-all', isActive ? 'text-primary' : 'text-neutral-60 group-hover:text-neutral-80')} />
        </div>
        <div className='flex-1 flex overflow-hidden'>
          <strong className={clsx('text-14 font-medium pr-10 text-left pl-6 overflow-hidden whitespace-nowrap', isCollapsed && 'invisible opacity-0 w-0')}>
            {title}
          </strong>
        </div>
      </button>
      {children}
    </div>
  );
};
