import { FC, useEffect } from 'react';
import IconImg from 'src/assets/images/icons/star.svg';
import { LandingSection } from './Section';
import { LandingPerformanceCard } from './PerformanceCard';

export interface LandingPerformanceProps {
  onCountUpdate?: (count: number) => void;
}

export interface ChartReport {
  name: string;
  hashFilters: string;
}

export const LandingPerformance: FC<LandingPerformanceProps> = ({ onCountUpdate }) => {
  const cardCount = 1;
  useEffect(() => {
    if (onCountUpdate) {
      onCountUpdate(cardCount);
    }
  }, [cardCount, onCountUpdate]);

  return (
    <LandingSection icon={IconImg} title='Performance Assessment' linkDetail='/performance'>
      <div className='-mx-12 flex flex-wrap'>
        <div className='px-12 w-1/4 mb-30 min-h-[8.4375rem]'>
          <LandingPerformanceCard />
        </div>
      </div>
    </LandingSection>
  );
};
