import { makeStyles } from 'tss-react/mui';

export const useFieldMaterialUIStyles = makeStyles()(() => ({
  fullWidth: {
    width: '100%',
  },

  shortWidth: {
    maxWidth: '250px',
    width: '100%',
  },
  shortSpace: {
    '& .MuiInputBase-input ': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  inputField: {
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      backgroundColor: '#F5F6FA',
      borderColor: '#BFBFBF',
    },
    '& .Mui-disabled': {
      backgroundColor: '#fff',
      color: '#BFBFBF',
      fontWeight: '400',
      borderColor: '##E8E8E8',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E8E8E8',
      },
    },
  },
}));
