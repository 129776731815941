/**
 * components/organism/DataTable/index.js
 */
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import clsx from 'clsx';
import { RefreshButton } from 'src/presentations/components/molecules/refreshButton';
import { cn } from 'src/utils/utility';
import { DownloadMenu } from 'src/presentations/components/molecules/dataTable/component/DownloadMenu';
import { DownloadButton } from 'src/presentations/components/molecules/dataTable/component/DownloadButton';
import TablePagination from 'src/presentations/components/molecules/table/Pagination';

const StyledTabs = styled(Tabs)({
  minHeight: 0,

  '& .MuiButtonBase-root': {
    color: '#808080',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: '#202020',
  },
  '& .MuiTouchRipple-root': {
    color: '#00992B',
  },
  '& .MuiTabs-indicator': {
    height: 2,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
});

export interface IOptionBase {
  value?: string;
  label?: string;
  count?: number;
  info?: string;
}

const FilterNav = ({ total, setTableTab, optionTabs, tableTab, pageSize, setPageNumber, pageNumber, refetchData }) => {
  const [moreActionEl, setMoreActionEl] = useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = useState(tableTab);

  const onClickMoreAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreActionEl(event.currentTarget);
  };

  const onCloseMoreAction = () => {
    setMoreActionEl(null);
  };

  const handleChange = (event, newValue: string) => {
    setTableTab(newValue);
    setSelected(newValue);
    setPageNumber(1);
  };

  return (
    <div className={clsx('flex items-center gap-32 px-[20px] pt-[12px] border-b border-neutral-20 justify-between bg-white rounded-tl-2xl rounded-tr-2xl')}>
      <div className='flex items-center gap-32  border-neutral-20'>
        <StyledTabs value={selected} onChange={handleChange}>
          {optionTabs.map((option) => (
            <Tab
              value={option?.value}
              key={`landing-menu-${option?.value}`}
              className='!py-16 !px-32 group'
              disabled={false}
              label={
                <div className='flex flex-row items-center gap-10'>
                  <strong className='tracking-normal normal-case text-16 font-semi-bold transition-all duration-300 group-hover:text-neutral-90'>
                    {option?.label}
                  </strong>
                  <span
                    className={cn(
                      'text-14 rounded-full px-6 py-1 font-medium transition-all duration-300 group-hover:bg-primary group-hover:text-white',
                      selected === option?.value ? 'bg-primary text-white' : 'bg-neutral-20 text-neutral-50'
                    )}
                  >
                    {option?.count}
                  </span>
                </div>
              }
            />
          ))}
        </StyledTabs>
      </div>
      <div className='flex flex-row items-center'>
        <div className='mr-24 pb-6'>
          <TablePagination
            total={total}
            onChangePage={(page) => {
              setPageNumber(page);
            }}
            pageSize={pageSize}
            params={{ page: pageNumber }}
          />
        </div>
        <RefreshButton onClick={refetchData} />
        <DownloadButton onClickMoreAction={onClickMoreAction} />
        <DownloadMenu tableTab={tableTab} moreActionEl={moreActionEl} onCloseMoreAction={onCloseMoreAction} />
      </div>
    </div>
  );
};

export default FilterNav;
