import { FC } from 'react';
import { useCardSelector } from './useCardselector';
import { ICardAnalyticItemProps } from './CardSelector';
import { CardAnalyticContent } from './CardAnalyticContent';

interface ICardAnalyticProps {
  item: ICardAnalyticItemProps;
  isLoading: boolean;
  isHideIconType?: boolean;
  readOnly?: boolean;
}

export const CardAnalytic: FC<ICardAnalyticProps> = ({ item, isLoading, isHideIconType, readOnly }) => {
  const { id, unit } = item;
  const { checkIsEnable, checkIsActive, toggle } = useCardSelector();

  const isEnable = checkIsEnable(unit);
  const isActive = checkIsActive(id);

  if (readOnly) {
    return (
      <CardAnalyticContent item={item} isLoading={isLoading} isHideIconType={isHideIconType} readOnly={readOnly} isEnable={isEnable} isActive={isActive} />
    );
  }

  return (
    <button aria-label='card' type='button' onClick={() => toggle(id)} disabled={!isEnable} className='block w-full'>
      <CardAnalyticContent item={item} isLoading={isLoading} isHideIconType={isHideIconType} readOnly={readOnly} isEnable={isEnable} isActive={isActive} />
    </button>
  );
};
