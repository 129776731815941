import { formatDate } from 'src/utils/date';
import { CardLanding } from 'src/presentations/components/molecules/card/CardLanding';

import IconCalender from 'src/assets/images/icons/filter-calendar.svg';
import { FC } from 'react';

export interface ChartReport {
  name: string;
  hashFilters: string;
}

export const LandingPerformanceCard: FC = () => (
  <CardLanding title='Overview' info='' path='/performance'>
    <div className='flex items-center'>
      <img src={IconCalender} alt='icon-calender' className='w-14 h-14 mr-2' />
      <div className='text-12 text-[#808080] font-normal ml-2'>{`Last Updated on ${formatDate(new Date())}`}</div>
    </div>
  </CardLanding>
);
