import { useState } from 'react';
import { useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import { useFormContext } from 'react-hook-form';
import IconEdit from 'src/assets/images/icons/edit-2.svg';
import { ContainerChargeDto } from 'src/generated/services/TFinancialApi';
import { beautifyEnum, formatMoney } from 'src/utils/utility';
import { TableFieldNumber } from './TableFieldNumber';
import { NoContractForm } from './types';

const useChargesContainerColumns = (): MRT_ColumnDef<ContainerChargeDto>[] => [
  {
    accessorKey: 'chargeName',
    header: 'Charge Name / Category - Container Type',
    enableEditing: false,
    accessorFn: (row) => (
      <span className='capitalize'>
        {beautifyEnum(row?.chargeName)}
        {row?.chargeCategory ? ` / ${beautifyEnum(row?.chargeCategory)}` : '-'}
        {row?.containerType ? ` - ${beautifyEnum(row?.containerType)}` : '-'}
      </span>
    ),
  },
  {
    accessorKey: 'legType',
    header: 'Leg',
    enableEditing: false,
    accessorFn: (row) => <span className='capitalize'>{row?.legType ? beautifyEnum(row.legType) : '-'}</span>,
  },
];

export interface ITableChargesContainerProps {
  data: ContainerChargeDto[];
  enableEditing?: boolean;
}

export const TableChargesContainer = ({ data, enableEditing = false }: ITableChargesContainerProps) => {
  const [price20rowIsEditable, setPrice20RowIsEditable] = useState({});
  const [price40rowIsEditable, setPrice40RowIsEditable] = useState({});

  const columns = useChargesContainerColumns();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setValue } = useFormContext<NoContractForm>();

  const table = useMaterialReactTable({
    data,
    enableRowNumbers: false,
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableEditing,
    editDisplayMode: 'table',
    muiTableHeadProps: {
      sx: {
        '& .MuiTableCell-head': {
          '&:first-of-type': {
            width: '40px',
            textAlign: 'center',
          },
        },
      },
    },
    columns: [
      {
        id: 'rowNumber',
        header: 'No.',
        size: 10,
        enableEditing: false,
        Cell: ({ row }) => <div style={{ width: '40px', textAlign: 'center' }}>{row.index + 1}</div>,
        Header: () => <div>No.</div>,
      },
      ...columns,
      {
        header: 'Rate Card 20ft Unit Price',
        enableEditing: ({ index }) => !!price20rowIsEditable[index],
        accessorKey: 'unitPrice20',
        accessorFn: (row) => <>{row?.unitPrice20 && row?.currency ? formatMoney(row.unitPrice20, row.currency) : '-'}</>,
        Cell: ({ row }) => (
          // const date = formatDate(new Date(row.original.etd));
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {row?.original?.unitPrice20} {price20rowIsEditable[row.index]}
            </span>
            {enableEditing && (
              <IconButton
                onClick={() => {
                  setPrice20RowIsEditable((pre) => ({
                    ...pre,
                    [row.index]: price20rowIsEditable[row.index] ? !price20rowIsEditable[row.index] : true,
                  }));
                }}
              >
                <img src={IconEdit} alt='' className='w-24' />
              </IconButton>
            )}
          </Box>
        ),
        Edit: ({ row }) => (
          <TableFieldNumber
            name={`containerCharges.${row.index}.unitPrice20`}
            startAdornment={row?.original?.currency}
            defaultValue={`${row?.original?.unitPrice20}`}
            onCancel={() => {
              setPrice20RowIsEditable((pre) => ({
                ...pre,
                [row.index]: false,
              }));
            }}
          />
        ),
      },
      {
        header: 'Rate Card 40ft Unit Price',
        enableEditing: ({ index }) => !!price40rowIsEditable[index],
        accessorKey: 'unitPrice40',
        accessorFn: (row) => <>{row?.unitPrice40 && row?.currency ? formatMoney(row.unitPrice40, row.currency) : '-'}</>,
        Cell: ({ row }) => (
          // const date = formatDate(new Date(row.original.etd));
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {row?.original?.unitPrice40} {price40rowIsEditable[row.index]}
            </span>
            {enableEditing && (
              <IconButton
                onClick={() => {
                  setPrice40RowIsEditable((pre) => ({
                    ...pre,
                    [row.index]: price40rowIsEditable[row.index] ? !price40rowIsEditable[row.index] : true,
                  }));
                }}
              >
                <img src={IconEdit} alt='' className='w-24' />
              </IconButton>
            )}
          </Box>
        ),
        Edit: ({ row }) => (
          <TableFieldNumber
            name={`containerCharges.${row.index}.unitPrice40`}
            defaultValue={`${row?.original?.unitPrice40}`}
            startAdornment={row?.original?.currency}
            onCancel={() => {
              setPrice40RowIsEditable((pre) => ({
                ...pre,
                [row.index]: false,
              }));
            }}
          />
        ),
      },
    ],
  });
  return <CustomTable table={table} />;
};
