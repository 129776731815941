/**
 * components/Table/Pagination.jsx
 */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowRightIcon from 'src/assets/images/icons/arrow-right_ico.svg';
import { cn } from 'src/utils/utility';

export interface ITablePaginationProps {
  pageSize?: number;
  total: number;
  onChangePage: (page: number) => void;
  params: { page: number };
}

const PaginationArrowImage = styled('img')(() => ({
  width: '20px',
  height: '20px',
}));

const TablePagination: React.FC<ITablePaginationProps> = ({ pageSize = 25, total, onChangePage, params }) => {
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (total && total > 0) {
      setTotalPages(Math.ceil(total / pageSize));
    }
  }, [total, pageSize]);

  const isPreviousDisabled = params?.page === 1;
  const isNextDisabled = params?.page >= totalPages;

  return (
    <div className='table__pagination'>
      <Box display='flex' gap={2} alignItems='center'>
        <Box display='flex' gap={1} alignItems='center'>
          <span>Page</span>
          <span>{params?.page}</span>
          <span>of</span>
          <span>{totalPages}</span>
        </Box>

        <button
          type='button'
          className={cn(
            'w-32 h-32 rounded-6 border border-neutral-20 bg-neutral-10 flex items-center justify-center',
            isPreviousDisabled && 'opacity-50 cursor-not-allowed'
          )}
          disabled={isPreviousDisabled}
          onClick={() => {
            if (!isPreviousDisabled) {
              const pageNumber = params?.page > 1 ? params?.page - 1 : 1;
              onChangePage(pageNumber);
            }
          }}
        >
          <span className='rotate-180 origin-center flex items-center justify-center'>
            <PaginationArrowImage src={ArrowRightIcon} alt='Previous' />
          </span>
        </button>

        <button
          type='button'
          className={cn(
            'w-32 h-32 rounded-6 border border-neutral-20 bg-neutral-10 flex items-center justify-center',
            isNextDisabled && 'opacity-50 cursor-not-allowed'
          )}
          disabled={isNextDisabled}
          onClick={() => {
            if (!isNextDisabled) {
              const pageNumber = params?.page ? params.page + 1 : 1;
              onChangePage(pageNumber);
            }
          }}
        >
          <PaginationArrowImage src={ArrowRightIcon} alt='Next' />
        </button>
      </Box>
    </div>
  );
};

export default TablePagination;
