import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AnalyticSearchRequestDto, InvoiceDisputeUpdateRequestDto, SetFapRefRequest } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { CacheKeys } from 'src/utils/cacheKeysUtil';

export interface UpdateArgs {
  disputeId: string;
  data: InvoiceDisputeUpdateRequestDto;
}

interface AssignFapRefArgs {
  page: {
    pageNumber: number;
    pageSize: number;
  };
  formValues: {
    [x: string]: any;
  };
}

export const useAssignFapRef = (props: AssignFapRefArgs) => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const apiGetArgs = {
    page: props?.page,
    searchRequest: props?.formValues as AnalyticSearchRequestDto,
  };

  return useMutation(async (args: SetFapRefRequest) => tFinancialService.api.assignFapRef(args), {
    onSuccess: async () => {
      setTimeout(async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            exact: false,
            queryKey: [CacheKeys.getInvoicesData],
          }),
        ]);
      }, 2000);
    },
  });
};
