import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';

import { byteConverter } from 'src/utils/utility';
import IconDocumentTextGreen from 'src/assets/images/icons/document-text-green.svg';
import { ACCEPT_FILE } from 'src/config';
import { IUploadFormValues } from './types';
import { UploadInputRaw } from '../../atom/upload';
import { simplifyFileNames } from './tools';

export const StepSelectInvoices = () => {
  const { watch, setValue } = useFormContext<IUploadFormValues>();
  const invoiceFiles = watch('invoiceFiles');

  /**
   * onDrop
   */
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const newFiles = [...invoiceFiles, ...acceptedFiles];
        setValue('invoiceFiles', newFiles);
      }
    },
    [invoiceFiles, setValue]
  );

  const dropzone = useDropzone({
    onDrop,
    multiple: true,
    accept: ACCEPT_FILE,
  });

  const onDeleteFile = useCallback(
    (index: number) => {
      const newFiles = [...invoiceFiles];
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      setValue('invoiceFiles', newFiles);
    },
    [invoiceFiles, setValue]
  );

  return (
    <div className='relative'>
      <h3 className='text-18 font-bold mb-4'>Select Invoices</h3>
      <p className='text-14 font-medium text-blue_grey-80 font-medium mt-5'>Select invoices from this service provider and modality to be uploaded:</p>

      <div className='mt-24'>
        <UploadInputRaw dropzone={dropzone} />
      </div>

      {invoiceFiles?.length > 0 && (
        <div className='mt-24 border border-neutral-20 rounded-8 p-24'>
          <div className='flex items-center mb-20'>
            <h4 className='font-medium text-18'>{invoiceFiles?.length} Items</h4>
            <button type='button' className='text-14 font-medium ml-auto text-red-40 underline' onClick={() => setValue('invoiceFiles', [])}>
              Clear Items
            </button>
          </div>

          <div className='flex flex-col gap-10'>
            {invoiceFiles.map((file, index) => (
              <div key={`upload-${file?.lastModified}`} className='border border-neutral-20 rounded-8 p-16 flex items-center gap-12'>
                <img src={IconDocumentTextGreen} alt='' />
                <strong className='text-16 font-medium'>{simplifyFileNames(file?.name)}</strong>

                <div className='ml-auto flex items-center gap-12'>
                  <span className='text-12 text-neutral-60'>{byteConverter(file?.size)}</span>

                  <button aria-label='delete button' type='button' onClick={() => onDeleteFile(index)}>
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
