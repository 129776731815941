/**
 * Deviation type
 */
export enum DeviationType {
  UNIT_PRICE = 'UNIT_PRICE',
  QUANTITY = 'QUANTITY',
  NEW_CHARGE = 'NEW_CHARGE',
  DUPLICATE = 'DUPLICATE',
}

export enum ActivityStatus {
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  FLAGGED = 'FLAGGED',
  PENDING_DOCUMENT = 'PENDING_DOCUMENT',
  NEW_INVOICE_UPLOADED = 'NEW_INVOICE_UPLOADED',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  READY_FOR_PAYMENT = 'READY_FOR_PAYMENT',
  APPROVED = 'APPROVED',
}

export enum DeviationWorkflowAction {
  SEND_TO_PROCUREMENT = 'SEND_TO_PROCUREMENT',
  SEND_TO_OPERATION = 'SEND_TO_OPERATION',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  START_DISPUTE = 'START_DISPUTE',
  MARK_INVOICE_TO_REJECT = 'MARK_INVOICE_TO_REJECT',
  MARK_INVOICE_TO_APPROVE = 'MARK_INVOICE_TO_APPROVE',
  ACCEPT_CREDIT_NOTE = 'ACCEPT_CREDIT_NOTE',
  CONTINUE_DISPUTE = 'CONTINUE_DISPUTE',
  READY_FOR_PAYMENT = 'READY_FOR_PAYMENT',
  REJECT_INVOICE = 'REJECT_INVOICE',
  ACCEPT_INVOICE = 'ACCEPT_INVOICE',
}
