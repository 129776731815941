/**
 * OperationsInputsField
 */
import { ReactNode } from 'react';
import { cn } from 'src/utils/utility';

export type FieldLabelBorder = 'dashed' | 'solid' | 'none';

interface IOperationsInputsFieldProps {
  label?: ReactNode;
  children: ReactNode;
  border?: FieldLabelBorder;
}

export const FieldLabel: React.FC<IOperationsInputsFieldProps> = ({ label, children, border }) => (
  <div
    className={cn(
      'mb-16 pb-16 border-b border-neutral-20 last:mb-0 last:pb-0 last:border-0',
      border === 'dashed' && 'border-dashed',
      border === 'solid' && 'border-solid',
      border === 'none' && 'border-none'
    )}
  >
    {label && (
      <div className='mb-10'>
        <span className='text-14 leading-18 text-blue_grey-30'>{label}</span>
      </div>
    )}
    {children}
  </div>
);
