import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { CircularProgress } from '@mui/material';
import { TableShippingService } from './TableShippingService';
import { INoContractContentStep2Props, NoContractForm } from './types';
import { NoContractSectionHeader } from './SectionHeader';
import { TableChargesShipment } from './TableChargesShipment';
import { TableChargesWeight } from './TableChargesWeight';
import { TableChargesContainer } from './TableChargesContainer';

export const NoContractContentStep2Create: FC<INoContractContentStep2Props> = (props) => {
  const { isEditable, activeStep, isLoadingCharges } = props;
  const { watch } = useFormContext<NoContractForm>();
  const { newMatchingService, containerCharges, weightCharges, shipmentDeclarationCharges } = watch();

  return (
    <div className={clsx(isEditable ? 'p-24' : 'mt-48')}>
      <div className='mb-42'>
        <NoContractSectionHeader
          title={isEditable ? 'Details of New Rate Card Shipping Service' : 'Details of New Shipping Service'}
          description={
            isEditable
              ? 'This new Rate Card shipping service will be created in the system. Please adjust the validity period of the invoice below'
              : 'New shipping service details. Please change the validity period if needed'
          }
        />
        <div className='mt-24'>
          <TableShippingService
            hideMismatched
            name='new-service'
            data={newMatchingService ? [newMatchingService] : []}
            isEditable={isEditable}
            activeStep={activeStep}
          />
        </div>
      </div>
      <NoContractSectionHeader
        title={isEditable ? 'Review & Edit Corresponding Rate Card Charges' : 'Corresponding New Rate Card Charges'}
        description={
          isEditable
            ? 'You can adjust the rates of the selected shipping service to match the invoice'
            : 'Here are the details of the Rate Card charges that will be associated to this new shipping service'
        }
      />
      <div className='mt-24'>
        <h3 className='text-16 font-medium mb-12'>Details of the charges at Shipment or Declaration level:</h3>
        {isLoadingCharges ? <CircularProgress color='primary' /> : <TableChargesShipment data={shipmentDeclarationCharges || []} enableEditing={isEditable} />}
      </div>
      {weightCharges && weightCharges.length > 0 && (
        <div className='mt-24'>
          <h3 className='text-16 font-medium mb-12'>Details of the charges based on Chargeable Weight / Volume:</h3>
          <TableChargesWeight data={weightCharges} enableEditing={isEditable} />
        </div>
      )}
      {containerCharges && containerCharges.length > 0 && (
        <div className='mt-24'>
          <h3 className='text-16 font-medium mb-12'>Details of the charges based on Container Type and Size:</h3>
          <TableChargesContainer data={containerCharges} enableEditing={isEditable} />
        </div>
      )}
    </div>
  );
};
