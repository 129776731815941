import { CircularProgress, Step, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from 'src/presentations/components/atom/button';
import { ModalConfirmCustom, StyledModal } from 'src/presentations/components/molecules/modal';

import format from 'date-fns/format';
import {
  useContractChargesDetailsForShippingService,
  useCreateShippingServiceAndContract,
  useIsValidityPeriodValid,
  useUpdateNoContractDraft,
} from 'src/use-cases/invoice/useNoContract';
import { ExistingShippingServiceDto, NoContractDraftData } from 'src/generated/services/TFinancialApi';
import { usePerformDeviationWorkflowAction } from 'src/use-cases/invoice/useInvoices';
import { DeviationWorkflowAction } from 'src/infra/types/deviation';
import { API_DATE_FORMAT } from 'src/config';
import { useToastResult } from 'src/presentations/components/molecules/toast/useToastResult';
import { ModalOpen, NoContractForm, RejectEnum, NoContractStep, INoContractMainProps, SelectedShippingServiceItem } from './types';
import { NoContractStepIcon } from './StepIcon';
import { NoContractHeader } from './Header';
import { NoContractContent } from './Content';
import { NoContractFooter } from './Footer';

const StyledStepper = styled(Stepper)({
  '& .MuiStepConnector-root': {
    padding: '0 8px',
  },
  '& .MuiStepConnector-line': {
    borderTopStyle: 'dashed',
    borderTopWidth: '2px',
  },
  '& .Mui-active .MuiStepConnector-line, & .Mui-completed .MuiStepConnector-line': {
    borderTopStyle: 'solid',
    borderColor: '#00992B',
  },
  '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed': {
    color: '#00992B',
    fontWeight: 'bold',
  },
  '& .MuiStepIcon-root': {
    color: '#ffffff',
    width: '32px',
    height: '32px',
    border: '1px solid #DFDFDF',
    borderRadius: '100%',

    '& .MuiStepIcon-text': {
      fill: '#00992B',
      fontWeight: 'bold',
    },

    '&.Mui-active': {
      color: '#00992B',
      borderColor: '#00992B',

      '& .MuiStepIcon-text': {
        fill: '#ffffff',
      },
    },
  },
});

const steps = [
  { id: NoContractStep.Step1_Select, label: 'Selection' },
  { id: NoContractStep.Step2_Resolution, label: 'Resolution' },
  { id: NoContractStep.Step3_Summary, label: 'Summary' },
];

const mismatchedFieldMap = {
  shipperName: 'shipper name',
  consigneeName: 'consignee name',
  service: 'service',
  incoterm: 'incoterm',
  type: 'type',
  carrier: 'carrier',
  pol: 'pol',
  pod: 'pod',
};

export const NoContractMain: FC<INoContractMainProps> = (props) => {
  const { matchingService, defaultContractFlowId, draft, invoice, onClose } = props;

  const [isShowModalConfirm, setIsShowModalConfirm] = useState<ModalOpen>(null);
  const [isShowErrorPeriod, setIsShowErrorPeriod] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<NoContractStep>(NoContractStep.Step1_Select);
  const [contractFlowId, setContractFlowId] = useState<string>(defaultContractFlowId);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);

  const { watch, setValue, resetField } = useFormContext<NoContractForm>();
  const formValues = watch();
  const { agreementType, verdict, selectedPartialMatchingService, newMatchingService, contractChoice, reason } = formValues;

  const { showToastResult } = useToastResult();

  useEffect(() => {
    if (defaultContractFlowId) {
      setContractFlowId(defaultContractFlowId);
    }
  }, [defaultContractFlowId]);

  const { mutateAsync: updateDraft } = useUpdateNoContractDraft(invoice?.id);
  const { mutateAsync: createShippingService } = useCreateShippingServiceAndContract(invoice?.id);
  const { mutateAsync: performDeviationWorkflowAction } = usePerformDeviationWorkflowAction();
  const { mutateAsync: checkIsValidityPeriodValid } = useIsValidityPeriodValid(invoice?.id);

  const { data, isRefetching } = useContractChargesDetailsForShippingService(contractFlowId || null);

  useEffect(() => {
    if (data?.containerCharges) {
      const items = data.containerCharges.filter((check) => check?.chargeType);
      setValue('containerCharges', items);
    }
    if (data?.shipmentDeclarationCharges) {
      const items = data.shipmentDeclarationCharges.filter((check) => check?.chargeType);
      setValue('shipmentDeclarationCharges', items);
    }
    if (data?.percentageCharges) {
      const items = data.percentageCharges.filter((check) => check?.chargeType);
      setValue('percentageCharges', items);
    }
    if (data?.weightCharges) {
      const items = data.weightCharges.filter((check) => check?.chargeType);
      setValue('weightCharges', items);
    }
  }, [data, draft, setValue]);

  useEffect(() => {
    if (draft) {
      setActiveStep(draft?.data?.currentStep);
    }
  }, [draft]);

  useEffect(() => {
    if (verdict !== 'accept') {
      resetField('contractChoice');
    }
  }, [verdict, resetField]);

  useEffect(() => {
    if (!selectedPartialMatchingService || selectedPartialMatchingService?.existingShippingService?.contractFlowId !== contractChoice) {
      const chosenShippingService = matchingService?.partialMatchingServices.find((op) => op.existingShippingService?.contractFlowId === contractChoice);
      setValue('selectedPartialMatchingService', chosenShippingService);
    }
  }, [contractChoice, matchingService?.partialMatchingServices, selectedPartialMatchingService, setValue]);

  const getMatchingServiceForApi = useCallback((matchingData: SelectedShippingServiceItem) => {
    let selectedData = null;
    if (matchingData?.existingShippingService) {
      const { validityPeriod, ...others } = matchingData.existingShippingService;
      selectedData = {
        existingShippingService: {
          ...others,
          startDate: format(validityPeriod?.start, 'yyyy-MM-dd'),
          endDate: format(validityPeriod?.end, 'yyyy-MM-dd'),
        },
        mismatchedFields: matchingData?.mismatchedFields,
      };
    }

    return selectedData;
  }, []);

  const onSaveDraft = useCallback(async () => {
    const selectedData = getMatchingServiceForApi(formValues.newMatchingService || formValues.selectedPartialMatchingService);

    let apiData = {};
    if (verdict === 'accept' || (verdict === 'reject-reject' && agreementType === RejectEnum.CREATE_NEW_SHIPPING_SERVICE)) {
      apiData = {
        selectedPartialMatchingService: selectedData,
        contractCharges: {
          containerCharges: formValues.containerCharges || [],
          shipmentDeclarationCharges: formValues.shipmentDeclarationCharges || [],
          weightCharges: formValues.weightCharges || [],
          percentageCharges: formValues.percentageCharges || [],
        },
        currentStep: activeStep,
        selectedResolutionOption: formValues.agreementType || '',
        rejectedReason: reason || '',
      };
    } else {
      apiData = {
        selectedPartialMatchingService: null,
        contractCharges: [],
        currentStep: activeStep,
        selectedResolutionOption: formValues.agreementType || '',
        rejectedReason: reason || '',
      };
    }

    await updateDraft(apiData as NoContractDraftData);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getMatchingServiceForApi,
    formValues.newMatchingService,
    formValues.containerCharges,
    formValues.shipmentDeclarationCharges,
    formValues.weightCharges,
    formValues.percentageCharges,
    formValues.agreementType,
    verdict,
    reason,
    updateDraft,
    onClose,
    activeStep,
  ]);

  const onConfirm = useCallback(async () => {
    const matchingService = getMatchingServiceForApi(formValues.newMatchingService);
    const apiData = {
      newShippingService: matchingService?.existingShippingService,
      contractCharges: {
        containerCharges: formValues.containerCharges || [],
        shipmentDeclarationCharges: formValues.shipmentDeclarationCharges || [],
        weightCharges: formValues.weightCharges || [],
        percentageCharges: formValues.percentageCharges || [],
      },
    };

    setIsLoadingConfirm(true);

    try {
      await createShippingService(apiData);
      await performDeviationWorkflowAction({ invoiceId: invoice?.id, deviationWorkflowAction: DeviationWorkflowAction.ACCEPT_INVOICE });

      setIsLoadingConfirm(false);
      setIsShowModalConfirm(null);
      onClose();
    } catch {
      setIsLoadingConfirm(false);
      setIsShowModalConfirm(null);
      setIsShowErrorPeriod(true);
      setActiveStep(activeStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getMatchingServiceForApi,
    formValues.newMatchingService,
    formValues.containerCharges,
    formValues.shipmentDeclarationCharges,
    formValues.weightCharges,
    formValues.percentageCharges,
    createShippingService,
    performDeviationWorkflowAction,
    invoice?.id,
    onClose,
  ]);

  const onSuccessSubmit = async () => {
    let isValid = true;
    const isStep2 = activeStep === NoContractStep.Step2_Resolution;
    if (isStep2 && verdict !== 'reject-reject' && formValues.newMatchingService?.existingShippingService) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { validityPeriod, ...others } = formValues.newMatchingService?.existingShippingService;
      const verifyData: ExistingShippingServiceDto = {
        ...others,
        startDate: format(new Date(validityPeriod?.start), API_DATE_FORMAT),
        endDate: format(new Date(validityPeriod?.end), API_DATE_FORMAT),
      };
      const checkResponse = await checkIsValidityPeriodValid(verifyData);
      isValid = !!checkResponse?.data?.data?.isValid;
    }

    if (!isValid) {
      setIsShowErrorPeriod(true);
    } else {
      const chosenAgreementType = agreementType === RejectEnum.CREATE_NEW_SHIPPING_SERVICE;
      const isStep2Accept = isStep2 && chosenAgreementType && verdict === 'reject-reject';

      if (activeStep === NoContractStep.Step1_Select || isStep2Accept) {
        const chosenShippingService = matchingService?.partialMatchingServices.find((op) => op.existingShippingService?.contractFlowId === contractChoice);
        if (chosenShippingService) {
          const newServiceData = {};
          for (const key in chosenShippingService.existingShippingService) {
            let value = null;
            switch (key) {
              case 'shipperName':
              case 'consigneeName':
              case 'pol':
              case 'pod':
              case 'service':
              case 'type':
              case 'carrier':
              case 'incoterm':
                if (
                  typeof mismatchedFieldMap[key] !== 'undefined' &&
                  chosenShippingService.mismatchedFields.includes(mismatchedFieldMap[key]) &&
                  chosenShippingService?.existingShippingService[key] !== null &&
                  chosenShippingService?.existingShippingService[key] !== ''
                ) {
                  value = matchingService?.unmatchedService[key];

                  break;
                }
                // eslint-disable-next-line no-continue
                continue;
              default:
                value = chosenShippingService?.existingShippingService[key] || null;
            }

            newServiceData[key] = value;
          }

          const newShippingServiceData = {
            mismatchedFields: chosenShippingService?.mismatchedFields,
            existingShippingService: {
              ...chosenShippingService.existingShippingService,
              ...newServiceData,
            },
          };
          setValue('newMatchingService', newShippingServiceData);
        }
      }
      if (isStep2 && chosenAgreementType) {
        const updatedNewMatchingService = {
          ...newMatchingService,
          existingShippingService: {
            ...newMatchingService.existingShippingService,
            pod: matchingService?.unmatchedService?.pod,
            pol: matchingService?.unmatchedService?.pol,
          },
        };
        setValue('newMatchingService', updatedNewMatchingService);
      }

      if (activeStep === NoContractStep.Step1_Select && verdict === 'accept') {
        setContractFlowId(contractChoice);
      }

      // if (isStep2 && verdict === 'reject-reject') {
      //   setValue('agreementType', agreementType);
      //   setValue('reason', reason);
      // }

      if (isStep2 && chosenAgreementType && verdict === 'reject-reject') {
        setValue('verdict', 'reject-approve');
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const onReject = useCallback(async () => {
    await performDeviationWorkflowAction({ invoiceId: invoice?.id, deviationWorkflowAction: DeviationWorkflowAction.REJECT_INVOICE, reason });
    showToastResult(invoice, DeviationWorkflowAction.REJECT_INVOICE);

    setIsShowModalConfirm(null);
    onClose();
  }, [performDeviationWorkflowAction, invoice, reason, showToastResult, onClose]);

  return (
    <>
      <div className='px-24 pb-20 border-neutral-20'>
        <div className='py-12 px-24 bg-neutral-10 rounded-8 mb-20'>
          <StyledStepper activeStep={activeStep}>
            {steps.map(({ id, label }) => (
              <Step key={id}>
                <StepLabel color='inherit' StepIconComponent={NoContractStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </StyledStepper>
        </div>

        <NoContractHeader invoice={invoice} />
      </div>

      <NoContractContent activeStep={activeStep} matchingService={matchingService} draft={draft} isLoadingCharges={isRefetching} />

      <NoContractFooter
        activeStep={activeStep}
        onGoBack={() => setActiveStep(activeStep - 1)}
        onClose={onClose}
        onSuccessSubmit={onSuccessSubmit}
        onSaveDraft={onSaveDraft}
        onConfirm={(e: NoContractForm) => {
          if (e.verdict === 'accept') {
            setIsShowModalConfirm('create');
          } else {
            setIsShowModalConfirm('reject');
          }
        }}
      />
      <ModalConfirmCustom
        open={isShowModalConfirm === 'create'}
        title='Confirmation'
        onAccept={onConfirm}
        onCancel={() => {
          setIsShowModalConfirm(null);
        }}
        isLoading={isLoadingConfirm}
        buttonAcceptLabel='Reject'
        buttonChildren={
          <>
            <Button
              variant='secondary'
              disabled={isLoadingConfirm}
              onClick={() => {
                setIsShowModalConfirm(null);
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={onConfirm} disabled={isLoadingConfirm}>
              <div className='flex items-center gap-6'>
                Confirm
                {isLoadingConfirm && <CircularProgress size={20} sx={{ marginLeft: '1em', color: '#ffffff' }} />}
              </div>
            </Button>
          </>
        }
      >
        <p>
          You will create a new shipping service in the system with the corresponding Rate Card charges that you reviewed and trigger the audit of the invoice.
          Are you sure?
        </p>
      </ModalConfirmCustom>

      <ModalConfirmCustom
        open={isShowModalConfirm === 'reject'}
        title='Confirmation'
        onAccept={onReject}
        onCancel={() => {
          setIsShowModalConfirm(null);
        }}
        buttonAcceptLabel='Reject'
        buttonChildren={
          <>
            <Button
              variant='secondary'
              onClick={() => {
                setIsShowModalConfirm(null);
              }}
            >
              Cancel
            </Button>
            <Button className='bg-[#E72547]' variant='danger' onClick={onReject}>
              Reject
            </Button>
          </>
        }
      >
        <p>Are you sure you want to proceed with this?</p>
      </ModalConfirmCustom>

      <StyledModal open={isShowErrorPeriod} title='Warning: Validity Period' fullWidth onClose={() => setIsShowErrorPeriod(false)}>
        <div className='relative'>
          <ol className='list-decimal px-3'>
            <li>Make sure the new Validity Period covers the ETD of the current invoice.</li>
            <li>Make sure the new Validity Period doesn’t overlap with existing Validity Periods of similar Shipping Services.</li>
          </ol>

          <div className='mt-48'>
            <Button variant='primary' onClick={() => setIsShowErrorPeriod(false)} isFull>
              Dismiss
            </Button>
          </div>
        </div>
      </StyledModal>
    </>
  );
};
