import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { useGetDomainMetaData } from 'src/use-cases/invoice/useInvoices';
import { useEncodedQueryState } from 'src/hooks/useEncodedQueryState';
import { useEffect } from 'react';
import { IAnalyticStateProps } from './types';
import { ChartViewType } from './sidebar/types';
import { AnalyticsContainerMain } from './Main';

const defaultFiltersState: IAnalyticStateProps = {
  name: 'New Report',
  description: 'Report Description',
  filters: {
    selectedChartView: ChartViewType.TREND,
    formValues: {
      granularity: 'DAILY',
      dateReference: 'PICKUP_DATE',
      currencyReference: null,
      filters: {
        dateFilters: {},
        tradeLaneFilters: {},
        shipmentFilters: {},
        billingFilters: {},
      },
      invoiceStatusSet: ['APPROVED'],
    } as AnalyticSearchRequestDto,
  },
};

const AnalyticsContainer = () => {
  const [hashedFilter, setHashedFilter] = useEncodedQueryState(defaultFiltersState, 'report');
  const { data: dataStatus, isLoading } = useGetDomainMetaData();
  const defaultCurrency = dataStatus?.defaultCurrency || null;

  useEffect(() => {
    if (!hashedFilter.filters.formValues.currencyReference && defaultCurrency) {
      setHashedFilter({
        name: hashedFilter.name,
        description: hashedFilter.description,
        filters: {
          selectedChartView: hashedFilter.filters.selectedChartView,
          formValues: {
            ...hashedFilter.filters.formValues,
            currencyReference: defaultCurrency,
          },
        },
      });
    }
  }, [defaultCurrency, hashedFilter, setHashedFilter]);

  if (isLoading || !hashedFilter.filters.formValues?.currencyReference) {
    return <></>;
  }

  return <AnalyticsContainerMain hashedFilter={hashedFilter} setHashedFilter={setHashedFilter} />;
};
export default AnalyticsContainer;
