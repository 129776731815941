import { cn } from 'src/utils/utility';
import { useFormContext } from 'react-hook-form';
import { CardStatType, IChipOptionsProps } from './types';
import { useCardOptions } from './helpers';

export const CardStatChip = ({ type }: { type: CardStatType }) => {
  const { watch } = useFormContext();
  const { currencyReference } = watch();

  const chipOptions: IChipOptionsProps | null = useCardOptions(type, {
    unitCurrency: currencyReference,
  });

  return (
    <div className={cn('inline-flex items-center gap-8 rounded-full pl-6 py-6 pr-16 border', chipOptions?.styles)}>
      <img src={chipOptions?.icon} alt='' className='w-24' />
      <div className='flex-1 text-14 font-bold text-blue_grey-90'>
        <div className='flex items-center gap-8'>
          {chipOptions?.label}
          {chipOptions?.unit && (
            <>
              <span className='h-16 w-1 bg-neutral-20' />
              <span className='text-neutral-50'>{chipOptions.unit}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
