import React, { ReactNode } from 'react';
import { ComposedChart, CartesianGrid, Tooltip, XAxis, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomToolTip';

interface CustomComposedChartProps {
  data: any[]; // Type your data appropriately
  children: ReactNode;
  xAxisKey: string;
}

const CustomComposedChart: React.FC<CustomComposedChartProps> = ({ data, children, xAxisKey }) => (
  <ResponsiveContainer width='100%' height={700}>
    <ComposedChart
      data={data}
      margin={{
        top: 100,
        right: 20,
        bottom: 100,
        left: 20,
      }}
    >
      <CartesianGrid stroke='#DFDFDF' strokeWidth={1} vertical={false} />
      <Tooltip content={<CustomTooltip />} />
      <XAxis dataKey={xAxisKey} angle={-45} textAnchor='end' interval='preserveStartEnd' padding={{ left: 50, right: 50 }} />
      {/* Render children */}
      {children}
    </ComposedChart>
  </ResponsiveContainer>
);

export default CustomComposedChart;
