export enum CardId {
  TOTAL_AMOUNT = 'totalAmount',
  CHARGABLE_WEIGHT = 'chargeableWeight',
  GROSS_WEIGHT = 'grossWeight',
  VOLUME = 'volume',
  TOTAL_CO2_EMISSIONS = 'totalCo2Emissions',
  NO_SHIPMENTS = 'noShipments',
  NO_INVOICES = 'noInvoices',
  NO_CHARGES = 'noCharges',
  TOTAL_DEVIATION_AMOUNT = 'totalDeviationAmount',
}
