import { ReactNode, createContext, useMemo, useState } from 'react';
import { InvoiceDetailDto, InvoiceDto } from 'src/generated/services/TFinancialApi';

type InvoiceDataDto = InvoiceDetailDto | InvoiceDto;

export interface IToastResultSelectorProps {
  children: ReactNode;
}

export interface IToastResultSelectorData {
  isShowResult: boolean;
  currentInvoice: InvoiceDataDto;
  workflowStatus: string;
  setIsShowResult(value: boolean): void;
  setCurrentInvoice(value: InvoiceDataDto): void;
  setWorkflowStatus(value: string): void;
  showToastResult(invoice: InvoiceDataDto, workflow: string): void;
  hideToastResult(): void;
}

export const ToastResultSelectorContext = createContext<IToastResultSelectorData>(null);

export const ToastResultSelector = ({ children }: IToastResultSelectorProps) => {
  const [isShowResult, setIsShowResult] = useState<boolean>(false);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceDataDto>(null);
  const [workflowStatus, setWorkflowStatus] = useState<string>('');

  const showToastResult = (invoice: InvoiceDataDto, workflow: string) => {
    setIsShowResult(true);
    setCurrentInvoice(invoice);
    setWorkflowStatus(workflow);
  };

  const hideToastResult = () => {
    setIsShowResult(false);
    setCurrentInvoice(null);
    setWorkflowStatus('');
  };

  const value = useMemo(
    () => ({
      isShowResult,
      setIsShowResult,
      currentInvoice,
      setCurrentInvoice,
      workflowStatus,
      setWorkflowStatus,
      showToastResult,
      hideToastResult,
    }),
    [isShowResult, setIsShowResult, currentInvoice, setCurrentInvoice, workflowStatus, setWorkflowStatus]
  );

  return <ToastResultSelectorContext.Provider value={value}>{children}</ToastResultSelectorContext.Provider>;
};
