import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const FilterLoader = () => (
  <div className='w-[11.25rem] bg-white border border-b-2 border-neutral-20 rounded-8 py-6 px-12 flex items-center justify-center gap-12'>
    <div className='flex-1'>
      <Skeleton animation='wave' height={20} width='100%' />
    </div>

    <div className='ml-auto'>
      <ArrowDropDownIcon sx={{ color: '#888888' }} />
    </div>
  </div>
);
