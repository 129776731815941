import IconEmpty from 'src/assets/images/icons/empty-notification.svg';

export const NotificationContentEmpty = () => (
  <div className='flex items-center justify-center h-[380px] bg-[#F5F6FA] rounded-b-8'>
    <div className='flex flex-col items-center'>
      <img src={IconEmpty} alt='' />
      <p className='text-16 text-blue_grey-30'>No Data</p>
    </div>
  </div>
);
