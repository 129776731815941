import { ReactNode, useCallback } from 'react';
import { DropzoneState, FileRejection, useDropzone } from 'react-dropzone';
import { useImmerReducer } from 'use-immer';
import IconDocumentText from 'src/assets/images/icons/document-text.svg';
import { cn } from 'src/utils/utility';
import { ACCEPT_FILE } from 'src/config/config';
import { ModalConfirm } from 'src/presentations/components/molecules/modal';

const initialState: UploadState = {
  fileUpload: null,
  uploadError: '',
  uploadPercentage: 0,
  isShowDeleteConfirm: false,
};

interface UploadState {
  uploadPercentage: number;
  // fileUpload will be non-null while uploading
  fileUpload: FileProps;
  uploadError: string;
  isShowDeleteConfirm: boolean;
}

interface FileProps {
  name: string;
  date: string;
}

type UploadAction =
  | {
      type: 'uploading';
      payload: number;
    }
  | {
      type: 'showUploadingFile';
      payload: FileProps;
    }
  | {
      type: 'error';
      payload: string;
    }
  | {
      type: 'deleting';
    }
  | {
      type: 'deleteSuccess';
    }
  | {
      type: 'openDeleteConfirm';
    }
  | {
      type: 'closeDeleteConfirm';
    }
  | {
      type: 'uploadComplete';
    };

const uploadReducer = (state: UploadState, action: UploadAction): UploadState => {
  switch (action.type) {
    case 'uploading':
      state.uploadPercentage = action.payload;
      return state;
    case 'showUploadingFile':
      state.uploadPercentage = 0;
      state.fileUpload = action.payload;
      state.uploadError = '';
      return state;
    case 'uploadComplete':
      state.fileUpload = null;
      return state;
    case 'deleting':
      state.fileUpload = null;
      state.isShowDeleteConfirm = false;
      return state;

    case 'error':
      state.fileUpload = null;
      state.uploadError = action.payload;
      return state;
    case 'openDeleteConfirm':
      state.isShowDeleteConfirm = true;
      return state;
    case 'closeDeleteConfirm':
      state.isShowDeleteConfirm = false;
      return state;

    default:
      return state;
  }
};

const UploadInput = () => {
  const [state, dispatch] = useImmerReducer(uploadReducer, initialState);
  const { uploadError, isShowDeleteConfirm } = state;

  const disableDropzone = false;

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        dispatch({ type: 'showUploadingFile', payload: { name: file.name, date: null } });
      } else if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          file.errors.forEach(() => {});
        });
      }
    },
    [dispatch]
  );

  const dropzone = useDropzone({
    onDrop,
    multiple: false,
    accept: ACCEPT_FILE,
    disabled: disableDropzone,
  });

  const onDeleteFile = async () => {
    dispatch({ type: 'deleting' });
  };

  return (
    <>
      <UploadInputRaw dropzone={dropzone} error={<p className='text-14 text-red-40'>{uploadError}</p>} />
      <ModalConfirm
        open={isShowDeleteConfirm}
        title='Warning'
        onAccept={onDeleteFile}
        onCancel={() => dispatch({ type: 'closeDeleteConfirm' })}
        buttonAcceptLabel='Discard'
      >
        <p>The uploaded document will not be saved. Are you sure want to discard your changes?</p>
      </ModalConfirm>
    </>
  );
};

interface IUploadInputRawProps {
  dropzone: DropzoneState;
  error?: ReactNode;
  disabled?: boolean;
}

export const UploadInputRaw = ({ dropzone, error, disabled }: IUploadInputRawProps) => {
  const { getRootProps, open } = dropzone;

  return (
    <div {...getRootProps()}>
      <div
        className={cn(
          'w-full h-[156px] flex items-center justify-center border border-dashed rounded-8 border-neutral-20 bg-neutral-10',
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        )}
      >
        <div className='flex flex-col items-center'>
          <img src={IconDocumentText} alt='' />
          <p className='mt-12 text-14'>
            Drag and drop file or{' '}
            <span
              onClick={open}
              role='presentation'
              className={cn('underline', { 'cursor-pointer text-green-50': !disabled, 'text-grey-40 cursor-not-allowed': disabled })}
            >
              click here
            </span>{' '}
            to upload
          </p>
          {error}
        </div>
      </div>
    </div>
  );
};

export default UploadInput;
