import { useContext } from 'react';
import { ToastResultSelectorContext } from './ToastResultSelector';

export function useToastResult() {
  const context = useContext(ToastResultSelectorContext);

  if (!context) {
    throw new Error(`Upload Invoices selector compound components cannot be rendered outside the Upload Invoices Selector component`);
  }
  return context;
}
