import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { InvoiceDto, InvoiceUploadSignedUrlRequestDto } from 'src/generated/services/TFinancialApi';

export interface INoContractSelectorProps {
  children: ReactNode;
}

export interface INoContractSelectorData {
  isShowModal: boolean;
  selected: InvoiceDto;
  modality: string;
  serviceProvider: string;
  showNoContract(value: InvoiceDto): void;
  closeNoContract(): void;
  setServiceProvider(value: string): void;
  setModality(value: InvoiceUploadSignedUrlRequestDto['modality']): void;
}

export const NoContractSelectorContext = createContext<INoContractSelectorData>(null);

export const NoContractSelector = ({ children }: INoContractSelectorProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<InvoiceDto>(null);
  const [serviceProvider, setServiceProvider] = useState<string>('');
  const [modality, setModality] = useState<InvoiceUploadSignedUrlRequestDto['modality']>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showNoContract = (item: InvoiceDto) => {
    setSelected(item);
    setIsShowModal(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeNoContract = () => {
    setSelected(null);
    setIsShowModal(false);
  };

  const value = useMemo(
    () => ({
      isShowModal,
      selected,
      serviceProvider,
      modality,
      setModality,
      setServiceProvider,
      showNoContract,
      closeNoContract,
    }),
    [isShowModal, selected, modality, serviceProvider, showNoContract, closeNoContract, setModality, setServiceProvider]
  );

  return <NoContractSelectorContext.Provider value={value}>{children}</NoContractSelectorContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useNoContractSelector() {
  const context = useContext(NoContractSelectorContext);

  if (!context) {
    throw new Error(`No Contract selector compound components cannot be rendered outside the No Contract Selector component`);
  }
  return context;
}
