import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { RhfMuiSelect, RhfMuiTextArea } from 'src/presentations/components/molecules/fields/rhf-mui-fields';
import { useFieldMaterialUIStyles } from 'src/presentations/components/molecules/fields/useFieldMaterialUIStyles';
import DocumentGreen from 'src/assets/images/icons/document-text-green.svg';
import { INoContractContentStep2Props, NoContractForm, RejectEnum } from './types';
import { NoContractSectionHeader } from './SectionHeader';
import { InformationCallout } from './InformationCallout';
import { SelectCard } from './SelectCard';
import { TableShippingService } from './TableShippingService';

const rejectResolutionOptions = [
  // {
  //   id: RejectEnum.ONE_OFF,
  //   label: 'Accept the invoice as is one-off',
  // },
  {
    id: RejectEnum.CREATE_NEW_SHIPPING_SERVICE,
    label: 'Accept invoice and create shipping service',
  },
  {
    id: RejectEnum.REJECT,
    label: 'Reject it',
  },
];

export const NoContractContentStep2Reject: FC<INoContractContentStep2Props> = (props) => {
  const { activeStep, matchingService } = props;
  const { classes } = useFieldMaterialUIStyles();
  const { watch } = useFormContext<NoContractForm>();
  const { agreementType } = watch();

  return (
    <div className='p-24'>
      <InformationCallout
        text={
          <p>
            You need to <span className='font-bold'>contact your Service Provider</span> to review the invoice and agree on a resolution approach.
          </p>
        }
        title='Contact Service Provider'
      />
      <div className='my-24'>
        <NoContractSectionHeader
          title='Resolution'
          description='Please document and classify the outcome of your exchange with your Service Provider to resolve this flagged invoice:'
        />
      </div>
      <div className='mb-24'>
        <RhfMuiSelect
          name='agreementType'
          className={classes.inputField}
          fullWidth
          sx={{ color: '#dddddd' }}
          label='Select Agreement Reached'
          hideFieldLabel
          options={rejectResolutionOptions}
        />
      </div>

      {agreementType === RejectEnum.CREATE_NEW_SHIPPING_SERVICE && (
        <div className='mt-12'>
          <SelectCard
            onClick={() => {}}
            icon={<img src={DocumentGreen} alt='green document icon' />}
            header='Available Rate Card Shipping Services Partially Matching this Invoice'
            description='Select one of these Shipping Services that closely match the key elements of the selected invoice. You will be able to amend the corresponding tariffs in the next screen.'
          >
            <TableShippingService name='reject-new-service' data={matchingService.partialMatchingServices || []} isSelectable activeStep={activeStep} />
          </SelectCard>
        </div>
      )}
      {agreementType === RejectEnum.REJECT && (
        <div className='mt-12'>
          <RhfMuiTextArea name='reason' className={classes.inputField} placeholder='Input Reason' fullWidth />
        </div>
      )}
    </div>
  );
};
