/* eslint-disable react-refresh/only-export-components */
const base = 'w-full text-18 text-neutral-70 leading-24 outline-none p-10';
const border = 'border-b border-neutral-60';
const borderFull = 'border rounded-4 px-16';

const stateIdle = 'border-neutral-60';
const stateError = 'border-yellow-60 bg-yellow-10';
// const stateDisabled = 'border-neutral-30 bg-neutral-20 text-neutral-60 cursor-not-allowed';
const stateDisabled = 'border-neutral-30 bg-transparent text-neutral-40 cursor-not-allowed';

export const getMainStyles = (state = 'idle') => {
  let styles = `${base} ${borderFull}`;
  if (state === 'error') {
    styles += ` ${stateError}`;
  } else if (state === 'disabled') {
    styles += ` ${stateDisabled}`;
  } else {
    styles += ` ${stateIdle}`;
  }
  return styles;
};

const InputStyles = {
  base,
  border,
  borderFull,
  stateError,
  stateIdle,
  stateDisabled,
  primary: `${base} ${borderFull}`,
};

export default InputStyles;
