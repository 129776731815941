import { ICustomIconProps } from './types';

const UpdateReport: React.FC<ICustomIconProps> = ({ size = 36, color = '#00992B' }) => (
  <svg width={size} height={size} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.4'
      d='M19.335 8.81982H7.66498C5.09998 8.81982 3 10.9198 3 13.4848V30.5248C3 32.6998 4.56 33.6298 6.465 32.5648L12.36 29.2798C12.99 28.9348 14.01 28.9348 14.625 29.2798L20.52 32.5648C22.425 33.6298 23.985 32.6998 23.985 30.5248V13.4848C24 10.9198 21.9 8.81982 19.335 8.81982Z'
      fill={color}
    />
    <path
      d='M33 7.66498V24.705C33 26.88 31.44 27.795 29.535 26.745L24 23.655V13.485C24 10.92 21.9 8.82001 19.335 8.82001H12V7.66498C12 5.09998 14.1 3 16.665 3H28.335C30.9 3 33 5.09998 33 7.66498Z'
      fill={color}
    />
  </svg>
);

export default UpdateReport;
