import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { TableContainer, TableFooter } from '@mui/material';
import { MRT_TableInstance, MRT_TableBody as MRTTableBody, MRT_TableHead as MRTTableHead } from 'material-react-table';
import { ReactNode } from 'react';

export const TableWrapper = styled(Paper)({
  borderRadius: '8px',
  boxShadow: 'none',
  border: '1px solid #DFDFDF',
});

export const TableMain = styled(Table)({
  '& .MuiTableCell-head, & .MuiTableCell-footer': {
    borderRight: '1px solid #DFDFDF',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#F5F6FA',

    '&:last-child': {
      borderRight: 0,
    },
  },
  '& .MuiTableRow-root.Mui-selected': {
    backgroundColor: 'rgba(0, 153, 43, 0.12) !important',
  },
  '& .MuiTableCell-head': {
    padding: '8px 16px',
  },
  '& .MuiTableHead-root': {
    top: 0,
  },
  '& .MuiTableCell-footer': {
    padding: '12px 16px',
    color: '#000000',
  },
  '& .MuiTableCell-body ': {
    borderRight: '1px solid #DFDFDF',
    padding: '12px 16px',
    fontSize: '14px',

    '&:last-child': {
      borderRight: 0,
    },
  },
});

interface CustomTableProps<T> {
  table: MRT_TableInstance<T>;
  footer?: ReactNode;
  tableContainerProps?: Record<string, any>;
}
export function CustomTable<T>({ table, footer, tableContainerProps = {} }: CustomTableProps<T>) {
  return (
    <TableContainer component={TableWrapper} {...tableContainerProps}>
      <TableMain>
        <MRTTableHead table={table} />
        <MRTTableBody table={table} />
        {footer && <TableFooter>{footer}</TableFooter>}
      </TableMain>
    </TableContainer>
  );
}
