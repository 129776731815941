import { combineReducers } from 'redux';
import api from './apiSlice';

import search from './searchSlice';
import authReducer from './auth';

export default combineReducers({
  api,
  auth: authReducer,
  search,
});
