import {
  DeviationQuestionDto,
  DeviationQuestionUserResponseDto,
  InvoiceDetailDto,
  ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto,
} from 'src/generated/services/TFinancialApi';

export const mockInvoiceDetailProcurement: InvoiceDetailDto = {
  id: '7bea6801-ce46-4386-a9dc-b78b0c25bdb4',
  mblHbl: 'GTD0640471',
  invoiceNumber: '123456789',
  invoiceDate: '2022-01-31',
  freightForwarder: {
    name: 'Bollore Logistics Japan K.K.',
    code: 'BOLLORE_LOGISTICS_JAPAN_K_K',
    country: null,
  },
  status: 'PENDING_DOCUMENT',
  paymentDate: '2022-04-30',
  incoterm: 'EXW',
  invoiceAmount: {
    beforeTax: 295174,
    afterTax: 295874,
    currency: 'JPY',
  },
  creditNoteAmount: {
    beforeTax: 9318.4,
    afterTax: 9318.4,
    currency: 'JPY',
  },
  amountToBePaid: {
    beforeTax: 285855.6,
    afterTax: 286555.6,
    currency: 'JPY',
  },
  invoicedParty: 'Valeo Japan (TCP/T36)',
  invoiceAmountDeviation: {
    beforeTax: 9318.4,
    afterTax: 9318.4,
    currency: 'JPY',
  },
  deviations: [
    {
      id: 'c7d60d41-92fa-4dea-90f7-163bb191d04f',
      name: 'Unit Price',
      type: 'UNIT_PRICE',
      status: 'DONE',
      legType: 'LEG_ONE',
      chargeDescription: 'SEAFREIGHT CHARGES',
      legCurrency: 'USD',
      deviationCurrency: 'USD',
      contractChargeUnit: 'CONTAINER',
      invoiceChargeUnit: 'SEAFREIGHT CHARGES',
      contractUnitPrice: 50,
      invoiceUnitPrice: 60,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 10,
      deviationAmount: 10,
      deviationAmountBeforeTax: 10,
      deviationAmountAfterTax: 10,
      rejectedByOperation: false,
    },
    {
      id: 'c0c6a0c7-5c9f-4724-8061-97421aa1ff43',
      name: 'New Charge',
      type: 'NEW_CHARGE',
      status: 'UNFILLED',
      legType: 'LEG_TWO',
      chargeDescription: 'SEAFREIGHT CHARGES',
      legCurrency: 'USD',
      deviationCurrency: 'USD',
      contractChargeUnit: 'CONTAINER',
      invoiceChargeUnit: 'SEAFREIGHT CHARGES',
      contractUnitPrice: 9999999,
      invoiceUnitPrice: 1600,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 150,
      deviationAmount: 300,
      deviationAmountBeforeTax: 300,
      deviationAmountAfterTax: 300,
      rejectedByOperation: false,
    },
    {
      id: 'c7d60d41-92fa-4dea-90f7-163bb191d04g',
      name: 'Unit Price',
      type: 'UNIT_PRICE',
      status: 'REJECTED_DONE',
      legType: 'LEG_THREE',
      chargeDescription: 'SEAFREIGHT CHARGES',
      legCurrency: 'USD',
      deviationCurrency: 'USD',
      contractChargeUnit: 'CONTAINER',
      invoiceChargeUnit: 'SEAFREIGHT CHARGES',
      contractUnitPrice: 50,
      invoiceUnitPrice: 60,
      invoiceQuantity: 1,
      deviationQuantity: null,
      deviationAmountPerUnit: 10,
      deviationAmount: 10,
      deviationAmountBeforeTax: 10,
      deviationAmountAfterTax: 10,
      rejectedByOperation: false,
    },
  ],
  shipmentDetail: {
    numberOfInvoices: 2,
    shipmentAmount: {
      beforeTax: 451634,
      afterTax: 465284,
      currency: 'JPY',
    },
    deviationAmount: {
      beforeTax: 50028.4,
      afterTax: 51878.4,
      currency: 'JPY',
    },
    containersCount: 2,
    containers: [
      {
        size: 20,
        quantity: 1,
        type: 'GP',
      },
      {
        size: 40,
        quantity: 1,
        type: 'GP',
      },
    ],
    incoterm: 'EXW',
    shipmentNumber: 'GTD0640471',
    shipper: 'Valeo Automotive (Thailand) CO. LTD',
    placeToPickup: 'Rayong T39 ok',
    consignee: 'Valeo Japan (TCP/T36)',
    deliveryPlace: 'Kohnan T36',
    totalGrossWeight: 37464,
    volume: 57.36,
    totalCo2Emission: 2.7,
  },
  legDetails: [
    {
      type: 'LEG_THREE',
      transportMedium: 'AIR',
      modality: 'AIR',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 1,
      legCurrency: 'JPY',
      legAmountAfterTax: 262310,
      paymentAmountAfterTax: 262310,
      legDeviationAmountAfterTax: 42560,
      paymentDeviationAmountAfterTax: 42560,
      co2Emission: 0.4,
      invoices: [
        {
          id: '0a183bc3-1e32-4363-9222-89013e09153a',
          invoiceNumber: '3509064',
          status: 'APPROVED',
        },
        {
          id: '7bea6801-ce46-4386-a9dc-b78b0c25bdb4',
          invoiceNumber: '3508101',
          status: 'PENDING_DOCUMENT',
        },
      ],
    },
    {
      type: 'LEG_ONE',
      transportMedium: 'AIR',
      modality: 'AIR',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 3.57,
      legCurrency: 'THB',
      legAmountAfterTax: 24717.09,
      paymentAmountAfterTax: 88240,
      legDeviationAmountAfterTax: 0,
      paymentDeviationAmountAfterTax: 0,
      co2Emission: 0.18,
      invoices: [
        {
          id: '7bea6801-ce46-4386-a9dc-b78b0c25bdb4',
          invoiceNumber: '3508101',
          status: 'PENDING_DOCUMENT',
        },
      ],
    },
    {
      type: 'LEG_TWO',
      transportMedium: 'AIR',
      modality: 'AIR',
      paymentCurrency: 'JPY',
      paymentCurrencyExchangeRate: 116.48,
      legCurrency: 'USD',
      legAmountAfterTax: 985.02,
      paymentAmountAfterTax: 114734,
      legDeviationAmountAfterTax: 80,
      paymentDeviationAmountAfterTax: 9318.4,
      co2Emission: 2.14,
      invoices: [
        {
          id: '7bea6801-ce46-4386-a9dc-b78b0c25bdb4',
          invoiceNumber: '3508101',
          status: 'PENDING_DOCUMENT',
        },
      ],
    },
  ],
  transportDetail: {
    legOne: {
      startLocation: 'Rayong T39 ok',
      startDate: '2022-01-21',
      transportMedium: 'AIR',
      deliveryLocation: 'THLCH',
      deliveryDate: '2022-01-30',
    },
    legTwo: {
      startLocation: 'THLCH',
      startDate: '2022-01-21',
      transportMedium: 'AIR',
      deliveryLocation: 'JPTYO',
      deliveryDate: '2022-01-30',
    },
    legThree: {
      startLocation: 'JPTYO',
      startDate: '2022-01-21',
      transportMedium: 'AIR',
      deliveryLocation: 'Kohnan T36',
      deliveryDate: '2022-01-30',
    },
  },
  permissions: ['CAN_GIVE_DISPUTE_REVIEW'],
  possibleWorkflowAction: 'MARK_INVOICE_TO_REJECT',
  nextWorkflowAction: null,
  currentDeviationWorkflow: 'PROCUREMENT_DISPUTE_REVIEW',
  importExportType: null,
  modality: 'AIR',
  invoiceDispute: {
    id: '6fc0c6e8-533e-4dd1-8651-ba9939510eed',
    invoiceId: '7bea6801-ce46-4386-a9dc-b78b0c25bdb4',
    currency: 'JPY',
    lumpSumAmount: null,
    lumpSumAmountBeforeTax: null,
    expectedTotalAmount: {
      beforeTax: 9318.4,
      afterTax: 9318.4,
      currency: 'JPY',
    },
    creditNoteAmount: {
      beforeTax: 9318.4,
      afterTax: 9318.4,
      currency: 'JPY',
    },
    resolutionDescription: 'nok',
    status: 'PENDING_DOCUMENT',
    creditNoteFile: null,
    rejectedDeviations: [
      {
        id: 'c7d60d41-92fa-4dea-90f7-163bb191d04f',
        name: 'Unit Price',
        type: 'UNIT_PRICE',
        status: 'REJECTED_DONE',
        legType: 'LEG_TWO',
        chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
        legCurrency: 'USD',
        deviationCurrency: 'USD',
        contractChargeUnit: 'CONTAINER',
        invoiceChargeUnit: null,
        contractUnitPrice: 50,
        invoiceUnitPrice: 60,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 10,
        deviationAmount: 10,
        deviationAmountBeforeTax: 10,
        deviationAmountAfterTax: 10,
        rejectedByOperation: false,
      },
    ],
    deviations: [
      {
        id: 'c7d60d41-92fa-4dea-90f7-163bb191d04f',
        name: 'Unit Price',
        type: 'UNIT_PRICE',
        status: 'REJECTED_DONE',
        legType: 'LEG_TWO',
        chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
        legCurrency: 'USD',
        deviationCurrency: 'USD',
        contractChargeUnit: 'CONTAINER',
        invoiceChargeUnit: null,
        contractUnitPrice: 50,
        invoiceUnitPrice: 60,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 10,
        deviationAmount: 10,
        deviationAmountBeforeTax: 10,
        deviationAmountAfterTax: 10,
        rejectedByOperation: false,
      },
      {
        id: 'c0c6a0c7-5c9f-4724-8061-97421aa1ff43',
        name: 'Unit Price',
        type: 'UNIT_PRICE',
        status: 'DONE',
        legType: 'LEG_TWO',
        chargeDescription: 'BUNKER ADJUSTMENT FACTOR',
        legCurrency: 'USD',
        deviationCurrency: 'USD',
        contractChargeUnit: 'CONTAINER',
        invoiceChargeUnit: null,
        contractUnitPrice: 50,
        invoiceUnitPrice: 120,
        invoiceQuantity: 1,
        deviationQuantity: null,
        deviationAmountPerUnit: 70,
        deviationAmount: 70,
        deviationAmountBeforeTax: 70,
        deviationAmountAfterTax: 70,
        rejectedByOperation: false,
      },
    ],
    nextWorkflowAction: null,
    agreementResolution: 'AMEND_INVOICE_CREDIT_NOTE',
    deviationResolutionType: 'BREAK_DOWN_PER_DEVIATION',
    deviationResolutions: [
      {
        deviationId: 'c0c6a0c7-5c9f-4724-8061-97421aa1ff43',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: 70,
        exchangeRate: null,
        periodType: null,
      },
      {
        deviationId: 'c7d60d41-92fa-4dea-90f7-163bb191d04f',
        endPeriod: null,
        startPeriod: null,
        expectedAmount: 10,
        exchangeRate: null,
        periodType: null,
      },
    ],
  },
};

const mockQuestionaireUCT: Record<string, DeviationQuestionDto[]> = {
  OPERATION_REVIEW: [
    {
      id: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      name: 'Do you accept this charge as-is?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: '24da64e0-32e8-4804-a83f-0a1276b81b39',
          name: 'Yes',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: '95055552-9891-4c2b-9a6a-e694f080df56',
              name: 'Why do you accept this charge as-is?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '81724600-9238-42f4-bde0-ed8dfe250653',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '1fc4f84d-8541-46e3-80cf-799562d0adb5',
          name: 'No',
          displayOrder: 20,
          type: 'NO',
          nextQuestions: [
            {
              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
              name: 'Why do you reject this charge?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '81724600-9238-42f4-bde0-ed8dfe250652',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  PROCUREMENT_REVIEW: [
    {
      id: '959f8089-8557-49d5-831b-17d1065c554c',
      name: 'Do you accept this deviation based on the inputs from Operations?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: 'cb1a7b24-e2f2-4872-8c0c-46ed8457dcb7',
          name: 'Yes, I accept as-is',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: 'b88e16ea-d1d2-40c0-a0c8-d90e8a8f644b',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '442d3913-3b28-4492-8f5a-17dd1d6c7f5c',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '8d4a0e02-35fd-49d1-80a7-08c1dc5048f7',
          name: 'Yes, I accept but change the recurrence',
          displayOrder: 20,
          type: 'YES_BUT_WITH_CHANGE_RECURRENCE',
          nextQuestions: [
            {
              id: '89621c07-1bbe-476d-8ee5-2e6c9697a09f',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '0ae24831-cd8a-46f5-81a0-eb9dda2e783e',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '82a17375-321b-4af8-813b-59b1c058f00f',
                      name: 'Modify Recurrence',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: '0d5b954e-9d45-44b1-8a3e-54055a858d62',
                          name: 'Recurring',
                          displayOrder: 10,
                          type: 'RECURRING',
                          nextQuestions: [
                            {
                              id: '64c83e80-002e-4050-a824-a162c37c586a',
                              name: 'Period for expected recurrence',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'DATE_RANGE',
                              answerOptions: [
                                {
                                  id: '8256e1e0-7935-4af3-a90c-1a2ff90d7e8d',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'e1421940-2c0d-4e0b-bb6e-2924aed9f3ab',
                          name: 'One off',
                          displayOrder: 20,
                          type: 'ONE_OFF',
                          nextQuestions: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '22647927-8620-4fe8-b513-36eb422f4144',
          name: 'No, I reject',
          displayOrder: 30,
          type: 'NO',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a01',
              name: 'Why do you reject this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51be8',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const mockQuestionaireUCTApproval: Record<string, DeviationQuestionDto[]> = {
  OPERATION_REVIEW: [
    {
      id: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      name: 'Do you have all the required Approvals for the Accessorial Charges?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: '24da64e0-32e8-4804-a83f-0a1276b81b39',
          name: 'Yes',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: 'cd920974-75e6-4a02-9d99-20266bbb6045',
              name: 'Do you have all the required Approvals for this Service?',
              deviationType: 'NEW_CHARGE',
              questionType: 'RADIO',
              answerOptions: [
                {
                  id: '24da64e0-32e8-4804-a83f-0a1276b81b11',
                  name: 'Yes',
                  displayOrder: 10,
                  type: 'YES',
                  nextQuestions: [],
                },
                {
                  id: '1fc4f84d-8541-46e3-80cf-799562d0ad55',
                  name: 'No',
                  displayOrder: 20,
                  type: 'NO',
                  nextQuestions: [],
                },
                {
                  id: '1fc4f84d-8541-46e3-80cf-799562d0ad6',
                  name: 'Not required',
                  displayOrder: 20,
                  type: 'NO',
                  nextQuestions: [],
                },
              ],
            },
          ],
        },
        {
          id: '1fc4f84d-8541-46e3-80cf-799562d0adb5',
          name: 'No',
          displayOrder: 20,
          type: 'NO',
          nextQuestions: [
            {
              id: '325df80d-0df7-4f54-a91f-217a68a207c4',
              name: 'What are you missing for the Approval of the Accessorial Charges?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '81724600-9238-42f4-bde0-ed8dfe250652',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: 'cd920974-75e6-4a02-9d99-20266bbb6085',
                      name: 'Do you have all the required Approvals for this Service?',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: '24da64e0-32e8-4804-a83f-0a1276b81b81',
                          name: 'Yes',
                          displayOrder: 10,
                          type: 'YES',
                          nextQuestions: null,
                        },
                        {
                          id: '1fc4f84d-8541-46e3-80cf-799562d0ad85',
                          name: 'No',
                          displayOrder: 20,
                          type: 'NO',
                          nextQuestions: [
                            {
                              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a34',
                              name: 'What are you missing for the Approval of this Service?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'TEXT',
                              answerOptions: [
                                {
                                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51348',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: '1fc4f84d-8541-46e3-80cf-799562d0a54',
                          name: 'Not required',
                          displayOrder: 20,
                          type: 'NO',
                          nextQuestions: [
                            {
                              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806544',
                              name: 'What are you missing for the Approval of this Service?',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'TEXT',
                              answerOptions: [
                                {
                                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51548',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  PROCUREMENT_REVIEW: [
    {
      id: '959f8089-8557-49d5-831b-17d1065c554c',
      name: 'Do you accept this deviation based on the inputs from Operations?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: 'cb1a7b24-e2f2-4872-8c0c-46ed8457dcb7',
          name: 'Yes, I accept as-is',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: 'b88e16ea-d1d2-40c0-a0c8-d90e8a8f644b',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '442d3913-3b28-4492-8f5a-17dd1d6c7f5c',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '8d4a0e02-35fd-49d1-80a7-08c1dc5048f7',
          name: 'Yes, I accept but change the recurrence',
          displayOrder: 20,
          type: 'YES_BUT_WITH_CHANGE_RECURRENCE',
          nextQuestions: [
            {
              id: '89621c07-1bbe-476d-8ee5-2e6c9697a09f',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '0ae24831-cd8a-46f5-81a0-eb9dda2e783e',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '82a17375-321b-4af8-813b-59b1c058f00f',
                      name: 'Modify Recurrence',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: '0d5b954e-9d45-44b1-8a3e-54055a858d62',
                          name: 'Recurring',
                          displayOrder: 10,
                          type: 'RECURRING',
                          nextQuestions: [
                            {
                              id: '64c83e80-002e-4050-a824-a162c37c586a',
                              name: 'Period for expected recurrence',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'DATE_RANGE',
                              answerOptions: [
                                {
                                  id: '8256e1e0-7935-4af3-a90c-1a2ff90d7e8d',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'e1421940-2c0d-4e0b-bb6e-2924aed9f3ab',
                          name: 'One off',
                          displayOrder: 20,
                          type: 'ONE_OFF',
                          nextQuestions: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '22647927-8620-4fe8-b513-36eb422f4144',
          name: 'No, I reject',
          displayOrder: 30,
          type: 'NO',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a01',
              name: 'Why do you reject this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51be8',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const mockQuestionaireUCTNewCharge: Record<string, DeviationQuestionDto[]> = {
  OPERATION_REVIEW: [
    {
      id: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      name: 'Do you accept this new charge as-is?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: '24da64e0-32e8-4804-a83f-0a1276b81b12',
          name: 'Yes, as a one-off',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a34',
              name: 'What are you missing for the Approval of this Service?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51348',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '24da64e0-32e8-4804-a83f-0a1276b81b13',
          name: 'Yes, as recurring',
          displayOrder: 10,
          type: 'YES_BUT_WITH_CHANGE_RECURRENCE',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a34',
              name: 'Why do you accept this new charge as recurring?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51348',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '9ae20239-5fb1-40b3-978b-52f6ec0ead99',
                      name: 'Period for expected recurrence',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'DATE_RANGE',
                      answerOptions: [
                        {
                          id: '7c564129-9b35-4605-9c15-8054e11d4098',
                          name: null,
                          displayOrder: 10,
                          type: null,
                          nextQuestions: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '1fc4f84d-8541-46e3-80cf-799562d0ad14',
          name: 'No, I reject',
          displayOrder: 20,
          type: 'NO',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a34',
              name: 'Why do you reject this charge?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51348',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '1fc4f84d-8541-46e3-80cf-799562d0ad15',
          name: 'Other',
          displayOrder: 20,
          type: 'RECURRING',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a34',
              name: 'Why do you reject this charge?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51348',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  PROCUREMENT_REVIEW: [
    {
      id: '959f8089-8557-49d5-831b-17d1065c554c',
      name: 'Do you accept this deviation based on the inputs from Operations?',
      deviationType: 'NEW_CHARGE',
      questionType: 'RADIO',
      answerOptions: [
        {
          id: 'cb1a7b24-e2f2-4872-8c0c-46ed8457dcb7',
          name: 'Yes, I accept as-is',
          displayOrder: 10,
          type: 'YES',
          nextQuestions: [
            {
              id: 'b88e16ea-d1d2-40c0-a0c8-d90e8a8f644b',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '442d3913-3b28-4492-8f5a-17dd1d6c7f5c',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
        {
          id: '8d4a0e02-35fd-49d1-80a7-08c1dc5048f7',
          name: 'Yes, I accept but change the recurrence',
          displayOrder: 20,
          type: 'YES_BUT_WITH_CHANGE_RECURRENCE',
          nextQuestions: [
            {
              id: '89621c07-1bbe-476d-8ee5-2e6c9697a09f',
              name: 'Why do you accept this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '0ae24831-cd8a-46f5-81a0-eb9dda2e783e',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: [
                    {
                      id: '82a17375-321b-4af8-813b-59b1c058f00f',
                      name: 'Modify Recurrence',
                      deviationType: 'NEW_CHARGE',
                      questionType: 'RADIO',
                      answerOptions: [
                        {
                          id: '0d5b954e-9d45-44b1-8a3e-54055a858d62',
                          name: 'Recurring',
                          displayOrder: 10,
                          type: 'RECURRING',
                          nextQuestions: [
                            {
                              id: '64c83e80-002e-4050-a824-a162c37c586a',
                              name: 'Period for expected recurrence',
                              deviationType: 'NEW_CHARGE',
                              questionType: 'DATE_RANGE',
                              answerOptions: [
                                {
                                  id: '8256e1e0-7935-4af3-a90c-1a2ff90d7e8d',
                                  name: null,
                                  displayOrder: 10,
                                  type: null,
                                  nextQuestions: null,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: 'e1421940-2c0d-4e0b-bb6e-2924aed9f3ab',
                          name: 'One off',
                          displayOrder: 20,
                          type: 'ONE_OFF',
                          nextQuestions: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '22647927-8620-4fe8-b513-36eb422f4144',
          name: 'No, I reject',
          displayOrder: 30,
          type: 'NO',
          nextQuestions: [
            {
              id: '5420d6a5-ce62-47ff-bd57-0d8fe8806a01',
              name: 'Why do you reject this deviation?',
              deviationType: 'NEW_CHARGE',
              questionType: 'TEXT',
              answerOptions: [
                {
                  id: '4dfffe65-b1d6-4204-aa5e-da1322e51be8',
                  name: null,
                  displayOrder: 10,
                  type: null,
                  nextQuestions: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

enum EMockCases {
  UCT_Normal = 'UCT_Normal',
  UCT_NewCharge = 'UCT_NewCharge',
  UCT_Approval = 'UCT_Approval',
}

export const mockQuestionaire: Record<EMockCases, Record<string, DeviationQuestionDto[]>> = {
  UCT_Normal: mockQuestionaireUCT,
  UCT_Approval: mockQuestionaireUCTApproval,
  UCT_NewCharge: mockQuestionaireUCTNewCharge,
};

export const mockAnswersUCT: ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto['data'] = {
  OPERATION_REVIEW: [
    {
      questionId: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      questionType: 'RADIO',
      answerOptionId: '24da64e0-32e8-4804-a83f-0a1276b81b39',
      answerText: null,
    },
    {
      questionId: '325df80d-0df7-4f54-a91f-217a68a207c4',
      questionType: 'TEXT',
      answerOptionId: '81724600-9238-42f4-bde0-ed8dfe250653',
      answerText: 'Test',
    },
  ],
  PROCUREMENT_REVIEW: [],
};

export const mockAnswersUCTApproval: ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto['data'] = {
  OPERATION_REVIEW: [
    {
      questionId: 'cd920974-75e6-4a02-9d99-20266bbb6044',
      questionType: 'RADIO',
      answerOptionId: '1fc4f84d-8541-46e3-80cf-799562d0adb5',
      answerText: null,
    },
    {
      questionId: '325df80d-0df7-4f54-a91f-217a68a207c4',
      questionType: 'TEXT',
      answerOptionId: '81724600-9238-42f4-bde0-ed8dfe250652',
      answerText: 'Test',
    },
    {
      questionId: 'cd920974-75e6-4a02-9d99-20266bbb6085',
      questionType: 'RADIO',
      answerOptionId: '1fc4f84d-8541-46e3-80cf-799562d0a54',
      answerText: null,
    },
    {
      questionId: '5420d6a5-ce62-47ff-bd57-0d8fe8806544',
      questionType: 'TEXT',
      answerOptionId: '4dfffe65-b1d6-4204-aa5e-da1322e51548',
      answerText: 'Test',
    },
  ],
  PROCUREMENT_REVIEW: [],
};

export const mockAnswersUCTEmpty: Record<string, DeviationQuestionUserResponseDto[]> = {
  OPERATION_REVIEW: [],
  PROCUREMENT_REVIEW: [],
};
