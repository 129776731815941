import { FC } from 'react';

import { cn } from 'src/utils/utility';
import { IChipChartProps } from './types';

export const ChipChart: FC<IChipChartProps> = (props) => {
  const { label, icon } = props;

  return (
    <div className={cn('relative flex items-center gap-6 px-12 py-4 bg-neutral-10 text-neutral-60 rounded-10')}>
      {icon}
      <strong className='text-14 font-medium'>{label}</strong>
    </div>
  );
};
