import { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SavingsSortDto } from 'src/generated/services/TFinancialApi';
import { useSavingsStats, useSavingsTable } from 'src/use-cases/performance/usePerformance';
import { OrderMethod } from 'src/types';
import { SimpleFormatNumber } from 'src/utils/utility';

import { TransportMedium } from 'src/presentations/components/molecules/card/types';
import { SectionDetail } from './SectionDetail';
import { CardStatDetail } from '../Stat/CardStatDetail';
import { ISectionSavingsProps } from './types';
import { getAnalyticsLink, getSavingStatsList } from '../helpers';
import { TableTab } from '../../analytics/table/useTable';

const tableColumns = [
  {
    enableEditing: false,
    accessorKey: 'month',
    header: 'MONTH',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => SimpleFormatNumber(row?.totalAmount, row?.totalAmount?.currency === 'JPY'),
    header: 'TOTAL AMOUNT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => SimpleFormatNumber(row?.deviations, row?.deviations?.currency === 'JPY'),
    header: 'DEVIATIONS',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => SimpleFormatNumber(row?.savings, row?.savings?.currency === 'JPY'),
    header: 'SAVINGS',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipmentsCount',
    header: 'NO. SHIPMENTS',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'invoicesCount',
    header: 'NO. INVOICES',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeLinesCount',
    header: 'NO.CHARGE LINES',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => `${row?.audited}%`,
    header: 'AUDITED',
    size: 20,
  },
];

const getSortField = (method: string): SavingsSortDto['field'] => {
  switch (method) {
    case 'TOTAL AMOUNT':
      return 'TOTAL_AMOUNT';
    case 'shipmentsCount':
      return 'SHIPMENTS_COUNT';
    case 'invoicesCount':
      return 'INVOICES_COUNT';
    case 'chargeLinesCount':
      return 'CHARGE_LINES_COUNT';
    default:
      return method?.toUpperCase() as SavingsSortDto['field'];
  }
};

export const ModalityProviderSavings: FC<ISectionSavingsProps> = ({ provider, modality }) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const [tablePage, setTablePage] = useState(1);
  const [orderBy, setOrderBy] = useState<SavingsSortDto['field']>('MONTH');
  const [orderMethod, setOrderMethod] = useState<SavingsSortDto['direction']>('DESC');

  const { data: dataStats, isLoading: isLoadingStats } = useSavingsStats({
    filterConfigs: formValues,
    provider,
    modality,
  });
  const { data: dataTable, isLoading: isLoadingTable } = useSavingsTable({
    filterConfigs: formValues,
    provider,
    modality,
    apiConfigs: {
      page: {
        pageNumber: tablePage,
        pageSize: 25,
      },
      sort: {
        field: getSortField(orderBy),
        direction: orderMethod,
      },
    },
  });

  const tableItems = dataTable?.savingsMonthWiseData || [];

  const statsList = useMemo(() => getSavingStatsList(dataStats), [dataStats]);

  if (tableItems?.length < 1) {
    return null;
  }

  return (
    <SectionDetail
      title={provider}
      tabName={TableTab.SAVINGS}
      transportMedium={modality as TransportMedium}
      linkReport={getAnalyticsLink(formValues)}
      linkAudit={`/dashboard?provider=${provider}&modality=${modality}`}
      tableProps={{
        totalItems: dataTable?.total || 0,
        data: tableItems,
        columns: tableColumns,
        orderBy,
        orderMethod: orderMethod?.toLowerCase() as OrderMethod,
        onRenderMassActions: () => <></>,
        onChangePage: (page) => setTablePage(page),
        onSorting: (method: string, fieldId: string) => {
          setOrderBy(fieldId as SavingsSortDto['field']);
          setOrderMethod(method?.toUpperCase() as SavingsSortDto['direction']);
        },
        params: {
          page: tablePage,
        },
        isLoading: isLoadingTable || isLoadingStats,
      }}
    >
      <div className='grid grid-cols-4 grid-rows-1 gap-24'>
        {statsList.map((item) => (
          <CardStatDetail key={`card-stat-1-${item?.type}`} type={item?.type} values={item?.values} />
        ))}
      </div>
    </SectionDetail>
  );
};
