import { useState, useEffect, useCallback } from 'react';
import { useGetServiceProvider } from 'src/use-cases/serviceProvider/useServiceProvider';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useChartReports } from '../../organisms/analytics/useAnalytics';
import { LandingPerformance } from '../../organisms/landing/Performance';
import { LandingTopWidget, LandingTopContent, LandingMenu, LandingAudit, LandingAnalytics, LandingSectionName } from '../../organisms/landing';

export const LandingPage = () => {
  const [tab, setTab] = useState<string>(LandingSectionName.ALL);

  const { data, isLoading: isLoadingProviders } = useGetServiceProvider();

  const { data: analyticsData } = useChartReports();
  const [analyticsCount, setAnalyticsCount] = useState(0);

  const providers = data?.data || [];
  const [performanceCount, setPerformanceCount] = useState(0);
  const handlePerformanceCount = useCallback((count) => {
    setPerformanceCount(count);
  }, []);

  useEffect(() => {
    if (analyticsData) {
      setAnalyticsCount(analyticsData.length);
    }
  }, [analyticsData]);

  const isDisplayAudit = tab === LandingSectionName.ALL || tab === LandingSectionName.AUDIT;
  const isDisplayAnalytics = tab === LandingSectionName.ALL || tab === LandingSectionName.ANALYTIC;
  const isDisplayPerformance = tab === LandingSectionName.ALL || tab === LandingSectionName.PERFORMANCE;

  const handleChange = (newValue: string) => {
    setTab(newValue);
  };

  const formContext = useForm({
    defaultValues: {
      auditSort: 'ACTION_NEEDED',
      analyticSort: 'desc',
    },
  });

  return (
    <FormContainer formContext={formContext}>
      <div className='relative bg-white'>
        <LandingTopWidget />
        <LandingTopContent />
        <LandingMenu
          onChangeMenu={handleChange}
          selected={tab}
          auditTotal={providers?.length || 0}
          analyticTotal={analyticsCount}
          performanceTotal={performanceCount}
        />

        <div className='pt-40 pb-64 flex flex-col gap-48 max-w-[90rem] mx-auto px-3%'>
          {isDisplayAudit && <LandingAudit providers={providers} isLoading={isLoadingProviders} />}
          {isDisplayPerformance && <LandingPerformance onCountUpdate={handlePerformanceCount} />}
          {isDisplayAnalytics && <LandingAnalytics />}
        </div>
      </div>
    </FormContainer>
  );
};
