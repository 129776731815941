/**
 * Base Form Input
 */
import { Controller } from 'react-hook-form';

import React from 'react';
import { getMainStyles } from './Styles';

interface IInputBaseProps {
  name: string;
  onRender: (field: any, inputStyles?: string, isError?: boolean) => React.JSX.Element;
  className?: string;
  defaultValue?: any;
  formHook?: any;
  disabled?: boolean;
}

const InputBase: React.FC<IInputBaseProps> = (props) => {
  const { className, name, defaultValue, onRender, formHook, disabled } = props;

  const renderInput = () => {
    const {
      control,
      formState: { errors },
    } = formHook;
    const isError = errors[name];

    let inputStyles = '';
    if (isError) {
      inputStyles = getMainStyles('error');
    } else if (disabled) {
      inputStyles = getMainStyles('disabled');
    } else {
      inputStyles = getMainStyles('idle');
    }

    return <Controller name={name} control={control} defaultValue={defaultValue} render={({ field }) => onRender(field, inputStyles, isError)} />;
  };

  return <div className={`${className}`}>{formHook ? renderInput() : <>{onRender({})}</>}</div>;
};

InputBase.defaultProps = {
  defaultValue: '',
};

export default InputBase;
