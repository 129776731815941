import { Link } from 'react-router-dom';
import IconArrow from 'src/assets/images/icons/arrow-left_ico.svg';
import { FilterHeader } from '../filter/FilterHeader';
import { FilterDrawer } from '../analytics/filter/FilterDrawer';
import { filterAccordionsMapperForPA } from '../analytics/analyticsMapper';

export const PerformanceTopContent = ({ dataFilter }: { dataFilter: Record<string, any> }) => {
  const filterAccordions = filterAccordionsMapperForPA(dataFilter);

  return (
    <div className='flex items-center gap-24 py-24 px-32'>
      <div className='flex items-center gap-18'>
        <Link
          to='/'
          className='w-40 h-40 rounded-full bg-neutral-10 border border-neutral-20 transition-all duration-300 hover:border-neutral-40 flex items-center justify-center'
        >
          <img src={IconArrow} alt='Back' />
        </Link>
        <div className='flex flex-col'>
          <h2 className='text-24 font-bold text-blue_grey-90'>Performance Assessment</h2>
          <p className='text-14 text-neutral-60'>Overview</p>
        </div>
      </div>
      <div className='ml-auto flex items-center gap-16'>
        <div className='w-1 h-16 bg-neutral-20' />
        <FilterHeader onChangeFilter={() => {}} />
        <FilterDrawer filterAccordions={filterAccordions} />
      </div>
    </div>
  );
};
