import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Button from 'src/presentations/components/atom/button';
import { Modal } from './Modal';

interface IModalProps {
  open: boolean;
  children: ReactElement;
  title: string;
  buttonLabel?: string;
  onClose: () => void;
}

const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '540px',
    borderRadius: '16px',
  },
});

export const ModalInfo: React.FC<IModalProps> = (props) => {
  const { open, title, children, onClose, buttonLabel = 'Dismiss' } = props;

  return (
    <StyledModal open={open} title={title} fullWidth onClose={onClose}>
      <div className='relative'>
        {children}

        <div className='mt-48'>
          <Button variant='primary' onClick={onClose} isFull>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};
