import { Provider, useDispatch, useSelector } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppDispatch, initStore } from 'src/infra/stores/store';
import React, { PropsWithChildren, Suspense, useEffect } from 'react';
import PageLoader from 'src/presentations/components/molecules/pageLoader';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routesConfig } from 'src/main/router';
import { getReactQueryClient } from 'src/utils/reactQuery';
import Logger from 'js-logger';
import { Amplify } from 'aws-amplify';
import { awsConfig } from 'src/config';
import { getUserActionCreator } from 'src/infra/stores/reducer/auth';
import authSelectors from 'src/infra/stores/reducer/auth/selectors';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { UploadSelector } from 'src/presentations/components/molecules/uploadInvoice/UploadSelector';

import { ToastResultSelector } from 'src/presentations/components/molecules/toast/ToastResultSelector';
import { NoContractSelector } from 'src/presentations/components/organisms/actionDetails/noContractModal/Selector';
import * as Sentry from '@sentry/react';
import MuiTheme from './MuiTheme';
import Notification from '../utils/notification';

const ENABLE_REACT_QUERY_DEV_TOOLS = false;

Logger.setLevel(Logger.INFO);
// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults();

Amplify.configure(awsConfig);

const router = createBrowserRouter(routesConfig);

// Init react query
const queryClient = getReactQueryClient();

const store = initStore();

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const App: React.FC = () => (
  // ToDo: fix this and make it look nice.
  // https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
  <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
    <Authenticator.Provider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {ENABLE_REACT_QUERY_DEV_TOOLS && <ReactQueryDevtools initialIsOpen />}
          <MuiTheme>
            <GetUserWrapper>
              <AppWrapper>
                <NoContractSelector>
                  <UploadSelector>
                    <ToastResultSelector>
                      <RouterProvider router={router} />
                      <Notification />
                    </ToastResultSelector>
                  </UploadSelector>
                </NoContractSelector>
              </AppWrapper>
            </GetUserWrapper>
          </MuiTheme>
        </QueryClientProvider>
      </Provider>
    </Authenticator.Provider>
  </Sentry.ErrorBoundary>
);

// Only done for main app. For tests we mock the store
const GetUserWrapper: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const user = useSelector(authSelectors.selectUser);

  useEffect(() => {
    Sentry.setUser(user?.email || user?.id ? { email: user?.email, id: user?.id } : null);
  }, [user]);

  // If authenticated, get user info (this is needed as amplify doesn't provide all info needed. E.g: user role)
  useEffect(() => {
    if (authStatus === 'authenticated') {
      dispatch(getUserActionCreator());
    }
  }, [authStatus, dispatch]);

  return <>{children}</>;
};

// Triggers auth flow
// Returns page loader while lazy routes load
// Wrapper is separated for reuse in tests
export const AppWrapper: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  // Show spinner while checking auth state

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const isLoadingUser = useSelector(authSelectors.selectIsLoading);

  if (authStatus === 'configuring' || isLoadingUser) {
    return <PageLoader />;
  }

  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
};

export default App;
