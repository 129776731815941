import { FormControl, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { Controller, useFormContext } from 'react-hook-form';
import { granularityMapper } from './analyticsMapper';

const StyledTabs = styled(Tabs)({
  overflow: 'visible',
  '& .MuiTabs-flexContainer': {
    width: '100%',
    backgroundColor: '#F5F6FA',
    border: '1px solid #DFDFDF',
    borderRadius: '16px',
    padding: '3px',
    display: 'flex',
    gap: '5px',
    justifyContent: 'space-evenly',
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible',
  },
  '& .MuiTabs-indicator': {
    opacity: 0,
  },
});

const StyledTab = styled(Tab)({
  position: 'relative',
  borderRadius: '16px',
  fontSize: '16px',
  fontWeight: '500',
  textTransform: 'capitalize',
  color: '#597282',
  paddingLeft: '36px',
  paddingRight: '36px',
  overflow: 'hidden',
  zIndex: 1,
  transition: 'all 0.2s ease-in-out',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    zIndex: -1,
    transition: 'all 0.2s ease-in-out',
    opacity: 0,
  },
  '&:hover::before': {
    opacity: 1,
  },
  '&:hover': {
    color: '#000000',
    transform: 'scale(1)',
    boxShadow: '0px 10px 24px 0px rgba(52, 81, 98, 0.10)',
  },
  '&.Mui-selected': {
    color: '#000000',
    boxShadow: '0px 10px 24px 0px rgba(52, 81, 98, 0.10)',
    '&::before': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
  },
});

export const GranularityTab = ({ granularityTab }: { granularityTab: Array<AnalyticSearchRequestDto['granularity']> }) => {
  const { control } = useFormContext();
  return (
    <FormControl size='small'>
      <Controller
        render={({ field: { onChange, value } }) => (
          <StyledTabs
            value={value}
            onChange={(e, value) => {
              onChange(value);
            }}
            aria-label='finance tab'
          >
            {granularityTab.map((v) => (
              <StyledTab key={v} label={granularityMapper(v)} disableRipple value={v} />
            ))}
          </StyledTabs>
        )}
        control={control}
        name='granularity'
      />
    </FormControl>
  );
};
