import { OPERATION_ROLE } from 'src/config';
import { COGNITO_GROUP } from 'src/infra/stores/reducer/auth/user';

const roleToDisplayNameLookup = {
  [COGNITO_GROUP.FINANCE]: 'Finance',
  [COGNITO_GROUP.OPERATIONS_MANAGER]: `${OPERATION_ROLE}s Manager`,
  [COGNITO_GROUP.PROCUREMENT_MANAGER]: 'Procurement Manager',
};

export const roleToDisplayName = (role: COGNITO_GROUP): string => {
  if (!role) return '';
  return roleToDisplayNameLookup[role];
};
