import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreatingContractReq,
  UpdatingContractReq,
  CreatingAndUpdatingShippingServiceReq,
  AddingOrRemovingRateCardToContractFlowReq,
  ResponseDtoContractFlowInfoViewDto,
  ResponseDtoListContractDto,
  ResponseDtoListServiceProviderDetailsDto,
  ResponseDtoListServiceProviderOverViewDto,
  ResponseDtoListShippingServiceDto,
  UpsertContractContainerChargeReq,
  UpsertContractDeclarationChargeReq,
  UpsertContractPercentageChargeReq,
  UpsertContractShipmentChargeReq,
  UpsertContractWeightChargeReq,
  ResponseDtoListLegalEntityDto,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { CacheKeys, cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useGetServiceProvider = (): UseQueryResult<ResponseDtoListServiceProviderDetailsDto> =>
  useQuery(
    cacheKeysUtil.serviceProvider(),
    async (): Promise<ResponseDtoListServiceProviderDetailsDto> => (await tFinancialService.api.getServiceProviderDetails()).data
  );

export const useGetShippingService = (serviceProviderId: number, billingPartyId: number): UseQueryResult<ResponseDtoListShippingServiceDto> =>
  useQuery(
    cacheKeysUtil.getShippingService(serviceProviderId, billingPartyId),
    async (): Promise<ResponseDtoListShippingServiceDto> =>
      (
        await tFinancialService.api.getShippingServiceByServiceProviderAndBillingParty({
          serviceProviderId,
          billingPartyId,
        })
      ).data,
    {
      enabled: !!serviceProviderId,
    }
  );

export const useGetAllServiceProviders = (): UseQueryResult<ResponseDtoListServiceProviderOverViewDto> =>
  useQuery(
    cacheKeysUtil.allServiceProvider(),
    async (): Promise<ResponseDtoListServiceProviderOverViewDto> => (await tFinancialService.api.getAllServiceProvider()).data
  );

export const useGetListBilledEntity = (): UseQueryResult<ResponseDtoListLegalEntityDto> =>
  useQuery(cacheKeysUtil.getListBilledEntity(), async (): Promise<ResponseDtoListLegalEntityDto> => (await tFinancialService.api.getListBilledEntity()).data);

export const useGetContractByShippingService = (
  shippingServiceId: string,
  options?: UseQueryOptions<ResponseDtoListContractDto>
): UseQueryResult<ResponseDtoListContractDto> =>
  useQuery(
    cacheKeysUtil.getContractByShippingService(shippingServiceId),
    async (): Promise<ResponseDtoListContractDto> => {
      const { data } = await tFinancialService.api.getContractByShippingService(shippingServiceId);

      return data;
    },
    {
      ...options,
    }
  );

export const useGetContractFlowByContractIdAndShippingServiceId = (
  contractId: string,
  shippingServiceId: string,
  options?: UseQueryOptions<ResponseDtoContractFlowInfoViewDto>
): UseQueryResult<ResponseDtoContractFlowInfoViewDto> =>
  useQuery(
    cacheKeysUtil.getContractFlowByContractIdAndShippingServiceId(contractId, shippingServiceId),
    async (): Promise<ResponseDtoContractFlowInfoViewDto> => {
      const { data } = await tFinancialService.api.getContractFlowByContractIdAndShippingServiceId(contractId, shippingServiceId);

      return data;
    },
    {
      ...options,
    }
  );

export const useCreateShippingService = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CreatingAndUpdatingShippingServiceReq) => tFinancialService.api.createShippingService(data), {
    onSuccess: async (_, args) => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getShippingService(args.serviceProviderId, args.billingPartyId))]);
    },
  });
};

export const useUpdateShippingService = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, data }: { id: string; data: CreatingAndUpdatingShippingServiceReq }) => tFinancialService.api.updateShippingService(id, data),
    {
      onSuccess: async (_, args) => {
        await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getShippingService(args.data.serviceProviderId, args.data.billingPartyId))]);
      },
    }
  );
};

export const useCreateContract = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CreatingContractReq) => tFinancialService.api.createContract(data), {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: async (_, args) => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractByShippingService])]);
    },
  });
};

export const useUpdateContract = () => {
  const queryClient = useQueryClient();

  return useMutation(async ({ id, data }: { id: string; data: UpdatingContractReq }) => tFinancialService.api.updateContract(id, data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractByShippingService])]);
    },
  });
};

export const useUpsertShipmentCharge = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpsertContractShipmentChargeReq) => tFinancialService.api.upsertShipmentCharge(data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
    },
  });
};

export const useUpsertContainerCharge = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpsertContractContainerChargeReq) => tFinancialService.api.upsertContainerCharge(data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
    },
  });
};

export const useUpsertDeclarationCharge = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpsertContractDeclarationChargeReq) => tFinancialService.api.upsertDeclarationCharge(data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
    },
  });
};

export const useUpsertPercentageCharge = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpsertContractPercentageChargeReq) => tFinancialService.api.upsertPercentageCharge(data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
    },
  });
};

export const useUpsertWeightCharge = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UpsertContractWeightChargeReq) => tFinancialService.api.upsertWeightCharge(data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
    },
  });
};

export const useRemoveRateCardsToContractFlow = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ contractFlowId, data }: { contractFlowId: string; data: AddingOrRemovingRateCardToContractFlowReq[] }) =>
      tFinancialService.api.removeRateCardsToContractFlow(contractFlowId, data),
    {
      onSuccess: async () => {
        await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
      },
    }
  );
};

export const useAddRateCardsToContractFlow = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ contractFlowId, data }: { contractFlowId: string; data: AddingOrRemovingRateCardToContractFlowReq[] }) =>
      tFinancialService.api.addRateCardsToContractFlow(contractFlowId, data),
    {
      onSuccess: async () => {
        await Promise.all([queryClient.invalidateQueries([CacheKeys.getContractFlowByContractIdAndShippingServiceId])]);
      },
    }
  );
};

export const useDeleteShippingService = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => tFinancialService.api.deleteShippingService(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.getShippingService]);
      queryClient.invalidateQueries([CacheKeys.allServiceProvider]);
    },
  });
};
