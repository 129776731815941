import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'src/infra/stores/store';

export interface ISearchState {
  keyword: string;
  isLoading: boolean;
}

const initialState: ISearchState = {
  keyword: '',
  isLoading: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setKeyword, setIsLoading } = searchSlice.actions;

export default searchSlice.reducer;

export const getKeyword = (state: IRootState) => state.search.keyword;
export const getIsLoading = (state: IRootState) => state.search.isLoading;
