import {
  AnalyticSearchRequestDto,
  FiltersRequestDto,
  InvoiceAnalyticSearchRequestDto,
  InvoiceChargeSearchRequestDto,
  InvoiceSearchRequestDto,
  PerformanceAssessmentKeyMetricRequestDto,
  PerformanceAssessmentSavingsTableRequestDto,
  PerformanceAssessmentVolumetricTableRequestDto,
  ShipmentSearchRequestDto,
} from 'src/generated/services/TFinancialApi';

export enum CacheKeys {
  invoiceStats = 'invoiceStats',
  invoices = 'invoices',
  invoicesUCT = 'invoicesUCT',
  getVersion = 'getVersion',
  actionDetails = 'actionDetails',
  invoiceFilters = 'invoiceFilters',
  questionnaire = 'questionnaire',
  questionnaireUCT = 'questionnaireUCT',
  approvalQuestionnaire = 'approvalQuestionnaire',
  questionnaireUCTApproval = 'questionnaireUCTApproval',
  userResponse = 'userResponse',
  approvalUserResponse = 'approvalUserResponse',
  userResponseUCT = 'userResponseUCT',
  userResponseUCTApproval = 'userResponseUCTApproval',
  questionnaireUCTNewCharge = 'questionnaireUCTNewCharge',
  getFileUrl = 'getFileUrl',
  getFileUrlAttachment = 'getFileUrlAttachment',
  getInvoiceReport = 'getInvoiceReport',
  getInvoiceConfiguredRateCard = 'getInvoiceConfiguredRateCard',
  getActivityLogs = 'getActivityLogs',
  dispute = 'dispute',
  getCreditNoteFileUrl = 'getCreditNoteFileUrl',
  getDisputeCreditNote = 'getDisputeCreditNote',
  getChart = 'getChart',
  getKeyMetrics = 'getKeyMetrics',
  serviceProvider = 'serviceProvider',
  serviceProviderUCT = 'serviceProviderUCT',
  allServiceProvider = 'allServiceProvider',
  getAnalyticFilters = 'getAnalyticFilters',
  getListBilledEntity = 'getListBilledEntity',
  userPermission = 'userPermission',
  invoiceTemplates = 'invoiceTemplates',
  getChartReports = 'getChartReports',
  getDomainMetaData = 'getDomainMetaData',
  getCurrencies = 'getCurrencies',
  getShipmentsData = 'getShipmentsData',
  getInvoicesData = 'getInvoicesData',
  getInvoiceChargesData = 'getInvoiceChargesData',
  performanceSavingsStats = 'performanceSavingsStats',
  performanceVolumetricStats = 'performanceVolumetricStats',
  performanceSavingsTable = 'performanceSavingsTable',
  performanceVolumetricTable = 'performanceVolumetricTable',
  invoiceSummary = 'invoiceSummary',
  invoiceSummaryUCT = 'invoiceSummaryUCT',
  invoiceCourierShipmentDetail = 'invoiceCourierShipmentDetail',
  getShipmentDetail = 'getShipmentDetail',
  courierInvoiceDispute = 'courierInvoiceDispute',
  activeNoContractDraft = 'activeNoContractDraft',
  matchingServicesForNoContract = 'matchingServicesForNoContract',
  contractChargesDetailsForShippingService = 'contractChargesDetailsForShippingService',
  calculateSubChargeDomain = 'calculateSubChargeDomain',
  getInvoiceFileProcessing = 'getInvoiceFileProcessing',
  getContractFlow = 'getContractFlow',
  getContractChargesDetails = 'getContractChargesDetails',
  isValidityPeriodValid = 'isValidityPeriodValid',
  getShippingService = 'getShippingService',
  getContractFlowByContractIdAndShippingServiceId = 'getContractFlowByContractIdAndShippingServiceId',
  getContractByShippingService = 'getContractByShippingService',
  getNotifications = 'getNotifications',
}

export const cacheKeysUtil = {
  invoiceStats: (invoiceDashboardFilters: FiltersRequestDto) => [CacheKeys.invoiceStats, invoiceDashboardFilters],
  invoices: (tableParams: InvoiceSearchRequestDto) => [CacheKeys.invoices, tableParams],
  actionDetails: (invoiceId: string) => [CacheKeys.invoices, invoiceId],
  actionDetailsUCT: (invoiceId: string) => [CacheKeys.invoicesUCT, invoiceId],
  approvalQuestionnaire: (invoiceId: string) => [CacheKeys.approvalQuestionnaire, invoiceId],
  invoiceSummary: (invoiceId: string) => [CacheKeys.invoiceSummary, invoiceId],
  getInvoiceFileProcessing: (batchId: string) => [CacheKeys.getInvoiceFileProcessing, batchId],
  invoiceSummaryUCT: (invoiceId: string) => [CacheKeys.invoiceSummaryUCT, invoiceId],
  invoiceCourierShipmentDetail: (shipmentId: string) => [CacheKeys.invoiceCourierShipmentDetail, shipmentId],
  getShipmentDetail: (shipmentId: string) => [CacheKeys.getShipmentDetail, shipmentId],
  getFileUrl: (invoiceId: string) => [CacheKeys.getFileUrl, invoiceId],
  getFileUrlAttachment: (invoiceId: string, attachmentId: string) => [CacheKeys.getFileUrlAttachment, invoiceId, attachmentId],
  getInvoiceReport: (invoiceId: string) => [CacheKeys.getInvoiceReport, invoiceId],
  getInvoiceConfiguredRateCard: (invoiceId: string) => [CacheKeys.getInvoiceConfiguredRateCard, invoiceId],
  getVersion: (invoiceId: string) => [CacheKeys.getVersion, invoiceId],
  getActivityLogs: (invoiceId: string) => [CacheKeys.getActivityLogs, invoiceId],
  questionnaire: (deviationId: string) => [CacheKeys.questionnaire, deviationId],
  questionnaireUCT: (deviationId: string) => [CacheKeys.questionnaireUCT, deviationId],
  questionnaireUCTApproval: (deviationId: string) => [CacheKeys.questionnaireUCTApproval, deviationId],
  questionnaireUCTNewCharge: (deviationId: string) => [CacheKeys.questionnaireUCTNewCharge, deviationId],
  userResponse: (deviationId: string) => [CacheKeys.userResponse, deviationId],
  approvalUserResponse: (deviationId: string) => [CacheKeys.approvalUserResponse, deviationId],
  userResponseUCT: (deviationId: string) => [CacheKeys.userResponseUCT, deviationId],
  userResponseUCTApproval: (deviationId: string) => [CacheKeys.userResponseUCTApproval, deviationId],
  dispute: (disputeId: string) => [CacheKeys.dispute, disputeId],
  serviceProvider: () => [CacheKeys.serviceProvider],
  allServiceProvider: () => [CacheKeys.allServiceProvider],
  getListBilledEntity: () => [CacheKeys.getListBilledEntity],
  serviceProviderUCT: () => [CacheKeys.serviceProviderUCT],
  getNotifications: (viewDate: string = null) => [CacheKeys.getNotifications, viewDate],
  getCreditNoteFileUrl: (disputeId: string) => [CacheKeys.getCreditNoteFileUrl, disputeId],
  getDisputeCreditNote: (disputeId: string) => [CacheKeys.getDisputeCreditNote, disputeId],
  getChart: (filter: AnalyticSearchRequestDto) => [CacheKeys.getChart, filter],
  getKeyMetrics: (filter: FiltersRequestDto) => [CacheKeys.getKeyMetrics, filter],
  getAnalyticFilters: (filter: FiltersRequestDto) => [CacheKeys.getAnalyticFilters, filter],
  invoiceTemplates: () => [CacheKeys.invoiceTemplates],
  userPermission: () => [CacheKeys.userPermission],
  getChartReports: () => [CacheKeys.getChartReports],
  getDomainMetaData: () => [CacheKeys.getDomainMetaData],
  getCurrencies: () => [CacheKeys.getCurrencies],
  getShipmentsData: (shipmentRequest: ShipmentSearchRequestDto) => [CacheKeys.getShipmentsData, shipmentRequest],
  getInvoicesData: (invoiceRequest: InvoiceAnalyticSearchRequestDto) => [CacheKeys.getInvoicesData, invoiceRequest],
  getInvoiceChargesData: (invoiceChargeRequest: InvoiceChargeSearchRequestDto) => [CacheKeys.getInvoiceChargesData, invoiceChargeRequest],
  performanceSavingsStats: (params: PerformanceAssessmentKeyMetricRequestDto) => [CacheKeys.performanceSavingsStats, params],
  performanceVolumetricStats: (params: PerformanceAssessmentKeyMetricRequestDto) => [CacheKeys.performanceVolumetricStats, params],
  performanceSavingsTable: (params: PerformanceAssessmentSavingsTableRequestDto) => [CacheKeys.performanceSavingsStats, params],
  performanceVolumetricTable: (params: PerformanceAssessmentVolumetricTableRequestDto) => [CacheKeys.performanceVolumetricStats, params],
  courierInvoiceDispute: (invoiceDisputeId: string) => [CacheKeys.courierInvoiceDispute, invoiceDisputeId],
  activeNoContractDraft: (invoiceId: string) => [CacheKeys.activeNoContractDraft, invoiceId],
  isValidityPeriodValid: (invoiceId: string) => [CacheKeys.isValidityPeriodValid, invoiceId],
  matchingServicesForNoContract: (invoiceId: string) => [CacheKeys.matchingServicesForNoContract, invoiceId],
  contractChargesDetailsForShippingService: (contractFlowId: string) => [CacheKeys.contractChargesDetailsForShippingService, contractFlowId],
  getContractFlow: (contractFlowId: number) => [CacheKeys.getContractFlow, contractFlowId],
  getShippingService: (serviceProviderId: number, billingPartyId: number) => [CacheKeys.getShippingService, serviceProviderId, billingPartyId],
  getContractFlowByContractIdAndShippingServiceId: (contractId: string, shippingServiceId: string) => [
    CacheKeys.getContractFlowByContractIdAndShippingServiceId,
    contractId,
    shippingServiceId,
  ],
  getContractByShippingService: (shippingServiceId: string) => [CacheKeys.getContractByShippingService, shippingServiceId],
  getContractChargesDetails: (contractFlowId: number) => [CacheKeys.getContractChargesDetails, contractFlowId],
  calculateSubChargeDomain: (deviationId: string, legType: string, chargeDomain: string) => [
    CacheKeys.calculateSubChargeDomain,
    deviationId,
    legType,
    chargeDomain,
  ],
};
