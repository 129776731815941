import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import { PartialMatchingServiceDto } from 'src/generated/services/TFinancialApi';
import Modal from 'src/presentations/components/molecules/modal';
import { useActiveNoContractDraft, useContractChargesDetailsForShippingService, useMatchingServicesForNoContract } from 'src/use-cases/invoice/useNoContract';
import useToast from 'src/use-cases/toast/useToast';
import { FormContainer } from '../../forms/FormContainer';
import { NoContractMain } from './Main';
import { IMatchingServiceData, INoContractModalProps, NoContractForm, RejectEnum, SelectedShippingServiceItem } from './types';
import { useNoContractSelector } from './Selector';

const StyledModal = styled(Modal)({
  '& .MuiDialog-paper': {
    borderRadius: '1.25rem', // Add border radius here
  },
  '& .MuiDialogContent-root': {
    padding: '24px 0 0',
  },
});

const getSelectedWithValidityPeriod = (data: PartialMatchingServiceDto): SelectedShippingServiceItem => ({
  mismatchedFields: data?.mismatchedFields,
  existingShippingService: {
    ...data?.existingShippingService,
    validityPeriod: {
      start: new Date(data?.existingShippingService?.startDate || ''),
      end: new Date(data?.existingShippingService?.endDate || ''),
    },
  },
});

export const NoContractModal: React.FC<INoContractModalProps> = (props) => {
  const { open, invoice } = props;
  const invoiceId = invoice?.id;
  const errorOpened = useRef<boolean>(false);
  const [contractFlowId] = useState<string | null>(null);
  const [draftContractFlowId, setDraftContractFlowId] = useState<string | null>(null);
  const openToast = useToast();
  const { closeNoContract, serviceProvider, modality } = useNoContractSelector();

  const { data: matchingService, isLoading: isMatchingServiceLoading, isFetched: isServiveFetched } = useMatchingServicesForNoContract(invoiceId);
  const { data: draft, isLoading: isDraftLoading, isFetched: isDraftFetched } = useActiveNoContractDraft(invoiceId);
  const {
    isLoading: isLoadingCharges,
    data: dataCharges,
    isFetched: isChargesFetched,
  } = useContractChargesDetailsForShippingService(draftContractFlowId || null);

  const isLoading = isMatchingServiceLoading || isDraftLoading || (isDraftFetched && draft && isLoadingCharges);

  useEffect(() => {
    setDraftContractFlowId(draft?.data?.selectedPartialMatchingService?.existingShippingService?.contractFlowId || null);
  }, [draft]);

  const matchingServiceData: IMatchingServiceData | false = useMemo(() => {
    if (isServiveFetched) {
      if (!matchingService?.matchedService || !matchingService?.unmatchedService || !matchingService?.partialMatchingServices) {
        return false;
      }
      const newData = {
        matchedService: matchingService?.matchedService || null,
        unmatchedService: matchingService?.unmatchedService,
        partialMatchingServices: matchingService?.partialMatchingServices?.map((item) => getSelectedWithValidityPeriod(item)),
      };
      return newData;
    }
    return null;
  }, [isServiveFetched, matchingService?.matchedService, matchingService?.partialMatchingServices, matchingService?.unmatchedService]);

  const defaultValues = useMemo(() => {
    const isFetched = isDraftFetched && draft ? isDraftFetched && isChargesFetched : isDraftFetched;

    if (matchingServiceData && isFetched) {
      let selectedPartial = null;
      let newService = null;
      const isReject = draft?.data?.selectedResolutionOption === RejectEnum.REJECT;

      if (draft?.data?.selectedPartialMatchingService && matchingServiceData?.partialMatchingServices && !isReject) {
        newService = getSelectedWithValidityPeriod(draft?.data?.selectedPartialMatchingService);
        selectedPartial = matchingServiceData?.partialMatchingServices.find(
          (op) => op.existingShippingService?.contractFlowId === draft?.data?.selectedPartialMatchingService?.existingShippingService?.contractFlowId
        );
      }

      let newVerdict = '';
      if (draft?.data?.selectedResolutionOption && draft?.data?.selectedResolutionOption !== '') {
        newVerdict = 'reject-reject';
      } else if (draft?.data?.selectedPartialMatchingService) {
        newVerdict = 'accept';
      }

      const contractCharges = draft ? draft?.data?.contractCharges : dataCharges;

      const values = {
        verdict: newVerdict,
        agreementType: draft?.data?.selectedResolutionOption || '',
        reason: draft?.data?.rejectedReason || '',
        contractChoice: draft?.data?.selectedPartialMatchingService?.existingShippingService?.contractFlowId || null,
        selectedPartialMatchingService: selectedPartial,
        newMatchingService: newService,
        containerCharges: contractCharges?.containerCharges || [],
        shipmentDeclarationCharges: contractCharges?.shipmentDeclarationCharges || [],
        weightCharges: contractCharges?.weightCharges || [],
        percentageCharges: contractCharges?.percentageCharges || [],
      };

      return values;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft, matchingServiceData, isDraftFetched, isChargesFetched]);

  const formContext = useForm({
    defaultValues: defaultValues as NoContractForm,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const onClose = useCallback(() => {
    if (location?.pathname !== '/dashboard' && serviceProvider && modality) {
      const targetUrl = `/dashboard?provider=${serviceProvider}&modality=${modality}`;
      navigate(targetUrl);
    }
    closeNoContract();
  }, [closeNoContract, location?.pathname, modality, navigate, serviceProvider]);

  useEffect(() => {
    formContext.reset(defaultValues as NoContractForm);
  }, [defaultValues, formContext]);

  useEffect(() => {
    if (matchingServiceData === false) {
      onClose();

      if (!errorOpened.current) {
        errorOpened.current = true;

        openToast({
          toastType: 'error',
          title: 'Error',
          description: 'No data available to read',
          toastOptions: {
            onClose: () => {
              errorOpened.current = false;
            },
          },
        });
      }
    }
  }, [matchingServiceData, onClose, openToast]);

  return (
    <>
      <StyledModal open={open} onClose={onClose} fullWidth maxWidth='xl'>
        {open && (
          <FormContainer formContext={formContext}>
            {isLoading ? (
              <div className='p-48 flex items-center justify-center'>
                <CircularProgress color='primary' />
              </div>
            ) : (
              <>
                {matchingServiceData && defaultValues && (
                  <NoContractMain
                    matchingService={matchingServiceData}
                    draft={draft}
                    invoice={invoice}
                    onClose={onClose}
                    defaultContractFlowId={contractFlowId}
                  />
                )}
              </>
            )}
          </FormContainer>
        )}
      </StyledModal>
    </>
  );
};
