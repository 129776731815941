import Chip from '@mui/material/Chip';

interface StatusChipProps {
  status: string;
  size?: 'small' | 'medium';
}

const StatusChip: React.FC<StatusChipProps> = ({ status, size = 'medium' }) => {
  let chipStyle = {};
  let label = '';

  if (status === 'APPROVED') {
    chipStyle = {
      background: 'rgba(0, 204, 58, 0.20)',
      border: '1px solid #00CC3A',
      borderRadius: '20px',
      color: '#00B233',
    };
    label = 'Ready for Payment';
  } else if (status === 'TO_APPROVE') {
    chipStyle = {
      background: 'rgba(38, 158, 204, 0.2)',
      border: '1px solid #269ECC',
      borderRadius: '20px',
      color: '#1D7699',
    };
    label = 'To Approve';
  } else if (status === 'TO_REJECT') {
    chipStyle = {
      background: '#DFDFDF',
      border: '1px solid #475B69',
      borderRadius: '20px',
      color: '#475B69',
    };
    label = 'To Reject';
  } else if (status === 'REJECTED') {
    chipStyle = {
      background: 'rgba(231, 37, 71, 0.20)',
      border: '1px solid #E72547',
      borderRadius: '20px',
      color: '#E72547',
    };
    label = 'Rejected';
  } else if (status === 'PENDING_DOCUMENTS' || status === 'PENDING_DOCUMENT') {
    chipStyle = {
      background: 'rgba(253, 202, 64, 0.20)',
      border: '1px solid #FDCA40',
      borderRadius: '20px',
      color: '#E3B539',
    };
    label = 'Pending Document';
  } else if (status === 'FLAGGED' || status === 'NO_CONTRACT_FOUND') {
    chipStyle = {
      background: 'rgba(247, 152, 36, 0.2)',
      border: '1px solid #F79824',
      borderRadius: '20px',
      color: '#E58D21',
    };
    label = 'Flagged';
  }

  return <Chip label={label} style={chipStyle} size={size} />;
};

export default StatusChip;
