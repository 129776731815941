import { useUserPermissions } from 'src/use-cases/user/useUserPermissions';

import TopDecoImage from 'src/assets/images/landing-top-deco.png';
import { featureFlags } from 'src/config/featureFlags';

export const LandingTopContent = () => {
  useUserPermissions();

  return (
    <>
      <div className='relative bg-neutral-5 pt-48 pb-30 px-32 border-b-2 border-neutral-20'>
        <div className='absolute top-0 right-0'>
          <img src={TopDecoImage} alt='' />
        </div>
        <div className='relative z-10 flex flex-col items-center justify-center gap-30'>
          <div className='text-center'>
            <h1 className='text-32 font-semibold mb-8 text-neutral-80'>
              Welcome to your Freight Invoice <br /> Audit & Analytics Platform
            </h1>
            {featureFlags['FINDEV-780'] && <h1 className='text-32 font-semibold mb-8 text-neutral-80'>Feature Flag FINDEV-780 is enabled</h1>}
          </div>
        </div>
      </div>
    </>
  );
};
