import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';

import { NotificationDto } from 'src/generated/services/TFinancialApi';

export interface INotificationSelectorProps {
  children: ReactNode;
}

export interface INotificationContext {
  isShowContent: boolean;
  isShowDetail: boolean;
  selectedDetail: NotificationDto;
  setIsShowContent: (status: boolean) => void;
  setIsShowDetail: (status: boolean) => void;
  setSelectedDetail: (data: NotificationDto) => void;
}

export const NotificationSelectorContext = createContext<INotificationContext>(null);

export const NotificationSelector = ({ children }: INotificationSelectorProps) => {
  const [isShowContent, setIsShowContent] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const [selectedDetail, setSelectedDetail] = useState<NotificationDto>(null);

  const formContext = useForm({
    defaultValues: {
      date: 'ALL',
    },
  });

  const value = useMemo(
    () => ({
      isShowContent,
      isShowDetail,
      setIsShowContent,
      setIsShowDetail,
      setSelectedDetail,
      selectedDetail,
      formContext,
    }),
    [isShowContent, isShowDetail, setIsShowContent, setIsShowDetail, setSelectedDetail, selectedDetail, formContext]
  );

  return (
    <FormContainer formContext={formContext}>
      <NotificationSelectorContext.Provider value={value}>{children}</NotificationSelectorContext.Provider>
    </FormContainer>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useNotificationSelector() {
  const context = useContext(NotificationSelectorContext);

  if (!context) {
    throw new Error(`Upload Invoices selector compound components cannot be rendered outside the Upload Invoices Selector component`);
  }
  return context;
}
