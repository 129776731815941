import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AttachInterceptorFunction } from 'src/infra/services/interceptors';

export const createAxiosInstance = (config: AxiosRequestConfig = {}, applyInterceptorFunctions: AttachInterceptorFunction[] = []): AxiosInstance => {
  const axiosInstance = axios.create(config);
  for (const applyInterceptorFunction of applyInterceptorFunctions) {
    applyInterceptorFunction(axiosInstance);
  }
  return axiosInstance;
};
