import { InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';
import { UploadStep } from './types';
import { StepSelectInvoices } from './StepSelectInvoices';
import { StepUploadStatus } from './StepUploadStatus';
import { useUploadSelector } from './useUploadSelector';

export interface IMainContentProps {
  onFinishUpload(data: InvoiceUploadSignedUrlDto): void;
}

export const MainContent: React.FC<IMainContentProps> = (props) => {
  const { onFinishUpload } = props;
  const { activeStep } = useUploadSelector();

  return (
    <div className='pt-20 px-24 pb-24'>
      {activeStep === UploadStep.Step1_SelectInvoice && <StepSelectInvoices />}
      {activeStep === UploadStep.Step2_UploadStatus && <StepUploadStatus onFinishUpload={onFinishUpload} />}
    </div>
  );
};
