import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authSelectors from 'src/infra/stores/reducer/auth/selectors';
import { AppDispatch } from 'src/infra/stores/store';
import { roleToDisplayName } from 'src/utils/roleMapper';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { logoutActionCreator } from 'src/infra/stores/reducer/auth';
import LogoImg from 'src/assets/images/tetrixx.svg';
import IconUser from 'src/assets/images/icons/user-white.svg';
import { cn } from 'src/utils/utility';
import { ReactNode } from 'react';
import { SentryDebug } from 'src/presentations/components/molecules/sentryDebug/SentryDebug';
import { Notification } from '../../molecules/notification/Notification';
import { NotificationSelector } from '../../molecules/notification/NotificationSelector';

export const LandingTopWidget = ({ title, children }: { title?: string; children?: ReactNode }) => {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(authSelectors.selectUser);
  const role = useSelector(authSelectors.selectUserRole);

  return (
    <div className='flex items-center bg-[#1C2429] text-white'>
      <div className='flex items-center py-18 px-20'>
        <Link to='/' className='rounded-12 bg-white flex items-center justify-center p-10'>
          <img alt='tetrixx' src={LogoImg} className='w-24' />
        </Link>
      </div>

      <div className={cn('flex-1 flex items-center gap-24 py-18 px-20', title && 'border-l border-[#2E363A]')}>
        {title && <div className='text-white text-18 font-medium'>{title}</div>}
        <SentryDebug />
        <div className='ml-auto flex items-center gap-24'>
          <div className='flex items-center gap-8'>
            {children}
            <NotificationSelector>
              <Notification />
            </NotificationSelector>
            <img src={IconUser} alt='' />
            <div className='flex flex-col gap-2'>
              <p>
                <strong className='block text-16 text-white font-bold truncate max-w-[18rem]'>{user?.email}</strong>
              </p>
              <p className='text-sm text-blue_grey-30'>{roleToDisplayName(role)}</p>
            </div>
          </div>

          <Button
            role='button'
            aria-label='logout'
            onClick={() => {
              dispatch(logoutActionCreator());
            }}
          >
            <LogoutIcon sx={{ color: '#597282' }} />
          </Button>
        </div>
      </div>
    </div>
  );
};
