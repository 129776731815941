import UpdateReport from '../../../atom/icons/UpdateReport';

interface IUpdateProps {
  onUpdate: () => void;
  isChosen: boolean;
  disabled: boolean;
}

export const UpdateChartReportOption = ({ onUpdate, isChosen, disabled }: IUpdateProps) => (
  <button
    disabled={disabled}
    onClick={onUpdate}
    className={`p-[20px] items-center gap-[20px] flex border-[1px] ${isChosen ? 'border-[#00992B] shadow-md' : 'border-[#DFDFDF]'}  rounded-[20px]  `}
  >
    <UpdateReport color={disabled ? '#808080' : '#00992B'} />
    <div className={`text-left ${disabled ? 'text-[#808080]' : ''}`}>
      <h1 className='font-extrabold'>Replace original report</h1>
      <p className='text-[#808080]'>You will save the changes replacing this report</p>
    </div>
  </button>
);
