/**
 * InputReason
 */

import { FieldLabel } from 'src/presentations/components/molecules/fields/FieldLabel';
import { FC } from 'react';
import { useFieldMaterialUIStyles } from 'src/presentations/components/molecules/fields/useFieldMaterialUIStyles';
import { FieldValues } from 'react-hook-form';
import { SelectElement, SelectElementProps } from 'react-hook-form-mui';
import { useDefaultProps } from '../useDefaultProps';

interface IRhfMuiSelectProps extends SelectElementProps<FieldValues> {
  hideFieldLabel?: boolean;
  multiple?: boolean;
}

export const RhfMuiSelect: FC<IRhfMuiSelectProps> = (props) => {
  const {
    fullWidth = true,
    size = 'small',
    options,
    label,
    placeholder,
    children,
    SelectProps,
    hideFieldLabel,
    ...rest
  } = { ...props, ...useDefaultProps(props) };
  const { classes, cx } = useFieldMaterialUIStyles();

  const selectOptions = placeholder
    ? [
        {
          id: '',
          label: placeholder,
          disabled: true,
        },
        ...options,
      ]
    : options;

  return (
    <FieldLabel label={!hideFieldLabel && label}>
      <div className='relative'>
        <SelectElement
          className={cx({ [classes.shortWidth]: !fullWidth }, classes.inputField)}
          fullWidth
          size={size}
          options={selectOptions}
          label={!placeholder && label}
          SelectProps={{
            displayEmpty: !!placeholder,
            ...SelectProps,
          }}
          {...rest}
        />
        {children}
      </div>
    </FieldLabel>
  );
};
