/**
 * Page Loader
 */
import { CircularProgress } from '@mui/material';
import LogoImg from 'src/assets/images/logo.svg';

export const PageLoader: React.FC = () => (
  <div className='w-screen h-screen flex items-center justify-center bg-white/80'>
    <div className='px-30 flex flex-col items-center'>
      <div className='text-center'>
        <img src={LogoImg} alt='' className='w-[140px]' />
      </div>
      <div className='mt-32'>
        <CircularProgress size={30} color='primary' />
      </div>
    </div>
  </div>
);
