import { ReactNode, useCallback, useMemo, createContext } from 'react';
import { CardTheme, ChartType } from './types';

export interface ICardAnalyticItemProps {
  id: string;
  title: string;
  cardTitle?: ReactNode;
  count: ReactNode;
  icon: string;
  theme: CardTheme;
  chartType: ChartType;
  unit: string;
}
export interface ICardSelectorProps<T> {
  onCardSelected(id: T): void;
  selectedCards: ICardAnalyticItemProps[];
  children: ReactNode;
}

export const CardSelectorContext = createContext(null);

export const CardSelector = <T,>({ onCardSelected, selectedCards, children }: ICardSelectorProps<T>) => {
  const toggle = useCallback(
    (id: any) => {
      onCardSelected(id);
    },
    [onCardSelected]
  );

  const checkIsActive = useCallback((id: any) => !!selectedCards?.find((item) => item.id === id), [selectedCards]);

  const checkIsEnable = useCallback((unit: string) => new Set([...selectedCards.map((item) => item.unit), unit]).size <= 3, [selectedCards]);

  const value = useMemo(() => ({ checkIsEnable, checkIsActive, toggle }), [checkIsEnable, checkIsActive, toggle]);
  return <CardSelectorContext.Provider value={value}>{children}</CardSelectorContext.Provider>;
};
