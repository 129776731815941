import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import format from 'date-fns/format';

import { NotificationDto } from 'src/generated/services/TFinancialApi';
import { NotificationCard } from './Card';
import { INotificationContentProps } from './types';

interface NotificationGroupDTO {
  date: string;
  dateOriginal: string;
  items: NotificationDto[];
}

export const NotificationContentMain: FC<INotificationContentProps> = (props) => {
  const { allNotifications } = props;
  const [currentNotifications, setCurrentNotifications] = useState<NotificationDto[]>(allNotifications);

  const { watch } = useFormContext();
  const viewDate = watch('date');

  useEffect(() => {
    if (!viewDate || viewDate === 'ALL') {
      setCurrentNotifications(allNotifications);
    } else {
      const newItems = allNotifications.filter((item) => format(new Date(item?.uploadedOn), 'EEEE, dd-MMM-yyyy') === viewDate);
      setCurrentNotifications(newItems);
    }
  }, [viewDate, allNotifications]);

  const itemsGrouped: NotificationGroupDTO[] = useMemo(() => {
    let newItems: NotificationGroupDTO[] = [];

    currentNotifications
      .sort((a, b) => {
        if (a.uploadedOn > b.uploadedOn) {
          return -1;
        }
        if (a.uploadedOn < b.uploadedOn) {
          return 1;
        }
        return 0;
      })
      .forEach((item) => {
        const itemDate = format(new Date(item?.uploadedOn), 'EEEE, dd-MMM-yyyy');
        const isExists = newItems.findIndex((item) => item?.date === itemDate);
        if (isExists !== -1) {
          newItems[isExists] = {
            ...newItems[isExists],
            items: [...newItems[isExists].items, item],
          };
        } else {
          newItems = [
            ...newItems,
            {
              date: itemDate,
              dateOriginal: item?.uploadedOn,
              items: [item],
            },
          ];
        }
      });

    return newItems;
  }, [currentNotifications]);

  return (
    <div className='flex-1 overflow-y-auto pb-24'>
      {itemsGrouped.map((item: NotificationGroupDTO) => (
        <div key={`notif-${item?.date}`} className='relative'>
          <div className='bg-[#F5F6FA] border-t border-b border-[#DFDFDF] pt-16 pb-10 px-24 sticky top-0'>
            <h3 className='text-14 font-medium'>{item?.date}</h3>
          </div>

          <div className='flex flex-col gap-10'>
            {item?.items?.map((notifItem: NotificationDto) => <NotificationCard key={`notif-item-${notifItem?.notificationId}`} data={notifItem} />)}
          </div>
        </div>
      ))}
    </div>
  );
};
