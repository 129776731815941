import { ToastOptions, toast } from 'react-toastify';
import IconCheckCircle from 'src/presentations/components/atom/icons/CheckCircle';
import IconExclamationCircle from 'src/presentations/components/atom/icons/ExclamationCircle';
import IconInfoCircle from 'src/presentations/components/atom/icons/InfoCircle';
import CloseIcon from '@mui/icons-material/Close';

export type PositionTypes = 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';

export type ToastTypes = 'info' | 'success' | 'warning' | 'error';

export const openToastError = <T extends Error>(error: T) => {
  openToast({ title: 'Error', description: error.message?.toString() ?? '', toastType: 'error', toastOptions: { toastId: error.message?.toString() } });
};

export interface ToastProps {
  title: string;
  description?: string;
  toastType?: ToastTypes;
  toastOptions?: ToastOptions;
}

export const getDefaultToastOptions = (toastOptions?: ToastOptions): ToastOptions => ({
  ...toastOptions,
  autoClose: 7000,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  pauseOnHover: false,
  position: 'bottom-left',
  closeButton: <CloseIcon style={{ width: '20px' }} />,
  icon: ({ type }) => {
    const size = 24;
    switch (type) {
      case 'success':
        return <IconCheckCircle size={size} />;
      case 'error':
      case 'warning':
        return <IconExclamationCircle size={size} />;
      default:
        return <IconInfoCircle color='white' size={size} />;
    }
  },
});

export const openToast = ({ toastType = 'info', title, description, toastOptions }: ToastProps) => {
  toast[toastType](
    <div className='w-fit'>
      <p className='text-14'>{title}</p>
      {description && <p className='text-12 text-grey-40 '>{description}</p>}
    </div>,
    getDefaultToastOptions(toastOptions)
  );
};

function useToast() {
  return openToast;
}

export default useToast;
