import { formatDate } from 'src/utils/date';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { useFormContext } from 'react-hook-form-mui';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconImg from 'src/assets/images/icons/analytic-landing.svg';
import { CardLanding } from 'src/presentations/components/molecules/card/CardLanding';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { ChartReportDto } from 'src/generated/services/TFinancialApi';
import { LandingSection } from './Section';
import { useChartReports } from '../analytics/useAnalytics';
import IconCalender from '../../../../assets/images/icons/filter-calendar.svg';
import { MultipleSelectDropdown } from '../analytics/filter/MultipleSelectDropdown';

export interface ChartReport {
  name: string;
  hashFilters: string;
}

const StyledBox = styled(Box)({
  '& .MuiFormControl-root': {
    width: '180px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

const sortOptions = [
  {
    label: 'Newest',
    value: 'desc',
  },
  {
    label: 'Oldest',
    value: 'asc',
  },
];

const CreateNewReport = () => (
  <div className='px-12 w-1/4 mb-30 min-h-[8.4375rem]'>
    <Link
      to='/analytics'
      className='shadow-[0_4px_7px_0_rgba(0,0,0,0.06)] border-b-2 rounded-8 flex items-center justify-center bg-neutral-10 border border-neutral-20 transition-all duration-300 hover:bg-grey-50 border-neutral-40 h-full'
    >
      <div className='flex flex-col items-center justify-center text-neutral-50'>
        <div className='mb-4'>
          <AddIcon className='text-neutral-50' sx={{ fontSize: 40 }} />
        </div>
        <p className='text-16'>Start New</p>
        <p className='text-12'>with a blank report</p>
      </div>
    </Link>
  </div>
);

const ReportCard = ({ item }: { item: ChartReportDto }) => (
  <CardLanding
    title={item?.name?.length === 0 ? 'Unnamed Report' : item?.name}
    info={item?.description?.length === 0 ? '' : item?.description}
    path={`/analytics?report=${item?.hashedFilters}&id=${item?.id}`}
  >
    {item?.updateDate && (
      <div className='flex items-center'>
        <img src={IconCalender} alt='icon-calender' className='w-14 h-14 mr-2' />
        <div className='text-12 text-[#808080] font-normal ml-2'>{`Last Updated on ${formatDate(new Date(item?.updateDate))}`}</div>
      </div>
    )}
  </CardLanding>
);

export const LandingAnalytics = () => {
  const { watch, control } = useFormContext();
  const sort = watch('analyticSort');
  const { data: dataAnalytics, isLoading } = useChartReports();
  const listAnalytics = useMemo(() => {
    if (dataAnalytics) {
      const newItems = [...dataAnalytics];
      newItems.sort((a, b) => {
        const aDate = new Date(a.updateDate);
        const bDate = new Date(b.updateDate);

        const compareStatus = sort === 'desc' ? isBefore(aDate, bDate) : isAfter(aDate, bDate);
        return compareStatus ? 1 : -1;
      });
      return newItems;
    }
    return [];
  }, [dataAnalytics, sort]);

  return (
    <LandingSection
      icon={IconImg}
      title='Reports & Analytics'
      linkDetail='/analytics-new'
      isLoading={isLoading}
      topRight={
        <StyledBox>
          <MultipleSelectDropdown name='analyticSort' label='Sort by Date' control={control} options={sortOptions} multiple={false} />
        </StyledBox>
      }
    >
      <div className='-mx-12 flex flex-wrap'>
        <CreateNewReport />
        {listAnalytics?.map((item) => (
          <div key={`card-audit-${item?.id}`} className='px-12 w-1/4 mb-30 min-h-[8.4375rem]'>
            <ReportCard item={item} />
          </div>
        ))}
      </div>
    </LandingSection>
  );
};
