import { ICustomIconProps } from './types';

const IconCourier: React.FC<ICustomIconProps> = ({ size = 20, color = '#30C5FF' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.1004 6.94C20.1004 7.48 19.8104 7.97 19.3504 8.22L17.6104 9.16L16.1304 9.95L13.0604 11.61C12.7304 11.79 12.3704 11.88 12.0004 11.88C11.6304 11.88 11.2704 11.79 10.9404 11.61L4.65039 8.22C4.19039 7.97 3.90039 7.48 3.90039 6.94C3.90039 6.4 4.19039 5.91 4.65039 5.66L6.62039 4.6L8.19039 3.75L10.9404 2.27C11.6004 1.91 12.4004 1.91 13.0604 2.27L19.3504 5.66C19.8104 5.91 20.1004 6.4 20.1004 6.94Z'
      fill={color}
    />
    <path
      d='M9.9007 12.79L4.0507 9.86999C3.6007 9.63999 3.0807 9.66999 2.6507 9.92999C2.2207 10.19 1.9707 10.65 1.9707 11.15V16.68C1.9707 17.64 2.5007 18.5 3.3607 18.93L9.2107 21.85C9.4107 21.95 9.6307 22 9.8507 22C10.1107 22 10.3707 21.93 10.6007 21.78C11.0307 21.52 11.2807 21.06 11.2807 20.56V15.03C11.2907 14.08 10.7607 13.22 9.9007 12.79Z'
      fill={color}
    />
    <path
      d='M22.0309 11.15V16.68C22.0309 17.63 21.5009 18.49 20.6409 18.92L14.7909 21.85C14.5909 21.95 14.3709 22 14.1509 22C13.8909 22 13.6309 21.93 13.3909 21.78C12.9709 21.52 12.7109 21.06 12.7109 20.56V15.04C12.7109 14.08 13.2409 13.22 14.1009 12.79L16.2509 11.72L17.7509 10.97L19.9509 9.87C20.4009 9.64 20.9209 9.66 21.3509 9.93C21.7709 10.19 22.0309 10.65 22.0309 11.15Z'
      fill={color}
    />
    <path d='M17.6091 9.16L16.1291 9.95L6.61914 4.6L8.18914 3.75L17.3691 8.93C17.4691 8.99 17.5491 9.07 17.6091 9.16Z' fill={color} />
    <path d='M17.75 10.97V13.24C17.75 13.65 17.41 13.99 17 13.99C16.59 13.99 16.25 13.65 16.25 13.24V11.72L17.75 10.97Z' fill={color} />
  </svg>
);

export default IconCourier;
