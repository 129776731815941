// src/form-component/FormInputDropdown.tsx

import { Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Control, Controller } from 'react-hook-form';

const StyledCheckbox = styled(Checkbox)({
  paddingLeft: '0!important',
});

interface IOption {
  isLabel?: boolean;
  value: any;
  label: ReactNode;
}

interface IProps {
  id?: string;
  name: string;
  multiple?: boolean;
  disabled?: boolean;
  label: ReactNode;
  options: IOption[];
  defaultValue?: any;
  control: Control<any>;
  onChangeCustom?: (e: SelectChangeEvent<string | string[]>) => void;
  onOpenSelect?: () => void;
}

export const MultipleSelectDropdown = ({
  id,
  name,
  control,
  label,
  options,
  defaultValue = null,
  multiple = true,
  onChangeCustom = null,
  onOpenSelect,
  disabled,
}: IProps) => {
  const checkValue = useCallback(
    (value?: string | string[]) => {
      if (value) {
        return value;
      }
      if (multiple) {
        return [];
      }
      return '';
    },
    [multiple]
  );

  const ValueRender = useCallback(
    (value: string | string[]) => {
      if (multiple && Array.isArray(value)) {
        const selected = value.map((v) => options?.find((option) => option.value === v)?.label);
        return <span className='capitalize'>{selected.join(', ')}</span>;
      }
      const selected = options?.find((option) => option.value === value)?.label;
      return <span className='capitalize'>{selected}</span>;
    },
    [multiple, options]
  );

  return (
    <FormControl size='small'>
      <InputLabel>{label}</InputLabel>
      <Controller
        defaultValue={defaultValue}
        render={({ field: { onChange, value = [] } }) => (
          <Select
            id={id || 'dd'}
            onChange={(event: SelectChangeEvent<string | string[]>) => {
              onChange(event);
              if (onChangeCustom) {
                onChangeCustom(event);
              }
            }}
            value={checkValue(value)}
            multiple={multiple}
            input={<OutlinedInput label={label} />}
            renderValue={ValueRender}
            onOpen={onOpenSelect}
            disabled={disabled}
          >
            {options?.map((option) => {
              if (option?.isLabel) {
                return <ListSubheader key={option.value}>{option.label}</ListSubheader>;
              }
              return (
                <MenuItem key={option.value} value={option.value}>
                  {multiple && <StyledCheckbox checked={!!value?.find((v) => v === option.value)} />}
                  <ListItemText primary={option.label} sx={{ textTransform: 'capitalize' }} />
                </MenuItem>
              );
            })}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
