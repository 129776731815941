import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSavingsStats } from 'src/use-cases/performance/usePerformance';

import { CardStat } from './Stat/CardStat';
import { LoaderSavings } from './LoaderSavings';
import { ModalityProviderSavings } from './SectionDetail/ModalityProviderSavings';
import { getProviderModalityCombinations, getSavingStatsList } from './helpers';
import { IProviderModalityProps } from './types';

export const PerformanceContentSavings = ({ dataFilter }) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const combinations = getProviderModalityCombinations(formValues, dataFilter);

  const { data: dataStats, isLoading: isLoadingStats } = useSavingsStats({
    filterConfigs: formValues,
  });

  const statsList = useMemo(() => getSavingStatsList(dataStats), [dataStats]);

  return (
    <div>
      {isLoadingStats ? (
        <LoaderSavings />
      ) : (
        <div className='pt-8 px-32 pb-24 grid gap-24 border-b border-neutral-20 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4'>
          {statsList.map((item) => (
            <CardStat key={`card-stat-main-${item?.type}`} type={item.type} values={item.values} />
          ))}
        </div>
      )}

      {combinations?.length > 0 && (
        <div className='border-t border-white px-32 py-24 flex flex-col gap-40'>
          {combinations.map((item: IProviderModalityProps) => (
            <ModalityProviderSavings key={`performance-${item.provider}-${item.modality}`} provider={item.provider} modality={item.modality} />
          ))}
        </div>
      )}
    </div>
  );
};
