const defaultDisabled = 'bg-neutral-40 text-white border-none hover:text-white hover:bg-neutral-40';
const whiteDisabled = 'bg-blue_grey-80 text-blue_grey-90 border-none hover:text-blue_grey-90 hover:bg-blue_grey-80';

export default {
  base: {
    center: 'items-center inline-flex justify-center hover:no-underline',
    left: 'items-center inline-flex hover:no-underline text-left',
  },
  size: {
    medium: 'h-40 text-14 font-bold',
  },
  space: {
    center: {
      medium: 'px-24 min-w-120',
    },
    left: {
      medium: 'px-16',
    },
  },
  primary: {
    active: 'bg-primary text-white border-none hover:text-white hover:bg-green-60',
    disabled: defaultDisabled,
  },
  primary_outline: {
    active: 'bg-white border-2 border-primary text-primary hover:text-green-60 hover:border-green-60 hover:bg-green-10',
    disabled: defaultDisabled,
  },
  secondary: {
    active: 'bg-neutral-20 text-black hover:text-black hover:bg-neutral-40',
    disabled: defaultDisabled,
  },
  white: {
    active: 'bg-neutral-10 text-neutral-80 border-none hover:text-neutral-80 hover:bg-neutral-20',
    disabled: whiteDisabled,
  },
  white_outline: {
    active: 'bg-transparent border-2 text-white border-blue_grey-50 hover:text-neutral-20 hover:border-neutral-20 hover:bg-blue_grey-80',
    disabled: whiteDisabled,
  },
  neutral_outline: {
    active: 'bg-transparent border text-neutral-80 border-neutral-20 hover:border-neutral-40 hover:bg-neutral-10',
    disabled: whiteDisabled,
  },
  danger: {
    active: 'bg-red-40 text-white border-none hover:text-white hover:bg-red-50',
    disabled: defaultDisabled,
  },
  danger_outline: {
    active: 'bg-transparent border-2 text-white border-red-40 hover:text-red-40 hover:border-red-40 hover:bg-transparent',
    disabled: defaultDisabled,
  },
  rounded: {
    medium: 'rounded-4',
    large: 'rounded-12',
  },
};
