// src/components/CustomTooltip.tsx

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000000',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
  },
}));

export default CustomTooltip;
