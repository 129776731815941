/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ResponseDtoObject {
  success?: boolean;
  message?: string;
  data?: object;
  /** @format int32 */
  statusCode?: number;
}

export interface UserPermissionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  userId?: string;
  /** @format int64 */
  billedEntityId?: number;
  /** @format int64 */
  shipperEntityId?: number;
  /** @format int64 */
  consigneeEntityId?: number;
  /** @format int64 */
  serviceProviderId?: number;
  /** @format uuid */
  shippingServiceId?: string;
  /** @uniqueItems true */
  permissions?: ('OPERATION' | 'PROCUREMENT' | 'DISPUTE' | 'FINANCE' | 'AUDIT' | 'REPORT' | 'PERFORMANCE')[];
  allowed?: boolean;
}

export interface ResponseDtoUserPermissionDto {
  success?: boolean;
  message?: string;
  data?: UserPermissionDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoString {
  success?: boolean;
  message?: string;
  data?: string;
  /** @format int32 */
  statusCode?: number;
}

export interface ChargeMessageDto {
  name?: string;
  quantity?: number;
  currency?: string;
  unitPrice?: number;
  exchangeRate?: number;
  amountBeforeTax?: number;
  amountAfterTax?: number;
  taxAmount?: number;
  discountAmount?: number;
  taxPercentage?: number;
  code?: string;
  chargeUnit?: string;
}

export interface InvoiceBankDetailMessageDto {
  bankName?: string;
  bankBranch?: string;
  swiftCode?: string;
  accountName?: string;
  accountNumber?: string;
}

export interface InvoiceMessageDto {
  invoiceNumber?: string;
  /** @format date */
  invoiceDate?: string;
  /** @format date */
  dueDate?: string;
  terms?: string;
  accountNo?: string;
  jobNumber?: string;
  businessUnit?: string;
  modality?: string;
  customerCode?: string;
  serviceProviderName?: string;
  serviceProviderAddress?: string;
  serviceProviderTelephone?: string;
  serviceProviderFax?: string;
  serviceProviderCode?: string;
  billedPartyName?: string;
  billedPartyAddress?: string;
  billedPartyTelephone?: string;
  preparedBy?: string;
  notes?: string;
  important?: string;
  remarks?: string;
  invoiceCurrency?: string;
  totalAmountBeforeTax?: number;
  totalTaxAmount?: number;
  totalAmountAfterTax?: number;
  totalAmountAfterTaxInWords?: string;
  bankDetails?: InvoiceBankDetailMessageDto[];
  invoiceFileLink?: string;
  /** @format uuid */
  pipelineId?: string;
  configId?: string;
  incoterm?: string;
  pickUpPoint?: string;
  deliveryPoint?: string;
  totalAmountDiscount?: number;
  shipments?: ShipmentMessageDto[];
  salesPerson?: string;
  customerReference?: string;
  poNumber?: string;
  fapNumber?: string;
  companyRegistrationNumber?: string;
  gstRegistrationNumber?: string;
  lotNumber?: string;
}

export interface ShipmentMessageDto {
  internalTrackingNumber?: string;
  shipmentNumber?: string;
  shipper?: string;
  consignee?: string;
  placeOfLoading?: string;
  placeOfDischarge?: string;
  transportationReferenceNumber?: string;
  carrier?: string;
  /** @format int32 */
  numberOfPackages?: number;
  numberOfPackagesDescription?: string;
  serviceType?: string;
  commodity?: string;
  containerNumbers?: string[];
  /** @format date */
  estimatedDepartureDate?: string;
  /** @format date */
  estimatedArrivalDate?: string;
  masterBillOfLading?: string;
  houseBillOfLading?: string;
  grossWeightInKg?: number;
  grossWeight?: string;
  chargeableWeightInKg?: number;
  chargeableWeight?: string;
  volumeInCbm?: number;
  netWeightInKg?: number;
  netWeight?: string;
  charges?: ChargeMessageDto[];
}

export interface CreatingAndUpdatingShippingServiceReq {
  code?: string;
  /** @format int64 */
  serviceProviderId: number;
  shipperCode?: string;
  consigneeCode?: string;
  /** @format int64 */
  billingPartyId?: number;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  dominantModality: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  pol: string;
  pod: string;
  service?: string;
  type?: string;
  carrier?: string;
  totalLeadTime?: number;
}

export interface LegalEntityDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  active?: boolean;
  /** @format int64 */
  parentId?: number;
}

export interface ResponseDtoShippingServiceDto {
  success?: boolean;
  message?: string;
  data?: ShippingServiceDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ShippingServiceDto {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format int64 */
  serviceProviderId?: number;
  shipperInfo?: LegalEntityDto;
  consigneeInfo?: LegalEntityDto;
  /** @format int64 */
  billingPartyId?: number;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  active?: boolean;
  dominantModality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  dataPipelineConfigId?: string;
  pol?: string;
  pod?: string;
  service?: string;
  type?: string;
  carrier?: string;
  totalLeadTime?: number;
}

export interface UpsertContractWeightChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  unitPrice?: number;
  currency: string;
  chargeUnit: 'KG' | 'CBM';
  minimumPrice?: number;
  minimumChargeableWeight?: number;
  maximumChargeableWeight?: number;
}

export interface ResponseDtoWeightChargeDto {
  success?: boolean;
  message?: string;
  data?: WeightChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface WeightChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice?: number;
  currency?: string;
  chargeUnit?: 'KG' | 'CBM';
  minimumPrice?: number;
  minimumChargeableWeight?: number;
  maximumChargeableWeight?: number;
}

export interface UpsertContractShipmentChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  currency: string;
  unitPrice: number;
}

export interface ResponseDtoShipmentChargeDto {
  success?: boolean;
  message?: string;
  data?: ShipmentChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ShipmentChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice?: number;
  currency?: string;
}

export interface UpsertContractPercentageChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  value?: number;
  eligibleChargeDomain: string;
}

export interface PercentageChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  value?: number;
  eligibleChargeDomain?: string;
}

export interface ResponseDtoPercentageChargeDto {
  success?: boolean;
  message?: string;
  data?: PercentageChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractDeclarationChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  unitPrice: number;
  currency: string;
}

export interface DeclarationChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice?: number;
  currency?: string;
}

export interface ResponseDtoDeclarationChargeDto {
  success?: boolean;
  message?: string;
  data?: DeclarationChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UpsertContractContainerChargeReq {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractFlowId: string;
  legType: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain: string;
  accessorial: boolean;
  approval: boolean;
  outlay: boolean;
  containerType?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP';
  unitPrice20?: number;
  unitPrice40?: number;
  currency?: string;
}

export interface ContainerChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice20?: number;
  unitPrice40?: number;
  currency?: string;
  containerType?: 'STANDARD' | 'HIGH_CUBE' | 'OPEN_TOP';
}

export interface ResponseDtoContainerChargeDto {
  success?: boolean;
  message?: string;
  data?: ContainerChargeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationWorkflowActionBodyDto {
  reason?: string;
}

export interface Amount {
  beforeTax?: number;
  afterTax?: number;
  currency?: string;
}

export interface CreditNoteFileDto {
  fileName?: string;
  /** @format date-time */
  uploadedTime?: string;
}

export interface DeviationDto {
  /** @format uuid */
  id?: string;
  name?: string;
  type?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  status?: 'UNFILLED' | 'APPROVED_UNDONE' | 'REJECTED_UNDONE' | 'REJECTED_DONE' | 'DONE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeLegType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeSubDomain?: string;
  chargeDescription?: string;
  legCurrency?: string;
  deviationCurrency?: string;
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  invoiceChargeUnit?: string;
  contractUnitPrice?: number;
  invoiceUnitPrice?: number;
  invoiceQuantity?: number;
  deviationQuantity?: number;
  deviationAmountPerUnit?: number;
  deviationAmount?: number;
  deviationAmountBeforeTax?: number;
  deviationAmountAfterTax?: number;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  chargeQuantity?: number;
  chargeUnitPrice?: number;
  rejectedByOperation?: boolean;
}

export interface DisputeDeviationResolutionDto {
  /** @format uuid */
  deviationId?: string;
  /** @format date */
  endPeriod?: string;
  /** @format date */
  startPeriod?: string;
  expectedAmount?: number;
  exchangeRate?: number;
  periodType?: 'ONE_OFF' | 'RECURRING';
}

export interface FreightForwarderDto {
  name?: string;
  code?: string;
  country?: string;
}

export interface InvoiceDetailDto {
  /** @format uuid */
  id?: string;
  mblHbl?: string;
  invoiceNumber?: string;
  /** @format date */
  invoiceDate?: string;
  freightForwarder?: FreightForwarderDto;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @format date */
  paymentDate?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  invoiceAmount?: Amount;
  creditNoteAmount?: Amount;
  amountToBePaid?: Amount;
  invoicedParty?: string;
  invoiceAmountDeviation?: Amount;
  deviations?: DeviationDto[];
  shipmentDetail?: ShipmentDetailDto;
  legDetails?: LegDetail[];
  transportDetail?: ShipmentTransportDetailDto;
  /** @uniqueItems true */
  permissions?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  possibleWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'OPERATION_DISPUTE_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PROCUREMENT_DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  importExportType?: string;
  modality?: string;
  invoiceDispute?: InvoiceDisputeDto;
}

export interface InvoiceDisputeDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  currency?: string;
  lumpSumAmount?: number;
  lumpSumAmountBeforeTax?: number;
  expectedTotalAmount?: Amount;
  creditNoteAmount?: Amount;
  resolutionDescription?: string;
  status?: 'DRAFT' | 'PENDING_DOCUMENT' | 'DONE';
  creditNoteFile?: CreditNoteFileDto;
  rejectedDeviations?: DeviationDto[];
  deviations?: DeviationDto[];
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  agreementResolution?: 'ACCEPT_INVOICE_AS_IS' | 'AMEND_INVOICE_CREDIT_NOTE' | 'REJECT_INVOICE' | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  deviationResolutions?: DisputeDeviationResolutionDto[];
  canContinueDispute?: boolean;
}

export interface InvoiceDto {
  /** @format uuid */
  id?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  paymentDate?: string;
  portArrivalDateEta?: string;
  freightForwarder?: string;
  portOfLoading?: string;
  portOfDischarge?: string;
  deviations?: DeviationDto[];
  mblHbl?: string;
  /** @format int32 */
  shipmentCount?: number;
  shipmentNumber?: string;
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'OPERATION_DISPUTE_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PROCUREMENT_DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  /** @uniqueItems true */
  permissions?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  invoiceStatusOverview?: InvoiceStatusOverviewDto;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
}

export interface InvoiceStatusOverviewDto {
  overview?: string;
  details?: string[];
}

export interface LegDetail {
  type?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  transportMedium?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  paymentCurrency?: string;
  paymentCurrencyExchangeRate?: number;
  legCurrency?: string;
  legAmountAfterTax?: number;
  paymentAmountAfterTax?: number;
  legDeviationAmountAfterTax?: number;
  paymentDeviationAmountAfterTax?: number;
  co2Emission?: number;
  invoices?: InvoiceDto[];
}

export interface LegTransportDetailDto {
  startLocation?: string;
  /** @format date */
  startDate?: string;
  transportMedium?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  deliveryLocation?: string;
  /** @format date */
  deliveryDate?: string;
}

export interface ResponseDtoInvoiceDetailDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDetailDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceTypeDto {
  name?: string;
  importExportType?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  approval?: boolean;
}

export interface ShipmentContainerDto {
  /** @format int32 */
  size?: number;
  /** @format int32 */
  quantity?: number;
  type?: string;
}

export interface ShipmentDetailDto {
  /** @format int64 */
  numberOfInvoices?: number;
  shipmentAmount?: Amount;
  deviationAmount?: Amount;
  /** @format int32 */
  containersCount?: number;
  containers?: ShipmentContainerDto[];
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  serviceType?: ServiceTypeDto;
  numberOfPackagesDescription?: string;
  shipmentNumber?: string;
  shipper?: string;
  placeToPickup?: string;
  consignee?: string;
  deliveryPlace?: string;
  totalGrossWeight?: number;
  totalChargeableWeight?: number;
  volume?: number;
  totalCo2Emission?: number;
}

export interface ShipmentTransportDetailDto {
  legOne?: LegTransportDetailDto;
  legTwo?: LegTransportDetailDto;
  legThree?: LegTransportDetailDto;
}

export interface DeviationQuestionUserResponseDto {
  /** @format uuid */
  questionId?: string;
  questionType?: 'TEXT' | 'SELECT' | 'RADIO' | 'DATE' | 'DATE_RANGE' | 'DATE_TIME' | 'DATE_TIME_RANGE' | 'MULTIPLE_SELECT';
  questionName?: string;
  /** @format uuid */
  answerOptionId?: string;
  answerText?: string;
  answerType?: 'YES' | 'NO' | 'RECURRING' | 'ONE_OFF' | 'YES_BUT_WITH_CHANGE_RECURRENCE' | 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE' | 'NOT_REQUIRED' | 'OTHER';
  answerName?: string;
  approvalType?: 'ACCESSORIAL_CHARGE' | 'SERVICE_TYPE';
}

export interface ResponseDtoListDeviationQuestionUserResponseDto {
  success?: boolean;
  message?: string;
  data?: DeviationQuestionUserResponseDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceDisputeUpdateRequestDto {
  /** @format uuid */
  id?: string;
  lumpSumAmount?: number;
  resolutionDescription?: string;
  agreementResolution?: 'ACCEPT_INVOICE_AS_IS' | 'AMEND_INVOICE_CREDIT_NOTE' | 'REJECT_INVOICE' | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  deviationResolutions?: DisputeDeviationResolutionDto[];
}

export interface ResponseDtoInvoiceDisputeDto {
  success?: boolean;
  message?: string;
  data?: InvoiceDisputeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreditNoteDto {
  totalCreditAmountAfterTax?: number;
  currencyExchangeRates?: Record<string, number>;
  creditNoteNumber: string;
}

export interface ResponseDtoCreditNoteDto {
  success?: boolean;
  message?: string;
  data?: CreditNoteDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UploadCreditNoteUrlRequestDto {
  creditNoteFileUrl: string;
}

export interface ResponseDtoCreditNoteFileDto {
  success?: boolean;
  message?: string;
  data?: CreditNoteFileDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceFileProcessingStatusUpdateReq {
  message?: string;
  dataPipelineConfigId?: string;
  modality?: string;
  fileLink?: string;
}

export interface InvoiceFileProcessingDto {
  /** @format uuid */
  id?: string;
  fileName?: string;
  /** @format uuid */
  invoiceDataPipelineConfigId?: string;
  status?:
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'NOT_AN_INVOICE';
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  message?: string;
  /** @format uuid */
  batchId?: string;
  fileLink?: string;
}

export interface ResponseDtoInvoiceFileProcessingDto {
  success?: boolean;
  message?: string;
  data?: InvoiceFileProcessingDto;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationQuestionnaireQuestionUserResponseDto {
  questionnaireAnswer?: DeviationQuestionUserResponseDto[];
  newChargeAnswer?: LegChargeDto;
}

export interface LegChargeDto {
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
}

export interface UpdatingContractReq {
  /** @format uuid */
  contractFlowId: string;
  code?: string;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  filePath?: string;
}

export interface ContractDto {
  /** @format uuid */
  id?: string;
  /** @format int64 */
  serviceProviderId?: number;
  active?: boolean;
  filePath?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
  code?: string;
  /** @format uuid */
  contractFlowId?: string;
}

export interface ResponseDtoContractDto {
  success?: boolean;
  message?: string;
  data?: ContractDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreatingAndUpdatingContractFlowReq {
  /** @format uuid */
  shippingServiceId: string;
  /** @format uuid */
  contractId: string;
  code?: string;
  leg1Currency?: string;
  leg2Currency?: string;
  leg3Currency?: string;
}

export interface ContractFlowInfoViewDto {
  /** @format uuid */
  id?: string;
  code?: string;
  /** @format uuid */
  contractId?: string;
  /** @format uuid */
  shippingServiceId?: string;
  active?: boolean;
  leg1Currency?: string;
  leg2Currency?: string;
  leg3Currency?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
  containerCharges?: ContainerChargeDto[];
  percentageCharges?: PercentageChargeDto[];
  shipmentCharges?: ShipmentChargeDto[];
  declarationCharges?: DeclarationChargeDto[];
  weightCharges?: WeightChargeDto[];
}

export interface ResponseDtoContractFlowInfoViewDto {
  success?: boolean;
  message?: string;
  data?: ContractFlowInfoViewDto;
  /** @format int32 */
  statusCode?: number;
}

export interface AddingOrRemovingRateCardToContractFlowReq {
  /** @format uuid */
  id: string;
  contractChargeUnit: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
}

export interface ChartReportRequestDto {
  /** @format uuid */
  id?: string;
  name?: string;
  description?: string;
  hashedFilters?: string;
}

export interface ChartReportDto {
  /** @format uuid */
  id?: string;
  name?: string;
  description?: string;
  hashedFilters?: string;
  /** @format date-time */
  updateDate?: string;
  createdBy?: string;
}

export interface ResponseDtoChartReportDto {
  success?: boolean;
  message?: string;
  data?: ChartReportDto;
  /** @format int32 */
  statusCode?: number;
}

export interface MigrationBatchSizeRequest {
  /** @format int32 */
  size?: number;
}

export interface UserCreateRequestDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  cognitoId?: string;
  active?: boolean;
  /** @uniqueItems true */
  roles?: ('OPERATION' | 'PROCUREMENT' | 'FINANCE' | 'ADMIN')[];
  legalEntityCode?: string;
}

export interface ResponseDtoUserDto {
  success?: boolean;
  message?: string;
  data?: UserDto;
  /** @format int32 */
  statusCode?: number;
}

export interface UserDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  cognitoId?: string;
  active?: boolean;
  /** @uniqueItems true */
  roles?: ('OPERATION' | 'PROCUREMENT' | 'FINANCE' | 'ADMIN')[];
  legalEntity?: LegalEntityDto;
  userPermissionList?: UserPermissionDto[];
}

export interface DeviationQuestionCreateRequest {
  deviationTypes?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
}

export interface DeviationQuestionAnswerOptionDto {
  /** @format uuid */
  id?: string;
  name?: string;
  /** @format int32 */
  displayOrder?: number;
  type?: 'YES' | 'NO' | 'RECURRING' | 'ONE_OFF' | 'YES_BUT_WITH_CHANGE_RECURRENCE' | 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE' | 'NOT_REQUIRED' | 'OTHER';
  nextQuestions?: DeviationQuestionDto[];
}

export interface DeviationQuestionDto {
  /** @format uuid */
  id?: string;
  name?: string;
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  questionType?: 'TEXT' | 'SELECT' | 'RADIO' | 'DATE' | 'DATE_RANGE' | 'DATE_TIME' | 'DATE_TIME_RANGE' | 'MULTIPLE_SELECT';
  questionDomainType?:
    | 'WAS_SHIPMENT_SPECIAL_INSTRUCTION_GIVEN'
    | 'WHAT_WAS_THE_SHIPMENT_SPECIAL_INSTRUCTION'
    | 'IS_THIS_RECURRING_CHARGE'
    | 'IS_THIS_DUE_TO_MARKET_CONDITION'
    | 'WHAT_IS_THE_NEW_MARKET_CONDITION'
    | 'PERIOD_OF_EXPECTED_RECURRENCE'
    | 'DO_YOU_ACCEPT_THIS_CHARGE_AS_IS'
    | 'CHARGE_REJECTED_REASON_TEXT'
    | 'DO_YOU_ACCEPT_THIS_QUANTITY'
    | 'WHY_DO_YOU_ACCEPT_THIS_QUANTITY'
    | 'WHY_DO_YOU_REJECT_THIS_QUANTITY'
    | 'IS_THERE_REASON_FOR_DOUBLE_CHARGE'
    | 'WHAT_IS_THE_REASON_FOR_DOUBLE_CHARGE'
    | 'DO_YOU_ACCEPT_THIS_DOUBLE_CHARGE'
    | 'WHY_DO_YOU_REJECT_THIS_DOUBLE_CHARGE'
    | 'DO_YOU_ACCEPT_THIS_DEVIATION_FROM_OPERATION'
    | 'WHY_DO_YOU_ACCEPT_THIS_DEVIATION'
    | 'WHY_DO_YOU_ACCEPT_THIS_DEVIATION_WITH_RECURRENCE_CHANGE'
    | 'WHY_DO_YOU_REJECT_THIS_CHARGE'
    | 'MODIFY_RECURRENCE'
    | 'WHY_DO_YOU_REJECT_THIS_DEVIATION'
    | 'CHANGE_LEG_OF_INVOICE_CHARGE'
    | 'DO_YOU_HAVE_ALL_REQUIRED_APPROVALS_FOR_THE_ACCESSORIAL_CHARGES'
    | 'DO_YOU_HAVE_ALL_THE_REQUIRED_APPROVALS_FOR_THIS_SERVICE'
    | 'DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_IT'
    | 'WHY_DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_ONE_OFF'
    | 'WHY_DO_YOU_REJECT_THIS_CHARGE_AS_IT'
    | 'WHY_DO_YOU_ACCEPT_THIS_NEW_CHARGE_AS_RECURRING'
    | 'WHAT_ARE_YOU_MISSING_FOR_THE_APPROVAL_OF_THE_ACCESSORIAL_CHARGES'
    | 'WHAT_ARE_YOU_MISSING_FOR_THE_APPROVAL_OF_THIS_SERVICE'
    | 'WHY_IS_NOT_REQUIRED'
    | 'SELECT_A_CHARGE_NAME';
  approvalType?: 'ACCESSORIAL_CHARGE' | 'SERVICE_TYPE';
  answerOptions?: DeviationQuestionAnswerOptionDto[];
}

export interface ResponseDtoListMapDeviationWorkflowListDeviationQuestionDto {
  success?: boolean;
  message?: string;
  data?: Record<string, DeviationQuestionDto[]>[];
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationCreateRequest {
  /** @format uuid */
  chargeId?: string;
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
}

export interface Deviation {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  chargeId?: string;
  active?: boolean;
  type?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  subType?: 'CURRENCY_NOT_MATCHED';
  status?: 'OPERATION_UNFILLED' | 'OPERATION_REJECTED' | 'OPERATION_APPROVED' | 'PROCUREMENT_REJECTED' | 'PROCUREMENT_APPROVED';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  chargeDescription?: string;
  deviatedCurrency?: string;
  contractChargeUnit?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  invoiceChargeUnit?: string;
  contractUnitPrice?: number;
  invoiceUnitPrice?: number;
  deviationAmountPerUnit?: number;
  taxPercentage?: number;
  exchangeRates?: number;
  deviationAmountBeforeTax?: number;
  deviationAmountAfterTax?: number;
  invoiceQuantity?: number;
  deviationQuantity?: number;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
}

export interface ResponseDtoDeviation {
  success?: boolean;
  message?: string;
  data?: Deviation;
  /** @format int32 */
  statusCode?: number;
}

export interface Co2Emission {
  total?: number;
  wtt?: number;
  ttw?: number;
  intensity?: number;
}

export interface Details {
  vessel?: Vessel;
  fuelType?: string;
}

export interface Leg {
  co2e?: Co2Emission;
  metadata?: Record<string, string>;
}

export interface Order {
  type?: string;
  /** @format int32 */
  quantity?: number;
  sizeTypeCode?: string;
  weight?: number;
}

export interface Parameters {
  orders?: Order[];
  details?: Details;
}

export interface ResponseDtoSeaRoutesResponseDto {
  success?: boolean;
  message?: string;
  data?: SeaRoutesResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface SeaRoutesError {
  /** @format int64 */
  timestamp?: number;
  /** @format int32 */
  status?: number;
  error?: string;
  errorCodes?: string[];
  messages?: string[];
}

export interface SeaRoutesResponse {
  parameters?: Parameters;
  co2e?: Co2Emission;
  legs?: Leg[];
}

export interface SeaRoutesResponseDto {
  seaRoutesResponse?: SeaRoutesResponse;
  seaRoutesError?: SeaRoutesError;
}

export interface Vessel {
  name?: string;
}

export interface BillingFiltersDto {
  /** @uniqueItems true */
  legType?: string[];
  /** @uniqueItems true */
  chargeDomain?: string[];
}

export interface DateFiltersDto {
  /** @uniqueItems true */
  years?: string[];
  /** @uniqueItems true */
  quarters?: string[];
  /** @uniqueItems true */
  months?: string[];
  /** @uniqueItems true */
  weeks?: string[];
  /** @uniqueItems true */
  days?: string[];
}

export interface FiltersDto {
  dateFilters?: DateFiltersDto;
  shipmentFilters?: ShipmentFiltersDto;
  tradeLaneFilters?: TradeLaneFiltersDto;
  billingFilters?: BillingFiltersDto;
  serviceProviders?: ServiceProviderDto[];
}

export interface FiltersRequestDto {
  currencyReference?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  invoiceNumber?: string;
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE';
  filters?: FiltersDto;
  /** @uniqueItems true */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
}

export interface PageDto {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
}

export interface PerformanceAssessmentKeyMetricRequestDto {
  filtersRequest?: FiltersRequestDto;
  serviceProvider?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
}

export interface PerformanceAssessmentVolumetricTableRequestDto {
  performanceAssessmentRequest?: PerformanceAssessmentKeyMetricRequestDto;
  page?: PageDto;
  sort?: VolumetricSortDto;
}

export interface ServiceProviderDto {
  serviceProviderName?: string;
  serviceProviderCode?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
}

export interface ShipmentFiltersDto {
  /** @uniqueItems true */
  serviceProviderName?: string[];
  /** @uniqueItems true */
  serviceProviderCode?: string[];
  /** @uniqueItems true */
  modality?: string[];
  /** @uniqueItems true */
  vehicleID?: string[];
}

export interface TradeLaneFiltersDto {
  /** @uniqueItems true */
  tradeLaneCompany?: string[];
  /** @uniqueItems true */
  tradeLaneCityHub?: string[];
  /** @uniqueItems true */
  tradeLaneCountry?: string[];
}

export interface VolumetricSortDto {
  field?:
    | 'VOLUMETRIC_MONTH'
    | 'VOLUMETRIC_TOTAL_AMOUNT'
    | 'GROSS_WEIGHT'
    | 'CHARGEABLE_WEIGHT'
    | 'VOLUME'
    | 'CO2_EMISSIONS'
    | 'VOLUMETRIC_SHIPMENTS_COUNT'
    | 'VOLUMETRIC_AUDITED';
  direction?: 'ASC' | 'DESC';
}

export interface PerformanceVolumetricTableDto {
  month?: string;
  totalAmount?: number;
  grossWeight?: number;
  chargeableWeight?: number;
  volume?: number;
  co2Emissions?: number;
  /** @format int64 */
  shipmentsCount?: number;
  audited?: number;
}

export interface PerformanceVolumetricTableResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  volumetricMonthWiseData?: PerformanceVolumetricTableDto[];
}

export interface ResponseDtoPerformanceVolumetricTableResponseDto {
  success?: boolean;
  message?: string;
  data?: PerformanceVolumetricTableResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoVolumetricResponseDto {
  success?: boolean;
  message?: string;
  data?: VolumetricResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface VolumetricAmount {
  totalValue?: Amount;
  pricePerUnit?: Amount;
  unitPerShipment?: Amount;
}

export interface VolumetricBigDecimal {
  totalValue?: number;
  pricePerUnit?: number;
  unitPerShipment?: number;
}

export interface VolumetricResponseDto {
  totalCo2Emissions?: VolumetricBigDecimal;
  chargeableWeight?: VolumetricBigDecimal;
  grossWeight?: VolumetricBigDecimal;
  volume?: VolumetricBigDecimal;
  askedAmount?: VolumetricAmount;
}

export interface PerformanceAssessmentSavingsTableRequestDto {
  performanceAssessmentRequest?: PerformanceAssessmentKeyMetricRequestDto;
  page?: PageDto;
  sort?: SavingsSortDto;
}

export interface SavingsSortDto {
  field?: 'MONTH' | 'TOTAL_AMOUNT' | 'DEVIATIONS' | 'SAVINGS' | 'SHIPMENTS_COUNT' | 'INVOICES_COUNT' | 'CHARGE_LINES_COUNT' | 'AUDITED';
  direction?: 'ASC' | 'DESC';
}

export interface PerformanceSavingsTableDto {
  month?: string;
  totalAmount?: number;
  deviations?: number;
  savings?: number;
  /** @format int64 */
  shipmentsCount?: number;
  /** @format int64 */
  invoicesCount?: number;
  /** @format int64 */
  chargeLinesCount?: number;
  audited?: number;
}

export interface PerformanceSavingsTableResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  savingsMonthWiseData?: PerformanceSavingsTableDto[];
}

export interface ResponseDtoPerformanceSavingsTableResponseDto {
  success?: boolean;
  message?: string;
  data?: PerformanceSavingsTableResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoSavingsMetricResponseDto {
  success?: boolean;
  message?: string;
  data?: SavingsMetricResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface SavingMetricAmountBigDecimal {
  beforeAudit?: Amount;
  afterAudit?: Amount;
  deviations?: Amount;
  savings?: Amount;
  amountPercentage?: number;
  deviationPercentage?: number;
  savingsPercentage?: number;
}

export interface SavingMetricLongBigDecimal {
  /** @format int64 */
  beforeAudit?: number;
  /** @format int64 */
  afterAudit?: number;
  /** @format int64 */
  deviations?: number;
  /** @format int64 */
  savings?: number;
  amountPercentage?: number;
  deviationPercentage?: number;
  savingsPercentage?: number;
}

export interface SavingsMetricResponseDto {
  amount?: SavingMetricAmountBigDecimal;
  shipments?: SavingMetricLongBigDecimal;
  invoices?: SavingMetricLongBigDecimal;
  chargeLines?: SavingMetricLongBigDecimal;
}

export interface KeyMetricsAggregatedDto {
  totalAmount?: Amount;
  totalDeviationAmount?: Amount;
  totalCo2Emissions?: number;
  chargeableWeight?: number;
  grossWeight?: number;
  volume?: number;
  /** @format int64 */
  noShipments?: number;
  /** @format int64 */
  noCharges?: number;
  /** @format int64 */
  noInvoices?: number;
}

export interface KeyMetricsMetaDto {
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  grossWeightUnit?: string;
  chargeableWeightUnit?: string;
  co2EmissionUnit?: string;
  volumeUnit?: string;
}

export interface KeyMetricsResponseDto {
  keymetrics?: KeyMetricsAggregatedDto;
  meta?: KeyMetricsMetaDto;
}

export interface ResponseDtoKeyMetricsResponseDto {
  success?: boolean;
  message?: string;
  data?: KeyMetricsResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ExistingShippingServiceDto {
  /** @format uuid */
  contractFlowId?: string;
  pol: string;
  pod: string;
  shipperName?: string;
  consigneeName?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  service?: string;
  type?: string;
  carrier?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
}

export interface ResponseDtoMapStringBoolean {
  success?: boolean;
  message?: string;
  data?: Record<string, boolean>;
  /** @format int32 */
  statusCode?: number;
}

export interface ContractChargeListsDto {
  containerCharges?: ContainerChargeDto[];
  shipmentDeclarationCharges?: MergedShipmentDeclarationChargeDto[];
  weightCharges?: WeightChargeDto[];
  percentageCharges?: PercentageChargeDto[];
}

export interface MatchingServiceDto {
  pol?: string;
  pod?: string;
  shipperName?: string;
  consigneeName?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  service?: string;
  type?: string;
  carrier?: string;
  incoterm?: 'EXW' | 'FCA' | 'FAS' | 'FOB' | 'CPT' | 'CIP' | 'CFR' | 'CIF' | 'DAP' | 'DPU' | 'DDP';
  /** @format date */
  estimatedDepartureDate?: string;
}

export interface MergedShipmentDeclarationChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
  unitPrice?: number;
  currency?: string;
}

export interface NoContractDraftData {
  /** @format int32 */
  currentStep?: number;
  unrecognisedShippingService?: MatchingServiceDto;
  partialMatchingServices?: PartialMatchingServiceDto[];
  selectedPartialMatchingService?: PartialMatchingServiceDto;
  selectedResolutionOption?: string;
  contractCharges?: ContractChargeListsDto;
  rejectedReason?: string;
}

export interface NoContractDraftRequestDto {
  data?: NoContractDraftData;
}

export interface PartialMatchingServiceDto {
  existingShippingService?: ExistingShippingServiceDto;
  mismatchedFields?: string[];
}

export interface NoContractDraft {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  userId?: string;
  /** @format uuid */
  invoiceId?: string;
  data?: NoContractDraftData;
  status?: 'ACTIVE' | 'DISCARDED';
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
  updatedBy?: string;
}

export interface ResponseDtoNoContractDraft {
  success?: boolean;
  message?: string;
  data?: NoContractDraft;
  /** @format int32 */
  statusCode?: number;
}

export interface NewShippingServiceAndContractRequestDto {
  newShippingService?: ExistingShippingServiceDto;
  contractCharges?: ContractChargeListsDto;
}

export interface InvoiceSearchRequestDto {
  invoiceNumber?: string;
  invoiceStatusCategory?: 'ALL' | 'ACTION_NEEDED';
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE';
  searchFilters?: FiltersDto;
  /** @uniqueItems true */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
  page?: PageDto;
  sorting?: SortDto;
}

export interface SortDto {
  field?: 'INVOICE_STATUS' | 'INVOICE_NUMBER' | 'INVOICE_DATE' | 'INVOICE_PAYMENT_DATE' | 'POL' | 'POD' | 'MBL_HBL';
  sortingDirection?: 'ASC' | 'DESC';
}

export interface InvoiceSearchResponseDto {
  stats?: InvoiceStatusCategoryStatsDto;
  invoiceStatusCategory?: 'ALL' | 'ACTION_NEEDED';
  /** @format int64 */
  totalRecord?: number;
  page?: PageDto;
  invoices?: InvoiceDto[];
}

export interface InvoiceStatusCategoryStatsDto {
  /** @format int64 */
  actionNeeded?: number;
  /** @format int64 */
  all?: number;
}

export interface ResponseDtoInvoiceSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceUploadSignedUrlRequestDto {
  /** @uniqueItems true */
  fileNames: string[];
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  serviceProviderName?: string;
  serviceProviderCode?: string;
  /** @format uuid */
  batchId?: string;
}

export interface InvoiceUploadSignedUrlDto {
  fileName?: string;
  preSignedURLs?: string;
  headers?: Record<string, string>;
  /** @format uuid */
  dataPipelineProcessingId?: string;
}

export interface InvoiceUploadSignedUrlResponseDto {
  uploadUrls?: InvoiceUploadSignedUrlDto[];
  /** @format uuid */
  batchId?: string;
}

export interface ResponseDtoInvoiceUploadSignedUrlResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceUploadSignedUrlResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceAttachmentUploadSignedUrlRequestDto {
  /** @format uuid */
  invoiceId: string;
  fileName: string;
  attachmentType: 'APPROVAL' | 'RATE_CARD' | 'ADDITIONAL_ATTACHMENT';
}

export interface SetFapRefRequest {
  invoiceIds?: string[];
  fapRef?: string;
}

export interface SearchingInvoiceFileProcessingRequest {
  ids?: string[];
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  statuses?: (
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'NOT_AN_INVOICE'
  )[];
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
}

export interface PageInvoiceFileProcessingDto {
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  size?: number;
  content?: InvoiceFileProcessingDto[];
  /** @format int32 */
  number?: number;
  sort?: SortObject[];
  pageable?: PageableObject;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  sort?: SortObject[];
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface ResponseDtoPageInvoiceFileProcessingDto {
  success?: boolean;
  message?: string;
  data?: PageInvoiceFileProcessingDto;
  /** @format int32 */
  statusCode?: number;
}

export interface SortObject {
  direction?: string;
  nullHandling?: string;
  ascending?: boolean;
  property?: string;
  ignoreCase?: boolean;
}

export interface ResponseDtoFiltersDto {
  success?: boolean;
  message?: string;
  data?: FiltersDto;
  /** @format int32 */
  statusCode?: number;
}

export interface CreatingContractReq {
  /** @format uuid */
  shippingServiceId: string;
  code?: string;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  filePath?: string;
}

export interface AnalyticSearchRequestDto {
  currencyReference?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  granularity:
    | 'QUARTERLY'
    | 'MONTHLY'
    | 'WEEKLY'
    | 'DAILY'
    | 'YEARLY'
    | 'SERVICE_PROVIDER_NAME'
    | 'SERVICE_PROVIDER_CODE'
    | 'MODALITY'
    | 'VEHICLE_ID'
    | 'COUNTRY'
    | 'CITY_HUB'
    | 'COMPANY'
    | 'LEG_TYPE'
    | 'CHARGE_DOMAIN';
  sortingOrder?: 'ASC' | 'DESC';
  dateReference?: 'PICKUP_DATE' | 'DELIVERY_DATE' | 'INVOICE_DATE' | 'INVOICE_DUE_DATE';
  filters?: FiltersDto;
  /** @uniqueItems true */
  invoiceStatusSet?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
}

export interface ShipmentSearchRequestDto {
  sorting?: ShipmentSortDto;
  page?: PageDto;
  searchRequest?: AnalyticSearchRequestDto;
}

export interface ShipmentSortDto {
  field?:
    | 'SHIPMENT_NUMBER'
    | 'DEPARTURE_DATE'
    | 'ARRIVAL_DATE'
    | 'INVOICE_COUNT'
    | 'INVOICE_CHARGES_COUNT'
    | 'TOTAL_AMOUNT'
    | 'GROSS_WEIGHT'
    | 'CHARGEABLE_WEIGHT'
    | 'VOLUME'
    | 'CO2_EMISSION'
    | 'SERVICE_PROVIDER'
    | 'MODALITY'
    | 'VEHICLE_ID'
    | 'SHIPPER_NAME'
    | 'POL'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'POD'
    | 'CONSIGNEE_COUNTRY'
    | 'GOOD_NAME'
    | 'PACKAGE_COUNT'
    | 'PACKAGE_UNIT';
  direction?: 'ASC' | 'DESC';
}

export interface ResponseDtoShipmentSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: ShipmentSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ShipmentDto {
  /** @format uuid */
  invoiceId?: string;
  shipmentNumber?: string;
  departureDate?: string;
  arrivalDate?: string;
  /** @format int32 */
  invoiceCount?: number;
  /** @format int32 */
  invoiceChargesCount?: number;
  totalAmount?: number;
  totalAmountCurrency?: string;
  invoiceAmountAfterTax?: number;
  invoiceCurrency?: string;
  grossWeight?: number;
  chargeableWeight?: number;
  volume?: number;
  co2Emission?: number;
  serviceProvider?: string;
  modality?: string;
  vehicleId?: string;
  shipper?: string;
  pol?: string;
  shipperCountry?: string;
  consignee?: string;
  pod?: string;
  consigneeCountry?: string;
  goodsName?: string;
  packageCount?: string;
  packageUnit?: string;
}

export interface ShipmentSearchResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  shipments?: ShipmentDto[];
}

export interface InvoiceAnalyticSearchRequestDto {
  sorting?: InvoiceSortDto;
  page?: PageDto;
  searchRequest?: AnalyticSearchRequestDto;
}

export interface InvoiceSortDto {
  field?:
    | 'INVOICE_NUMBER'
    | 'INVOICE_DATE'
    | 'INVOICE_DUE_DATE'
    | 'SHIPMENT_COUNT'
    | 'INVOICE_CHARGES_COUNT'
    | 'BILLED_ENTITY_NAME'
    | 'INVOICE_STATUS'
    | 'TOTAL_AMOUNT'
    | 'SERVICE_PROVIDER_NAME'
    | 'SHIPPER_NAME'
    | 'MODALITY'
    | 'POL'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'CONSIGNEE_COUNTRY'
    | 'POD';
  direction?: 'ASC' | 'DESC';
}

export interface InvoiceAnalyticsDto {
  invoiceNumber?: string;
  shipmentNumber?: string;
  invoiceDate?: string;
  invoiceDueDate?: string;
  /** @format int32 */
  shipmentCount?: number;
  /** @format int32 */
  invoiceChargesCount?: number;
  billedEntity?: string;
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  totalAmount?: number;
  invoiceCurrency?: string;
  totalAmountInReferenceCurrency?: number;
  invoiceReferenceCurrency?: string;
  serviceProvider?: string;
  modality?: string;
  shipper?: string;
  pol?: string;
  shipperCountry?: string;
  consignee?: string;
  pod?: string;
  consigneeCountry?: string;
  goodsPoRef?: string;
  fapRef?: string;
  /** @format uuid */
  invoiceId?: string;
}

export interface InvoiceAnalyticsSearchResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  invoices?: InvoiceAnalyticsDto[];
}

export interface ResponseDtoInvoiceAnalyticsSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceAnalyticsSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceChargeSearchRequestDto {
  sorting?: InvoiceChargeSortDto;
  page?: PageDto;
  searchRequest?: AnalyticSearchRequestDto;
}

export interface InvoiceChargeSortDto {
  field?:
    | 'INVOICE_NUMBER'
    | 'SHIPMENT_NUMBER'
    | 'INVOICE_DATE'
    | 'PICKUP_DATE'
    | 'CHARGE_DOMAIN'
    | 'CHARGE_NAME'
    | 'CHARGE_AMOUNT'
    | 'INVOICE_CURRENCY'
    | 'CHARGE_QUANTITY'
    | 'CHARGE_UNIT_PRICE'
    | 'CHARGE_CURRENCY'
    | 'EXCHANGE_RATE'
    | 'SERVICE_PROVIDER_NAME'
    | 'SHIPPER_NAME'
    | 'MODALITY'
    | 'POL'
    | 'POD'
    | 'SHIPPER_COUNTRY'
    | 'CONSIGNEE_NAME'
    | 'CONSIGNEE_COUNTRY';
  direction?: 'ASC' | 'DESC';
}

export interface InvoiceChargeDto {
  /** @format uuid */
  invoiceId?: string;
  /** @format uuid */
  invoiceChargeId?: string;
  shipmentNumber?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  pickupDate?: string;
  chargeDomain?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  chargeName?: string;
  chargeUnitPrice?: number;
  /** @format int32 */
  chargeQuantity?: number;
  quantityUnit?: string;
  chargeAmount?: number;
  chargeCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  chargeAmountInInvoiceCurrency?: number;
  invoiceCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  chargeAmountInReferenceCurrency?: number;
  referenceCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  exchangeRate?: number;
  serviceProvider?: string;
  shipper?: string;
  shipperCountry?: string;
  consignee?: string;
  consigneeCountry?: string;
  modality?: string;
  pol?: string;
  pod?: string;
}

export interface InvoiceChargeSearchResponseDto {
  page?: PageDto;
  /** @format int64 */
  total?: number;
  invoiceCharges?: InvoiceChargeDto[];
}

export interface ResponseDtoInvoiceChargeSearchResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceChargeSearchResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface AggregatedDto {
  aggregatedUnit?: string;
  totalAmount?: number;
  totalCo2Emissions?: number;
  chargeableWeight?: number;
  grossWeight?: number;
  volume?: number;
  /** @format int64 */
  noShipments?: number;
  /** @format int64 */
  noCharges?: number;
  /** @format int64 */
  noInvoices?: number;
}

export interface ChartResponseDto {
  aggregated?: AggregatedDto[];
  dateRange?: RangeDto;
  meta?: MetaDto;
}

export interface MetaDto {
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  grossWeightUnit?: string;
  chargeableWeightUnit?: string;
  co2EmissionUnit?: string;
  volumeUnit?: string;
}

export interface RangeDto {
  from?: string;
  to?: string;
}

export interface ResponseDtoChartResponseDto {
  success?: boolean;
  message?: string;
  data?: ChartResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ExchangeRateAmountReCalculationRequest {
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  invoiceIds?: string[];
}

export interface ResponseDtoListUserDto {
  success?: boolean;
  message?: string;
  data?: UserDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListShippingServiceDto {
  success?: boolean;
  message?: string;
  data?: ShippingServiceDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceStatusCountDto {
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @format int64 */
  count?: number;
}

export interface ResponseDtoListServiceProviderDetailsDto {
  success?: boolean;
  message?: string;
  data?: ServiceProviderDetailsDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceProviderDetailsDto {
  serviceProviderName?: string;
  serviceProviderCode?: string;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  /** @format int64 */
  id?: number;
  invoiceStatuses?: InvoiceStatusCountDto[];
  /** @format date-time */
  latestInvoiceCreatedDate?: string;
}

export interface ResponseDtoListServiceProviderOverViewDto {
  success?: boolean;
  message?: string;
  data?: ServiceProviderOverViewDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ServiceProviderOverViewDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  parentServiceProviderId?: number;
  name?: string;
  code?: string;
  active?: boolean;
  email?: string;
}

export interface InvoiceUploadTemplateDto {
  /** @format uuid */
  id?: string;
  name?: string;
}

export interface InvoiceUploadTemplateResponseDto {
  /** @uniqueItems true */
  templates?: InvoiceUploadTemplateDto[];
}

export interface ResponseDtoInvoiceUploadTemplateResponseDto {
  success?: boolean;
  message?: string;
  data?: InvoiceUploadTemplateResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ContractChargeDto {
  /** @format uuid */
  id?: string;
  code?: string;
  chargeType?: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
  /** @format int32 */
  number?: number;
  chargeName?: string;
  chargeCategory?:
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE';
  legType?: 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
  active?: boolean;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
}

export interface ResponseDtoListContractChargeDto {
  success?: boolean;
  message?: string;
  data?: ContractChargeDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface NotificationDto {
  /** @uniqueItems true */
  serviceProviderCodes?: string[];
  /** @uniqueItems true */
  modalities?: ('ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT')[];
  /** @format int64 */
  flaggedInvoices?: number;
  /** @format int64 */
  approvedInvoices?: number;
  /** @format int64 */
  rejectedInvoices?: number;
  /** @format int64 */
  errorInvoices?: number;
  /** @format int64 */
  processingInvoices?: number;
  isRead?: boolean;
  /** @format uuid */
  batchId?: string;
  /** @format uuid */
  notificationId?: string;
  notificationStatus?: 'EMAIL_RECEIVED' | 'BATCH_PROCESSED_WITH_WARNINGS' | 'BATCH_PROCESSED';
  notificationSourceType?: 'EMAIL' | 'GENERAL_CONFIG' | 'SPECIFIC_CONFIG';
  uploadedBy?: string;
  /** @format date-time */
  uploadedOn?: string;
}

export interface NotificationResponse {
  isRead?: boolean;
  notifications?: NotificationDto[];
}

export interface ResponseDtoNotificationResponse {
  success?: boolean;
  message?: string;
  data?: NotificationResponse;
  /** @format int32 */
  statusCode?: number;
}

export interface LoggedInUserDto {
  /** @format uuid */
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  displayRole?: string;
}

export interface ResponseDtoLoggedInUserDto {
  success?: boolean;
  message?: string;
  data?: LoggedInUserDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoSetPermission {
  success?: boolean;
  message?: string;
  /** @uniqueItems true */
  data?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListLegalEntityDto {
  success?: boolean;
  message?: string;
  data?: LegalEntityDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface NoContractResponseDto {
  matchedService?: MatchingServiceDto;
  unmatchedService?: MatchingServiceDto;
  partialMatchingServices?: PartialMatchingServiceDto[];
}

export interface ResponseDtoNoContractResponseDto {
  success?: boolean;
  message?: string;
  data?: NoContractResponseDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoMapDeviationWorkflowListDeviationQuestionDto {
  success?: boolean;
  message?: string;
  data?: Record<string, DeviationQuestionDto[]>;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto {
  success?: boolean;
  message?: string;
  data?: Record<string, DeviationQuestionUserResponseDto[]>;
  /** @format int32 */
  statusCode?: number;
}

export interface DeviationActivity {
  deviationType?: 'UNIT_PRICE' | 'QUANTITY' | 'NEW_CHARGE' | 'DOUBLE_CHARGE' | 'APPROVAL_CHECK';
  chargeName?: string;
  unitPrice?: number;
  chargesCurrency?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
}

export interface EmailDeliveryHistoryDto {
  /** @format uuid */
  id?: string;
  type?: 'INVOICE_FORWARD' | 'INVOICE_REJECTION';
  subject?: string;
  /** @format date-time */
  deliveryTime?: string;
  attachmentFile?: string;
  receiver?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  /** @format date-time */
  updatedOn?: string;
}

export interface EntityActivityLogDto {
  activityType?:
    | 'INVOICE_UPLOAD'
    | 'STATUS_UPDATE'
    | 'DISPUTE_UPDATE'
    | 'CREDIT_NOTE_UPDATE'
    | 'DOCUMENT_UPDATE'
    | 'DEVIATION_UPDATE'
    | 'NO_CONTRACT_FOUND_UPDATE';
  objectStatus?:
    | 'OBJECT_UPLOADED'
    | 'OBJECT_FLAGGED'
    | 'OBJECT_APPROVED'
    | 'OBJECT_TO_APPROVE'
    | 'OBJECT_TO_REJECT'
    | 'OBJECT_PENDING_DOCUMENT'
    | 'OBJECT_REJECTED'
    | 'OBJECT_NO_CONTRACT_FOUND'
    | 'OBJECT_DOCUMENT_UPDATED';
  actionOnObject?:
    | 'UPLOADED_ON_OPERATION'
    | 'SENT_TO_OPERATION'
    | 'SENT_TO_PROCUREMENT'
    | 'SENT_TO_FINANCE'
    | 'DISPUTE_STARTED'
    | 'DISPUTE_RESOLVED'
    | 'CREDIT_NOTE_UPLOADED'
    | 'CREDIT_NOTE_DELETED'
    | 'DOCUMENT_UPLOADED'
    | 'DOCUMENT_DELETED'
    | 'MARKED_PENDING'
    | 'MARKED_TO_REJECT'
    | 'MARKED_TO_APPROVE'
    | 'MARKED_READY_FOR_PAYMENT'
    | 'REJECTED'
    | 'APPROVED'
    | 'DEVIATION_DROPPED'
    | 'DEVIATION_ADDED';
  disputeAgreementType?: 'ACCEPT_INVOICE_AS_IS' | 'AMEND_INVOICE_CREDIT_NOTE' | 'REJECT_INVOICE' | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE';
  deviationActivity?: DeviationActivity;
  actionBy?: string;
  /** @format date-time */
  createdOn?: string;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  emailDeliveryHistory?: EmailDeliveryHistoryDto;
  documentName?: string;
}

export interface ResponseDtoListEntityActivityLogDto {
  success?: boolean;
  message?: string;
  data?: EntityActivityLogDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface Attachment {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  attachmentType?: 'APPROVAL' | 'RATE_CARD' | 'ADDITIONAL_ATTACHMENT';
  filePath?: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
}

export interface ChargeDto {
  /** @format uuid */
  id?: string;
  chargeName?: string;
  quantity?: number;
  totalAmount?: number;
  totalAmountCurrency?: string;
  accessorial?: boolean;
  approval?: boolean;
  outlay?: boolean;
}

export interface CourierShipmentBasicInfoDto {
  /** @format uuid */
  shipmentId?: string;
  shipmentNumber?: string;
  /** @format int32 */
  numberOfDeviations?: number;
  status?: 'UNFILLED' | 'REJECTED' | 'APPROVED';
}

export interface InvoiceMeta {
  rejectReason?: string;
}

export interface InvoiceSummaryDto {
  /** @format uuid */
  id?: string;
  invoiceNumber?: string;
  /** @format uuid */
  invoiceDisputeId?: string;
  serviceProvider?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @uniqueItems true */
  permissions?: (
    | 'CAN_GIVE_PROCUREMENT_REVIEW'
    | 'CAN_GIVE_OPERATION_REVIEW'
    | 'CAN_GIVE_FINANCE_REVIEW'
    | 'CAN_GIVE_DISPUTE_REVIEW'
    | 'CAN_GIVE_NO_CONTRACT_REVIEW'
    | 'CAN_UPLOAD_INVOICE_FILE'
  )[];
  possibleWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  currentDeviationWorkflow?:
    | 'OPERATION_REVIEW'
    | 'OPERATION_DISPUTE_REVIEW'
    | 'PROCUREMENT_REVIEW'
    | 'PROCUREMENT_DISPUTE_REVIEW'
    | 'PROCUREMENT_NO_CONTRACT_REVIEW'
    | 'FINANCE_REVIEW';
  /** @format date */
  invoiceDate?: string;
  /** @format date */
  paymentDate?: string;
  invoicedParty?: string;
  invoiceAmount?: Amount;
  deviations?: Record<string, number>;
  invoiceAmountDeviation?: Amount;
  servicesAndShipments?: Record<string, number>;
  totalChargeableWeight?: number;
  volume?: number;
  /** @format int32 */
  packageCount?: number;
  totalCo2Emission?: number;
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  shipmentBasicInfoList?: CourierShipmentBasicInfoDto[];
  invoiceCharges?: ChargeDto[];
  invoiceAttachments?: Attachment[];
  serviceType?: ServiceTypeDto;
  meta?: InvoiceMeta;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  approvalRequired?: boolean;
  approvalTypeApprovalStatusMap?: Record<string, 'PENDING' | 'APPROVED' | 'REJECTED'>;
}

export interface ResponseDtoInvoiceSummaryDto {
  success?: boolean;
  message?: string;
  data?: InvoiceSummaryDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceFileProcessingStatusDto {
  /** @format uuid */
  invoiceId?: string;
  status?:
    | 'BEING_UPLOADED'
    | 'COMPLETE'
    | 'OCR_PROCESSING'
    | 'OCR_COMPLETE'
    | 'OCR_ERROR'
    | 'TRANSFORM_ERROR'
    | 'TRANSFORM_COMPLETE'
    | 'MESSAGE_PROCESSING_FAILED'
    | 'UPLOAD_FROM_EMAIL_FAILED'
    | 'NOT_AN_INVOICE';
  invoiceStatus?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  /** @format uuid */
  invoiceFileProcessingId?: string;
  /** @format date-time */
  updatedOn?: string;
  isNoRateCardFound?: boolean;
  fileName?: string;
  invoiceNumber?: string;
  finalResolution?:
    | 'NO_CONTRACT_REJECT'
    | 'REJECT_AFTER_DISPUTE'
    | 'UNKNOWN_BILLING_PARTY'
    | 'WRONG_BILLING_PARTY'
    | 'DUPLICATE_INVOICE'
    | 'NO_RATE_CARD_FOUND';
  modality?: 'ROAD' | 'SEA' | 'RAIL' | 'AIR' | 'COURIER' | 'CT';
  serviceProviderCode?: string;
}

export interface ResponseDtoListInvoiceFileProcessingStatusDto {
  success?: boolean;
  message?: string;
  data?: InvoiceFileProcessingStatusDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface DomainMetaDataDto {
  defaultCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW';
  currencies?: (
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
  )[];
  /** @uniqueItems true */
  resolvedInvoiceStatuses?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
  /** @uniqueItems true */
  unResolvedInvoiceStatuses?: ('FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT')[];
}

export interface ResponseDtoDomainMetaDataDto {
  success?: boolean;
  message?: string;
  data?: DomainMetaDataDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListChargeDomain {
  success?: boolean;
  message?: string;
  data?: (
    | 'COLLECTION'
    | 'CONTAINER_CLEARANCE'
    | 'CONTAINER_MAINTENANCE'
    | 'CUSTOMS_CLEARANCE'
    | 'DELIVERY'
    | 'DOCUMENTATION'
    | 'FREIGHT'
    | 'FUEL_SURCHARGE'
    | 'HANDLING_CHARGE'
    | 'INSPECTION'
    | 'OVERTIME'
    | 'PICK_UP'
    | 'PICK_UP_AND_DROP_OFF'
    | 'PORT_CHARGES'
    | 'SEAL_FEE'
    | 'SPECIAL_HANDLING'
    | 'STORAGE'
    | 'THC'
    | 'TOTAL_ORIGIN_CHARGES'
    | 'VGM_FEE'
    | 'DEFAULT_CHARGE_DOMAIN'
    | 'SCREE'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListString {
  success?: boolean;
  message?: string;
  data?: string[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListCurrencyEnum {
  success?: boolean;
  message?: string;
  data?: (
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BOV'
    | 'BRL'
    | 'BSD'
    | 'BTN'
    | 'BWP'
    | 'BYN'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHE'
    | 'CHF'
    | 'CHW'
    | 'CLF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'COU'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MXV'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SRD'
    | 'SSP'
    | 'STD'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'USN'
    | 'UYI'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XAG'
    | 'XAU'
    | 'XBA'
    | 'XBB'
    | 'XBC'
    | 'XBD'
    | 'XCD'
    | 'XDR'
    | 'XFU'
    | 'XOF'
    | 'XPD'
    | 'XPF'
    | 'XPT'
    | 'XSU'
    | 'XTS'
    | 'XUA'
    | 'XXX'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
  )[];
  /** @format int32 */
  statusCode?: number;
}

export interface CourierInvoiceDisputeDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  invoiceId?: string;
  currency?: string;
  lumpSumAmount?: number;
  lumpSumAmountBeforeTax?: number;
  expectedTotalAmount?: Amount;
  creditNoteAmount?: Amount;
  resolutionDescription?: string;
  status?: 'DRAFT' | 'PENDING_DOCUMENT' | 'DONE';
  creditNoteFile?: CreditNoteFileDto;
  shipmentDeviations?: CourierShipmentDeviationsResolutionsDto[];
  nextWorkflowAction?:
    | 'SEND_TO_OPERATION'
    | 'SEND_TO_PROCUREMENT'
    | 'SEND_TO_FINANCE'
    | 'START_DISPUTE'
    | 'MARK_INVOICE_TO_REJECT'
    | 'MARK_INVOICE_TO_APPROVE'
    | 'ACCEPT_CREDIT_NOTE'
    | 'ACCEPT_APPROVAL'
    | 'READY_FOR_PAYMENT'
    | 'REJECT_INVOICE'
    | 'ACCEPT_INVOICE';
  agreementResolution?: 'ACCEPT_INVOICE_AS_IS' | 'AMEND_INVOICE_CREDIT_NOTE' | 'REJECT_INVOICE' | 'COMMERCIAL_SETTLEMENT_CREDIT_NOTE';
  deviationResolutionType?: 'BREAK_DOWN_PER_DEVIATION' | 'LUMP_SUM';
  amountToBePaid?: Amount;
}

export interface CourierShipmentDeviationsResolutionsDto {
  /** @format uuid */
  shipmentId?: string;
  shipmentNumber?: string;
  /** @format int32 */
  numberOfDeviations?: number;
  deviations?: DeviationDto[];
  deviationResolution?: DisputeDeviationResolutionDto[];
  status?: 'UNFILLED' | 'REJECTED' | 'APPROVED';
}

export interface ResponseDtoCourierInvoiceDisputeDto {
  success?: boolean;
  message?: string;
  data?: CourierInvoiceDisputeDto;
  /** @format int32 */
  statusCode?: number;
}

export interface InvoiceCourierShipmentDetailDto {
  /** @format uuid */
  shipmentId?: string;
  shipmentNumber?: string;
  status?: 'FLAGGED' | 'APPROVED' | 'TO_APPROVE' | 'PENDING_DOCUMENT' | 'REJECTED' | 'TO_REJECT';
  shipmentAmount?: Amount;
  deviationAmount?: Amount;
  shippingServiceType?: string;
  shipper?: string;
  placeToPickup?: string;
  /** @format date */
  departureDate?: string;
  consignee?: string;
  deliveryPlace?: string;
  /** @format date */
  arrivalDate?: string;
  totalChargeableWeight?: number;
  /** @format int32 */
  packageCount?: number;
  volume?: number;
  totalCo2Emission?: number;
  deviations?: DeviationDto[];
}

export interface ResponseDtoInvoiceCourierShipmentDetailDto {
  success?: boolean;
  message?: string;
  data?: InvoiceCourierShipmentDetailDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListContractDto {
  success?: boolean;
  message?: string;
  data?: ContractDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoContractChargeListsDto {
  success?: boolean;
  message?: string;
  data?: ContractChargeListsDto;
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoListChartReportDto {
  success?: boolean;
  message?: string;
  data?: ChartReportDto[];
  /** @format int32 */
  statusCode?: number;
}

export interface ResponseDtoBoolean {
  success?: boolean;
  message?: string;
  data?: boolean;
  /** @format int32 */
  statusCode?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://3ekikaif3i.ap-southeast-1.awsapprunner.com',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({ secure, path, type, query, format, body, ...params }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams = ((typeof secure === 'boolean' ? secure : this.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://3ekikaif3i.ap-southeast-1.awsapprunner.com
 */
export class TFinancialApi<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateUserPermission
     * @request PUT:/api/users/permissions/{userPermissionId}
     * @response `200` `ResponseDtoUserPermissionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateUserPermission: (userPermissionId: string, data: UserPermissionDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUserPermissionDto, ResponseDtoObject>({
        path: `/api/users/permissions/${userPermissionId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteUserPermission
     * @request DELETE:/api/users/permissions/{userPermissionId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteUserPermission: (userPermissionId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/users/permissions/${userPermissionId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name UpdateInvoiceQuestionnaire
     * @request PUT:/api/test/invoices/{invoiceId}/questionnaire
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateInvoiceQuestionnaire: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/test/invoices/${invoiceId}/questionnaire`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name TestImport
     * @request PUT:/api/test/invoices/test-import
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    testImport: (data: InvoiceMessageDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/test/invoices/test-import`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name UpdateShippingService
     * @request PUT:/api/shipping-services/{id}
     * @response `200` `ResponseDtoShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateShippingService: (id: string, data: CreatingAndUpdatingShippingServiceReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name DeleteShippingService
     * @request DELETE:/api/shipping-services/{id}
     * @response `200` `ResponseDtoBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteShippingService: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoBoolean, ResponseDtoObject>({
        path: `/api/shipping-services/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertWeightCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/weight
     * @response `200` `ResponseDtoWeightChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertWeightCharge: (data: UpsertContractWeightChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoWeightChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/weight`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertShipmentCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/shipment
     * @response `200` `ResponseDtoShipmentChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertShipmentCharge: (data: UpsertContractShipmentChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipmentChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/shipment`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertPercentageCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/percentage
     * @response `200` `ResponseDtoPercentageChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertPercentageCharge: (data: UpsertContractPercentageChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPercentageChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/percentage`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertDeclarationCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/declaration
     * @response `200` `ResponseDtoDeclarationChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertDeclarationCharge: (data: UpsertContractDeclarationChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoDeclarationChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/declaration`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name UpsertContainerCharge
     * @request PUT:/api/rate-cards/contract-charge-unit/container
     * @response `200` `ResponseDtoContainerChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    upsertContainerCharge: (data: UpsertContractContainerChargeReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContainerChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards/contract-charge-unit/container`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags notification-controller
     * @name MarkNotificationAsRead
     * @request PUT:/api/notifications/mark-as-read
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    markNotificationAsRead: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/notifications/mark-as-read`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name PerformDeviationWorkflowAction
     * @request PUT:/api/invoices/{invoiceId}/{deviationWorkflowAction}
     * @response `200` `ResponseDtoInvoiceDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    performDeviationWorkflowAction: (
      invoiceId: string,
      deviationWorkflowAction:
        | 'SEND_TO_OPERATION'
        | 'SEND_TO_PROCUREMENT'
        | 'SEND_TO_FINANCE'
        | 'START_DISPUTE'
        | 'MARK_INVOICE_TO_REJECT'
        | 'MARK_INVOICE_TO_APPROVE'
        | 'ACCEPT_CREDIT_NOTE'
        | 'ACCEPT_APPROVAL'
        | 'READY_FOR_PAYMENT'
        | 'REJECT_INVOICE'
        | 'ACCEPT_INVOICE',
      data: DeviationWorkflowActionBodyDto,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceDetailDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/${deviationWorkflowAction}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetUserResponse
     * @request GET:/api/invoices/{invoiceId}/approval-questionnaire-user-response
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserResponse: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/approval-questionnaire-user-response`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name PostUserResponse
     * @request PUT:/api/invoices/{invoiceId}/approval-questionnaire-user-response
     * @response `200` `ResponseDtoListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    postUserResponse: (invoiceId: string, data: DeviationQuestionUserResponseDto[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/approval-questionnaire-user-response`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetInvoiceDisputeDetail
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDisputeDetail: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name UpdateInvoiceDispute
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateInvoiceDispute: (invoiceDisputeId: string, data: InvoiceDisputeUpdateRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name PerformDeviationWorkflowAction1
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/{deviationWorkflowAction}
     * @response `200` `ResponseDtoInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    performDeviationWorkflowAction1: (
      invoiceDisputeId: string,
      deviationWorkflowAction:
        | 'SEND_TO_OPERATION'
        | 'SEND_TO_PROCUREMENT'
        | 'SEND_TO_FINANCE'
        | 'START_DISPUTE'
        | 'MARK_INVOICE_TO_REJECT'
        | 'MARK_INVOICE_TO_APPROVE'
        | 'ACCEPT_CREDIT_NOTE'
        | 'ACCEPT_APPROVAL'
        | 'READY_FOR_PAYMENT'
        | 'REJECT_INVOICE'
        | 'ACCEPT_INVOICE',
      data: DeviationWorkflowActionBodyDto,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/${deviationWorkflowAction}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetInvoiceDisputeCreditNote
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note
     * @response `200` `ResponseDtoCreditNoteDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDisputeCreditNote: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name UpdateInvoiceDisputeCreditNote
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/credit-note
     * @response `200` `ResponseDtoCreditNoteDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateInvoiceDisputeCreditNote: (invoiceDisputeId: string, data: CreditNoteDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetUploadCreditNoteUrl
     * @request PUT:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url
     * @response `200` `ResponseDtoCreditNoteFileDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUploadCreditNoteUrl: (invoiceDisputeId: string, data: UploadCreditNoteUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCreditNoteFileDto, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name DeleteCreditNotes
     * @request DELETE:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteCreditNotes: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name UpdateStatusInvoiceFileProcessing
     * @request PUT:/api/invoice-file-processing/{invoiceFileProcessingId}/{status}
     * @response `200` `ResponseDtoInvoiceFileProcessingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateStatusInvoiceFileProcessing: (
      invoiceFileProcessingId: string,
      status:
        | 'BEING_UPLOADED'
        | 'COMPLETE'
        | 'OCR_PROCESSING'
        | 'OCR_COMPLETE'
        | 'OCR_ERROR'
        | 'TRANSFORM_ERROR'
        | 'TRANSFORM_COMPLETE'
        | 'MESSAGE_PROCESSING_FAILED'
        | 'UPLOAD_FROM_EMAIL_FAILED'
        | 'NOT_AN_INVOICE',
      data: InvoiceFileProcessingStatusUpdateReq,
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoInvoiceFileProcessingDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing/${invoiceFileProcessingId}/${status}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name GetUserResponse1
     * @request GET:/api/deviations/{id}/user-response
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserResponse1: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/user-response`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name PostUserResponse1
     * @request PUT:/api/deviations/{id}/user-response
     * @response `200` `ResponseDtoListDeviationQuestionUserResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    postUserResponse1: (id: string, data: DeviationQuestionnaireQuestionUserResponseDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListDeviationQuestionUserResponseDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/user-response`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name UpdateContract
     * @request PUT:/api/contracts/{id}
     * @response `200` `ResponseDtoContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateContract: (id: string, data: UpdatingContractReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractDto, ResponseDtoObject>({
        path: `/api/contracts/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractFlow
     * @request GET:/api/contract-flows/{contractFlowId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractFlow: (contractFlowId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name UpdateContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateContractFlow: (contractFlowId: string, data: CreatingAndUpdatingContractFlowReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name RemoveRateCardsToContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}/rate-cards/removal
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    removeRateCardsToContractFlow: (contractFlowId: string, data: AddingOrRemovingRateCardToContractFlowReq[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/rate-cards/removal`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name AddRateCardsToContractFlow
     * @request PUT:/api/contract-flows/{contractFlowId}/rate-cards/addition
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    addRateCardsToContractFlow: (contractFlowId: string, data: AddingOrRemovingRateCardToContractFlowReq[], params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/rate-cards/addition`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name GetChartReports
     * @request GET:/api/chart-report
     * @response `200` `ResponseDtoListChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getChartReports: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name UpdateChartReport
     * @request PUT:/api/chart-report
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateChartReport: (data: ChartReportRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name CreateChartReport
     * @request POST:/api/chart-report
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createChartReport: (data: ChartReportRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name StopMigration
     * @request PUT:/api/admin/recalculate-exchange-price/migration/stop
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    stopMigration: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/stop`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name GetBatchSize
     * @request GET:/api/admin/recalculate-exchange-price/migration/batch-size
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getBatchSize: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/batch-size`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name SetBatchSize
     * @request PUT:/api/admin/recalculate-exchange-price/migration/batch-size
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    setBatchSize: (
      query: {
        request: MigrationBatchSizeRequest;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration/batch-size`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CreateUser
     * @request POST:/api/users
     * @response `200` `ResponseDtoUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createUser: (data: UserCreateRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUserDto, ResponseDtoObject>({
        path: `/api/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserPermissions
     * @request GET:/api/users/permissions
     * @response `200` `ResponseDtoListUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUserPermissions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListUserDto, ResponseDtoObject>({
        path: `/api/users/permissions`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CreateUserPermission
     * @request POST:/api/users/permissions
     * @response `200` `ResponseDtoUserPermissionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createUserPermission: (data: UserPermissionDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoUserPermissionDto, ResponseDtoObject>({
        path: `/api/users/permissions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name CreateQuestionnaire
     * @request POST:/api/test/invoices/{invoiceId}/create-questionnaire
     * @response `200` `ResponseDtoListMapDeviationWorkflowListDeviationQuestionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createQuestionnaire: (invoiceId: string, data: DeviationQuestionCreateRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListMapDeviationWorkflowListDeviationQuestionDto, ResponseDtoObject>({
        path: `/api/test/invoices/${invoiceId}/create-questionnaire`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-endpoints-controller
     * @name CreateDeviation
     * @request POST:/api/test/charges/{chargeId}/create-deviation
     * @response `200` `ResponseDtoDeviation` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createDeviation: (chargeId: string, data: DeviationCreateRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoDeviation, ResponseDtoObject>({
        path: `/api/test/charges/${chargeId}/create-deviation`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name GetShippingServiceByServiceProviderAndBillingParty
     * @request GET:/api/shipping-services
     * @response `200` `ResponseDtoListShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getShippingServiceByServiceProviderAndBillingParty: (
      query: {
        /** @format int64 */
        serviceProviderId: number;
        /** @format int64 */
        billingPartyId: number;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipping-service-controller
     * @name CreateShippingService
     * @request POST:/api/shipping-services
     * @response `200` `ResponseDtoShippingServiceDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createShippingService: (data: CreatingAndUpdatingShippingServiceReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShippingServiceDto, ResponseDtoObject>({
        path: `/api/shipping-services`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shipment-controller
     * @name CalculateShipmentCo2Emission
     * @request POST:/api/shipment/{shipmentId}/calculate-co2e
     * @response `200` `ResponseDtoSeaRoutesResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    calculateShipmentCo2Emission: (shipmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoSeaRoutesResponseDto, ResponseDtoObject>({
        path: `/api/shipment/${shipmentId}/calculate-co2e`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetVolumetricTableDataByMonthWithFilter
     * @request POST:/api/performance-assessment/volumetric/per-months
     * @response `200` `ResponseDtoPerformanceVolumetricTableResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVolumetricTableDataByMonthWithFilter: (data: PerformanceAssessmentVolumetricTableRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPerformanceVolumetricTableResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/volumetric/per-months`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetVolumetricTableDataDownload
     * @request POST:/api/performance-assessment/volumetric/per-months/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVolumetricTableDataDownload: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: PerformanceAssessmentVolumetricTableRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/performance-assessment/volumetric/per-months/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetKeyMetricsVolumesWithFilter
     * @request POST:/api/performance-assessment/volumes/metric
     * @response `200` `ResponseDtoVolumetricResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsVolumesWithFilter: (data: PerformanceAssessmentKeyMetricRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoVolumetricResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/volumes/metric`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetSavingsTableDataByMonthWithFilter
     * @request POST:/api/performance-assessment/savings/per-months
     * @response `200` `ResponseDtoPerformanceSavingsTableResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getSavingsTableDataByMonthWithFilter: (data: PerformanceAssessmentSavingsTableRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPerformanceSavingsTableResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/per-months`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetSavingsTableDataDownload
     * @request POST:/api/performance-assessment/savings/per-months/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getSavingsTableDataDownload: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: PerformanceAssessmentSavingsTableRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/per-months/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags performance-assessment-controller
     * @name GetKeyMetricsSavingsWithFilter
     * @request POST:/api/performance-assessment/savings/metric
     * @response `200` `ResponseDtoSavingsMetricResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsSavingsWithFilter: (data: PerformanceAssessmentKeyMetricRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoSavingsMetricResponseDto, ResponseDtoObject>({
        path: `/api/performance-assessment/savings/metric`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags key-metrics-controller
     * @name GetKeyMetricsWithFilter
     * @request POST:/api/key-metrics/{useType}
     * @response `200` `ResponseDtoKeyMetricsResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getKeyMetricsWithFilter: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', data: FiltersRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoKeyMetricsResponseDto, ResponseDtoObject>({
        path: `/api/key-metrics/${useType}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name IsValidityPeriodValid
     * @request POST:/api/invoices/{invoiceId}/verify-validity-period
     * @response `200` `ResponseDtoMapStringBoolean` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    isValidityPeriodValid: (invoiceId: string, data: ExistingShippingServiceDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapStringBoolean, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/verify-validity-period`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name UpdateNrcfInvoices
     * @request POST:/api/invoices/{invoiceId}/nrcf-update
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    updateNrcfInvoices: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/nrcf-update`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name GetActiveNoContractDraft
     * @request GET:/api/invoices/{invoiceId}/no-contract-drafts
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getActiveNoContractDraft: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/no-contract-drafts`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name CreateOrUpdateNoContractDraft
     * @request POST:/api/invoices/{invoiceId}/no-contract-drafts
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createOrUpdateNoContractDraft: (invoiceId: string, data: NoContractDraftRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/no-contract-drafts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags no-contract-draft-controller
     * @name DiscardNoContractDraft
     * @request POST:/api/invoices/{invoiceId}/no-contract-drafts/discard
     * @response `200` `ResponseDtoNoContractDraft` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    discardNoContractDraft: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractDraft, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/no-contract-drafts/discard`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name CreateShippingServiceAndContract
     * @request POST:/api/invoices/{invoiceId}/new-service-contract
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createShippingServiceAndContract: (invoiceId: string, data: NewShippingServiceAndContractRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/new-service-contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name CalculateDeviations
     * @request POST:/api/invoices/{invoiceId}/calculate-deviations
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    calculateDeviations: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/calculate-deviations`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceList
     * @request POST:/api/invoices/search
     * @response `200` `ResponseDtoInvoiceSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceList: (data: InvoiceSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceSearchResponseDto, ResponseDtoObject>({
        path: `/api/invoices/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name GetInvoicePreSignedUrLs
     * @request POST:/api/invoices/invoice-upload-pre-signed-urls
     * @response `200` `ResponseDtoInvoiceUploadSignedUrlResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoicePreSignedUrLs: (data: InvoiceUploadSignedUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceUploadSignedUrlResponseDto, ResponseDtoObject>({
        path: `/api/invoices/invoice-upload-pre-signed-urls`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name GetAttachmentPreSignedUrLs
     * @request POST:/api/invoices/invoice-attachment-pre-signed-urls
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAttachmentPreSignedUrLs: (data: InvoiceAttachmentUploadSignedUrlRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/invoice-attachment-pre-signed-urls`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name AssignFapRef
     * @request POST:/api/invoices/fap-ref
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    assignFapRef: (data: SetFapRefRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/fap-ref`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name Query
     * @request POST:/api/invoice-file-processing/query
     * @response `200` `ResponseDtoPageInvoiceFileProcessingDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    query: (data: SearchingInvoiceFileProcessingRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoPageInvoiceFileProcessingDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing/query`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags filter-controller
     * @name GetFilters
     * @request POST:/api/filters/{useType}
     * @response `200` `ResponseDtoFiltersDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFilters: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', data: FiltersRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoFiltersDto, ResponseDtoObject>({
        path: `/api/filters/${useType}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name CreateContract
     * @request POST:/api/contracts
     * @response `200` `ResponseDtoContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createContract: (data: CreatingContractReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractDto, ResponseDtoObject>({
        path: `/api/contracts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name CreateContractFlow
     * @request POST:/api/contract-flows
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    createContractFlow: (data: CreatingAndUpdatingContractFlowReq, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchShipmentData
     * @request POST:/api/analytics/shipments-data
     * @response `200` `ResponseDtoShipmentSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchShipmentData: (data: ShipmentSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoShipmentSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/shipments-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadShipmentData
     * @request POST:/api/analytics/shipments-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadShipmentData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: ShipmentSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/shipments-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchInvoiceColumns
     * @request POST:/api/analytics/invoice-data
     * @response `200` `ResponseDtoInvoiceAnalyticsSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchInvoiceColumns: (data: InvoiceAnalyticSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceAnalyticsSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/invoice-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadInvoiceData
     * @request POST:/api/analytics/invoice-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadInvoiceData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: InvoiceAnalyticSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/invoice-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name FetchInvoiceChargeColumns
     * @request POST:/api/analytics/invoice-charges-data
     * @response `200` `ResponseDtoInvoiceChargeSearchResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    fetchInvoiceChargeColumns: (data: InvoiceChargeSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceChargeSearchResponseDto, ResponseDtoObject>({
        path: `/api/analytics/invoice-charges-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name DownloadInvoiceChargesData
     * @request POST:/api/analytics/invoice-charges-data/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    downloadInvoiceChargesData: (
      query: {
        'file-name': string;
        'file-type': 'PDF' | 'CSV' | 'EXCEL';
      },
      data: InvoiceChargeSearchRequestDto,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/analytics/invoice-charges-data/download`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytic-controller
     * @name SearchChartData
     * @request POST:/api/analytics/chart-data
     * @response `200` `ResponseDtoChartResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    searchChartData: (data: AnalyticSearchRequestDto, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartResponseDto, ResponseDtoObject>({
        path: `/api/analytics/chart-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name StartMigration
     * @request POST:/api/admin/recalculate-exchange-price
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    startMigration: (data: ExchangeRateAmountReCalculationRequest, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-provider-controller
     * @name GetServiceProviderDetails
     * @request GET:/api/service-providers
     * @response `200` `ResponseDtoListServiceProviderDetailsDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getServiceProviderDetails: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListServiceProviderDetailsDto, ResponseDtoObject>({
        path: `/api/service-providers`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-provider-controller
     * @name GetAllServiceProvider
     * @request GET:/api/service-providers/overview
     * @response `200` `ResponseDtoListServiceProviderOverViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getAllServiceProvider: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListServiceProviderOverViewDto, ResponseDtoObject>({
        path: `/api/service-providers/overview`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-provider-controller
     * @name GetInvoiceTemplates
     * @request GET:/api/service-providers/invoice-upload-templates
     * @response `200` `ResponseDtoInvoiceUploadTemplateResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceTemplates: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceUploadTemplateResponseDto, ResponseDtoObject>({
        path: `/api/service-providers/invoice-upload-templates`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags rate-card-controller
     * @name GetRateCards
     * @request GET:/api/rate-cards
     * @response `200` `ResponseDtoListContractChargeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getRateCards: (
      query: {
        contractChargeUnit: 'SHIPMENT' | 'DECLARATION' | 'CONTAINER' | 'WEIGHT' | 'PERCENTAGE';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListContractChargeDto, ResponseDtoObject>({
        path: `/api/rate-cards`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags notification-controller
     * @name GetNotifications
     * @request GET:/api/notifications
     * @response `200` `ResponseDtoNotificationResponse` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getNotifications: (
      query?: {
        /** @format date */
        viewDate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoNotificationResponse, ResponseDtoObject>({
        path: `/api/notifications`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-permission-controller
     * @name GetLoggedInUserDetails
     * @request GET:/api/logged-in-user
     * @response `200` `ResponseDtoLoggedInUserDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLoggedInUserDetails: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoLoggedInUserDto, ResponseDtoObject>({
        path: `/api/logged-in-user`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-permission-controller
     * @name GetLoggedInUserPermissions
     * @request GET:/api/logged-in-user/permissions
     * @response `200` `ResponseDtoSetPermission` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getLoggedInUserPermissions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoSetPermission, ResponseDtoObject>({
        path: `/api/logged-in-user/permissions`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags legal-entity-controller
     * @name GetListBilledEntity
     * @request GET:/api/legal-entities/billed-entities
     * @response `200` `ResponseDtoListLegalEntityDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getListBilledEntity: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListLegalEntityDto, ResponseDtoObject>({
        path: `/api/legal-entities/billed-entities`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetVersion
     * @request GET:/api/invoices/{invoiceId}/version
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getVersion: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/version`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetMatchingServicesForNoContractInvoice
     * @request GET:/api/invoices/{invoiceId}/matching-services
     * @response `200` `ResponseDtoNoContractResponseDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getMatchingServicesForNoContractInvoice: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoNoContractResponseDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/matching-services`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetFileUrl
     * @request GET:/api/invoices/{invoiceId}/file-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileUrl: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/file-url`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetFileUrlAttachment
     * @request GET:/api/invoices/{invoiceId}/file-url/{attachmentId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getFileUrlAttachment: (invoiceId: string, attachmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/file-url/${attachmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-report-controller
     * @name GetConfiguredRateCard
     * @request GET:/api/invoices/{invoiceId}/configured-rate-card
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getConfiguredRateCard: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/configured-rate-card`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetApprovalQuestionnaire
     * @request GET:/api/invoices/{invoiceId}/approval-questionnaire
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getApprovalQuestionnaire: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/approval-questionnaire`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-activity-log-controller
     * @name GetInvoiceActivityLogs
     * @request GET:/api/invoices/{invoiceId}/activity-logs
     * @response `200` `ResponseDtoListEntityActivityLogDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceActivityLogs: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListEntityActivityLogDto, ResponseDtoObject>({
        path: `/api/invoices/${invoiceId}/activity-logs`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-report-controller
     * @name GenerateInvoiceReport
     * @request GET:/api/invoices/{id}/reports/download
     * @response `200` `void` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    generateInvoiceReport: (id: string, params: RequestParams = {}) =>
      this.http.request<void, ResponseDtoObject>({
        path: `/api/invoices/${id}/reports/download`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-controller
     * @name GetInvoiceSummary
     * @request GET:/api/invoices/invoice-summary/{invoiceId}
     * @response `200` `ResponseDtoInvoiceSummaryDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceSummary: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceSummaryDto, ResponseDtoObject>({
        path: `/api/invoices/invoice-summary/${invoiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetUploadCreditNoteUrl1
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note-upload-url/{filename}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getUploadCreditNoteUrl1: (invoiceDisputeId: string, filename: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-upload-url/${filename}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-dispute-controller
     * @name GetCreditNoteFileUrl
     * @request GET:/api/invoices/dispute/{invoiceDisputeId}/credit-note-file-url
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCreditNoteFileUrl: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/dispute/${invoiceDisputeId}/credit-note-file-url`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-file-processing-controller
     * @name GetListInvoiceFileProcessing
     * @request GET:/api/invoice-file-processing
     * @response `200` `ResponseDtoListInvoiceFileProcessingStatusDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getListInvoiceFileProcessing: (
      query?: {
        invoiceIds?: string[];
        batchId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListInvoiceFileProcessingStatusDto, ResponseDtoObject>({
        path: `/api/invoice-file-processing`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-detail-controller
     * @name GetInvoiceDetail
     * @request GET:/api/invoice-detail/{invoiceId}
     * @response `200` `ResponseDtoInvoiceDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceDetail: (invoiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceDetailDto, ResponseDtoObject>({
        path: `/api/invoice-detail/${invoiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domain-meta-data-controller
     * @name GetDomainMetaData
     * @request GET:/api/domain-meta-data
     * @response `200` `ResponseDtoDomainMetaDataDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getDomainMetaData: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoDomainMetaDataDto, ResponseDtoObject>({
        path: `/api/domain-meta-data`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags domain-meta-data-controller
     * @name GetChargeDomainOptions
     * @request GET:/api/domain-meta-data/charge-domain-option
     * @response `200` `ResponseDtoListChargeDomain` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getChargeDomainOptions: (params: RequestParams = {}) =>
      this.http.request<ResponseDtoListChargeDomain, ResponseDtoObject>({
        path: `/api/domain-meta-data/charge-domain-option`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name GetQuestionnaire
     * @request GET:/api/deviations/{id}/questionnaire
     * @response `200` `ResponseDtoMapDeviationWorkflowListDeviationQuestionDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getQuestionnaire: (id: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoMapDeviationWorkflowListDeviationQuestionDto, ResponseDtoObject>({
        path: `/api/deviations/${id}/questionnaire`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags deviation-questionnaire-controller
     * @name CalculateSubChargeDomain
     * @request GET:/api/deviations/{id}/calculate-sub-charge-domain
     * @response `200` `ResponseDtoListString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    calculateSubChargeDomain: (
      id: string,
      query: {
        'leg-type': 'LEG_ONE' | 'LEG_TWO' | 'LEG_THREE';
        'charge-domain':
          | 'COLLECTION'
          | 'CONTAINER_CLEARANCE'
          | 'CONTAINER_MAINTENANCE'
          | 'CUSTOMS_CLEARANCE'
          | 'DELIVERY'
          | 'DOCUMENTATION'
          | 'FREIGHT'
          | 'FUEL_SURCHARGE'
          | 'HANDLING_CHARGE'
          | 'INSPECTION'
          | 'OVERTIME'
          | 'PICK_UP'
          | 'PICK_UP_AND_DROP_OFF'
          | 'PORT_CHARGES'
          | 'SEAL_FEE'
          | 'SPECIAL_HANDLING'
          | 'STORAGE'
          | 'THC'
          | 'TOTAL_ORIGIN_CHARGES'
          | 'VGM_FEE'
          | 'DEFAULT_CHARGE_DOMAIN'
          | 'SCREE';
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ResponseDtoListString, ResponseDtoObject>({
        path: `/api/deviations/${id}/calculate-sub-charge-domain`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags currency-controller
     * @name GetCurrencies
     * @request GET:/api/currencies/{useType}
     * @deprecated
     * @response `200` `ResponseDtoListCurrencyEnum` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCurrencies: (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', params: RequestParams = {}) =>
      this.http.request<ResponseDtoListCurrencyEnum, ResponseDtoObject>({
        path: `/api/currencies/${useType}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags courier-invoice-dispute-controller
     * @name GetCourierInvoiceDisputeDetail
     * @request GET:/api/courier-invoice/dispute/{invoiceDisputeId}
     * @response `200` `ResponseDtoCourierInvoiceDisputeDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getCourierInvoiceDisputeDetail: (invoiceDisputeId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoCourierInvoiceDisputeDto, ResponseDtoObject>({
        path: `/api/courier-invoice/dispute/${invoiceDisputeId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags courier-shipment-controller
     * @name GetInvoiceCourierShipmentDetail
     * @request GET:/api/courier-invoice-shipment-detail/{shipmentId}
     * @response `200` `ResponseDtoInvoiceCourierShipmentDetailDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getInvoiceCourierShipmentDetail: (shipmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoInvoiceCourierShipmentDetailDto, ResponseDtoObject>({
        path: `/api/courier-invoice-shipment-detail/${shipmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name GetContractByShippingService
     * @request GET:/api/contracts/shipping-service/{shippingServiceId}
     * @response `200` `ResponseDtoListContractDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractByShippingService: (shippingServiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoListContractDto, ResponseDtoObject>({
        path: `/api/contracts/shipping-service/${shippingServiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractChargesDetailsForShippingService
     * @request GET:/api/contract-flows/{contractFlowId}/contract-detail
     * @response `200` `ResponseDtoContractChargeListsDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractChargesDetailsForShippingService: (contractFlowId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractChargeListsDto, ResponseDtoObject>({
        path: `/api/contract-flows/${contractFlowId}/contract-detail`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-flow-controller
     * @name GetContractFlowByContractIdAndShippingServiceId
     * @request GET:/api/contract-flows/contract/{contractId}/shipping-service/{shippingServiceId}
     * @response `200` `ResponseDtoContractFlowInfoViewDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getContractFlowByContractIdAndShippingServiceId: (contractId: string, shippingServiceId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoContractFlowInfoViewDto, ResponseDtoObject>({
        path: `/api/contract-flows/contract/${contractId}/shipping-service/${shippingServiceId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exchange-rate-amounts-re-calculator
     * @name GetStatus
     * @request GET:/api/admin/recalculate-exchange-price/migration-status
     * @response `200` `string` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    getStatus: (params: RequestParams = {}) =>
      this.http.request<string, ResponseDtoObject>({
        path: `/api/admin/recalculate-exchange-price/migration-status`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice-upload-controller
     * @name DeleteAttachment
     * @request DELETE:/api/invoices/attachment/{attachmentId}
     * @response `200` `ResponseDtoString` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteAttachment: (attachmentId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoString, ResponseDtoObject>({
        path: `/api/invoices/attachment/${attachmentId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags chart-report-controller
     * @name DeleteChartReport
     * @request DELETE:/api/chart-report/{chartReportId}
     * @response `200` `ResponseDtoChartReportDto` OK
     * @response `403` `ResponseDtoObject` Forbidden
     */
    deleteChartReport: (chartReportId: string, params: RequestParams = {}) =>
      this.http.request<ResponseDtoChartReportDto, ResponseDtoObject>({
        path: `/api/chart-report/${chartReportId}`,
        method: 'DELETE',
        ...params,
      }),
  };
}
