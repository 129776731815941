import { ICustomIconProps } from './types';

const IconChartBar: React.FC<ICustomIconProps> = ({ size = 17, color = '#BFBFBF' }) => (
  <svg width={size} height={size} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.6667 15.4167H1.33333C1.05999 15.4167 0.833328 15.19 0.833328 14.9167C0.833328 14.6433 1.05999 14.4167 1.33333 14.4167H14.6667C14.94 14.4167 15.1667 14.6433 15.1667 14.9167C15.1667 15.19 14.94 15.4167 14.6667 15.4167Z'
      fill={color}
    />
    <path d='M6.5 3.41668V15.4167H9.5V3.41668C9.5 2.68334 9.2 2.08334 8.3 2.08334H7.7C6.8 2.08334 6.5 2.68334 6.5 3.41668Z' fill={color} />
    <path d='M2 7.41668V15.4167H4.66667V7.41668C4.66667 6.68334 4.4 6.08334 3.6 6.08334H3.06667C2.26667 6.08334 2 6.68334 2 7.41668Z' fill={color} />
    <path d='M11.3333 10.75V15.4167H14V10.75C14 10.0167 13.7333 9.41666 12.9333 9.41666H12.4C11.6 9.41666 11.3333 10.0167 11.3333 10.75Z' fill={color} />
  </svg>
);

export default IconChartBar;
