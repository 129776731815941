import { FC } from 'react';
import { useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import { formatDate } from 'src/utils/date';
import { MatchingServiceDto } from 'src/generated/services/TFinancialApi';

export interface IShippingService {
  id?: string;
  polpod: string;
  shipperName: string;
  consigneeName: string;
  service: string;
  type: string;
  carrier: string;
  incoterm: string;
  etd: string;
}

export interface IReviewContractCharges {
  chargeType: string;
  chargeNameOrCategory: string;
  contractUnitPrice: string;
}

const useColumns = (): MRT_ColumnDef<MatchingServiceDto>[] => [
  {
    enableEditing: false,
    accessorKey: 'polpod',
    header: 'POL/POD',
    accessorFn: (row) => (
      <>
        {row?.pol} / {row?.pod}
      </>
    ),
  },
  {
    enableEditing: false,
    accessorKey: 'shipperName',
    header: 'SHIPPER NAME',
    accessorFn: (row) => row?.shipperName || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'consigneeName',
    header: 'CONSIGNEE NAME',
    accessorFn: (row) => row?.consigneeName || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'modality',
    header: 'MODALITY',
    accessorFn: (row) => row?.modality || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'service',
    header: 'SERVICE',
    accessorFn: (row) => row?.service || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'type',
    header: 'TYPE',
    accessorFn: (row) => row?.type || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'carrier',
    header: 'CARRIER',
    accessorFn: (row) => row?.carrier || '-',
  },
  {
    enableEditing: false,
    accessorKey: 'incoterm',
    header: 'INCOTERM',
    accessorFn: (row) => row?.incoterm || '-',
  },
  {
    header: 'ETD',
    id: 'etd',
    accessorFn: (row) => {
      if (row?.estimatedDepartureDate) {
        return formatDate(new Date(row?.estimatedDepartureDate));
      }
      return '-';
    },
    enableEditing: false,
  },
];

interface IUnrecognizedTableProps {
  data: MatchingServiceDto[];
}

export const TableUnrecognizedService: FC<IUnrecognizedTableProps> = (props) => {
  const { data = [] } = props;
  const columns = useColumns();

  const table = useMaterialReactTable({
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableEditing: false,
    editDisplayMode: 'table',
    enableRowSelection: false,
    enableMultiRowSelection: false,
    data,
    columns,
  });

  return <CustomTable table={table} />;
};
