import { useState, FC } from 'react';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ILandingSectionProps } from './types';
import { LandingLoader } from './Loader';

const StyledAccordion = styled(MuiAccordion)({
  borderRadius: '8px!important',
  boxShadow: 'none',
  border: 'none',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '12px 0',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: '#606060',
  backgroundColor: 'transparent',
  borderRadius: '0!important',
  borderBottom: 'none!important',

  '&.Mui-expanded': {
    margin: 0,
    minHeight: 0,
    borderRadius: '8px 8px 0 0!important',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    border: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#606060',

    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },

    '& .MuiSvgIcon-root': {
      color: '#606060',
    },
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0',
});

export const LandingSection: FC<ILandingSectionProps> = (props) => {
  const { icon, title, children, topRight, isLoading } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <div className='relative'>
      <StyledAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <div className='flex items-center border-b border-grey-30 pb-10'>
          <div className='flex-1'>
            <div className='flex-1 flex items-center mr-16'>
              <div className='flex items-center gap-8'>
                <img src={icon} alt='' />
                <strong className='text-20 font-bold text-blue_grey-30'>{title}</strong>
              </div>
              <div className='pl-10 ml-auto'>{topRight}</div>
            </div>
          </div>
          <div className='ml-auto'>
            <StyledAccordionSummary className='border-none border-0' expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.8rem', color: '#ffffff' }} />} />
          </div>
        </div>
        <StyledAccordionDetails>
          <div className='mt-24'>{isLoading ? <LandingLoader /> : children}</div>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};
