import { useFormContext } from 'react-hook-form';
import { FC } from 'react';

import { FieldLabel } from 'src/presentations/components/molecules/fields/FieldLabel';

import { INoContractContentStep2Props, NoContractForm } from './types';
import { NoContractSectionHeader } from './SectionHeader';
import { InformationCallout } from './InformationCallout';
import { NoContractContentStep2Create } from './ContentStep2Create';

export const NoContractContentStep3: FC<INoContractContentStep2Props> = (props) => {
  const { matchingService, draft, activeStep } = props;
  const { watch } = useFormContext<NoContractForm>();
  const { agreementType, verdict, reason } = watch();

  return (
    <div className='p-24'>
      <InformationCallout text='Upon confirmation, we will create a new shipping service in the system with the corresponding Rate Card charges that you reviewed and trigger the audit of the invoice. Going forward, any new invoice matching these shipping service details will be audited against the Rate Card charges provided below.' />

      {verdict === 'reject-reject' ? (
        <div className='mt-48'>
          <NoContractSectionHeader title='Summary' description="Here's the summary" />
          <div className='flex flex-col gap-2 mt-40'>
            <FieldLabel label='Agreement Reached'>
              <p>{agreementType}</p>
            </FieldLabel>
          </div>
          <div className='flex flex-col gap-2 mt-24'>
            <FieldLabel label='Reason'>
              <p>{reason}</p>
            </FieldLabel>
          </div>
        </div>
      ) : (
        <NoContractContentStep2Create matchingService={matchingService} draft={draft} activeStep={activeStep} />
      )}
    </div>
  );
};
