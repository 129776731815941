import { useContext } from 'react';
import { UploadSelectorContext } from './UploadSelector';

export function useUploadSelector() {
  const context = useContext(UploadSelectorContext);

  if (!context) {
    throw new Error(`Upload Invoices selector compound components cannot be rendered outside the Upload Invoices Selector component`);
  }
  return context;
}
