import { useLocation, Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const location = useLocation();

  if (authStatus === 'unauthenticated') {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
