import React from 'react';
import { ICustomIconProps } from './types';

const IconTruck: React.FC<ICustomIconProps> = ({ size = 20, color = '#F79824' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.01202 8.33258C8.15027 8.51642 8.3612 8.61328 8.5747 8.61328C8.72192 8.61328 8.87042 8.56714 8.99694 8.47193C9.30749 8.23847 9.36993 7.79737 9.13647 7.48701C8.67175 6.86884 8.41577 6.10273 8.41577 5.32948C8.41577 4.5024 8.70452 3.69435 9.22875 3.05458C9.47485 2.75411 9.4309 2.31081 9.13043 2.06454C8.82995 1.81844 8.38665 1.86239 8.14038 2.16286C7.4107 3.05349 7.00879 4.17812 7.00879 5.32948C7.00879 6.40595 7.36511 7.47236 8.01202 8.33258Z'
      fill={color}
    />
    <path
      d='M6.50976 10.5012C6.66576 10.5012 6.82269 10.4496 6.95306 10.3436C7.25463 10.0986 7.30022 9.65548 7.05523 9.35409C6.1333 8.22012 5.62573 6.7908 5.62573 5.32944C5.62573 3.80052 6.17559 2.32085 7.17425 1.16289C7.42785 0.868824 7.39507 0.424611 7.10082 0.170828C6.80676 -0.0827725 6.36254 -0.0499967 6.10876 0.24407C4.89001 1.65727 4.21875 3.46342 4.21875 5.32963C4.21875 7.11307 4.83838 8.85751 5.96356 10.2416C6.10253 10.4126 6.30523 10.5012 6.50976 10.5012Z'
      fill={color}
    />
    <path
      d='M15.5972 5.32945C15.5972 6.1027 15.3414 6.869 14.8765 7.48698C14.643 7.79752 14.7054 8.23844 15.016 8.4719C15.1425 8.56711 15.291 8.61325 15.4382 8.61325C15.6517 8.61325 15.8627 8.51639 16.0009 8.33255C16.6478 7.47233 17.0041 6.40574 17.0041 5.32945C17.0041 4.17809 16.6024 3.05345 15.8725 2.16283C15.6263 1.86236 15.1832 1.81841 14.8825 2.06451C14.5822 2.31078 14.5381 2.7539 14.7844 3.05437C15.3086 3.69451 15.5972 4.50236 15.5972 5.32945Z'
      fill={color}
    />
    <path
      d='M18.3872 5.32946C18.3872 6.79082 17.8796 8.22014 16.9577 9.3541C16.7125 9.6555 16.7583 10.0986 17.0598 10.3436C17.1902 10.4496 17.3471 10.5013 17.5031 10.5013C17.7075 10.5013 17.9104 10.4125 18.0493 10.2414C19.1745 8.85752 19.7941 7.11308 19.7941 5.32946C19.7941 3.46325 19.1229 1.65729 17.9041 0.244084C17.6503 -0.0501658 17.2061 -0.0829416 16.9119 0.170842C16.6178 0.424442 16.585 0.868655 16.8386 1.1629C17.8373 2.32086 18.3872 3.80053 18.3872 5.32946Z'
      fill={color}
    />
    <path
      d='M18.6197 22.5931H16.6449L13.0503 7.27202C13.741 6.89885 14.2114 6.16808 14.2114 5.32946C14.2114 4.1131 13.2217 3.12341 12.0054 3.12341C10.789 3.12341 9.7993 4.1131 9.7993 5.32946C9.7993 6.16827 10.2697 6.89885 10.9604 7.27202L7.36584 22.5931H5.3949C5.00635 22.5931 4.69141 22.9081 4.69141 23.2966C4.69141 23.685 5.00635 23.9999 5.3949 23.9999H18.6197C19.0082 23.9999 19.3231 23.685 19.3231 23.2966C19.3231 22.9081 19.0082 22.5931 18.6197 22.5931ZM13.8721 16.9345L11.1821 15.3307L13.2162 14.1388L13.8721 16.9345ZM12.0054 4.53039C12.4461 4.53039 12.8046 4.88891 12.8046 5.32946C12.8046 5.7702 12.4461 6.12872 12.0054 6.12872C11.5648 6.12872 11.2063 5.7702 11.2063 5.32946C11.2063 4.88891 11.5648 4.53039 12.0054 4.53039ZM12.0054 8.97783L12.8799 12.7053L10.8522 13.8935L12.0054 8.97783ZM10.2596 16.4187L13.5524 18.3817L9.19432 20.9597L10.2596 16.4187ZM9.19615 22.5931L14.4681 19.4747L15.1996 22.5931H9.19615Z'
      fill={color}
    />
  </svg>
);

export default IconTruck;
