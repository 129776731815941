import { useContext } from 'react';
import { CardSelectorContext } from './CardSelector';

export function useCardSelector() {
  const context = useContext(CardSelectorContext);

  if (!context) {
    throw new Error(`Card selector compound components cannot be rendered outside the Card Selector component`);
  }
  return context;
}
