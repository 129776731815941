import { Button, Drawer } from '@mui/material';
import { useCallback, useState } from 'react';
import IconSetting from 'src/assets/images/icons/setting.svg';
import IconClose from 'src/assets/images/icons/close-rounded.svg';
import { useFormContext } from 'react-hook-form';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { nameofFactory } from 'src/utils/nameofFactory';
import { CustomFilterAccordion } from './CustomFilterAccordion';
import { FilterAccordionProps, countUnemptyField } from '../analyticsMapper';
import { FilterNum } from './FilterNum';

export interface FilterDrawerProps {
  filterAccordions: FilterAccordionProps[];
  onChangeFilterNew?(filter: Record<string, any>): void;
  onResetFilter?(): void;
}

export const FilterDrawer = ({ filterAccordions, onChangeFilterNew, onResetFilter }: FilterDrawerProps) => {
  const [openState, setOpenState] = useState(false);
  const { resetField, watch } = useFormContext();
  const filtersFieldName = nameofFactory<AnalyticSearchRequestDto>()('filters');
  const formValues = watch(filtersFieldName);

  const FilterNumDisplay = useCallback(() => {
    if (formValues) {
      const filterNum = Object.keys(formValues).reduce((pre, curr) => pre + countUnemptyField(formValues[curr]), 0);
      return <FilterNum filterNum={filterNum} />;
    }
    return null;
  }, [formValues]);

  return (
    <div>
      <button
        type='button'
        onClick={() => setOpenState(true)}
        className='flex items-center justify-between px-[16px] py-[8px] border border-[#D3D7DF] rounded-10 gap-[10px]'
      >
        <img src={IconSetting} alt='' />
        <div className='flex justify-center items-center gap-[8px]'>
          <p>Filters</p>
          <FilterNumDisplay />
        </div>
      </button>

      <Drawer
        anchor='right'
        open={openState}
        ModalProps={{ onBackdropClick: () => setOpenState(false) }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#f5f6fa',
          },
        }}
      >
        <div className='flex h-full w-[360px] flex-col items-start bg-neutral-10 '>
          <div className='flex w-full px-[20px] py-[16px] justify-between items-center bg-white border-[#D3D7DF] border-[1px] '>
            <div className='flex gap-[8px] items-center'>
              <p className='text-xl font-bold'>Filters</p>
              <FilterNumDisplay />
            </div>
            <div className='flex items-center gap-[16px]'>
              <text
                onClick={() => {
                  if (onResetFilter) {
                    onResetFilter();
                  }
                  resetField(filtersFieldName);
                }}
                className='text-red-40 underline text-14 hover:cursor-pointer'
              >
                Reset to Default
              </text>
              <button onClick={() => setOpenState(false)}>
                <img src={IconClose} alt='Close' />
              </button>
            </div>
          </div>
          <div className='p-[12px] w-full h-fit flex flex-col gap-[16px]'>
            {filterAccordions?.map((v) => (
              <CustomFilterAccordion
                key={`filter-${v.name}`}
                filtersDetails={v.filtersDetails}
                icon={v.icon}
                name={v.name}
                label={v.label}
                onChangeFilterNew={onChangeFilterNew}
              />
            ))}
          </div>
          <div className='mt-auto flex items-center justify-center p-24 border-t border-[#D3D7DF] w-full'>
            <Button
              variant='outlined'
              fullWidth
              sx={{ backgroundColor: '#E0F3E6', borderColor: '#00992B', textTransform: 'capitalize' }}
              onClick={() => setOpenState(false)}
            >
              Close Filters
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
