import { useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useEncodedQueryState } from 'src/hooks/useEncodedQueryState';
import { FiltersRequestDto } from 'src/generated/services/TFinancialApi';
import { useFilters } from '../analytics/useAnalytics';
import { PerformanceTopContent } from './TopContent';
import { PerformanceTabs } from './Tabs';
import { PerformanceContentSavings } from './ContentSavings';
import { PerformanceContentVolumetric } from './ContentVolumetric';
import { TableTab } from '../analytics/table/useTable';

export const PerformanceAnalysisMain = ({ defaultCurrency }: { defaultCurrency: string }) => {
  const [tabName, setTabName] = useState<string>(TableTab.SAVINGS);

  const defaultFiltersState = {
    filters: {
      formValues: {
        currencyReference: defaultCurrency || '',
        dateReference: 'PICKUP_DATE',
        filters: {
          dateFilters: {},
          tradeLaneFilters: {},
          shipmentFilters: {},
          billingFilters: {},
        },
        invoiceStatusSet: ['APPROVED', 'REJECTED', 'FLAGGED'],
      } as FiltersRequestDto,
    },
  };

  const [hashedFilter] = useEncodedQueryState(defaultFiltersState, 'report');

  const { data: dataFilter } = useFilters('PERFORMANCE_ASSESSMENT', hashedFilter.filters.formValues);

  const formContext = useForm({
    defaultValues: { ...hashedFilter.filters.formValues },
  });

  return (
    <FormContainer formContext={formContext}>
      <div className='sticky top-0 z-10 bg-white border-b border-grey-30'>
        <PerformanceTopContent dataFilter={dataFilter} />
        <PerformanceTabs selected={tabName} onChangeTab={(name) => setTabName(name)} />
      </div>
      <div className='py-24'>
        {tabName === TableTab.SAVINGS && <PerformanceContentSavings dataFilter={dataFilter} />}
        {tabName === TableTab.VOLUMETRIC && <PerformanceContentVolumetric dataFilter={dataFilter} />}
      </div>
    </FormContainer>
  );
};
