import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';

export const mockFilters = {
  dateFilters: {
    years: ['2020', '2021', '2022'],
    quarters: ['Q1-2023', 'Q2-2023', 'Q3-2023'],
    months: ['Jan-2023', 'Feb-2023'],
    weeks: ['W5-2023', 'W6-2023'],
    days: ['08-Mon-2023'],
  },
  shipmentFilters: {
    serviceProviderName: ['Bollore Logistics Japan K.K.'],
    modality: ['SEA', 'ROAD'],
    vehicleID: ['CNC SATURN', 'CNC BANGKOK', 'NORDLEOPARD', 'KUO LIN'],
  },
  tradeLaneFilters: {
    tradeLaneCompany: ['Valeo Automotive (Thailand) CO. LTD -> Valeo Japan (TCP/T36)'],
    tradeLaneCityHub: ['LAEM CHABANG -> TOKYO'],
    tradeLaneCountry: ['THAILAND -> JAPAN'],
  },
  billingFilters: {
    shipmentNumber: ['SL23040594'],
    invoiceNumber: ['AI23030029', 'AI23030018'],
    legType: ['Leg 1', 'Leg 2'],
    chargeType: ['Transportation Charge'],
  },
};

export const dateReferenceOptions = [
  {
    label: 'Pickup Date',
    value: 'PICKUP_DATE',
  },
  {
    label: 'Delivery Date',
    value: 'DELIVERY_DATE',
  },
  {
    label: 'Invoice Date',
    value: 'INVOICE_DATE',
  },
  {
    label: 'Invoice Due Date',
    value: 'INVOICE_DUE_DATE',
  },
] as { label: string; value: AnalyticSearchRequestDto['dateReference'] }[];

export const invoiceStatusOptions = [
  {
    label: 'Flagged',
    value: 'FLAGGED',
  },
  {
    label: 'Ready for Payment',
    value: 'APPROVED',
  },
  {
    label: 'To Approve',
    value: 'TO_APPROVE',
  },
  {
    label: 'Pending Document',
    value: 'PENDING_DOCUMENT',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'To Reject',
    value: 'TO_REJECT',
  },
];

export const currencyReferenceOptions = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'JPY',
    value: 'JPY',
  },
];
