export const FilterNum = ({ filterNum }) => {
  if (filterNum > 0) {
    return (
      <div className='flex w-[20px] h-[20px] px-[7px] flex-col items-center justify-center gap-[10px] rounded-full bg-[#00992B1F]'>
        <p className='text-12 text-[#00992B] font-bold'>{filterNum}</p>
      </div>
    );
  }
  return <></>;
};
