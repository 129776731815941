import { Link } from 'react-router-dom';

import Content, { TButtonContentState } from './Content';
import styles from './styles';

interface IButtonProps {
  size?: 'medium';
  variant?: 'primary' | 'primary_outline' | 'secondary' | 'white' | 'white_outline' | 'neutral_outline' | 'danger' | 'danger_outline';
  rounded?: 'medium' | 'large';
  align?: 'center' | 'left';
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  path?: string;
  isOutside?: boolean;
  isFull?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Button: React.FC<IButtonProps> = ({
  size = 'medium',
  variant = 'primary',
  type = 'button',
  rounded = 'medium',
  align = 'center',
  className = '',
  disabled,
  isLoading,
  isFull,
  path,
  isOutside,
  children,
  onClick,
}) => {
  let currentState: TButtonContentState = isLoading ? 'loading' : 'active';
  let styleState: TButtonContentState = disabled ? 'disabled' : 'active';
  if (isLoading) {
    currentState = 'loading';
  }
  if (disabled) {
    currentState = 'disabled';
    styleState = 'disabled';
  }

  const roundedClass = styles.rounded[rounded];
  const btnStyles = `
    transition-all duration-500
    ${styles.base[align]} 
    ${styles.size[size]} 
    ${styles.space[align][size]} 
    ${styles[variant][styleState]} 
    ${roundedClass}${isFull ? ' w-full' : ''} 
    ${className || ''}`;

  if (path && !isOutside) {
    return (
      <Link to={path} className={btnStyles} onClick={onClick}>
        <Content state={currentState}>{children}</Content>
      </Link>
    );
  }

  if (path && isOutside) {
    return (
      <a href={path} target='_blank' rel='noreferrer' className={btnStyles} onClick={onClick}>
        <Content state={currentState}>{children}</Content>
      </a>
    );
  }

  return (
    <button type={type} disabled={currentState === 'disabled' || currentState === 'loading'} className={btnStyles} onClick={onClick}>
      <Content state={currentState}>{children}</Content>
    </button>
  );
};

export default Button;
