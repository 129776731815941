import { CustomTooltip } from '../../atom/customtooltip';

export const simplifyFileNames = (filePath: string) => {
  let newFilePath = filePath;
  let isSimplified = false;
  const fileExt = filePath.split('.').pop();
  const filename = filePath.split('.').shift();
  if (filename.length > 40) {
    isSimplified = true;
    const firstName = filename.slice(0, 20);
    const lastName = filename.slice(-20);
    newFilePath = `${firstName}...${lastName}.${fileExt}`;
  }

  if (!isSimplified) return newFilePath;

  return (
    <CustomTooltip title={filePath}>
      <div className='relative'>{newFilePath}</div>
    </CustomTooltip>
  );
};
