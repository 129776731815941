import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { NoContractSectionSelected } from './SectionSelected';
import { NoContractContentStep1 } from './ContentStep1';
import { NoContractContentStep2Create } from './ContentStep2Create';
import { NoContractContentStep2Reject } from './ContentStep2Reject';
import { NoContractContentStep3 } from './ContentStep3';
import { NoContractSection } from './Section';
import { INoContractContentProps, NoContractForm, NoContractStep } from './types';
import { TableUnrecognizedService } from './TableUnrecognizedService';

export const NoContractContent: FC<INoContractContentProps> = (props) => {
  const { activeStep, matchingService, draft, isLoadingCharges } = props;
  const { watch } = useFormContext<NoContractForm>();
  const { contractChoice, verdict } = watch();

  const isStep2Accept = activeStep === NoContractStep.Step2_Resolution && !!contractChoice && (verdict === 'reject-approve' || verdict === 'accept');

  return (
    <>
      <div className='p-24 bg-[#F5F6FA] border-y-1 border-[#DFDFDF]'>
        <NoContractSection title='Unrecognized Shipping Service from Invoice' description='Shipping service details'>
          <TableUnrecognizedService data={[matchingService?.unmatchedService]} />
        </NoContractSection>

        {isStep2Accept && <NoContractSectionSelected activeStep={activeStep} />}
      </div>

      {activeStep === NoContractStep.Step1_Select && <NoContractContentStep1 draft={draft} matchingService={matchingService} activeStep={activeStep} />}
      {isStep2Accept && (
        <NoContractContentStep2Create isLoadingCharges={isLoadingCharges} isEditable draft={draft} matchingService={matchingService} activeStep={activeStep} />
      )}
      {activeStep === NoContractStep.Step2_Resolution && verdict === 'reject-reject' && (
        <NoContractContentStep2Reject draft={draft} matchingService={matchingService} activeStep={activeStep} />
      )}

      {activeStep === NoContractStep.Step3_Summary && <NoContractContentStep3 draft={draft} matchingService={matchingService} activeStep={activeStep} />}
    </>
  );
};
