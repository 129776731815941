import { FC } from 'react';
import TransportMediumIcon from 'src/presentations/components/molecules/card/TransportMediumIcon';
import { beautifyEnum } from 'src/utils/utility';
import { SectionDetailTopActions } from './TopActions';
import { ISectionDetailHeaderProps } from './types';

export const SectionDetailHeader: FC<ISectionDetailHeaderProps> = (props) => {
  const { title, transportMedium, linkAudit, linkReport } = props;

  return (
    <div className='flex items-center border-b border-grey-30 p-16'>
      <div className='flex-1'>
        <div className='flex-1 flex items-center mr-16'>
          <div className='flex items-center gap-8'>
            <TransportMediumIcon medium={transportMedium} iconColor='#9F9F9F' />
            <strong className='text-18 font-medium text-blue_grey-90'>{beautifyEnum(title).toUpperCase()}</strong>
          </div>
        </div>
      </div>
      <SectionDetailTopActions linkAudit={linkAudit} linkReport={linkReport} />
    </div>
  );
};
