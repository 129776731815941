import { ReactNode } from 'react';
import {
  ContainerChargeDto,
  ExistingShippingServiceDto,
  InvoiceDto,
  MergedShipmentDeclarationChargeDto,
  NoContractDraft,
  PercentageChargeDto,
  ShippingServiceDto,
  WeightChargeDto,
} from 'src/generated/services/TFinancialApi';
import { IRangeValue } from 'src/presentations/components/molecules/fields/rhf-mui-fields';

export type ModalOpen = 'create' | 'approve' | 'reject' | null;

export enum NoContractStep {
  Step1_Select = 0,
  Step2_Resolution = 1,
  Step3_Summary = 2,
}

export interface SelectedShippingServiceItemMain extends ExistingShippingServiceDto {
  validityPeriod?: IRangeValue;
}

export interface SelectedShippingServiceItem {
  existingShippingService?: SelectedShippingServiceItemMain;
  mismatchedFields?: string[];
}

export interface IMatchingServiceData {
  matchedService?: ShippingServiceDto;
  unmatchedService?: ShippingServiceDto;
  partialMatchingServices?: SelectedShippingServiceItem[];
}

export interface NoContractForm {
  verdict: NoContractOption;
  agreementType: RejectEnum;
  contractChoice: string;
  selectedPartialMatchingService?: SelectedShippingServiceItem;
  newMatchingService?: SelectedShippingServiceItem;
  containerCharges?: ContainerChargeDto[];
  shipmentDeclarationCharges?: MergedShipmentDeclarationChargeDto[];
  weightCharges?: WeightChargeDto[];
  percentageCharges?: PercentageChargeDto[];
  reason?: string;
}

export interface INoContractHeaderProps {
  invoice: InvoiceDto;
}

export interface INoContractMainProps {
  matchingService: IMatchingServiceData;
  draft: NoContractDraft;
  invoice: InvoiceDto;
  defaultContractFlowId: string | null;
  onClose(): void;
}

export interface INoContractContentProps {
  matchingService: IMatchingServiceData;
  draft: NoContractDraft;
  activeStep: NoContractStep;
  isLoadingCharges?: boolean;
}

export interface INoContractContentStep2Props extends INoContractContentProps {
  isEditable?: boolean;
  isLoadingCharges?: boolean;
}

export type NoContractOption = null | 'reject-reject' | 'reject-approve' | 'accept';

export interface IOptionContext {
  option: NoContractOption;
  setOption: React.Dispatch<React.SetStateAction<NoContractOption>>;
}

export enum RejectEnum {
  ONE_OFF = 'Accept the invoice as is one-off',
  CREATE_NEW_SHIPPING_SERVICE = 'Accept invoice and create shipping service',
  REJECT = 'Reject it',
}

export interface INoContractModalProps {
  open: boolean;
  invoice: InvoiceDto;
}

export interface ModalFooterProps {
  activeStep: NoContractStep;
  onSuccessSubmit: () => void;
  onSaveDraft: () => void;
  onGoBack: () => void;
  onClose: () => void;
  onConfirm: (e: any) => void;
}

export interface INoContractSectionProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export interface INoContractSectionHeaderProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export interface SelectCardProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isChosen?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  header?: string;
  description?: string;
}

export interface IInformationCalloutProps {
  title?: ReactNode;
  text?: ReactNode;
}

export interface ITableFieldNumberProps {
  name: string;
}
