import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IPerformanceTabsProps } from './types';
import { TableTab } from '../analytics/table/useTable';

const StyledTabs = styled(Tabs)({
  minHeight: 0,
  '& .MuiButtonBase-root': {
    color: '#808080',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: '#202020',
  },
  '& .MuiTouchRipple-root': {
    color: '#00992B',
  },
  '& .MuiTabs-indicator': {
    height: 2,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
});

export const PerformanceTabs: FC<IPerformanceTabsProps> = (props) => {
  const { selected, onChangeTab } = props;

  const handleChange = (event, newValue: string) => {
    onChangeTab(newValue);
  };

  const menuItems = useMemo(
    () => [
      {
        id: TableTab.SAVINGS,
        name: 'Savings',
      },
      {
        id: TableTab.VOLUMETRIC,
        name: 'Volumetrics',
      },
    ],
    []
  );

  return (
    <div className='px-32 bg-white flex items-center shadow-[0_6px_20px_0_rgba(0,0,0,0.04)]'>
      <StyledTabs value={selected} onChange={handleChange}>
        {menuItems.map((item) => (
          <Tab
            value={item.id}
            key={`landing-menu-${item.id}`}
            className='!py-16 !px-32 group'
            label={
              <div className='flex flex-row items-center gap-10'>
                <strong className='normal-case text-16 font-medium transition-all duration-300 group-hover:text-neutral-90'>{item.name}</strong>
              </div>
            }
          />
        ))}
      </StyledTabs>
    </div>
  );
};
