import InformationIcon from 'src/assets/images/icons/information-red.svg';
import { IInformationCalloutProps } from './types';

export const InformationCallout = ({ title, text }: IInformationCalloutProps) => (
  <div className={`p-[16px] rounded-[8px] bg-[#F5F6FA] `}>
    <div className='flex gap-[20px] items-center'>
      <img src={InformationIcon} alt='red information alert' />
      <div className='text-left'>
        <h1 className='leading-[165%] text-[18px] font-medium'>{title ?? 'Information'}</h1>
        <p className='leading-[170%] text-[14px] font-normal text-[#475B69]'>{text}</p>
      </div>
    </div>
  </div>
);
