import {
  AnalyticSearchRequestDto,
  KeyMetricsResponseDto,
  FiltersDto,
  DateFiltersDto,
  ShipmentFiltersDto,
  BillingFiltersDto,
  TradeLaneFiltersDto,
} from 'src/generated/services/TFinancialApi';
import IconDollar from 'src/assets/images/icons/analytic-dollar.svg';
import IconCloud from 'src/assets/images/icons/analytic-cloud.svg';
import IconWeight from 'src/assets/images/icons/analytic-weight.svg';
import IconWeightBlue from 'src/assets/images/icons/analytic-weight-blue.svg';
import IconTruck from 'src/assets/images/icons/analytic-truck.svg';
import IconReceipt from 'src/assets/images/icons/analytic-receipt.svg';
import IconWarning from 'src/assets/images/icons/analytic-warning.svg';
import IconBox from 'src/assets/images/icons/analytic-box.svg';
import IconCalender from 'src/assets/images/icons/filter-calendar.svg';
import IconTradeLane from 'src/assets/images/icons/filter-trade-lane.svg';
import IconShipment from 'src/assets/images/icons/filter-shipment.svg';
import IconBilling from 'src/assets/images/icons/filter-billing.svg';
import { nameofFactory } from 'src/utils/nameofFactory';
import { beautifyEnum, SimpleFormatNumber } from 'src/utils/utility';
import { CardId } from './generalOverview/types';
import { CardTheme, ChartType } from '../../molecules/card/types';
import { ICardAnalyticItemProps } from '../../molecules/card/CardSelector';
import { CO2 } from '../../atom/unit';

export interface Option {
  value: string;
  label: string;
}

export interface FilterDetail {
  name: string;
  label: string;
  options: Option[];
}

export interface FilterAccordionProps {
  label: string;
  name: string;
  icon: string;
  filtersDetails: FilterDetail[];
  onChangeFilterNew?(filter: Record<string, any>): void;
}

export const formatNumberChart = (value: number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);

export const renderLegType = (legType: string) => {
  switch (legType) {
    case 'LEG_ONE':
      return 'Leg 1';
    case 'LEG_THREE':
      return 'Leg 3';
    case 'LEG_TWO':
      return 'Leg 2';
    default:
      return legType;
  }
};

export const keyMetricsMapper = (keyMetricsResponse: KeyMetricsResponseDto): ICardAnalyticItemProps[] => [
  {
    id: CardId.TOTAL_AMOUNT,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.totalAmount.afterTax),
    title: `Total Amount (${keyMetricsResponse?.meta?.currency ?? '?'})`,
    icon: IconDollar,
    theme: CardTheme.ORANGE,
    chartType: ChartType.LINE,
    unit: keyMetricsResponse?.meta?.currency,
  },
  {
    id: CardId.TOTAL_CO2_EMISSIONS,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.totalCo2Emissions),
    title: `CO2e Emissions (${keyMetricsResponse?.meta?.co2EmissionUnit ?? '?'})`,
    cardTitle: (
      <span>
        <CO2 />
        &nbsp;{`Emissions (${keyMetricsResponse?.meta?.co2EmissionUnit})`}
      </span>
    ),
    icon: IconCloud,
    theme: CardTheme.GREEN,
    chartType: ChartType.LINE,
    unit: keyMetricsResponse?.meta?.co2EmissionUnit,
  },
  {
    id: CardId.CHARGABLE_WEIGHT,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.chargeableWeight),
    title: `Chargable Weight (${keyMetricsResponse?.meta?.chargeableWeightUnit ?? '?'})`,
    icon: IconWeight,
    theme: CardTheme.NEUTRAL,
    chartType: ChartType.LINE,
    unit: keyMetricsResponse?.meta?.chargeableWeightUnit,
  },
  {
    id: CardId.GROSS_WEIGHT,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.grossWeight),
    title: `Gross Weight (${keyMetricsResponse?.meta?.grossWeightUnit ?? '?'})`,
    icon: IconWeightBlue,
    theme: CardTheme.BLUE,
    chartType: ChartType.LINE,
    unit: keyMetricsResponse?.meta?.grossWeightUnit,
  },
  {
    id: CardId.VOLUME,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.volume),
    title: `Volume (${keyMetricsResponse?.meta?.volumeUnit === 'cbm' ? 'm³' : keyMetricsResponse?.meta?.volumeUnit ?? '?'})`,
    icon: IconBox,
    theme: CardTheme.BLUE_LIGHT,
    chartType: ChartType.LINE,
    unit: keyMetricsResponse?.meta?.volumeUnit === 'cbm' ? 'm³' : keyMetricsResponse?.meta?.volumeUnit,
  },
  {
    id: CardId.NO_SHIPMENTS,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.noShipments),
    title: 'Shipments',
    icon: IconTruck,
    theme: CardTheme.PURPLE,
    chartType: ChartType.BAR,
    unit: '#',
  },
  {
    id: CardId.NO_INVOICES,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.noInvoices),
    title: 'Invoices',
    icon: IconReceipt,
    theme: CardTheme.PURPLE_LIGHT,
    chartType: ChartType.BAR,
    unit: '#',
  },
  {
    id: CardId.NO_CHARGES,
    count: SimpleFormatNumber(keyMetricsResponse?.keymetrics?.noCharges),
    title: 'Charges',
    icon: IconWarning,
    theme: CardTheme.PINK,
    chartType: ChartType.BAR,
    unit: '#',
  },
];

export const colorMapper = (theme: string) => {
  switch (theme) {
    case CardTheme.NEUTRAL:
      return '#A9B2C9';
    case CardTheme.ORANGE:
      return '#F79824';
    case CardTheme.GREEN:
      return '#3DA264';
    case CardTheme.BLUE:
      return '#4879F6';
    case CardTheme.PURPLE:
      return '#A375B8';
    case CardTheme.PURPLE_LIGHT:
      return '#CB6FF6';
    case CardTheme.PINK:
      return '#ED96DA';
    default:
      return '#D3D7DF';
  }
};

const nameof = nameofFactory<AnalyticSearchRequestDto>();
const nameofFilters = nameofFactory<FiltersDto>();

export const dateFiltersMapper = (dateFilters: DateFiltersDto) => [
  {
    name: `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('years')}`,
    label: 'Year',
    options: dateFilters?.years.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('quarters')}`,
    label: 'Quarter',
    options: dateFilters?.quarters.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('months')}`,
    label: 'Month',
    options: dateFilters?.months.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('weeks')}`,
    label: 'Week',
    options: dateFilters?.weeks.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('days')}`,
    label: 'Day',
    options: dateFilters?.days.map((value) => ({
      value,
      label: value,
    })),
  },
];

export const shipmentFiltersMapper = (shipmentFilters: ShipmentFiltersDto) => [
  {
    name: `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('serviceProviderCode')}`,
    label: 'Service Provider',
    options: shipmentFilters?.serviceProviderCode.map((value) => ({
      value,
      label: beautifyEnum(value).toUpperCase(),
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('modality')}`,
    label: 'Modality',
    options: shipmentFilters?.modality.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('vehicleID')}`,
    label: 'Vehicle ID',
    options: shipmentFilters?.vehicleID.map((value) => ({
      value,
      label: value,
    })),
  },
];

export const billingFiltersMapper = (billingFilters: BillingFiltersDto) => [
  {
    name: `${nameof('filters')}.${nameofFilters('billingFilters')}.${nameofFactory<BillingFiltersDto>()('legType')}`,
    label: 'Leg Type',
    options: billingFilters?.legType
      .map((item) => ({
        value: item,
        label: renderLegType(item),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('billingFilters')}.${nameofFactory<BillingFiltersDto>()('chargeDomain')}`,
    label: 'Charge Type',
    options: billingFilters?.chargeDomain.map((value) => ({
      value,
      label: value
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '),
    })),
  },
];

export const tradeLaneFiltersMapper = (tradeLaneFilters: TradeLaneFiltersDto) => [
  {
    name: `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCompany')}`,
    label: 'Company',
    options: tradeLaneFilters?.tradeLaneCompany.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCityHub')}`,
    label: 'City Hub',
    options: tradeLaneFilters?.tradeLaneCityHub.map((value) => ({
      value,
      label: value,
    })),
  },
  {
    name: `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCountry')}`,
    label: 'Country',
    options: tradeLaneFilters?.tradeLaneCountry.map((value) => ({
      value,
      label: value,
    })),
  },
];

export const filterAccordionsMapper = (filterData: FiltersDto): FilterAccordionProps[] => [
  {
    label: 'Date',
    name: nameofFilters('dateFilters'),
    icon: IconCalender,
    filtersDetails: dateFiltersMapper(filterData?.dateFilters),
  },
  {
    label: 'Trade Lane',
    name: nameofFilters('tradeLaneFilters'),
    icon: IconTradeLane,
    filtersDetails: tradeLaneFiltersMapper(filterData?.tradeLaneFilters),
  },
  {
    label: 'Shipments Details',
    name: nameofFilters('shipmentFilters'),
    icon: IconShipment,
    filtersDetails: shipmentFiltersMapper(filterData?.shipmentFilters),
  },
  {
    label: 'Billing Reference',
    name: nameofFilters('billingFilters'),
    icon: IconBilling,
    filtersDetails: billingFiltersMapper(filterData?.billingFilters),
  },
];

export const filterAccordionsMapperForPA = (filterData: FiltersDto): FilterAccordionProps[] => [
  {
    label: 'Date',
    name: nameofFilters('dateFilters'),
    icon: IconCalender,
    filtersDetails: dateFiltersMapper(filterData?.dateFilters),
  },
  {
    label: 'Trade Lane',
    name: nameofFilters('tradeLaneFilters'),
    icon: IconTradeLane,
    filtersDetails: tradeLaneFiltersMapper(filterData?.tradeLaneFilters),
  },
  {
    label: 'Shipments Details',
    name: nameofFilters('shipmentFilters'),
    icon: IconShipment,
    filtersDetails: shipmentFiltersMapper(filterData?.shipmentFilters),
  },
];

export const filterChartGranularityMapper = (granularity: string) => {
  switch (granularity) {
    case 'YEARLY':
      return `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('years')}`;
    case 'QUARTERLY':
      return `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('quarters')}`;
    case 'MONTHLY':
      return `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('months')}`;
    case 'WEEKLY':
      return `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('weeks')}`;
    case 'DAILY':
      return `${nameof('filters')}.${nameofFilters('dateFilters')}.${nameofFactory<DateFiltersDto>()('days')}`;
    case 'COMPANY':
      return `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCompany')}`;
    case 'CITY_HUB':
      return `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCityHub')}`;
    case 'COUNTRY':
      return `${nameof('filters')}.${nameofFilters('tradeLaneFilters')}.${nameofFactory<TradeLaneFiltersDto>()('tradeLaneCountry')}`;
    case 'SERVICE_PROVIDER_NAME':
      return `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('serviceProviderCode')}`;
    case 'MODALITY':
      return `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('modality')}`;
    case 'VEHICLE_ID':
      return `${nameof('filters')}.${nameofFilters('shipmentFilters')}.${nameofFactory<ShipmentFiltersDto>()('vehicleID')}`;
    default:
      return null;
  }
};

export const countUnemptyField = (currentFilter: object) =>
  currentFilter ? Object.keys(currentFilter)?.filter((key) => currentFilter[key] !== undefined && currentFilter[key]?.length > 0).length : 0;

export const granularityMapper = (granularity: AnalyticSearchRequestDto['granularity']) => {
  switch (granularity) {
    case 'CHARGE_DOMAIN':
      return 'Charge Domain';
    case 'CITY_HUB':
      return 'City Hub';
    case 'COMPANY':
      return 'Company';
    case 'LEG_TYPE':
      return 'Leg Type';
    case 'COUNTRY':
      return 'Country';
    case 'DAILY':
      return 'Daily';
    case 'MONTHLY':
      return 'Monthly';
    case 'QUARTERLY':
      return 'Quarterly';
    case 'YEARLY':
      return 'Yearly';
    case 'WEEKLY':
      return 'Weekly';
    case 'SERVICE_PROVIDER_NAME':
      return 'Service Provider';
    case 'MODALITY':
      return 'Modality';
    case 'VEHICLE_ID':
      return 'Vehicle Id';
    default:
      return '';
  }
};
