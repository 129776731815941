import { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Button from 'src/presentations/components/atom/button';
import { cn } from 'src/utils/utility';
import Base from './Base';
import Styles from './Styles';

interface IMultipleSelectProps {
  name: string;
  data?: Array<{ value: any; label: string }>;
  placeholder?: string;
  rootClassName?: string;
  className?: string;
  formHook?: {
    watch: (name: string) => any;
  };
  disabled?: boolean;
  onChange?: (value: any) => void;
  onReset?: () => void;
  placeholderClassName?: string;
  isShowSearch?: boolean;
}

const MultipleSelect: React.FC<IMultipleSelectProps> = (props) => {
  const { name, data, placeholder, rootClassName, className, formHook, disabled, onChange, onReset, placeholderClassName, isShowSearch } = props;
  const [isFocused, setIsFocused] = useState(false);

  const { watch } = formHook;
  const currentValues = watch(name);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [dropdownPos, setDropdownPos] = useState(null); // Initialize as null
  const [keyword, setKeyword] = useState<string>('');
  const elementRef = useRef<HTMLDivElement>();
  const dropdownRef = useRef<HTMLDivElement>();
  const currentValuesRef = useRef([]);

  const optionItems = useMemo(() => {
    if (keyword && keyword !== '') {
      return data?.filter((item) => item?.label?.toLowerCase()?.includes(keyword?.toLowerCase()));
    }
    return data;
  }, [data, keyword]);

  useEffect(() => {
    if (isOpenOptions) {
      const winHeight = window.innerHeight;
      const ddRect = dropdownRef.current.getBoundingClientRect();
      const rootRect = elementRef.current.getBoundingClientRect();
      const ddMaxBottom = rootRect?.y + rootRect?.height + ddRect?.height + 30;

      if (ddMaxBottom > winHeight) {
        setDropdownPos('bottom');
      } else {
        setDropdownPos('top');
      }
    }
  }, [isOpenOptions]);

  useEffect(() => {
    if (currentValues && Array.isArray(currentValues) && currentValues.length > 0) {
      currentValuesRef.current = currentValues;
      setSelectedItems(currentValues);
    } else {
      currentValuesRef.current = [];
      setSelectedItems([]);
    }
  }, [currentValues]);

  const onBodyClick = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setIsOpenOptions(false);
      setSelectedItems(currentValuesRef.current);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', onBodyClick, true);

    return function cleanup() {
      document.body.removeEventListener('click', onBodyClick, true);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isOpenOptions && dropdownPos === null) {
        const winHeight = window.innerHeight;
        const ddRect = dropdownRef.current.getBoundingClientRect();
        const rootRect = elementRef.current.getBoundingClientRect();
        const ddMaxBottom = rootRect?.y + rootRect?.height + ddRect?.height + 30;

        if (ddMaxBottom > winHeight) {
          setDropdownPos('bottom');
        } else {
          setDropdownPos('top');
        }
      }
    };

    handleResize(); // Call it initially

    // Update the position when window is resized
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpenOptions, dropdownPos]);

  const getSelectedLabel = () => {
    let label = '';
    if (selectedItems?.length > 0) {
      const filteredData = data?.filter((check) => selectedItems.includes(check?.value))?.map((check) => check?.label);

      label = filteredData?.join(', ');
    }
    return label;
  };

  const applyValues = watch(name);

  return (
    <Base
      name={name}
      className={rootClassName ?? ''}
      formHook={formHook}
      disabled={disabled}
      onRender={(field, inputStyles, isError) => (
        <div ref={elementRef} className={cn('relative min-w-[190px]', className)} data-state={isOpenOptions ? 'open' : 'close'}>
          <div
            className={cn([
              className,
              isOpenOptions
                ? {
                    'text-blue-grey-10 border border-green-50 rounded-8': true,
                  }
                : {
                    'border rounded-8': true,
                    [Styles.stateError]: isError,
                    [Styles.stateDisabled]: !isError && disabled,
                    'border-neutral-40': !isError && !disabled,
                  },
            ])}
          >
            <button
              type='button'
              className={`w-full text-left flex items-center px-10 py-6 text-sm 
                ${isOpenOptions ? 'text-blue_grey-80' : 'text-grey-40'} 
                ${disabled ? 'cursor-not-allowed text-neutral-40' : ''}`}
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  if (isOpenOptions) {
                    setSelectedItems(currentValuesRef.current);
                  }
                  setIsOpenOptions(!isOpenOptions);
                }
              }}
            >
              <div
                className={cn(
                  placeholderClassName,
                  'flex items-center absolute left-14 transition-all duration-300 origin-top-left',
                  isOpenOptions || selectedItems.length > 0 ? 'text-blue_grey-50 -top-[7px] scale-[0.8] px-4 bg-white' : 'text-grey-40 top-10'
                )}
              >
                {placeholder}
              </div>

              {selectedItems?.length > 0 && (
                <div className={cn('text-sm truncate max-w-[178px]', { 'text-neutral-40': disabled, 'text-blue_grey-80': !disabled })}>
                  {getSelectedLabel()}
                </div>
              )}
              <span className='ml-auto pl-16'>
                <ExpandMoreIcon sx={{ color: disabled ? '#BFBFBF' : '#475B69' }} />
              </span>
            </button>
            {onReset && applyValues?.length > 0 && !disabled && (
              <button aria-label='close' type='button' onClick={onReset} className='absolute top-6 right-36'>
                <CancelRoundedIcon className='' sx={{ fontSize: 18, color: '#BFBFBF' }} />
              </button>
            )}
          </div>
          {isOpenOptions && !disabled && (
            <div
              ref={dropdownRef}
              className={`
                  absolute left-0 right-0 z-20 ${dropdownPos === 'bottom' ? 'bottom-full' : 'top-full'}
                  transition-opacity duration-300 ease-in-out ${isOpenOptions ? 'opacity-100' : 'opacity-0'}
                `}
            >
              <div
                className={`
                  mt-2 pt-8 mb-0 bg-white border border-neutral-40 rounded-8
                `}
              >
                {isShowSearch && (
                  <div className='pb-8 px-8 relative'>
                    <input
                      type='text'
                      placeholder='Search'
                      name='keyword'
                      value={keyword}
                      className={clsx(
                        'py-8 pl-8 pr-36 w-full rounded-8 text-12 border outline-none',
                        keyword !== '' || isFocused ? 'border-primary' : 'border-neutral-40'
                      )}
                      onChange={(evt) => setKeyword(evt.target.value)}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    />
                    {keyword !== '' && (
                      <button aria-label='close' type='button' className='absolute top-4 right-16' onClick={() => setKeyword('')}>
                        <CloseIcon sx={{ color: '#bfbfbf', fontSize: '18px' }} />
                      </button>
                    )}
                  </div>
                )}
                <ul className='overflow-auto max-h-[180px]'>
                  {optionItems.map((item) => {
                    const found = selectedItems.some((check) => check === item.value);

                    return (
                      <li key={`option-${name}-${item.value}`} data-state={found ? 'active' : 'inactive'}>
                        <button
                          type='button'
                          className='w-full text-left py-14 px-12 text-14 leading-16 ease-in duration-400 flex items-center hover:bg-neutral-20'
                          onClick={() => {
                            const newItems = found ? selectedItems.filter((check) => check !== item.value) : [...selectedItems, item.value];
                            setSelectedItems(newItems);
                          }}
                        >
                          <div
                            className={cn(
                              'relative w-18 h-18 rounded-4 border-2 border-blue_grey-50 flex items-center justify-center mr-6',
                              'transition-all duration-500 cursor-pointer',
                              found ? 'bg-green-50 border-50' : 'bg-transparent border-blue_grey-50'
                            )}
                          >
                            <CheckIcon style={{ color: '#ffffff', fontSize: 14, opacity: found ? 1 : 0 }} />
                          </div>
                          <span className='text-sm'>{item.label}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>

                <div className='py-12 p-16 border-t border-neutral-20'>
                  <Button
                    type='button'
                    isFull
                    onClick={() => {
                      setIsOpenOptions(false);

                      if (onChange) {
                        onChange(selectedItems);
                      }
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

MultipleSelect.defaultProps = {
  data: [],
  placeholder: '',
  rootClassName: '',
  className: '',
  placeholderClassName: '',
  disabled: false,
  onChange: undefined,
};

export default MultipleSelect;
