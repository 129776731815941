import { ReactNode, useEffect, useState } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, DatePickerElementProps, FieldValues, useFormContext, useFormError, useWatch } from 'react-hook-form-mui';
import { formatDateString } from 'src/utils/date';
import { FieldLabel } from '../FieldLabel';
import { useDefaultProps } from '../useDefaultProps';

export interface IRangeValue {
  start?: Date;
  end?: Date;
}

export interface IRhfMuiDateRangePickerProps<TFieldValues extends FieldValues = FieldValues> extends DatePickerElementProps<TFieldValues, any> {
  label?: ReactNode;
  placeholder?: any;
  size?: 'small' | 'medium';
}

export const RhfMuiDateRangePicker = <TFieldValues extends FieldValues>(props: IRhfMuiDateRangePickerProps<TFieldValues>) => {
  const {
    label,
    onChange,
    isReadOnly,
    parseError,
    name,
    required,
    validation = {},
    inputProps,
    control,
    slotProps,
    textReadOnly,
    size = 'medium',
    format = 'dd/MM/yyyy',
    ...rest
  } = { ...props, ...useDefaultProps(props) };
  const { setValue, trigger } = useFormContext();
  const value = useWatch({ name });
  const [rangeValues, setRangeValues] = useState<IRangeValue>(value);

  useEffect(() => {
    try {
      if (!rangeValues?.end || !rangeValues?.start) {
        setValue(name, null);
        return;
      }
      const start = Date.parse(rangeValues?.start.toString());
      const end = Date.parse(rangeValues?.end.toString());
      if (Number.isNaN(start) || Number.isNaN(end)) {
        setValue(name, null);
        return;
      }

      setValue(name as string, rangeValues);
      trigger(name);
    } catch (error) {
      setValue(name, null);
    }
  }, [name, rangeValues, setValue, trigger]);

  /**
   * onChangeStart
   */
  const onChangeStart = (value: Date) => {
    setRangeValues((current) => {
      // Check if value changed is before the current end date
      const isBeforeEnd = value < current?.end;

      return {
        ...current,
        start: value,
        end: isBeforeEnd ? current.end : null,
      };
    });
  };

  /**
   * onChangeEnd
   */
  const onChangeEnd = (value: Date) => {
    setRangeValues((current) => ({
      ...current,
      end: value,
    }));
  };

  const errorMsgFn = useFormError();

  if (required && !validation.required) {
    validation.required = 'This field is required';
  }

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => {
        if (field?.value && typeof field?.value === 'string') {
          field.value = new Date(field.value) as any;
        }
        const hasStart = rangeValues?.start;
        const hasEnd = rangeValues?.end;
        const hasStartButNotEnd = !hasEnd && hasStart;
        const hasEndButNotStart = !hasStart && hasEnd;

        const invalidRange = hasStartButNotEnd ?? hasEndButNotStart;
        const customErrorMessage = invalidRange && 'Invalid date range';

        // helperText by props
        const defaultHelperText = inputProps?.helperText ?? rest.helperText;
        // if error occurs get the error message
        const helperText = error ? errorMsgFn(error) : defaultHelperText;

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FieldLabel label={label}>
              <div className='flex justify-between'>
                <div className='w-1/2 me-10'>
                  {!isReadOnly && (
                    <DatePicker
                      className='w-full'
                      {...rest}
                      {...field}
                      format={format}
                      value={rangeValues?.start ?? null}
                      label='Start Date'
                      ref={(r) => {
                        field.ref(r?.querySelector('input'));
                      }}
                      onChange={onChangeStart}
                      slotProps={{
                        ...slotProps,
                        textField: {
                          size,
                          required,
                          error: !!error,
                        },
                      }}
                    />
                  )}
                  {isReadOnly && (
                    <div className=''>
                      <div className='text-blue_grey-80 text-12 mb-8'>Start Date</div>
                      <div>{formatDateString(value?.start?.toDateString())}</div>
                    </div>
                  )}
                </div>
                <div className='w-1/2 ms-10'>
                  {!isReadOnly && (
                    <DatePicker
                      minDate={rangeValues?.start ?? null}
                      className='w-full'
                      {...rest}
                      {...field}
                      format={format}
                      disabled={!rangeValues?.start}
                      value={rangeValues?.end ?? null}
                      label='End Date'
                      ref={(r) => {
                        field.ref(r?.querySelector('input'));
                      }}
                      onChange={onChangeEnd}
                      slotProps={{
                        ...slotProps,
                        textField: {
                          size,
                          required,
                          error: !!error,
                        },
                      }}
                    />
                  )}
                  {isReadOnly && (
                    <div className=''>
                      <div className='text-blue_grey-80 text-12 mb-8'>End Date</div>
                      <div>{formatDateString(value?.end?.toDateString())}</div>
                    </div>
                  )}
                </div>
              </div>
              <p className='text-red-40 text-12'>{!!error && (customErrorMessage || helperText)}</p>
            </FieldLabel>
          </LocalizationProvider>
        );
      }}
    />
  );
};
