import { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { VolumetricSortDto } from 'src/generated/services/TFinancialApi';
import { useVolumetricStats, useVolumetricTable } from 'src/use-cases/performance/usePerformance';
import { OrderMethod } from 'src/types';
import { SimpleFormatNumber } from 'src/utils/utility';
import { TransportMedium } from 'src/presentations/components/molecules/card/types';

import { SectionDetail } from './SectionDetail';
import { CardStatDetail } from '../Stat/CardStatDetail';
import { getAnalyticsLink, getVolumetricStatsList } from '../helpers';
import { ISectionSavingsProps } from './types';
import { TableTab } from '../../analytics/table/useTable';

const tableColumns = [
  {
    enableEditing: false,
    accessorKey: 'month',
    header: 'MONTH',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => SimpleFormatNumber(row?.totalAmount, row?.totalAmount?.currency === 'JPY'),
    header: 'TOTAL AMOUNT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'grossWeight',
    accessorFn: (row) => SimpleFormatNumber(row.grossWeight),
    header: 'GROSS WEIGHT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'chargeableWeight',
    accessorFn: (row) => SimpleFormatNumber(row.chargeableWeight),
    header: 'CHARG. WEIGHT',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'volume',
    accessorFn: (row) => SimpleFormatNumber(row.volume),
    header: 'VOLUME',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'co2Emissions',
    accessorFn: (row) => SimpleFormatNumber(row.co2Emissions),
    header: 'CO₂e EMISSIONS',
    size: 20,
  },
  {
    enableEditing: false,
    accessorKey: 'shipmentsCount',
    header: 'NO. SHIPMENTS',
    size: 20,
  },
  {
    enableEditing: false,
    accessorFn: (row) => `${row?.audited}%`,
    header: 'AUDITED',
    size: 20,
  },
];

const getSortField = (method: string): VolumetricSortDto['field'] => {
  switch (method) {
    case 'TOTAL AMOUNT':
      return 'VOLUMETRIC_TOTAL_AMOUNT';
    case 'grossWeight':
      return 'GROSS_WEIGHT';
    case 'chargeableWeight':
      return 'CHARGEABLE_WEIGHT';
    case 'co2Emissions':
      return 'CO2_EMISSIONS';
    case 'shipmentsCount':
      return 'VOLUMETRIC_SHIPMENTS_COUNT';
    default:
      return method?.toUpperCase() as VolumetricSortDto['field'];
  }
};

export const SectionVolumetric: FC<ISectionSavingsProps> = ({ provider, modality }) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const [tablePage, setTablePage] = useState(1);
  const [orderBy, setOrderBy] = useState<VolumetricSortDto['field']>('VOLUMETRIC_MONTH');
  const [orderMethod, setOrderMethod] = useState<VolumetricSortDto['direction']>('DESC');

  const { data: dataStats, isLoading: isLoadingStats } = useVolumetricStats({
    filterConfigs: formValues,
    provider,
    modality,
  });

  const { data: dataTable, isLoading: isLoadingTable } = useVolumetricTable({
    filterConfigs: formValues,
    provider,
    modality,
    apiConfigs: {
      page: {
        pageNumber: tablePage,
        pageSize: 25,
      },
      sort: {
        field: getSortField(orderBy),
        direction: orderMethod,
      },
    },
  });

  const tableItems = dataTable?.volumetricMonthWiseData || [];

  const { currencyReference } = formValues;

  const statsList = useMemo(
    () =>
      getVolumetricStatsList(dataStats, {
        unitCurrency: currencyReference,
      }),
    [dataStats, currencyReference]
  );

  if (tableItems?.length < 1) {
    return null;
  }

  return (
    <SectionDetail
      title={provider}
      tabName={TableTab.VOLUMETRIC}
      transportMedium={modality as TransportMedium}
      linkReport={getAnalyticsLink(formValues)}
      linkAudit={`/dashboard?provider=${provider}&modality=${modality}`}
      tableProps={{
        totalItems: dataTable?.total || 0,
        data: tableItems,
        columns: tableColumns,
        orderBy,
        orderMethod: orderMethod?.toLowerCase() as OrderMethod,
        onRenderMassActions: () => <></>,
        onChangePage: (page) => setTablePage(page),
        onSorting: (method: string, fieldId: string) => {
          setOrderBy(fieldId as VolumetricSortDto['field']);
          setOrderMethod(method?.toUpperCase() as VolumetricSortDto['direction']);
        },
        params: {
          page: tablePage,
        },
        isLoading: isLoadingTable || isLoadingStats,
      }}
    >
      <div className='grid grid-cols-5 grid-rows-1 gap-24'>
        {statsList.map((item) => (
          <CardStatDetail key={`card-stat-2-${item?.type}`} type={item?.type} values={item?.values} />
        ))}
      </div>
    </SectionDetail>
  );
};
