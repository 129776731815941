import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { nameofFactory } from 'src/utils/nameofFactory';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import InputMultipleSelect from 'src/presentations/components/atom/form/input/Multiselect';
import { useSearchParams } from 'react-router-dom';
import { FilterAccordionProps, countUnemptyField } from '../analyticsMapper';
import { FilterNum } from './FilterNum';

export const CustomFilterAccordion = ({ filtersDetails, icon, name, label, onChangeFilterNew }: FilterAccordionProps) => {
  const [searchParams] = useSearchParams();
  const provider = searchParams.get('provider');
  const modality = searchParams.get('modality');
  const formHook = useFormContext();
  const { watch, setValue } = formHook;
  const dateReference = watch(nameofFactory<AnalyticSearchRequestDto>()('dateReference'));
  const currentFilter = watch(`${nameofFactory<AnalyticSearchRequestDto>()('filters')}.${name}`);
  const formValues = watch();

  const FilterNumDisplay = useCallback(() => {
    const filterNum = countUnemptyField(currentFilter);
    return <FilterNum filterNum={filterNum} />;
  }, [currentFilter]);

  return (
    <Accordion
      sx={{
        borderRadius: '16px',
        boxShadow: 'none',
        borderColor: '#DFDFDF',
        borderWidth: '0.0625rem',
        '&:before': {
          height: '0',
        },
        '&:first-of-type': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className='flex gap-8 items-center'>
          <img src={icon} alt={label} />
          <p>{label}</p>
          {label.startsWith('Date') && (
            <div className='flex gap-[10px]'>
              <Divider className='bg-neutral-20' orientation='vertical' flexItem />
              <p className='text-[#9F9F9F] text-12'>{dateReference}</p>
            </div>
          )}
          <FilterNumDisplay />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='flex flex-col gap-[16px]'>
          {filtersDetails?.map((option) => (
            <div className='relative w-full' key={`filter-option-${option?.name}`}>
              <InputMultipleSelect
                name={option?.name}
                formHook={formHook}
                data={option?.options || []}
                placeholder={option?.label}
                onReset={() => {
                  setValue(option?.name, null);
                  if (onChangeFilterNew) {
                    onChangeFilterNew(formValues);
                  }
                }}
                onChange={(value) => {
                  setValue(option?.name, value);
                  if (onChangeFilterNew) {
                    onChangeFilterNew(formValues);
                  }
                }}
                disabled={!!option?.options?.find((o) => o.value === provider || o.value === modality)}
                isShowSearch
              />
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
