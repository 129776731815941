import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/ReactToastify.css';

const Notification: FC = () => (
  <ToastContainer
    style={{ width: 'fit-content', minWidth: '250px' }}
    toastClassName='bg-black flex items-center min-h-10 py-0 rounded-md overflow-hidden cursor-pointer px-15'
    bodyClassName='flex items-center text-white p-3 '
    limit={2}
  />
);
export default Notification;
