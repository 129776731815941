import IconDollar from 'src/assets/images/icons/analytic-dollar.svg';
import IconTruck from 'src/assets/images/icons/analytic-truck.svg';
import IconReceipt from 'src/assets/images/icons/analytic-receipt.svg';
import IconChargeLines from 'src/assets/images/icons/analytic-charge-lines.svg';
import IconCloud from 'src/assets/images/icons/analytic-cloud.svg';
import IconWeight from 'src/assets/images/icons/analytic-weight.svg';
import IconWeightBlue from 'src/assets/images/icons/analytic-weight-blue.svg';
import IconBox from 'src/assets/images/icons/analytic-box.svg';
import { CardStatType, CardStatDefault } from './types';

export const useCardOptions = (type: CardStatType, defaultValue?: CardStatDefault) => {
  switch (type) {
    case CardStatType.AMOUNT:
    case CardStatType.ASKED_AMOUNT:
      return {
        icon: IconDollar,
        label: 'Amount',
        unit: defaultValue?.unitCurrency ?? 'USD',
        styles: 'border-orange-30 bg-[#FEF3E5]',
      };
    case CardStatType.SHIPMENTS:
      return {
        icon: IconTruck,
        label: 'Shipments',
        unit: null,
        styles: 'border-[#702A91] bg-[#EEE5F2]',
      };
    case CardStatType.INVOICES:
      return {
        icon: IconReceipt,
        label: 'Invoices',
        unit: null,
        styles: 'border-[#CB6FF6] bg-[#F9EEFE]',
      };
    case CardStatType.CHARGER_LINES:
      return {
        icon: IconChargeLines,
        label: 'Charge Lines',
        unit: null,
        styles: 'border-[#D921B0] bg-[#FAE4F6]',
      };
    case CardStatType.GROSS_WEIGHT:
      return {
        icon: IconWeightBlue,
        label: 'Gross Weight',
        unit: 'kg',
        styles: 'border-[#4476F6] bg-[#E9EFFE]',
      };
    case CardStatType.CHARGEABLE_WEIGHT:
      return {
        icon: IconWeight,
        label: 'Chargeable Weight',
        unit: 'kg',
        styles: 'border-[#495C8C] bg-[#E9EBF1]',
      };
    case CardStatType.VOLUME:
      return {
        icon: IconBox,
        label: 'Volume',
        unit: (
          <>
            m<sup>3</sup>
          </>
        ),
        styles: 'border-[#92BEFF] bg-[#EBF3FF]',
      };
    case CardStatType.CO2E_EMISSIONS:
      return {
        icon: IconCloud,
        label: (
          <span>
            CO<sub>2</sub>e Emissions
          </span>
        ),
        unit: 't',
        styles: 'border-[#00992B] bg-[#E0F3E6]',
      };
    default:
      return null;
  }
};
