import { CardSelector, ICardAnalyticItemProps } from 'src/presentations/components/molecules/card/CardSelector';
import { CardAnalytic } from 'src/presentations/components/molecules/card/CardAnalytic';
import { CardId } from './types';
import { GeneralOverviewLoader } from './Loader';

export interface GeneralOverviewProps {
  selectedCards: ICardAnalyticItemProps[];
  setSelectedCards: React.Dispatch<React.SetStateAction<ICardAnalyticItemProps[]>>;
  items: ICardAnalyticItemProps[];
  isLoading: boolean;
}

export const GeneralOverview = ({ selectedCards, setSelectedCards, items, isLoading }: GeneralOverviewProps) => {
  const onCardSelected = (selectedId: CardId) => {
    setSelectedCards((currVal) => {
      const selectedItem = items.find((item) => item.id === selectedId);

      const isExists = currVal.find((item) => item.id === selectedId);
      if (isExists) {
        return [...currVal.filter((item) => item.id !== selectedId)];
      }

      if (new Set([...currVal, selectedItem].map((item) => item.unit)).size <= 3) {
        return [...currVal, selectedItem];
      }

      return [...currVal];
    });
  };

  return (
    <div className='pb-24 px-24 border-b border-neutral-20'>
      {isLoading ? (
        <GeneralOverviewLoader />
      ) : (
        <CardSelector onCardSelected={onCardSelected} selectedCards={selectedCards}>
          <div className='flex items-center -mx-8 gap-y-16'>
            {items?.map((item) => (
              <div key={`card-analytic-${item.id}`} className='w-1/2 px-8'>
                <CardAnalytic item={item} isLoading={isLoading} />
              </div>
            ))}
          </div>
        </CardSelector>
      )}
    </div>
  );
};
