import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import IUser, { COGNITO_GROUP, USER_TYPE } from './user';

interface ILoginParamsEmail {
  type: USER_TYPE.EMAIL;
  email: string;
  password: string;
}

interface ILoginParamsAzure {
  type: USER_TYPE.AZURE;
}

export type ILoginParams = ILoginParamsEmail | ILoginParamsAzure;

export interface AuthState {
  isLoading: boolean;
  user: IUser | null;
  error: SerializedError | string;
}

const getConitoUser = async (): Promise<IUser> => {
  let cognitoUser: IUser = null;

  const [session, user] = await Promise.all([fetchAuthSession(), getCurrentUser()]);

  const cognitoGroups = session.tokens.accessToken.payload['cognito:groups'] as COGNITO_GROUP[];

  cognitoUser = {
    id: user.userId,
    email: user.signInDetails.loginId,
    roles: cognitoGroups,
  };

  return cognitoUser;
};

const getUser = createAsyncThunk<// Return type of the payload creator
IUser>('auth/getUser', async () => getConitoUser());

// Calls the amplify signOut method and then set the user to null
const logout = createAsyncThunk<// Return type of the payload creator
IUser>('auth/logout', async () => {
  await signOut();
  window.location.reload();
  return null;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    error: '',
    user: null,
  } as AuthState,
  reducers: {},

  extraReducers: (builder) => {
    // Get User
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
    });
    // Logout
    builder.addCase(logout.rejected, (state, action: any) => {
      state.error = action.error;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
    });
  },
});

// Export Actions
export const logoutActionCreator = logout;
export const getUserActionCreator = getUser;

const authReducer = authSlice.reducer;

export default authReducer;
