import '@fontsource/roboto';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { PropsWithChildren } from 'react';

type TetrixxCommonColors = (typeof palette)['common'];

declare module '@mui/material/styles/createPalette' {
  interface CommonColors extends TetrixxCommonColors {}
}

const palette = {
  primary: {
    main: '#00992B',
  },
  common: {
    black: '#00000',
    greyDefault: '#597282',
    white: '#fff',
    green: 'rgba(0, 153, 43, 0.12)',
    veryLightGrey: '#F5F6FA',
    borderGreen: '#00992B',
    transportBorder: '#9F9F9F',
    transportTruck: '#F79824',
    transportShip: '#30C5FF',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
      },
    },
    ...{
      MuiBox: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto, Arial, sans-serif',
          },
        },
      },
    },
  },
});

const disputeTableTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
          borderWidth: 1,
          borderColor: '#DFDFDF',
          borderStyle: 'solid',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: '500',
          borderColor: '#DFDFDF',
          '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            padding: '0.75rem',
            '&:first-of-type': {
              borderLeft: 'none',
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: '500',
          backgroundColor: '#DFDFDF',
          '& .MuiTableRow-root': {
            backgroundColor: '#F5F6FA',
          },
          '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            '&:first-of-type': {
              borderLeft: 'none',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #DFDFDF',
          boxShadow: 'none',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root.Mui-checked': {
            color: '#00992B',
          },
        },
      },
    },
  },
});

// eslint-disable-next-line react-refresh/only-export-components
export { disputeTableTheme };

const MuiTheme: React.FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default MuiTheme;
