import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CreateIcon from 'src/assets/images/icons/create-report.svg';

interface ICreateProps {
  onCreate: () => void;
  isChosen: boolean;
}

export const CreateChartReportOption = ({ onCreate, isChosen }: ICreateProps) => {
  const { register } = useFormContext();
  return (
    <button onClick={onCreate} className={`p-[20px] border-[1px] ${isChosen ? 'border-[#00992B] shadow-md' : 'border-[#DFDFDF]'} rounded-[20px]`}>
      <div className='flex gap-[20px] items-center'>
        <img src={CreateIcon} alt='CreateIcon' />
        <div className='text-left'>
          <h1 className='font-extrabold'>Save as new report</h1>
          <p className='text-[#808080]'>You will save the update as a new report</p>
        </div>
      </div>

      {isChosen && (
        <div className='mt-[24px] flex flex-col gap-[16px]'>
          <TextField name='' className='w-full' label='New Report Name' size='small' {...register('name')} />
          <TextField className='w-full' label='Description' size='small' {...register('description')} />
        </div>
      )}
    </button>
  );
};
