import Skeleton from '@mui/material/Skeleton';

export const LoaderSavings = () => (
  <div className='flex items-center gap-24 px-32'>
    {[1, 2, 3, 4].map((index) => (
      <div key={`loader-${index}`} className='w-1/4 bg-white border border-b-2 border-neutral-20 rounded-8 p-16 flex flex-col items-start'>
        <Skeleton animation='wave' height={48} width='50%' />
        <div className='flex gap-16 w-full'>
          <div className='flex flex-col w-full'>
            <div className='border-b border-neutral-20 py-12'>
              <Skeleton animation='wave' height={28} width='100%' />
              <Skeleton animation='wave' height={14} width='100%' />
            </div>
            <div className='py-12'>
              <Skeleton animation='wave' height={28} width='100%' />
              <Skeleton animation='wave' height={14} width='100%' />
            </div>
          </div>
          <div className='flex flex-col w-full'>
            <div className='border-b border-neutral-20 py-12'>
              <Skeleton animation='wave' height={28} width='100%' />
              <Skeleton animation='wave' height={14} width='100%' />
            </div>
            <div className='py-12'>
              <Skeleton animation='wave' height={28} width='100%' />
              <Skeleton animation='wave' height={14} width='100%' />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);
