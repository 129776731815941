import { styled } from '@mui/material/styles';
import * as Yup from 'yup';

import { ChartResponseDto } from 'src/generated/services/TFinancialApi';
import Modal from 'src/presentations/components/molecules/modal';
import { FormContainer } from 'src/presentations/components/organisms/forms/FormContainer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FapRefForm } from './FapRefForm';

interface IFapRefModalProps {
  selectedItems: any[];
  chartData: ChartResponseDto;
  isShow: boolean;
  onClose(): void;
  onSuccess(values: IFapRefValues): void;
}

interface IFapRefValues {
  fap_ref_number: string;
}

const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '540px',
    borderRadius: '16px',
  },
});
const validationSchema = Yup.object().shape({
  fap_ref_number: Yup.string().required('Please input FAP Ref.'),
});

export const FapRefModal: React.FC<IFapRefModalProps> = (props) => {
  const { selectedItems, chartData, isShow, onClose, onSuccess } = props;

  const defaultValues = {
    fap_ref_number: '',
  };

  const formContext = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: IFapRefValues) => {
    onSuccess(data);
  };

  return (
    <StyledModal open={isShow} onClose={onClose} fullWidth maxWidth='xs'>
      <FormContainer formContext={formContext} FormProps={{ className: 'h-full flex flex-col' }}>
        <FapRefForm currency={chartData?.meta?.currency} onClose={onClose} selectedItems={selectedItems} onSubmit={formContext.handleSubmit(onSubmit)} />
      </FormContainer>
    </StyledModal>
  );
};
