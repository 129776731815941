import { StepIconProps } from '@mui/material';
import clsx from 'clsx';

export const NoContractStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;

  return (
    <span
      className={clsx('flex items-center justify-center w-36 h-36 text-20 font-bold rounded-full', {
        'bg-primary/25 text-primary': completed,
        'bg-primary text-white': active,
        'bg-white text-primary border border-neutral-20': !active && !completed,
      })}
    >
      {icon}
    </span>
  );
};
