import Skeleton from '@mui/material/Skeleton';

export const GeneralOverviewLoader = () => (
  <div className='flex items-center justify-between gap-16'>
    {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
      <div
        key={`loader-${index}`}
        className='w-full bg-white border border-b-2 border-neutral-20 rounded-8 px-[16px] py-[12px] flex flex-col items-start gap-y-10'
      >
        <Skeleton animation='wave' variant='circular' width={44} height={44} />
        <div className='flex-1 flex flex-col gap-4 w-full'>
          <Skeleton animation='wave' height={32} width='40%' />
          <Skeleton animation='wave' height={14} width='80%' />
        </div>
      </div>
    ))}
  </div>
);
