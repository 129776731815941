/**
 * Template Main
 */
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const TemplateMain: React.FC = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', background: '#f5f6fa' }}>
    <Outlet />
  </Box>
);

export default TemplateMain;
