import { useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const StyledIcon = styled(CachedIcon)(() => ({
  animation: `${rotate} 2s linear`,
}));

interface IRefreshButtonProps {
  onClick: () => void;
}

export const RefreshButton: React.FC<IRefreshButtonProps> = ({ onClick }) => {
  const [showIcon, setShowIcon] = useState(true);

  const handleClick = () => {
    setShowIcon(false);

    setTimeout(() => {
      setShowIcon(true);
      onClick();
    }, 10);
  };

  return (
    <IconButton sx={{ color: '#101010' }} onClick={handleClick}>
      {showIcon && <StyledIcon />}
    </IconButton>
  );
};
