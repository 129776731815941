import React from 'react';
import { ICustomIconProps } from './types';

const IconTruck: React.FC<ICustomIconProps> = ({ size = 20, color = '#F79824' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.666 2.43317V9.35817C11.666 10.2082 10.9743 10.8998 10.1243 10.8998H2.49935C2.04102 10.8998 1.66602 10.5248 1.66602 10.0665V4.7415C1.66602 3.0415 3.04102 1.6665 4.74102 1.6665H10.891C11.3243 1.6665 11.666 2.00817 11.666 2.43317Z'
      fill={color}
    />
    <path
      d='M17.916 12.9165C18.1493 12.9165 18.3327 13.0998 18.3327 13.3332V14.1665C18.3327 15.5498 17.216 16.6665 15.8327 16.6665C15.8327 15.2915 14.7077 14.1665 13.3327 14.1665C11.9577 14.1665 10.8327 15.2915 10.8327 16.6665H9.16602C9.16602 15.2915 8.04102 14.1665 6.66602 14.1665C5.29102 14.1665 4.16602 15.2915 4.16602 16.6665C2.78268 16.6665 1.66602 15.5498 1.66602 14.1665V12.4998C1.66602 12.0415 2.04102 11.6665 2.49935 11.6665H10.416C11.566 11.6665 12.4993 10.7332 12.4993 9.58317V4.99984C12.4993 4.5415 12.8743 4.1665 13.3327 4.1665H14.0327C14.6327 4.1665 15.1827 4.4915 15.4827 5.00817L16.016 5.9415C16.091 6.07484 15.991 6.24984 15.8327 6.24984C14.6827 6.24984 13.7493 7.18317 13.7493 8.33317V10.8332C13.7493 11.9832 14.6827 12.9165 15.8327 12.9165H17.916Z'
      fill={color}
    />
    <path
      d='M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z'
      fill={color}
    />
    <path
      d='M13.3327 18.3333C14.2532 18.3333 14.9993 17.5871 14.9993 16.6667C14.9993 15.7462 14.2532 15 13.3327 15C12.4122 15 11.666 15.7462 11.666 16.6667C11.666 17.5871 12.4122 18.3333 13.3327 18.3333Z'
      fill={color}
    />
    <path
      d='M18.3333 10.4417V11.6667H15.8333C15.375 11.6667 15 11.2917 15 10.8333V8.33333C15 7.875 15.375 7.5 15.8333 7.5H16.9083L18.1167 9.61667C18.2583 9.86667 18.3333 10.15 18.3333 10.4417Z'
      fill={color}
    />
  </svg>
);

export default IconTruck;
