import Skeleton from '@mui/material/Skeleton';

export const LandingLoader = () => (
  <div className='flex items-center gap-24'>
    {[1, 2, 3, 4].map((index) => (
      <div key={`loader-${index}`} className='w-1/4 bg-white border border-b-2 border-neutral-20 rounded-8'>
        <div className='px-12 pt-20 pb-30 border-b border-neutral-20 flex gap-12'>
          <Skeleton animation='wave' variant='circular' width={36} height={36} />
          <div className='flex-1 flex flex-col gap-6'>
            <Skeleton animation='wave' height={12} width='60%' />
            <Skeleton animation='wave' height={10} width='80%' />
          </div>
        </div>
        <div className='p-12'>
          <Skeleton animation='wave' height={18} width='80%' />
        </div>
      </div>
    ))}
  </div>
);
