import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import IconReceipt from 'src/assets/images/icons/receipt-landing.svg';
import IconChart from 'src/assets/images/icons/analytic-landing.svg';
import { FC } from 'react';
import { TopButton } from './TopButton';
import { ISectionDetailTopActionsProps } from './types';

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  minHeight: 0,
  color: '#606060',
  backgroundColor: 'transparent',
  borderRadius: '0!important',

  '&.Mui-expanded': {
    margin: 0,
    minHeight: 0,
    borderRadius: '8px 8px 0 0!important',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    border: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#606060',

    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },

    '& .MuiSvgIcon-root': {
      color: '#606060',
    },
  },
});

export const SectionDetailTopActions: FC<ISectionDetailTopActionsProps> = ({ linkAudit, linkReport }) => (
  <div className='ml-auto flex items-center gap-16'>
    <TopButton title='Report & Analytics' icon={IconChart} path={linkReport ?? ''} />
    <TopButton title='Audit' icon={IconReceipt} path={linkAudit ?? ''} />

    <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.8rem', color: '#ffffff' }} />} />
  </div>
);
